import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitle } from "../../../../components/ShipTitle";
import UserAppBar from "../../common/UserAppBar";
import UserShipLogTransitTabs from "./UserShipLogTransitTabs";
import { mainColor } from "../../../../assets/colors/color";

import { useLocation } from "react-router-dom";
import {
  ImportanceAtom,
  MemoAtom,
  PortGauge1Atom,
  PortGauge2Atom,
  PortGauge3Atom,
  PortGauge4Atom,
  PortGauge5Atom,
  PortGaugeToTalAtom,
  PortKL1Atom,
  PortKL2Atom,
  PortKL3Atom,
  PortKL4Atom,
  PortKL5Atom,
  PortKLTotalAtom,
  ShippingExtortionAtom,
  ShippingSafetyAtom,
  StartBoardGauge1Atom,
  StartBoardGauge2Atom,
  StartBoardGauge3Atom,
  StartBoardGauge4Atom,
  StartBoardGauge5Atom,
  StartBoardGaugeToTalAtom,
  StartBoardKL1Atom,
  StartBoardKL2Atom,
  StartBoardKL3Atom,
  StartBoardKL4Atom,
  StartBoardKL5Atom,
  StartBoardKLTotalAtom,
  TemperatureAtom,
  TmtNumberAtom,
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
  noteTAtom,
} from "../../../../recoil/OptionsAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ModalSubUser1, ModalSubUser2 } from "../../../../components/ModalSub";
import { useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { useEffect } from "react";
import {
  TDBerthingDateAtom1,
  TDBerthingTimeAtom1,
  TDHoseConnectionAtom1,
  TDHoseConnectionDateAtom1,
  TDHoseSeparationAtom1,
  TDHoseSeparationDateAtom1,
  TDIanTimeAtom1,
  TDIanTimeDateAtom1,
  TDStartWorkAtom1,
  TDStartWorkDateAtom1,
  TDWorkCompleteAtom1,
  TDWorkCompleteDateAtom1,
  TUBerthingTimeAtom1,
  TUBerthingTimeDateAtom1,
  TUHoseConnectionAtom1,
  TUHoseConnectionDateAtom1,
  TUHoseSeparationAtom1,
  TUHoseSeparationDateAtom1,
  TUIanTimeAtom1,
  TUIanTimeDateAtom1,
  TUStartWorkAtom1,
  TUStartWorkDateAtom1,
  TUWorkCompleteAtom1,
  TUWorkCompleteDateAtom1,
} from "../../../../recoil/TimeAtom";
import { getShipData } from "../../../../recoil/GetData";
import { loginAuthenticationUser } from "../../../../hooks/loginAuthentication";

//작업일지 운송
function UserShipLogTransit() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyDetailId = shipInfo.journeyDetailId;
  const shipName = shipInfo.shipName;
  console.log(journeyDetailId);

  const shipApi = useRecoilValue(
    getShipData(`floating?detailJourneyId=${journeyDetailId}`)
  );
  console.log(shipApi);
  const shipApi2 = useRecoilValue(
    getShipData(`floating/shipping?journeyDetailId=${journeyDetailId}`)
  );
  console.log(shipApi2);
  const shipApi3 = useRecoilValue(
    getShipData(`floating/unloading?journeyDetailId=${journeyDetailId}`)
  );
  console.log(shipApi3);
  console.log(journeyDetailId);

  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  //단계
  const status = shipApi.status;

  // 수량 입력값 받아오기
  const amount = shipApi.amount;
  //BL값 받아오기
  const [amountBL, setamountBL] = useRecoilState(TmtNumberAtom);

  //접안시간 적하
  const [TDBerthingDate, setTDBerthingDate] =
    useRecoilState(TDBerthingDateAtom1);
  const [TDBerthingTime, setTDBerthingTime] =
    useRecoilState(TDBerthingTimeAtom1);

  const shippingDockingAt = `${TDBerthingDate.year}-${TDBerthingDate.month}-${TDBerthingDate.day}T${TDBerthingTime.hh}:${TDBerthingTime.mm}:00`;

  //호스연결 적하
  const [TDHoseConnectionDate, setTDHoseConnectionDate] = useRecoilState(
    TDHoseConnectionDateAtom1
  );
  const [TDHoseConnection, setTDHoseConnection] = useRecoilState(
    TDHoseConnectionAtom1
  );

  const shippingHoseConnectionAt = `${TDHoseConnectionDate.year}-${TDHoseConnectionDate.month}-${TDHoseConnectionDate.day}T${TDHoseConnection.hh}:${TDHoseConnection.mm}:00`;

  //작업시작 적하
  const [TDStartWorkDate, setTDStartWorkDate] =
    useRecoilState(TDStartWorkDateAtom1);
  const [TDStartWork, setTDStartWork] = useRecoilState(TDStartWorkAtom1);

  const shippingJobStartAt = `${TDStartWorkDate.year}-${TDStartWorkDate.month}-${TDStartWorkDate.day}T${TDStartWork.hh}:${TDStartWork.mm}:00`;

  console.log(shippingJobStartAt);
  console.log(TDStartWorkDate);
  console.log(TDStartWork);

  //작업완료 적하
  const [TDWorkCompleteDate, setTDWorkCompleteDate] = useRecoilState(
    TDWorkCompleteDateAtom1
  );
  const [TDWorkComplete, setTDWorkComplete] =
    useRecoilState(TDWorkCompleteAtom1);

  const shippingJobDoneAt = `${TDWorkCompleteDate.year}-${TDWorkCompleteDate.month}-${TDWorkCompleteDate.day}T${TDWorkComplete.hh}:${TDWorkComplete.mm}:00`;

  console.log(shippingJobDoneAt);
  //호스분리 적하
  const [TDHoseSeparationDate, setTDHoseSeparationDate] = useRecoilState(
    TDHoseSeparationDateAtom1
  );
  const [TDHoseSeparation, setTDHoseSeparation] = useRecoilState(
    TDHoseSeparationAtom1
  );

  const shippingHoseDisconnectionAt = `${TDHoseSeparationDate.year}-${TDHoseSeparationDate.month}-${TDHoseSeparationDate.day}T${TDHoseSeparation.hh}:${TDHoseSeparation.mm}:00`;

  //이안시간 적하
  const [TDIanTimeDate, setTDIanTimeDate] = useRecoilState(TDIanTimeDateAtom1);
  const [TDIanTime, setTDIanTime] = useRecoilState(TDIanTimeAtom1);

  const shippingDepartureAt = `${TDIanTimeDate.year}-${TDIanTimeDate.month}-${TDIanTimeDate.day}T${TDIanTime.hh}:${TDIanTime.mm}:00`;

  //접안시간 양하
  const [TUBerthingTimeDate, setTUBerthingTimeDate] = useRecoilState(
    TUBerthingTimeDateAtom1
  );
  const [TUBerthingTime, setTUBerthingTime] =
    useRecoilState(TUBerthingTimeAtom1);

  const unloadingDockingAt = `${TUBerthingTimeDate.year}-${TUBerthingTimeDate.month}-${TUBerthingTimeDate.day}T${TUBerthingTime.hh}:${TUBerthingTime.mm}:00`;

  //호스연결 양하
  const [TUHoseConnectionDate, setTUHoseConnectionDate] = useRecoilState(
    TUHoseConnectionDateAtom1
  );
  const [TUHoseConnection, setTUHoseConnection] = useRecoilState(
    TUHoseConnectionAtom1
  );

  const unloadingHoseConnectionAt = `${TUHoseConnectionDate.year}-${TUHoseConnectionDate.month}-${TUHoseConnectionDate.day}T${TUHoseConnection.hh}:${TUHoseConnection.mm}:00`;

  //작업시작 양하
  const [TUStartWorkDate, setTUStartWorkDate] =
    useRecoilState(TUStartWorkDateAtom1);
  const [TUStartWork, setTUStartWork] = useRecoilState(TUStartWorkAtom1);

  const unloadingJobStartAt = `${TUStartWorkDate.year}-${TUStartWorkDate.month}-${TUStartWorkDate.day}T${TUStartWork.hh}:${TUStartWork.mm}:00`;

  //작업완료 양하
  const [TUWorkCompleteDate, setTUWorkCompleteDate] = useRecoilState(
    TUWorkCompleteDateAtom1
  );
  const [TUWorkComplete, setTUWorkComplete] =
    useRecoilState(TUWorkCompleteAtom1);

  const unloadingJobDoneAt = `${TUWorkCompleteDate.year}-${TUWorkCompleteDate.month}-${TUWorkCompleteDate.day}T${TUWorkComplete.hh}:${TUWorkComplete.mm}:00`;

  //호스분리 양하
  const [TUHoseSeparationDate, setTUHoseSeparationDate] = useRecoilState(
    TUHoseSeparationDateAtom1
  );
  const [TUHoseSeparation, setTUHoseSeparation] = useRecoilState(
    TUHoseSeparationAtom1
  );

  const unloadingHoseDisconnectionAt = `${TUHoseSeparationDate.year}-${TUHoseSeparationDate.month}-${TUHoseSeparationDate.day}T${TUHoseSeparation.hh}:${TUHoseSeparation.mm}:00`;

  //이안시간 양하
  const [TUIanTimeDate, setTUIanTimeDate] = useRecoilState(TUIanTimeDateAtom1);
  const [TUIanTime, setTUIanTime] = useRecoilState(TUIanTimeAtom1);

  const unloadingDepartureAt = `${TUIanTimeDate.year}-${TUIanTimeDate.month}-${TUIanTimeDate.day}T${TUIanTime.hh}:${TUIanTime.mm}:00`;

  const shippingRateOfH = Number(
    (
      amount /
      ((parseISO(shippingJobDoneAt).getTime() -
        parseISO(shippingJobStartAt).getTime()) /
        (60 * 60 * 1000))
    ).toFixed(2)
  );

  const unloadingRateOfH = Number(
    (
      amount /
      ((parseISO(unloadingJobDoneAt).getTime() -
        parseISO(unloadingJobStartAt).getTime()) /
        (60 * 60 * 1000))
    ).toFixed(2)
  );

  //안전관리
  const [shippingSafety, setShippingSafetyet] =
    useRecoilState(ShippingSafetyAtom);
  const [unloadingSafety, setUnloadingSafety] =
    useRecoilState(UnloadingSafetyAtom);

  //강취
  const [shippingLinePull, setShippingLinePull] = useRecoilState(
    ShippingExtortionAtom
  );
  const [unloadingLinePull, setUnloadingLinePull] = useRecoilState(
    UnloadingExtortionAtom
  );

  const [portGauge1, setPortGauge1] = useRecoilState(PortGauge1Atom);
  const [portGauge2, setPortGauge2] = useRecoilState(PortGauge2Atom);
  const [portGauge3, setPortGauge3] = useRecoilState(PortGauge3Atom);
  const [portGauge4, setPortGauge4] = useRecoilState(PortGauge4Atom);
  const [portGauge5, setPortGauge5] = useRecoilState(PortGauge5Atom);
  const [portGaugeTotal, setPortGaugeTotal] =
    useRecoilState(PortGaugeToTalAtom);
  const [portKL1, setPortKL1] = useRecoilState(PortKL1Atom);
  const [portKL2, setPortKL2] = useRecoilState(PortKL2Atom);
  const [portKL3, setPortKL3] = useRecoilState(PortKL3Atom);
  const [portKL4, setPortKL4] = useRecoilState(PortKL4Atom);
  const [portKL5, setPortKL5] = useRecoilState(PortKL5Atom);
  const [portKLTotal, setPortKLTotal] = useRecoilState(PortKLTotalAtom);

  const [startBoardGauge1, setStartBoardGauge1] =
    useRecoilState(StartBoardGauge1Atom);
  const [startBoardGauge2, setStartBoardGauge2] =
    useRecoilState(StartBoardGauge2Atom);
  const [startBoardGauge3, setStartBoardGauge3] =
    useRecoilState(StartBoardGauge3Atom);
  const [startBoardGauge4, setStartBoardGauge4] =
    useRecoilState(StartBoardGauge4Atom);
  const [startBoardGauge5, setStartBoardGauge5] =
    useRecoilState(StartBoardGauge5Atom);
  const [startBoardGaugeToTal, setStartBoardGaugeToTal] = useRecoilState(
    StartBoardGaugeToTalAtom
  );

  const [startBoardKL1, setStartBoardKL1] = useRecoilState(StartBoardKL1Atom);
  const [startBoardKL2, setStartBoardKL2] = useRecoilState(StartBoardKL2Atom);
  const [startBoardKL3, setStartBoardKL3] = useRecoilState(StartBoardKL3Atom);
  const [startBoardKL4, setStartBoardKL4] = useRecoilState(StartBoardKL4Atom);
  const [startBoardKL5, setStartBoardKL5] = useRecoilState(StartBoardKL5Atom);
  const [startBoardKLTotal, setStartBoardKLTotal] = useRecoilState(
    StartBoardKLTotalAtom
  );

  //온도

  const [temperature, setTemperature] = useRecoilState(TemperatureAtom);
  //비중
  const [importance, setImportance] = useRecoilState(ImportanceAtom);
  //메모
  const [memo, setMemo] = useRecoilState(MemoAtom);

  //비고
  const note = useRecoilValue(noteTAtom);

  const date = new Date();
  const defultDate = format(date, "yyyy-MM-dd") + "T--:--:00";

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const data = {
    detailJourneyId: journeyDetailId,
    bl: amountBL,
    shippingDockingAt:
      shippingDockingAt === defultDate ? null : shippingDockingAt, //접안시간
    shippingHoseConnectionAt:
      shippingHoseConnectionAt === defultDate ? null : shippingHoseConnectionAt, //호스연결
    shippingJobStartAt:
      shippingJobStartAt === defultDate ? null : shippingJobStartAt, //작업시작
    shippingJobDoneAt:
      shippingJobDoneAt === defultDate ? null : shippingJobDoneAt, //작업완료
    shippingHoseDisconnectionAt:
      shippingHoseDisconnectionAt === defultDate
        ? null
        : shippingHoseDisconnectionAt, //호스분리
    shippingDepartureAt:
      shippingDepartureAt === defultDate ? null : shippingDepartureAt, //이안시간
    shippingRateOfH:
      shippingRateOfH === Infinity || NaN ? null : shippingRateOfH,
    shippingSafety: shippingSafety,
    shippingLineHandling: shippingLinePull, //강취
    unloadingDockingAt:
      unloadingDockingAt === defultDate ? null : unloadingDockingAt,
    unloadingHoseConnectionAt:
      unloadingHoseConnectionAt === defultDate
        ? null
        : unloadingHoseConnectionAt,
    unloadingJobStartAt:
      unloadingJobStartAt === defultDate ? null : unloadingJobStartAt,
    unloadingJobDoneAt:
      unloadingJobDoneAt === defultDate ? null : unloadingJobDoneAt,
    unloadingHoseDisconnectionAt:
      unloadingHoseDisconnectionAt === defultDate
        ? null
        : unloadingHoseDisconnectionAt,
    unloadingDepartureAt:
      unloadingDepartureAt === defultDate ? null : unloadingDepartureAt,

    unloadingRateOfH:
      unloadingRateOfH === Infinity || NaN ? null : unloadingRateOfH, // //H/RATE
    unloadingSafety: unloadingSafety, //안전 관리
    unloadingLineHandling: unloadingLinePull, //강취
    portGauge1: portGauge1,
    portGauge2: portGauge2,
    portGauge3: portGauge3,
    portGauge4: portGauge4,
    portGauge5: portGauge5,
    portKL1: portKL1,
    portKL2: portKL2,
    portKL3: portKL3,
    portKL4: portKL4,
    portKL5: portKL5,
    portGaugeTotal: portGaugeTotal,
    portKlTotal: portKLTotal,

    starBoardGauge1: startBoardGauge1,
    starBoardGauge2: startBoardGauge2,
    starBoardGauge3: startBoardGauge3,
    starBoardGauge4: startBoardGauge4,
    starBoardGauge5: startBoardGauge5,
    starBoardKL1: startBoardKL1,
    starBoardKL2: startBoardKL2,
    starBoardKL3: startBoardKL3,
    starBoardKL4: startBoardKL4,
    starBoardKL5: startBoardKL5,
    starBoardGaugeTotal: startBoardGaugeToTal,
    starBoardKlTotal: startBoardKLTotal,

    temperature: temperature,
    specificGravity: importance,
    memo: memo,
    note: note,
  };

  console.log(data);
  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/floating/update/user", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/user-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch(() => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  useEffect(() => {
    if (shipApi2.dockingAt !== null) {
      setTDBerthingDate({
        year: format(parseISO(shipApi2.dockingAt), "yyyy"),
        month: format(parseISO(shipApi2.dockingAt), "MM"),
        day: format(parseISO(shipApi2.dockingAt), "dd"),
      });
      setTDBerthingTime({
        hh: format(parseISO(shipApi2.dockingAt), "HH"),
        mm: format(parseISO(shipApi2.dockingAt), "mm"),
      });
    }
    if (shipApi2.hoseConnectionAt !== null) {
      //적하 호스연결
      setTDHoseConnectionDate({
        year: format(parseISO(shipApi2.hoseConnectionAt), "yyyy"),
        month: format(parseISO(shipApi2.hoseConnectionAt), "MM"),
        day: format(parseISO(shipApi2.hoseConnectionAt), "dd"),
      });
      setTDHoseConnection({
        hh: format(parseISO(shipApi2.hoseConnectionAt), "HH"),
        mm: format(parseISO(shipApi2.hoseConnectionAt), "mm"),
      });
    }
    if (shipApi2.jobStartAt !== null) {
      //적하 작업시작
      setTDStartWorkDate({
        year: format(parseISO(shipApi2.jobStartAt), "yyyy"),
        month: format(parseISO(shipApi2.jobStartAt), "MM"),
        day: format(parseISO(shipApi2.jobStartAt), "dd"),
      });
      setTDStartWork({
        hh: format(parseISO(shipApi2.jobStartAt), "HH"),
        mm: format(parseISO(shipApi2.jobStartAt), "mm"),
      });
    }
    if (shipApi2.jobDoneAt !== null) {
      //적하 작업완료
      setTDWorkCompleteDate({
        year: format(parseISO(shipApi2.jobDoneAt), "yyyy"),
        month: format(parseISO(shipApi2.jobDoneAt), "MM"),
        day: format(parseISO(shipApi2.jobDoneAt), "dd"),
      });
      setTDWorkComplete({
        hh: format(parseISO(shipApi2.jobDoneAt), "HH"),
        mm: format(parseISO(shipApi2.jobDoneAt), "mm"),
      });
    }
    if (shipApi2.hoseDisconnectionAt !== null) {
      //적하 호스분리
      setTDHoseSeparationDate({
        year: format(parseISO(shipApi2.hoseDisconnectionAt), "yyyy"),
        month: format(parseISO(shipApi2.hoseDisconnectionAt), "MM"),
        day: format(parseISO(shipApi2.hoseDisconnectionAt), "dd"),
      });
      setTDHoseSeparation({
        hh: format(parseISO(shipApi2.hoseDisconnectionAt), "HH"),
        mm: format(parseISO(shipApi2.hoseDisconnectionAt), "mm"),
      });
    }
    if (shipApi2.departureAt !== null) {
      //적하 이안시간
      setTDIanTimeDate({
        year: format(parseISO(shipApi2.departureAt), "yyyy"),
        month: format(parseISO(shipApi2.departureAt), "MM"),
        day: format(parseISO(shipApi2.departureAt), "dd"),
      });
      setTDIanTime({
        hh: format(parseISO(shipApi2.departureAt), "HH"),
        mm: format(parseISO(shipApi2.departureAt), "mm"),
      });
    }
    if (shipApi3.dockingAt !== null) {
      //양하 접안시간
      setTUBerthingTimeDate({
        year: format(parseISO(shipApi3.dockingAt), "yyyy"),
        month: format(parseISO(shipApi3.dockingAt), "MM"),
        day: format(parseISO(shipApi3.dockingAt), "dd"),
      });
      setTUBerthingTime({
        hh: format(parseISO(shipApi3.dockingAt), "HH"),
        mm: format(parseISO(shipApi3.dockingAt), "mm"),
      });
    }
    if (shipApi3.hoseConnectionAt !== null) {
      //양하 호스연결
      setTUHoseConnectionDate({
        year: format(parseISO(shipApi3.hoseConnectionAt), "yyyy"),
        month: format(parseISO(shipApi3.hoseConnectionAt), "MM"),
        day: format(parseISO(shipApi3.hoseConnectionAt), "dd"),
      });
      setTUHoseConnection({
        hh: format(parseISO(shipApi3.hoseConnectionAt), "HH"),
        mm: format(parseISO(shipApi3.hoseConnectionAt), "mm"),
      });
    }
    if (shipApi3.jobStartAt !== null) {
      //양하 작업시작
      setTUStartWorkDate({
        year: format(parseISO(shipApi3.jobStartAt), "yyyy"),
        month: format(parseISO(shipApi3.jobStartAt), "MM"),
        day: format(parseISO(shipApi3.jobStartAt), "dd"),
      });
      setTUStartWork({
        hh: format(parseISO(shipApi3.jobStartAt), "HH"),
        mm: format(parseISO(shipApi3.jobStartAt), "mm"),
      });
    }
    if (shipApi3.jobDoneAt !== null) {
      //양하 작업완료
      setTUWorkCompleteDate({
        year: format(parseISO(shipApi3.jobDoneAt), "yyyy"),
        month: format(parseISO(shipApi3.jobDoneAt), "MM"),
        day: format(parseISO(shipApi3.jobDoneAt), "dd"),
      });
      setTUWorkComplete({
        hh: format(parseISO(shipApi3.jobDoneAt), "HH"),
        mm: format(parseISO(shipApi3.jobDoneAt), "mm"),
      });
    }
    if (shipApi3.hoseDisconnectionAt !== null) {
      //양하 호스분리
      setTUHoseSeparationDate({
        year: format(parseISO(shipApi3.hoseDisconnectionAt), "yyyy"),
        month: format(parseISO(shipApi3.hoseDisconnectionAt), "MM"),
        day: format(parseISO(shipApi3.hoseDisconnectionAt), "dd"),
      });
      setTUHoseSeparation({
        hh: format(parseISO(shipApi3.hoseDisconnectionAt), "HH"),
        mm: format(parseISO(shipApi3.hoseDisconnectionAt), "mm"),
      });
    }
    if (shipApi3.departureAt !== null) {
      //양하 이안시간
      setTUIanTimeDate({
        year: format(parseISO(shipApi3.departureAt), "yyyy"),
        month: format(parseISO(shipApi3.departureAt), "MM"),
        day: format(parseISO(shipApi3.departureAt), "dd"),
      });
      setTUIanTime({
        hh: format(parseISO(shipApi3.departureAt), "HH"),
        mm: format(parseISO(shipApi3.departureAt), "mm"),
      });
    }
    if (shipApi.gaugeReport !== null) {
      setPortGauge1(shipApi.gaugeReport.portGauge1);
      setPortGauge2(shipApi.gaugeReport.portGauge2);
      setPortGauge3(shipApi.gaugeReport.portGauge3);
      setPortGauge4(shipApi.gaugeReport.portGauge4);
      setPortGauge5(shipApi.gaugeReport.portGauge5);
      setPortGaugeTotal(shipApi.gaugeReport.portGaugeTotal);
      setPortKL1(shipApi.gaugeReport.portKL1);
      setPortKL2(shipApi.gaugeReport.portKL2);
      setPortKL3(shipApi.gaugeReport.portKL3);
      setPortKL4(shipApi.gaugeReport.portKL4);
      setPortKL5(shipApi.gaugeReport.portKL5);
      setPortKLTotal(shipApi.gaugeReport.portKlTotal);
      setStartBoardGauge1(shipApi.gaugeReport.starBoardGauge1);
      setStartBoardGauge2(shipApi.gaugeReport.starBoardGauge2);
      setStartBoardGauge3(shipApi.gaugeReport.starBoardGauge3);
      setStartBoardGauge4(shipApi.gaugeReport.starBoardGauge4);
      setStartBoardGauge5(shipApi.gaugeReport.starBoardGauge5);
      setStartBoardGaugeToTal(shipApi.gaugeReport.starBoardGaugeTotal);
      setStartBoardKL1(shipApi.gaugeReport.starBoardKL1);
      setStartBoardKL2(shipApi.gaugeReport.starBoardKL2);
      setStartBoardKL3(shipApi.gaugeReport.starBoardKL3);
      setStartBoardKL4(shipApi.gaugeReport.starBoardKL4);
      setStartBoardKL5(shipApi.gaugeReport.starBoardKL5);
      setStartBoardKLTotal(shipApi.gaugeReport.starBoardKlTotal);
      setTemperature(shipApi.gaugeReport.temperature);
      setImportance(shipApi.gaugeReport.specificGravity);
      setMemo(shipApi.gaugeReport.memo);
    }
    if (shipApi.amountBL !== null) {
      setamountBL(shipApi.amountBL);
    }
    if (shipApi2.safety !== null) {
      setShippingSafetyet(shipApi2.safety);
    }
    if (shipApi3.safety !== null) {
      setUnloadingSafety(shipApi3.safety);
    }
    if (shipApi2.lineHandling !== null) {
      setShippingLinePull(shipApi2.lineHandling);
    }
    if (shipApi3.lineHandling !== null) {
      setUnloadingLinePull(shipApi3.lineHandling);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          Step={status}
          ShipName={shipName}
          Date={format(parseISO(shipApi2.workStartAt), "yyyy / MM / dd")}
          color={`${mainColor}`}
        />
        <p>
          <span style={{ color: "red" }}>"확정"</span>단계에서만 작성이
          가능합니다.
        </p>
        <UserShipLogTransitTabs
          unloadingRateOfH={unloadingRateOfH}
          shippingRateOfH={shippingRateOfH}
          shipApi={shipApi}
          shipApi2={shipApi2}
          shipApi3={shipApi3}
        />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            disabled={status === "계획" || status === "완료" ? true : false}
            onClick={() => {
              setmodal1(!modal1);
            }}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>

        {modal1 ? (
          <ModalSubUser1
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? <ModalSubUser2 setmodal1={setmodal2} /> : null}
      </Container>
    </div>
  );
}

export default UserShipLogTransit;
