// 선원정보
import React from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { Container } from "@mui/material";
import { mainColor } from "../../../assets/colors/color";
import Person from "../../../assets/images/PersonIcon.svg";

import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { crewInfoList1 } from "../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function CrewInformation() {
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const crewInfoList = useRecoilValue(crewInfoList1);
  console.log("crewInformation", crewInfoList);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "20px" }}>선원 정보</h1>

        <div
          style={{
            width: "100%",
            borderTop: `4px solid ${mainColor}`,
            borderBottom: `4px solid ${mainColor}`,
          }}
        >
          {crewInfoList ? (
            <div>
              {crewInfoList.map((crewInfo) => (
                <Link
                  key={crewInfo.userId}
                  to={`/manager-crew-information-details?id=${crewInfo.userId}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "16px 16px",
                    borderBottom: `1px solid ${mainColor}`,
                    gap: 16,
                  }}
                  className="link-hover"
                >
                  <img
                    src={Person}
                    alt="사람 이미지 아이콘"
                    style={{ padding: 15 }}
                  />
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 6 }}
                  >
                    <div>{`이름 : ${crewInfo.name}`}</div>
                    <div>{`아이디 : ${crewInfo.uid}`}</div>
                    <div>{`번호 : ${crewInfo.phoneNumber}`}</div>
                    <div>{`입사일자 : ${crewInfo.dateOfEntry}`}</div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <p style={{ textAlign: "center", padding: "16px" }}>
              승무원 정보 로드 중...
            </p>
          )}
        </div>
      </Container>
    </div>
  );
}

export default CrewInformation;
