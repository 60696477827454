import firebase from "firebase/app";
import "firebase/messaging";

if (firebase.messaging.isSupported()) {
  var firebaseConfig = {
    apiKey: "AIzaSyB_sImWrGoCzBrPfBjORkkyiEhjXlb0Bmw",
    authDomain: "hyodong-d2ff0.firebaseapp.com",
    projectId: "hyodong-d2ff0",
    storageBucket: "hyodong-d2ff0.appspot.com",
    messagingSenderId: "496725071042",
    appId: "1:496725071042:web:b03212030ace3f422d175b",
    measurementId: "G-1EKHHLNYK4",
  };

  firebase.initializeApp(firebaseConfig);
  var messaging = firebase.messaging();

  console.log("적용됨");
} else {
  console.log("적용안됨");
}

export function requestPermission() {
  void Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      messaging
        .getToken({
          vapidKey:
            "BL2lUMUO3HXyQKMbHcGJc9ZUz2vAhPfjZwAKkCMrvKbpog3JC_5dGrZOrx8UQ5z_Ks5lgTD8_sjQ7W_KMytLqJQ",
        })
        .then((token) => {
          console.log(`푸시 토큰 발급 완료 : ${token}`);
          sessionStorage.setItem("firebaseToken", JSON.stringify(token));
        })
        .catch((err) => {
          console.log("푸시 토큰 가져오는 중에 에러 발생");
        });
    } else if (permission === "denied") {
      console.log("푸시 권한 차단");
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      console.log("메시지 수신: ", payload);
      resolve(payload);
    });
  });
