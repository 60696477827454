import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";

function ImageCard({ ImageUrl, Title, LinkUrl }) {
  return (
    <Link to={LinkUrl} style={{ textDecoration: "none", width: "100%" }}>
      <Card sx={{ width: "100%", bgcolor: "#3A5F96" }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="160"
            image={ImageUrl}
            alt="green iguana"
          />
          <CardContent>
            <Typography
              color="#fff"
              gutterBottom
              component="div"
              sx={{
                textAlign: "center",
                fontSize: "17px",
                fontWeight: 700,
              }}
            >
              {Title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export default ImageCard;
