import React from "react";
import { Container } from "@mui/material";
import { Row2 } from "../../../components/Row";
import { mainColor } from "../../../assets/colors/color";
import { ShipTitleNext } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";

import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getShipFlightLog } from "../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

//선박 운항 일지 운송 차항정보
function ShipFlightLogTransitNext() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const shipApi = useRecoilValue(
    getShipFlightLog(
      `shipId=${shipInfo.shipId}&jobType=${shipInfo.jobType}&shippingWorkStartAt=${shipInfo.shippingWorkStartAt}`
    )
  );
  console.log(shipApi);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitleNext ShipName={"1효동케미"} color={`${mainColor}`} />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.nextVoyageNumber} />
          <Row2 Tilte={"일자"} Desc={shipApi.nextWorkDate} />
          <Row2 Tilte={"화주"} Desc={shipApi.nextCharterer} />
          <Row2 Tilte={"공급자"} Desc={shipApi.nextSupplier} />
          <Row2 Tilte={"수급자"} Desc={shipApi.nextRecipient} />
          <Row2 Tilte={"중개사"} Desc={shipApi.nextBroker} />
          <Row2 Tilte={"화물명"} Desc={shipApi.nextCargo} />
          <Row2 Tilte={"수량"} Desc={shipApi.nextAmount} />
          <Row2 Tilte={"적하부두"} Desc={shipApi.nextShippingBerthingPlace} />
          <Row2 Tilte={"양하부두"} Desc={shipApi.nextUnloadingBerthingPlace} />
          {/* <Row2 Tilte={"특기사항"} Desc={"- "} /> */}
        </div>
      </Container>
    </div>
  );
}

export default ShipFlightLogTransitNext;
