import React from "react";

import { format, isSameDay, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  disabledColor,
  mainColor,
  whiteColor,
} from "../../../assets/colors/color";
import axios from "axios";
import { Button } from "@mui/material";

function ListItem({ data }) {
  console.log(data.shippingWorkStartAt);
  console.log(data);

  const startDate = parseISO(data.shippingWorkStartAt);

  const time = format(startDate, "HH:mm");

  const state = {
    ship: data.ship, //배이름
    shipType: data.shipType, //배 타입
    jobType: data.jobType,
    status: data.status, //배 단계
    voyageNumber: data.voyageNumber, //항차
    idJourney: data.idJourney,
    journeyDetailId: data.journeyDetailId,
    shippingWorkStartAt: data.shippingWorkStartAt,
  };

  const navigate = useNavigate();

  const navigateToPurchase = () => {
    data.shipType === "운송"
      ? navigate("/manager-ship-flight-log-transit", {
          state: state,
        })
      : navigate(null);
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          fontWeight: 700,
          paddingTop: 10,
          paddingRight: 10,
          borderRight: `2px solid ${disabledColor}`,
        }}
      >
        {time}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginLeft: 10,
        }}
      >
        <div
          onClick={navigateToPurchase}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
            width: "100%",
            padding: "15px 20px",
            backgroundColor: `${mainColor}`,
            borderRadius: "10px 10px 0 0",
            color: `${whiteColor}`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontWeight: 500, fontSize: 17 }}>{data.ship}</h1>
            <span
              style={{
                borderRadius: 999,
                border: `2px solid`,
                padding: 5,
                fontWeight: 700,
                fontSize: 15,
              }}
            >
              {data.status}
            </span>
          </div>
          <p style={{ marginTop: 6 }}>
            <span>항차 : </span>
            <span>{data.voyageNumber}</span>
          </p>
          <p>
            <span>구분 : </span>
            <span>{`${data.shipType} / ${data.jobType}`}</span>
          </p>
          <p>
            <span>용선주 : </span>
            <span>{data.charterer}</span>
          </p>
          <p>
            <span>화물 : </span>
            <span>{data.cargo}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

function TabListBoxT({ edata, selectedDate }) {
  const SelectSameDate =
    edata &&
    edata.filter((data) =>
      isSameDay(parseISO(data.shippingWorkStartAt), selectedDate)
    );

  const data123 = format(selectedDate, "yyyy-MM-dd");

  //엑셀파일 다운로드
  const handelXlsx = () => {
    axios({
      method: "GET",
      url: `/api/excel/generate/operation?date=${data123}`,
      responseType: "blob",
      // 구글링 해보니 responseType을 꼭 추가하라고 했다.
    })
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data123}_운항일지운송.xls`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert(
          "귀하의 계정 ID 또는 비밀번호가 일치하지 않습니다. 다시 시도해 주세요."
        );
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      {SelectSameDate && SelectSameDate.length > 0 ? (
        <Button
          onClick={handelXlsx}
          variant="contained"
          color="secondary"
          sx={{
            fontSize: 15,
            width: "100%",
            padding: "6px",
            flex: 1,
            borderRadius: 0,
            fontFamily: ["Noto Sans KR", "sans-serif"],
          }}
        >
          선박 운항계획표 엑셀 다운로드
        </Button>
      ) : null}

      {SelectSameDate && SelectSameDate.length > 0 ? (
        SelectSameDate.map((data, idx) => (
          <ListItem data={data} key={data.idJourney} />
        ))
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          운송 일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default TabListBoxT;
