import dayjs from "dayjs";
import { atom } from "recoil";
import { v1 } from "uuid";

const now = dayjs(new Date());
//적하 시작날짜
export const TDroppingStartDateAtom = atom({
  key: `TDroppingStartDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
//적하 완료 날짜
export const TDroppingEndDateAtom = atom({
  key: `TDroppingEndDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
//양하 시작날짜
export const TUnloadingStartDateAtom = atom({
  key: `TUnloadingStartDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
//양하 완료 날짜
export const TUnloadingEndDateAtom = atom({
  key: `TUnloadingEndDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});

//기선 시작 날짜
export const SteamerStartDateAtom = atom({
  key: `SteamerStartDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
//기선 완료날짜
export const SteamerEndDateAtom = atom({
  key: `SteamerEndDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
//순찰 시작날짜
export const PatrolStartDateAtom = atom({
  key: `PatrolStartDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});

//순찰 완료 날짜
export const PatrolEndDateAtom = atom({
  key: `PatrolEndDateAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});

//검사일정
export const InspectionScheduleAtom = atom({
  key: `InspectionScheduleAtom/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});

//시간

//적하 시작 시간
export const TDroppingStartTimeAtom = atom({
  key: `TDroppingStartTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});
//적하 완료 날짜
export const TDroppingEndTimeAtom = atom({
  key: `TDroppingEndTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});

//양하 시작 시간
export const TUnloadingStartTimeAtom = atom({
  key: `TUnloadingStartTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});
//양하 완료 시간
export const TUnloadingEndTimeAtom = atom({
  key: `TUnloadingEndTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});

//기선 시작 시간
export const SteamerStartTimeAtom = atom({
  key: `SteamerStartTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});
//기선 완료시간
export const SteamerEndTimeAtom = atom({
  key: `SteamerEndTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});
//순찰 시작시간
export const PatrolStartTimeAtom = atom({
  key: `PatrolStartTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});

//순찰 완료 시간
export const PatrolEndTimeAtom = atom({
  key: `PatrolEndTimeAtom/${v1()}`,
  default: {
    hh: "00",
    mm: "00",
  },
});
