import React from "react";
import { Row3 } from "../../../../components/Row";
import { mainColor, whiteColor } from "../../../../assets/colors/color";
import { NoteText } from "../../../../components/Note";
import { noteTAtom } from "../../../../recoil/OptionsAtom";
import { useRecoilState } from "recoil";

function ManagerShipLogTransitGR() {
  const [note, setNote] = useRecoilState(noteTAtom);
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: 20,
            padding: "20px 0",
            fontWeight: 700,
          }}
        >
          PORT
        </div>
        <Row3
          Tilte={"TANK NO"}
          Desc1={"GAUGE"}
          Desc2={"KL"}
          bgcolor={`${mainColor}`}
          Desxcolor={`${whiteColor}`}
          TitleColor={`${whiteColor}`}
        />
        <Row3 Tilte={"1"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"2"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"3"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"4"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"5"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"Total"} Desc1={"-"} Desc2={"-"} />

        <div
          style={{
            fontSize: 20,
            padding: "20px 0",
            fontWeight: 700,
          }}
        >
          STAR BOARD
        </div>
        <Row3
          Tilte={"TANK NO"}
          Desc1={"GAUGE"}
          Desc2={"KL"}
          bgcolor={`${mainColor}`}
          Desxcolor={`${whiteColor}`}
          TitleColor={`${whiteColor}`}
        />
        <Row3 Tilte={"1"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"2"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"3"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"4"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"5"} Desc1={"-"} Desc2={"-"} />
        <Row3 Tilte={"Total"} Desc1={"-"} Desc2={"-"} />
      </div>
      <NoteText note={note} onChange={(e) => setNote(e.target.value)} />
    </div>
  );
}

export default ManagerShipLogTransitGR;
