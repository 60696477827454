import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";
import { InspectionScheduleAtom } from "../recoil/DateAndTimeAtom";
import {
  STSBerthingDateAtom1,
  STSIanDateAtom1,
  STSOilInstallationDateAtom1,
  STSRecoveryDateAtom1,
  TDBerthingDateAtom1,
  TDHoseConnectionDateAtom1,
  TDHoseSeparationDateAtom1,
  TDIanTimeDateAtom1,
  TDStartWorkDateAtom1,
  TDWorkCompleteDateAtom1,
  TUBerthingTimeDateAtom1,
  TUHoseConnectionDateAtom1,
  TUHoseSeparationDateAtom1,
  TUIanTimeDateAtom1,
  TUStartWorkDateAtom1,
  TUWorkCompleteDateAtom1,
} from "../recoil/TimeAtom";

import styles from "./DateSelectDetail.module.css";
import { useCallback } from "react";

function getDayArray(year, month) {
  const dayCount = new Date(year, month, 0).getDate();
  return Array.from({ length: dayCount }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
}

function DateSelectDetail({ valueDateType, disabled }) {
  const [timeShow, setTimeShow] = useState(false);

  //운송일지 적하 접안 시간 날짜
  const [tdBerthingDate, setTDBerthingDate] =
    useRecoilState(TDBerthingDateAtom1);
  const resetTDBerthingDateAtom1 = useResetRecoilState(TDBerthingDateAtom1);
  //운송일지 적하 호스연결 날짜
  const [tdHoseConnectionDat, setTDHoseConnectionDat] = useRecoilState(
    TDHoseConnectionDateAtom1
  );
  const resetTDHoseConnectionDateAtom1 = useResetRecoilState(
    TDHoseConnectionDateAtom1
  );
  //운송일지 적하 작업시작 날짜
  const [tdStartWorkDate, setTDStartWorkDate] =
    useRecoilState(TDStartWorkDateAtom1);
  const resetTDStartWorkDateAtom1 = useResetRecoilState(TDStartWorkDateAtom1);
  //운송일지 적하 작업완료 날짜
  const [tdWorkCompleteDate, setTDWorkCompleteDate] = useRecoilState(
    TDWorkCompleteDateAtom1
  );
  const resetTDWorkCompleteDateAtom1 = useResetRecoilState(
    TDWorkCompleteDateAtom1
  );
  //운송일지 적하 호스분리날짜
  const [tdHoseSeparationDate, setTDHoseSeparationDate] = useRecoilState(
    TDHoseSeparationDateAtom1
  );
  const resetTDHoseSeparationDateAtom1 = useResetRecoilState(
    TDHoseSeparationDateAtom1
  );
  //운송일지 적하 이안시간 날짜
  const [tdIanTimeDate, setTDIanTimeDate] = useRecoilState(TDIanTimeDateAtom1);
  const resetTDIanTimeDateAtom1 = useResetRecoilState(TDIanTimeDateAtom1);
  //운송 일지 양하 접안시간 날짜
  const [tuBerthingTimeDate, setTUBerthingTimeDate] = useRecoilState(
    TUBerthingTimeDateAtom1
  );
  const resetTUBerthingTimeDateAtom1 = useResetRecoilState(
    TUBerthingTimeDateAtom1
  );
  //운송 일지 양하 호스연결 날짜
  const [tuHoseConnectionDate, setTUHoseConnectionDate] = useRecoilState(
    TUHoseConnectionDateAtom1
  );
  const resetTUHoseConnectionDateAtom1 = useResetRecoilState(
    TUHoseConnectionDateAtom1
  );
  //운송 일지 양하 작업시작날짜
  const [tuStartWorkDate, setTUStartWorkDate] =
    useRecoilState(TUStartWorkDateAtom1);
  const resetTUStartWorkDateAtom1 = useResetRecoilState(TUStartWorkDateAtom1);
  // 운송일지 양하 작업완료날짜
  const [tuWorkCompleteDate, setTUWorkCompleteDate] = useRecoilState(
    TUWorkCompleteDateAtom1
  );
  const resetTUWorkCompleteDateAtom1 = useResetRecoilState(
    TUWorkCompleteDateAtom1
  );
  //운송일지 양하 호스분리 날짜
  const [tuHoseSeparationDate, setTUHoseSeparationDate] = useRecoilState(
    TUHoseSeparationDateAtom1
  );
  const resetTUHoseSeparationDateAtom1 = useResetRecoilState(
    TUHoseSeparationDateAtom1
  );
  ///운송일지 양하 이안시간 날짜
  const [tuIanTimeDate, setTUIanTimeDate] = useRecoilState(TUIanTimeDateAtom1);
  const resetTUIanTimeDateAtom1 = useResetRecoilState(TUIanTimeDateAtom1);

  //기선 접안시간 날짜
  const [stsBerthingDate, setSTSBerthingDate] =
    useRecoilState(STSBerthingDateAtom1);
  const resetSTSBerthingDateAtom1 = useResetRecoilState(STSBerthingDateAtom1);
  console.log(stsBerthingDate);
  //기선 오일 설치 날짜
  const [stsOilInstallationDate, setSTSOilInstallationDate] = useRecoilState(
    STSOilInstallationDateAtom1
  );
  const resetSTSOilInstallationDateAtom1 = useResetRecoilState(
    STSOilInstallationDateAtom1
  );
  //기선  기선 오일회수 날짜
  const [stsRecoveryDate, setSTSRecoveryDate] =
    useRecoilState(STSRecoveryDateAtom1);
  const resetSTSRecoveryDateAtom1 = useResetRecoilState(STSRecoveryDateAtom1);
  //기선  기선 이안시간 날짜
  const [stsIanDate, setSTSIanDate] = useRecoilState(STSIanDateAtom1);
  const resetSTSIanDateAtom1 = useResetRecoilState(STSIanDateAtom1);

  //검사일정
  const [inspectionSchedule, setInspectionSchedule] = useRecoilState(
    InspectionScheduleAtom
  );
  const resetInspectionScheduleAtom = useResetRecoilState(
    InspectionScheduleAtom
  );

  const handlePickerChange = useCallback(
    (newValue, key) => {
      //검사일정
      if (key === "day" && valueDateType === "InspectionSchedule") {
        setInspectionSchedule(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDBerthingDate") {
        setTDBerthingDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDHoseConnectionDate") {
        setTDHoseConnectionDat(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDStartWorkDate") {
        setTDStartWorkDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDWorkCompleteDate") {
        setTDWorkCompleteDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDHoseSeparationDate") {
        setTDHoseSeparationDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDIanTimeDate") {
        setTDIanTimeDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUBerthingTimeDate") {
        setTUBerthingTimeDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUHoseConnectionDate") {
        setTUHoseConnectionDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUStartWorkDate") {
        setTUStartWorkDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUWorkCompleteDate") {
        setTUWorkCompleteDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUHoseSeparationDate") {
        setTUHoseSeparationDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUIanTimeDate") {
        setTUIanTimeDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "STSBerthingDate") {
        setSTSBerthingDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "STSOilInstallationDate") {
        setSTSOilInstallationDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "STSRecoveryDate") {
        setSTSRecoveryDate(newValue);
        return;
      }
      if (key === "day" && valueDateType === "STSIanDate") {
        setSTSIanDate(newValue);
        return;
      }

      const { year, month } = newValue;
      const newDayArray = getDayArray(Number(year), Number(month));
      if (valueDateType === "InspectionSchedule") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setInspectionSchedule({ ...newValue, day: newDay });
      }
      if (valueDateType === "TDBerthingDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDBerthingDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TDHoseConnectionDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDHoseConnectionDat({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TDStartWorkDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDStartWorkDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TDWorkCompleteDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDWorkCompleteDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TDHoseSeparationDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDHoseSeparationDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TDIanTimeDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDIanTimeDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUBerthingTimeDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUBerthingTimeDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUHoseConnectionDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUHoseConnectionDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUStartWorkDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUStartWorkDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUWorkCompleteDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUWorkCompleteDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUHoseSeparationDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUHoseSeparationDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUIanTimeDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUIanTimeDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "STSBerthingDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setSTSBerthingDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "STSOilInstallationDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setSTSOilInstallationDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "STSRecoveryDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setSTSRecoveryDate({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "STSIanDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setSTSIanDate({ ...newValue, day: newDay });
        return;
      }
    },
    [
      setInspectionSchedule,
      setSTSBerthingDate,
      setSTSIanDate,
      setSTSOilInstallationDate,
      setSTSRecoveryDate,
      setTDBerthingDate,
      setTDHoseConnectionDat,
      setTDHoseSeparationDate,
      setTDIanTimeDate,
      setTDStartWorkDate,
      setTDWorkCompleteDate,
      setTUBerthingTimeDate,
      setTUHoseConnectionDate,
      setTUHoseSeparationDate,
      setTUIanTimeDate,
      setTUStartWorkDate,
      setTUWorkCompleteDate,
      valueDateType,
    ]
  );

  const resetClick = () => {
    if (valueDateType === "InspectionSchedule") {
      resetInspectionScheduleAtom();
      setTimeShow(!timeShow);
    }

    if (valueDateType === "TDBerthingDate") {
      resetTDBerthingDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDHoseConnectionDate") {
      resetTDHoseConnectionDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDStartWorkDate") {
      resetTDStartWorkDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDWorkCompleteDate") {
      resetTDWorkCompleteDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDHoseSeparationDate") {
      resetTDHoseSeparationDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDIanTimeDate") {
      resetTDIanTimeDateAtom1();
      setTimeShow(!timeShow);
    }

    if (valueDateType === "TUBerthingTimeDate") {
      resetTUBerthingTimeDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUHoseConnectionDate") {
      resetTUHoseConnectionDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUStartWorkDate") {
      resetTUStartWorkDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUWorkCompleteDate") {
      resetTUWorkCompleteDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUHoseSeparationDate") {
      resetTUHoseSeparationDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUIanTimeDate") {
      resetTUIanTimeDateAtom1();
      setTimeShow(!timeShow);
    }

    if (valueDateType === "STSBerthingDate") {
      resetSTSBerthingDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "STSOilInstallationDate") {
      resetSTSOilInstallationDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "STSRecoveryDate") {
      resetSTSRecoveryDateAtom1();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "STSIanDate") {
      resetSTSIanDateAtom1();
      setTimeShow(!timeShow);
    }
  };

  return (
    <>
      {
        <button
          className={timeShow === false ? styles.btn : styles.click}
          disabled={disabled}
          onClick={() => setTimeShow(!timeShow)}
        >
          {valueDateType === "InspectionSchedule" //검사일정
            ? `${inspectionSchedule.year}-${inspectionSchedule.month}-${inspectionSchedule.day}`
            : valueDateType === "TDBerthingDate" //운송 적하 접안시간 날짜
            ? `${tdBerthingDate.year}-${tdBerthingDate.month}-${tdBerthingDate.day}`
            : valueDateType === "TDHoseConnectionDate" //운송 적하 호스연결 날짜
            ? `${tdHoseConnectionDat.year}-${tdHoseConnectionDat.month}-${tdHoseConnectionDat.day}`
            : valueDateType === "TDStartWorkDate" //운송 적하 작업시작 날짜
            ? `${tdStartWorkDate.year}-${tdStartWorkDate.month}-${tdStartWorkDate.day}`
            : valueDateType === "TDWorkCompleteDate" //운송 적하 작업완료 날짜
            ? `${tdWorkCompleteDate.year}-${tdWorkCompleteDate.month}-${tdWorkCompleteDate.day}`
            : valueDateType === "TDHoseSeparationDate" //운송 적하 호스분리 날짜
            ? `${tdHoseSeparationDate.year}-${tdHoseSeparationDate.month}-${tdHoseSeparationDate.day}`
            : valueDateType === "TDIanTimeDate" //운송 적하 이안시간 날짜
            ? `${tdIanTimeDate.year}-${tdIanTimeDate.month}-${tdIanTimeDate.day}`
            : valueDateType === "TUBerthingTimeDate" //운송 양하 접안시간 날짜
            ? `${tuBerthingTimeDate.year}-${tuBerthingTimeDate.month}-${tuBerthingTimeDate.day}`
            : valueDateType === "TUHoseConnectionDate" //운송 양하 호스연결 날짜
            ? `${tuHoseConnectionDate.year}-${tuHoseConnectionDate.month}-${tuHoseConnectionDate.day}`
            : valueDateType === "TUStartWorkDate" //운송 양하 작업시작 날짜
            ? `${tuStartWorkDate.year}-${tuStartWorkDate.month}-${tuStartWorkDate.day}`
            : valueDateType === "TUWorkCompleteDate" //운송 양하 작업완료 날짜
            ? `${tuWorkCompleteDate.year}-${tuWorkCompleteDate.month}-${tuWorkCompleteDate.day}`
            : valueDateType === "TUHoseSeparationDate" //운송 양하 호스분리 날짜
            ? `${tuHoseSeparationDate.year}-${tuHoseSeparationDate.month}-${tuHoseSeparationDate.day}`
            : valueDateType === "TUIanTimeDate" //운송 양하 이안시긴 날짜
            ? `${tuIanTimeDate.year}-${tuIanTimeDate.month}-${tuIanTimeDate.day}`
            : valueDateType === "STSBerthingDate" //기선 접안시간 날짜
            ? `${stsBerthingDate.year}-${stsBerthingDate.month}-${stsBerthingDate.day}`
            : valueDateType === "STSOilInstallationDate" //기선오일설치 날짜
            ? `${stsOilInstallationDate.year}-${stsOilInstallationDate.month}-${stsOilInstallationDate.day}`
            : valueDateType === "STSRecoveryDate" //기선 오일 회수 날짜
            ? `${stsRecoveryDate.year}-${stsRecoveryDate.month}-${stsRecoveryDate.day}`
            : valueDateType === "STSIanDate" //기선 이안시간 날짜
            ? `${stsIanDate.year}-${stsIanDate.month}-${stsIanDate.day}`
            : null}
        </button>
      }

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                value={
                  valueDateType === "InspectionSchedule"
                    ? inspectionSchedule
                    : valueDateType === "TDBerthingDate" //운송 적하 접안시간 날짜
                    ? tdBerthingDate
                    : valueDateType === "TDHoseConnectionDate" //운송 적하 호스연결 날짜
                    ? tdHoseConnectionDat
                    : valueDateType === "TDStartWorkDate" //운송 적하 작업시작 날짜
                    ? tdStartWorkDate
                    : valueDateType === "TDWorkCompleteDate" //운송 적하 작업완료 날짜
                    ? tdWorkCompleteDate
                    : valueDateType === "TDHoseSeparationDate" //운송 적하 호스분리 날짜
                    ? tdHoseSeparationDate
                    : valueDateType === "TDIanTimeDate" //운송 적하 이안시간 날짜
                    ? tdIanTimeDate
                    : valueDateType === "TUBerthingTimeDate" //운송 양하 접안시간 날짜
                    ? tuBerthingTimeDate
                    : valueDateType === "TUHoseConnectionDate" //운송 양하 호스연결 날짜
                    ? tuHoseConnectionDate
                    : valueDateType === "TUStartWorkDate" //운송 양하 작업시작 날짜
                    ? tuStartWorkDate
                    : valueDateType === "TUWorkCompleteDate" //운송 양하 작업완료 날짜
                    ? tuWorkCompleteDate
                    : valueDateType === "TUHoseSeparationDate" //운송 양하 호스분리 날짜
                    ? tuHoseSeparationDate
                    : valueDateType === "TUIanTimeDate" //운송 양하 이안시긴 날짜
                    ? tuIanTimeDate
                    : valueDateType === "STSBerthingDate" //기선 접안시간 날짜
                    ? stsBerthingDate
                    : valueDateType === "STSOilInstallationDate" //기선오일설치 날짜
                    ? stsOilInstallationDate
                    : valueDateType === "STSRecoveryDate" //기선 오일 회수 날짜
                    ? stsRecoveryDate
                    : valueDateType === "STSIanDate" //기선 이안시간 날짜
                    ? stsIanDate
                    : null
                }
                onChange={handlePickerChange}
                wheel="normal"
              >
                <Picker.Column name="year">
                  {Array.from({ length: 500 }, (_, i) => `${1923 + i}`).map(
                    (year) => (
                      <Picker.Item key={year} value={year}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {year}
                          </div>
                        )}
                      </Picker.Item>
                    )
                  )}
                </Picker.Column>
                <Picker.Column name="month">
                  {Array.from({ length: 12 }, (_, i) =>
                    String(i + 1).padStart(2, "0")
                  ).map((month) => (
                    <Picker.Item key={month} value={month}>
                      {({ selected }) => (
                        <div
                          className={
                            selected
                              ? "font-semibold text-neutral-900"
                              : "text-neutral-400"
                          }
                        >
                          {month}
                        </div>
                      )}
                    </Picker.Item>
                  ))}
                </Picker.Column>
                {valueDateType === "InspectionSchedule" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(inspectionSchedule.year),
                      Number(inspectionSchedule.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDBerthingDate" ? ( //운송 적하 접안시간 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdBerthingDate.year),
                      Number(tdBerthingDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDHoseConnectionDate" ? ( //운송 적하 호스연결 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdHoseConnectionDat.year),
                      Number(tdHoseConnectionDat.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDStartWorkDate" ? ( //운송 적하 작업시작 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdStartWorkDate.year),
                      Number(tdStartWorkDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDWorkCompleteDate" ? ( //운송 적하 작업완료 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdWorkCompleteDate.year),
                      Number(tdWorkCompleteDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDHoseSeparationDate" ? ( //운송 적하 호스분리 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdHoseSeparationDate.year),
                      Number(tdHoseSeparationDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDIanTimeDate" ? ( //운송 적하 이안시간 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tdIanTimeDate.year),
                      Number(tdIanTimeDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUBerthingTimeDate" ? ( //운송 양하 접안시간 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuBerthingTimeDate.year),
                      Number(tuBerthingTimeDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUHoseConnectionDate" ? ( //운송 양하 호스연결 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuHoseConnectionDate.year),
                      Number(tuHoseConnectionDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUStartWorkDate" ? ( //운송 양하 작업시작 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuStartWorkDate.year),
                      Number(tuStartWorkDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUWorkCompleteDate" ? ( //운송 양하 작업완료 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuWorkCompleteDate.year),
                      Number(tuWorkCompleteDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUHoseSeparationDate" ? ( //운송 양하 호스분리 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuHoseSeparationDate.year),
                      Number(tuHoseSeparationDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUIanTimeDate" ? ( //운송 양하 이안시긴 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tuIanTimeDate.year),
                      Number(tuIanTimeDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "STSBerthingDate" ? ( //기선 접안시간 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stsBerthingDate.year),
                      Number(stsBerthingDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "STSOilInstallationDate" ? ( //기선오일설치 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stsOilInstallationDate.year),
                      Number(stsOilInstallationDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "STSRecoveryDate" ? ( //기선 오일 회수 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stsRecoveryDate.year),
                      Number(stsRecoveryDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "STSIanDate" ? ( //기선 이안시간 날짜
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stsIanDate.year),
                      Number(stsIanDate.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : null}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DateSelectDetail;
