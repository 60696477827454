import React from "react";
import { Row2, Row3 } from "../../../../components/Row";
import { mainColor, whiteColor } from "../../../../assets/colors/color";
import { NoteText, NoteTextList } from "../../../../components/Note";
import { noteTAtom } from "../../../../recoil/OptionsAtom";
import { useRecoilState } from "recoil";

function ManagerShipLogTransitGRFix({ noteList, gaugeReport }) {
  const noteList2 = [...noteList];
  const noteList3 =
    noteList2 &&
    noteList2.sort(
      (a, b) =>
        new Date(`${a.writtenAt.substr(0, 10)}T${a.writtenAt.substr(11)}`) -
        new Date(`${b.writtenAt.substr(0, 10)}T${b.writtenAt.substr(11)}`)
    );
  console.log("123123123", gaugeReport);
  const [note, setNote] = useRecoilState(noteTAtom);
  return (
    <div>
      {gaugeReport === null ? (
        <div>
          <div
            style={{
              width: "100%",
              borderBottom: `2px solid ${mainColor}`,
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontSize: 20,
                padding: "20px 0",
                fontWeight: 700,
              }}
            >
              PORT
            </div>
            <Row3
              Tilte={"TANK NO"}
              Desc1={"GAUGE"}
              Desc2={"KL"}
              bgcolor={`${mainColor}`}
              Desxcolor={`${whiteColor}`}
              TitleColor={`${whiteColor}`}
            />
            <Row3 Tilte={"1"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"2"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"3"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"4"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"5"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"Tatal"} Desc1={"-"} Desc2={"-"} />

            <div
              style={{
                fontSize: 20,
                padding: "20px 0",
                fontWeight: 700,
              }}
            >
              STAR BOARD
            </div>
            <Row3
              Tilte={"TANK NO"}
              Desc1={"GAUGE"}
              Desc2={"KL"}
              bgcolor={`${mainColor}`}
              Desxcolor={`${whiteColor}`}
              TitleColor={`${whiteColor}`}
            />
            <Row3 Tilte={"1"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"2"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"3"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"4"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"5"} Desc1={"-"} Desc2={"-"} />
            <Row3 Tilte={"Tatal"} Desc1={"-"} Desc2={"-"} />
          </div>

          <div
            style={{
              width: "100%",
              borderBottom: `2px solid ${mainColor}`,
              borderTop: `3px solid ${mainColor}`,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <Row2 Tilte={"온도"} Desc={"-"} />
            <Row2 Tilte={"비중"} Desc={"-"} />
            <Row2 Tilte={"메모"} Desc={"-"} />
          </div>
          {noteList3.length > 0 ? (
            <NoteTextList
              noteList={noteList3}
              note={note}
              onChange={(e) => setNote(e.target.value)}
            />
          ) : (
            <NoteText note={note} onChange={(e) => setNote(e.target.value)} />
          )}
        </div>
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              borderBottom: `2px solid ${mainColor}`,
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontSize: 20,
                padding: "20px 0",
                fontWeight: 700,
              }}
            >
              PORT
            </div>
            <Row3
              Tilte={"TANK NO"}
              Desc1={"GAUGE"}
              Desc2={"KL"}
              bgcolor={`${mainColor}`}
              Desxcolor={`${whiteColor}`}
              TitleColor={`${whiteColor}`}
            />
            <Row3
              Tilte={"1"}
              Desc1={gaugeReport.portGauge1}
              Desc2={gaugeReport.portKL1}
            />
            <Row3
              Tilte={"2"}
              Desc1={gaugeReport.portGauge2}
              Desc2={gaugeReport.portKL2}
            />
            <Row3
              Tilte={"3"}
              Desc1={gaugeReport.portGauge3}
              Desc2={gaugeReport.portKL3}
            />
            <Row3
              Tilte={"4"}
              Desc1={gaugeReport.portGauge4}
              Desc2={gaugeReport.portKL4}
            />
            <Row3
              Tilte={"5"}
              Desc1={gaugeReport.portGauge5}
              Desc2={gaugeReport.portKL5}
            />
            <Row3
              Tilte={"Tatal"}
              Desc1={gaugeReport.portGaugeTotal}
              Desc2={gaugeReport.portKlTotal}
            />

            <div
              style={{
                fontSize: 20,
                padding: "20px 0",
                fontWeight: 700,
              }}
            >
              STAR BOARD
            </div>
            <Row3
              Tilte={"TANK NO"}
              Desc1={"GAUGE"}
              Desc2={"KL"}
              bgcolor={`${mainColor}`}
              Desxcolor={`${whiteColor}`}
              TitleColor={`${whiteColor}`}
            />
            <Row3
              Tilte={"1"}
              Desc1={gaugeReport.starBoardGauge1}
              Desc2={gaugeReport.starBoardKL1}
            />
            <Row3
              Tilte={"2"}
              Desc1={gaugeReport.starBoardGauge2}
              Desc2={gaugeReport.starBoardKL2}
            />
            <Row3
              Tilte={"3"}
              Desc1={gaugeReport.starBoardGauge3}
              Desc2={gaugeReport.starBoardKL3}
            />
            <Row3
              Tilte={"4"}
              Desc1={gaugeReport.starBoardGauge4}
              Desc2={gaugeReport.starBoardKL4}
            />
            <Row3
              Tilte={"5"}
              Desc1={gaugeReport.starBoardGauge5}
              Desc2={gaugeReport.starBoardKL5}
            />
            <Row3
              Tilte={"Tatal"}
              Desc1={gaugeReport.starBoardGaugeTotal}
              Desc2={gaugeReport.starBoardKlTotal}
            />
          </div>

          <div
            style={{
              width: "100%",
              borderBottom: `2px solid ${mainColor}`,
              borderTop: `3px solid ${mainColor}`,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            <Row2 Tilte={"온도"} Desc={gaugeReport.temperature} />
            <Row2 Tilte={"비중"} Desc={gaugeReport.specificGravity} />
            <Row2 Tilte={"메모"} Desc={gaugeReport.memo} />
          </div>
          {noteList3.length > 0 ? (
            <NoteTextList
              noteList={noteList3}
              note={note}
              onChange={(e) => setNote(e.target.value)}
            />
          ) : (
            <NoteText note={note} onChange={(e) => setNote(e.target.value)} />
          )}
        </div>
      )}
    </div>
  );
}

export default ManagerShipLogTransitGRFix;
