import axios from "axios";
import { manageSubscriptionAll } from "./manageSubscriptionAll";
import { manageSubscription } from "./manageSubscription";

export const noticesubList = async () => {
  try {
    const { data } = await axios.get(
      "/api/firebase/admin/topic/subscribe/status"
    );
    const results = data.data;
    console.log(results);
    console.log(results.scheduleTopic);
    console.log(results.inspectionsTopic);
    if (results.scheduleTopic === null && results.inspectionsTopic === null) {
      manageSubscriptionAll();
    } else if (
      results.scheduleTopic === null &&
      results.inspectionsTopic !== null
    ) {
      manageSubscription("schedule");
    } else if (
      results.scheduleTopic !== null &&
      results.inspectionsTopic === null
    ) {
      manageSubscription("inspection");
    } else {
      console.log(results);
      return results;
    }
  } catch (error) {
    console.error("Subscription error:", error);
    alert("오류가 발생했습니다. 다시 시도해 주세요.");
  }
};
