import React from "react";
import { Row2, RowInputMT, RowSelectListM } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import { TmtNumberAtom, statusAtom } from "../../../../recoil/OptionsAtom";
import { OptionGetSelectorFamilly } from "../../../../recoil/GetData";

//작업일지 일지

function ManagerShipLogTransitJournal() {
  const status = useRecoilValue(statusAtom);
  const [TmtNumber, setTmtNumber] = useRecoilState(TmtNumberAtom);

  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer")); //용선주
  const brokerApi = useRecoilValue(OptionGetSelectorFamilly("broker"));
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo")); // 화물명
  const supplierAPI = useRecoilValue(OptionGetSelectorFamilly("supplier")); // 공급자
  const recipientAPI = useRecoilValue(OptionGetSelectorFamilly("recipient")); //수급자

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2 Tilte={"항차"} Desc={"등록하기 이후 자동생성"} />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성자"} Desc="-" />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성일자"} Desc="-" />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"용선주"}
          optionTypes={"charterer"}
          api={chartererAPI}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"중개사"}
          optionTypes={"broker"}
          api={brokerApi}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"화물명"}
          optionTypes={"cargo"}
          api={cargoAPI}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"공급자"}
          optionTypes={"supplier"}
          api={supplierAPI}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"수급자"}
          optionTypes={"recipient"}
          api={recipientAPI}
          disabled={status === "완료" ? true : false}
        />
        <RowInputMT
          value={TmtNumber}
          Tilte={"수량"}
          placeholder={"수량을 입력해주세요"}
          onchangeText={(e) => setTmtNumber(e.target.value)}
          disabled={status === "완료" ? true : false}
        />
        <RowInputMT
          Tilte={"B/L"}
          placeholder={"-"}
          disabled={true}
          TitleColor={`${silverColor}`}
        />
      </div>
    </div>
  );
}

export default ManagerShipLogTransitJournal;
