import React from "react";
import { Row2, RowInput, RowSelectTime } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { format, parseISO } from "date-fns";
import {
  ShippingExtortionAtom,
  ShippingSafetyAtom,
} from "../../../../recoil/OptionsAtom";
import { useRecoilState } from "recoil";

//선박작업 일지 적하

function UserShipLogTransitDropping({ shipApi2, shippingRateOfH }) {
  const status = shipApi2.status;
  //안전관리
  const [ShippingSafety, setShippingSafety] =
    useRecoilState(ShippingSafetyAtom);
  //강취
  const [ShippingExtortion, setShippingExtortion] = useRecoilState(
    ShippingExtortionAtom
  );

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"시작일시"}
          Desc={format(parseISO(shipApi2.workStartAt), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"완료일시"}
          Desc={format(parseISO(shipApi2.workDoneAt), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"접안장소"}
          Desc={shipApi2.berthingPlace}
        />
        <RowSelectTime
          Tilte={"접안시간"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDBerthingDate"}
          valueTimeType1={"TDBerthingTime1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"호스연결"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDHoseConnectionDate"}
          valueTimeType1={"TDHoseConnection1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"작업시작"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDStartWorkDate"}
          valueTimeType1={"TDStartWork1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"작업완료"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDWorkCompleteDate"}
          valueTimeType1={"TDWorkComplete1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"호스분리"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDHoseSeparationDate"}
          valueTimeType1={"TDHoseSeparation1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"이안시간"}
          TitleColor={`${mainColor}`}
          valueDateType={"TDIanTimeDate"}
          valueTimeType1={"TDIanTime1"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"예인선"}
          Desc={shipApi2.tugShip}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"H/RATE"}
          Desc={shippingRateOfH}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"감정사"}
          Desc={shipApi2.certifier}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"하역사"}
          Desc={shipApi2.unloader}
        />
        <RowInput
          Tilte={"안전관리"}
          placeholder={"안전관리를 입력해 주세요"}
          onchangeText={(e) => setShippingSafety(e.target.value)}
          value={ShippingSafety}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput
          Tilte={"강취"}
          placeholder={"강취를 입력해 주세요"}
          onchangeText={(e) => setShippingExtortion(e.target.value)}
          value={ShippingExtortion}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
      </div>
    </div>
  );
}

export default UserShipLogTransitDropping;
