/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitle } from "../../../../components/ShipTitle";
import UserAppBar from "../../common/UserAppBar";
import UserShipLogSteamshipTabs from "./UserShipLogSteamshipTabs";
import { subColor1 } from "../../../../assets/colors/color";

import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  STSSafetyAtom,
  STSSignificantAtom,
  noteTAtom,
} from "../../../../recoil/OptionsAtom";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { ModalSubUser1, ModalSubUser2 } from "../../../../components/ModalSub";
import axios from "axios";
import {
  STSBerthingDateAtom1,
  STSBerthingTimeAtom1,
  STSIanDateAtom1,
  STSIanTimeAtom1,
  STSOilInstallationAtom1,
  STSOilInstallationDateAtom1,
  STSRecoveryDateAtom1,
  STSRecoveryTimeAtom1,
} from "../../../../recoil/TimeAtom";
import { useEffect } from "react";
import { getShipData } from "../../../../recoil/GetData";
import { loginAuthenticationUser } from "../../../../hooks/loginAuthentication";

//작업일지 기선
function UserShipLogSteamship() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyId = shipInfo.idJourney;

  const shipApi = useRecoilValue(getShipData(`sts?journeyId=${journeyId}`));
  console.log(shipApi);

  const shipApi2 = useRecoilValue(
    getShipData(`sts/detail?journeyId=${journeyId}`)
  );
  console.log(shipApi2);

  //선박 이름
  const shipName = shipInfo.shipName;

  //단계
  const status = shipApi.status;

  //기선 접안시간 날짜
  const [stsBerthingDate, setSTSBerthingDate] =
    useRecoilState(STSBerthingDateAtom1);
  const [stsBerthingTime1, setSTSBerthingTime1] =
    useRecoilState(STSBerthingTimeAtom1);
  const dockingAt = `${stsBerthingDate.year}-${stsBerthingDate.month}-${stsBerthingDate.day}T${stsBerthingTime1.hh}:${stsBerthingTime1.mm}:00`;
  console.log(dockingAt);
  //기선 오일 설치 날짜
  const [stsOilInstallationDate, setSTSOilInstallationDate] = useRecoilState(
    STSOilInstallationDateAtom1
  );
  const [stsOilInstallation1, setSTSOilInstallation1] = useRecoilState(
    STSOilInstallationAtom1
  );
  const setOilFenceAt = `${stsOilInstallationDate.year}-${stsOilInstallationDate.month}-${stsOilInstallationDate.day}T${stsOilInstallation1.hh}:${stsOilInstallation1.mm}:00`;
  //기선  기선 오일회수 날짜
  const [stsRecoveryDate, setSTSRecoveryDate] =
    useRecoilState(STSRecoveryDateAtom1);
  const [stsRecoveryTime1, setSTSRecoveryTime1] =
    useRecoilState(STSRecoveryTimeAtom1);
  const collectOilFenceAt = `${stsRecoveryDate.year}-${stsRecoveryDate.month}-${stsRecoveryDate.day}T${stsRecoveryTime1.hh}:${stsRecoveryTime1.mm}:00`;
  //기선  기선 이안시간 날짜
  const [stsIanDate, setSTSIanDate] = useRecoilState(STSIanDateAtom1);
  const [stsIanTime1, setSTSIanTime1] = useRecoilState(STSIanTimeAtom1);
  const departureAt = `${stsIanDate.year}-${stsIanDate.month}-${stsIanDate.day}T${stsIanTime1.hh}:${stsIanTime1.mm}:00`;

  //안전관리
  const [safety, setSafety] = useRecoilState(STSSafetyAtom);

  //강취
  const [remark, setRemark] = useRecoilState(STSSignificantAtom);
  const note = useRecoilValue(noteTAtom);
  const date = new Date();
  const defultDate = format(date, "yyyy-MM-dd") + "T--:--:00";

  const data = {
    //비고란 필요없음
    journeyId: journeyId,
    dockingAt: dockingAt === defultDate ? null : dockingAt,
    setOilFenceAt: setOilFenceAt === defultDate ? null : setOilFenceAt,
    collectOilFenceAt:
      collectOilFenceAt === defultDate ? null : collectOilFenceAt,
    departureAt: departureAt === defultDate ? null : departureAt,
    safety: safety,
    remarks: remark,
    note: note,
  };

  console.log(data);

  useEffect(() => {
    if (shipApi2.dockingAt !== null) {
      console.log(parseISO(shipApi2.dockingAt));
      setSTSBerthingDate({
        year: format(parseISO(shipApi2.dockingAt), "yyyy"),
        month: format(parseISO(shipApi2.dockingAt), "MM"),
        day: format(parseISO(shipApi2.dockingAt), "dd"),
      });
      setSTSBerthingTime1({
        hh: format(parseISO(shipApi2.dockingAt), "HH"),
        mm: format(parseISO(shipApi2.dockingAt), "mm"),
      });
    }
    if (shipApi2.setOilFenceAt !== null) {
      //오일설치
      setSTSOilInstallationDate({
        year: format(parseISO(shipApi2.setOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.setOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.setOilFenceAt), "dd"),
      });
      setSTSOilInstallation1({
        hh: format(parseISO(shipApi2.setOilFenceAt), "HH"),
        mm: format(parseISO(shipApi2.setOilFenceAt), "mm"),
      });
    }
    if (shipApi2.collectOilFenceAt !== null) {
      //오일회수
      setSTSRecoveryDate({
        year: format(parseISO(shipApi2.collectOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.collectOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.collectOilFenceAt), "dd"),
      });
      setSTSRecoveryTime1({
        hh: format(parseISO(shipApi2.collectOilFenceAt), "HH"),
        mm: format(parseISO(shipApi2.collectOilFenceAt), "mm"),
      });
    }
    if (shipApi2.departureAt !== null) {
      //이안시간
      console.log(shipApi2.departureAt);
      setSTSIanDate({
        year: format(parseISO(shipApi2.collectOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.collectOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.collectOilFenceAt), "dd"),
      });
      setSTSIanTime1({
        hh: format(parseISO(shipApi2.departureAt), "HH"),
        mm: format(parseISO(shipApi2.departureAt), "mm"),
      });
    }
    if (shipApi2.safety !== null) {
      setSafety(shipApi2.safety);
    }
    if (shipApi2.remark !== null) {
      setRemark(shipApi2.remark);
    }
  }, []);

  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/sts/update/user", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/user-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch(() => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          Step={status}
          ShipName={shipName}
          Date={format(parseISO(shipApi2.shippingWorkDoneAt), "yyyy / MM / dd")}
          color={`${subColor1}`}
        />
        <p>
          <span style={{ color: "red" }}>"확정"</span>단계에서만 작성이
          가능합니다.
        </p>
        <UserShipLogSteamshipTabs shipApi={shipApi} shipApi2={shipApi2} />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            disabled={status === "계획" || status === "완료" ? true : false}
            onClick={() => {
              setmodal1(!modal1);
            }}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
        {modal1 ? (
          <ModalSubUser1
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? <ModalSubUser2 setmodal1={setmodal2} /> : null}
      </Container>
    </div>
  );
}

export default UserShipLogSteamship;
