import React, { useState } from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import ManagerShipLogTransitTabs from "./ManagerShipLogTransitTabs";
import { mainColor } from "../../../../assets/colors/color";
import ManagerAppBar from "../../common/ManagerAppBar";
import { useLocation } from "react-router-dom";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  BrokerAtom,
  CargoAtom,
  ChartererAtom,
  RecipientAtom,
  ShippingExtortionAtom,
  ShippingSafetyAtom,
  SupplierAtom,
  TDCertifierAtom,
  TDLoaderAndUnloaderAtom,
  TDTug,
  TDWorkplaceAtom,
  TUCertifierAtom,
  TULoaderAndUnloaderAtom,
  TUTug,
  TUWorkplaceAtom,
  TmtNumberAtom,
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
  noteTAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import {
  TDroppingEndDateAtom,
  TDroppingEndTimeAtom,
  TDroppingStartDateAtom,
  TDroppingStartTimeAtom,
  TUnloadingEndDateAtom,
  TUnloadingEndTimeAtom,
  TUnloadingStartDateAtom,
  TUnloadingStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";

import { ModalSub1, ModalSub2 } from "../../../../components/ModalSub";
import {
  DateTextValidationAtom1,
  DateTextValidationAtom2,
  DateTextValidationAtom3,
} from "../../../../recoil/TimeOptionsValidation";
import { parseISO } from "date-fns";
import {
  OptionGetSelectorFamilly,
  getAllShipList,
  getAllShipType,
  getTugLsit,
} from "../../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";

//작업일지 운송
function ManagerShipLogTransit() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const [step, setStep] = useRecoilState(statusAtom);

  const location = useLocation();
  const shipInfo = { ...location.state };

  // 선박  typeId

  const shipTypeApi = useRecoilValue(getAllShipType);
  const shipType = shipInfo.shipType;

  const shipTypeId =
    shipTypeApi &&
    shipTypeApi
      .filter((item) => (item.type === shipType ? item.shipTypeId : ""))
      .map((item) => item.shipTypeId)
      .toString();

  // 선박  id

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipName = shipInfo.shipName;
  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipName ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //용선주id값 받아오기
  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer"));
  const charterer = useRecoilValue(ChartererAtom).data;
  const chartererId =
    chartererAPI &&
    chartererAPI
      .filter((item) => (item.charterer === charterer ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //화물명id값 받아오기
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo"));
  const cargo = useRecoilValue(CargoAtom).data;
  const cargoId =
    cargoAPI &&
    cargoAPI
      .filter((item) => (item.cargo === cargo ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //공급자id값 받아오기
  const supplierAPI = useRecoilValue(OptionGetSelectorFamilly("supplier")); // 공급자
  const supplier = useRecoilValue(SupplierAtom).data;

  const supplierId =
    supplierAPI &&
    supplierAPI
      .filter((item) => (item.supplier === supplier ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //수급자 id값받아오기
  const recipientAPI = useRecoilValue(OptionGetSelectorFamilly("recipient")); //수급자
  const recipient = useRecoilValue(RecipientAtom).data;

  const recipientId =
    recipientAPI &&
    recipientAPI
      .filter((item) => (item.recipient === recipient ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //중개사 id값받아오기
  const brokerApi = useRecoilValue(OptionGetSelectorFamilly("broker"));
  const broker = useRecoilValue(BrokerAtom).data;

  const brokerId =
    brokerApi &&
    brokerApi
      .filter((item) => (item.broker === broker ? item.id : ""))
      .map((item) => item.id)
      .toString();

  // 수량 입력값 받아오기
  const amount = useRecoilValue(TmtNumberAtom);

  //적하시작일시
  const shippingWorkStartDate = useRecoilValue(TDroppingStartDateAtom);
  const shippingWorkStartTime = useRecoilValue(TDroppingStartTimeAtom);

  const shippingWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //적하 완료 일시
  const shippingWorkDoneDate = useRecoilValue(TDroppingEndDateAtom);
  const shippingWorkDoneTime = useRecoilValue(TDroppingEndTimeAtom);

  const shippingWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  //양하시작일시
  const unloadingWorkStartDate = useRecoilValue(TUnloadingStartDateAtom);
  const unloadingWorkStartTime = useRecoilValue(TUnloadingStartTimeAtom);

  const unloadingWorkStartAt = `${unloadingWorkStartDate.year}-${unloadingWorkStartDate.month}-${unloadingWorkStartDate.day}T${unloadingWorkStartTime.hh}:${unloadingWorkStartTime.mm}:00`;
  //양하 완료 일시
  const unloadingWorkDoneDate = useRecoilValue(TUnloadingEndDateAtom);
  const unloadingWorkDoneTime = useRecoilValue(TUnloadingEndTimeAtom);

  const unloadingWorkDoneAt = `${unloadingWorkDoneDate.year}-${unloadingWorkDoneDate.month}-${unloadingWorkDoneDate.day}T${unloadingWorkDoneTime.hh}:${unloadingWorkDoneTime.mm}:00`;

  //적하 작업장소 id값받아오기
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const workplaceTD = useRecoilValue(TDWorkplaceAtom).data.split(" / ")[0];
  console.log(workplaceTD);

  const TDWorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 작업장소 id값
  const workplaceTU = useRecoilValue(TUWorkplaceAtom).data.split(" / ")[0];

  const TUWorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTU ? item.id : ""))
      .map((item) => item.id)
      .toString();

  console.log(workplaceTU);

  //적하 검정사
  const certifierApi = useRecoilValue(OptionGetSelectorFamilly("certifier"));
  const certifierTD = useRecoilValue(TDCertifierAtom).data;

  const certifierTDId =
    certifierApi &&
    certifierApi
      .filter((item) => (item.certifier === certifierTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 검정사

  const certifierTU = useRecoilValue(TUCertifierAtom).data;

  const certifierTUId =
    certifierApi &&
    certifierApi
      .filter((item) => (item.certifier === certifierTU ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //적하 하역사

  const loaderAndUnloaderApi = useRecoilValue(
    OptionGetSelectorFamilly("unloader")
  );
  const TDUnloader = useRecoilValue(TDLoaderAndUnloaderAtom).data;

  const TDshippingUnloaderId =
    loaderAndUnloaderApi &&
    loaderAndUnloaderApi
      .filter((item) => (item.unLoader === TDUnloader ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 하역사
  const TUUnloader = useRecoilValue(TULoaderAndUnloaderAtom).data;

  const TUshippingUnloaderId =
    loaderAndUnloaderApi &&
    loaderAndUnloaderApi
      .filter((item) => (item.unLoader === TUUnloader ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //적하 예인선
  const TugApi = useRecoilValue(getTugLsit); //예인선
  console.log(TugApi);

  const tdTug = useRecoilValue(TDTug).data;
  console.log(tdTug);

  const tdTugId =
    TugApi &&
    TugApi.filter((item) => (item.shipName === tdTug ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();
  // 양하 예인선

  const tuTug = useRecoilValue(TUTug).data;
  console.log(tuTug);
  const tuTugId =
    TugApi &&
    TugApi.filter((item) => (item.shipName === tuTug ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  const note = useRecoilValue(noteTAtom);

  //적하 안전관리
  const ShippingSafety = useRecoilValue(ShippingSafetyAtom);
  //적하 강취
  const ShippingExtortion = useRecoilValue(ShippingExtortionAtom);

  //양하안전관리
  const UnloadingSafety = useRecoilValue(UnloadingSafetyAtom);

  //양하강취
  const UnloadingExtortion = useRecoilValue(UnloadingExtortionAtom);

  const data = {
    shipId: shipId,
    shipTypeId: shipTypeId,
    status: step,
    chartererId: chartererId, //용선주
    brokerId: brokerId, //중개사
    cargoId: cargoId, //화물명
    supplierId: supplierId, //공급자
    recipientId: recipientId, //수급자
    amount: amount, //수량

    //적하
    shippingWorkStartAt: shippingWorkStartAt, //적하 시작일시
    shippingWorkDoneAt: shippingWorkDoneAt, //적하 완료일시
    shippingBerthingPlaceId: TDWorkplaceAtomId, //적하 접안장소
    shippingCertifierId: certifierTDId, //적하 검정사
    shippingUnloaderId: TDshippingUnloaderId, //적하 하역사
    shippingTugShipId: tdTugId, //적하예인선

    shippingSafety: ShippingSafety, //적하 안전관리
    shippingLineHandling: ShippingExtortion, //적하 강취

    //양하
    unloadingWorkStartAt: unloadingWorkStartAt, //양하 시작일시
    unloadingWorkDoneAt: unloadingWorkDoneAt, //양하 완료일시
    unloadingBerthingPlaceId: TUWorkplaceAtomId, //양하 접안장소
    unloadingCertifierId: certifierTUId, //양하 검정사
    unloadingUnloaderId: TUshippingUnloaderId, //양하 하역사
    unloadingTugShipId: tuTugId, //양하예인선

    unloadingSafety: UnloadingSafety, //양하 안전관리
    unloadingLineHandling: UnloadingExtortion, //양하 강취

    note: note,
  };
  console.log("운송 데이터 추가", data);

  //적하시작완료
  const before1 =
    parseISO(shippingWorkStartAt).getTime() <=
    parseISO(shippingWorkDoneAt).getTime();

  console.log(before1);

  //적하 완료 양하 시작

  const before2 =
    parseISO(shippingWorkDoneAt).getTime() <
    parseISO(unloadingWorkStartAt).getTime();

  const before22 =
    parseISO(shippingWorkDoneAt).getTime() ===
    parseISO(unloadingWorkStartAt).getTime();

  //양하 시작 적하완료

  const before3 =
    parseISO(unloadingWorkStartAt).getTime() <=
    parseISO(unloadingWorkDoneAt).getTime();

  const setDateTextValidation1 = useSetRecoilState(DateTextValidationAtom1);
  const setDateTextValidation2 = useSetRecoilState(DateTextValidationAtom2);
  const setDateTextValidation3 = useSetRecoilState(DateTextValidationAtom3);
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  React.useEffect(() => {
    if (before1) {
      //적하 완료 시작
      setDateTextValidation1("");
    } else {
      setDateTextValidation1("적하 완료일시가 적하 시작일시보다 빠릅니다");
    }

    if (before2) {
      //적하 완료 시작
      setDateTextValidation2("");
    } else {
      setDateTextValidation2("양하 시작일시가 적하 완료일시보다 빠릅니다.");
    }
    if (before22) {
      //적하 완료 시작
      setDateTextValidation2("양하 시작일시가 적하 완료일시와 같습니다.");
    }
    if (before3) {
      //적하 완료 시작
      setDateTextValidation3("");
    } else {
      setDateTextValidation3("양하 완료일시가 양하 시작일시보다 빠릅니다.");
    }
  }, [
    before1,
    before2,
    before22,
    before3,
    setDateTextValidation1,
    setDateTextValidation2,
    setDateTextValidation3,
  ]);

  const dataValidation =
    data.shipId &&
    data.shipTypeId &&
    data.status &&
    data.chartererId && //용선주
    data.brokerId && //중개사
    data.cargoId && //화물명
    data.supplierId && //공급자
    data.recipientId && //수급자
    data.amount && //수량
    //적하
    data.shippingWorkStartAt && //적하 시작일시
    data.shippingWorkDoneAt && //적하 완료일시
    data.shippingBerthingPlaceId && //적하 접안장소
    data.shippingTugShipId &&
    data.shippingCertifierId &&
    data.shippingUnloaderId &&
    //양하
    data.unloadingWorkStartAt &&
    data.unloadingWorkDoneAt &&
    data.unloadingBerthingPlaceId &&
    data.unloadingCertifierId &&
    data.unloadingTugShipId &&
    data.unloadingUnloaderId &&
    before1 &&
    before2 &&
    before3;

  console.log(data);
  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .post("/api/admin/journey/floating/add", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
          console.log(response);
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch(() => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonGroupStep step={step} setStep={setStep} />
        <ShipTitleLog ShipName={shipInfo.shipName} color={`${mainColor}`} />
        <ManagerShipLogTransitTabs />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            disabled={!dataValidation}
            onClick={() => {
              setmodal1(!modal1);
            }}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
        {!dataValidation ? (
          <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
        ) : null}
        {modal1 ? (
          <ModalSub1
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? <ModalSub2 setmodal1={setmodal2} /> : null}
      </Container>
    </div>
  );
}

export default ManagerShipLogTransit;
