import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  PatrolEndDateAtom,
  PatrolStartDateAtom,
  SteamerEndDateAtom,
  SteamerStartDateAtom,
  TDroppingEndDateAtom,
  TDroppingStartDateAtom,
  TUnloadingEndDateAtom,
  TUnloadingStartDateAtom,
} from "../recoil/DateAndTimeAtom";

import styles from "./DateSelect.module.css";
import { useCallback } from "react";

function getDayArray(year, month) {
  const dayCount = new Date(year, month, 0).getDate();
  return Array.from({ length: dayCount }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
}

function DateSelect({ valueDateType, disabled }) {
  const [timeShow, setTimeShow] = useState(false);

  //운송-적하 시작일
  const [tDStartDatevalue, setTDStartDateValue] = useRecoilState(
    TDroppingStartDateAtom
  );
  const resetTDroppingStartDateAtom = useResetRecoilState(
    TDroppingStartDateAtom
  );
  //운송-적하 완료일
  const [tDEndDatevalue, setTDEndDateValue] =
    useRecoilState(TDroppingEndDateAtom);
  const resetTDroppingEndDateAtom = useResetRecoilState(TDroppingEndDateAtom);
  //운송-양하 시작일
  const [tUStartDatevalue, setTUStartDateValue] = useRecoilState(
    TUnloadingStartDateAtom
  );
  const resetTUnloadingStartDateAtom = useResetRecoilState(
    TUnloadingStartDateAtom
  );
  //운송-양하 완료일
  const [tUEndDatevalue, setTUEndDateValue] = useRecoilState(
    TUnloadingEndDateAtom
  );
  const resetTUnloadingEndDateAtom = useResetRecoilState(TUnloadingEndDateAtom);
  //기선STS-시작일
  const [stStartDatevalue, setStStartDateValue] =
    useRecoilState(SteamerStartDateAtom);
  const resetSteamerStartDateAtom = useResetRecoilState(SteamerStartDateAtom);
  //기선 STS- 완료일
  const [stEndDatevalue, setStEndDateValue] =
    useRecoilState(SteamerEndDateAtom);
  const resetSteamerEndDateAtom = useResetRecoilState(SteamerEndDateAtom);
  //순찰-시작일
  const [plStartDatevalue, setPlStartDateValue] =
    useRecoilState(PatrolStartDateAtom);
  const resetPatrolStartDateAtom = useResetRecoilState(PatrolStartDateAtom);
  //순찰- 완료일
  const [plEndDatevalue, setPlEndDateValue] = useRecoilState(PatrolEndDateAtom);
  const resetPatrolEndDateAtom = useResetRecoilState(PatrolEndDateAtom);

  const handlePickerChange = useCallback(
    (newValue, key) => {
      if (key === "day" && valueDateType === "TDStartDate") {
        setTDStartDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TDEndDate") {
        setTDEndDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUStartDate") {
        setTUStartDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "TUEndDate") {
        setTUEndDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "StStartDate") {
        setStStartDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "StEndDate") {
        setStEndDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "PlStartDate") {
        setPlStartDateValue(newValue);
        return;
      }
      if (key === "day" && valueDateType === "PlEndDate") {
        setPlEndDateValue(newValue);
        return;
      }

      const { year, month } = newValue;
      const newDayArray = getDayArray(Number(year), Number(month));
      if (valueDateType === "TDStartDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDStartDateValue({ ...newValue, day: newDay });
      }
      if (valueDateType === "TDEndDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTDEndDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUStartDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUStartDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "TUEndDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setTUEndDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "StStartDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setStStartDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "StEndDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setStEndDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "PlStartDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setPlStartDateValue({ ...newValue, day: newDay });
        return;
      }
      if (valueDateType === "PlEndDate") {
        const newDay = newDayArray.includes(newValue.day)
          ? newValue.day
          : newDayArray[newDayArray.length - 1];
        setPlEndDateValue({ ...newValue, day: newDay });
        return;
      }
    },
    [
      setPlEndDateValue,
      setPlStartDateValue,
      setStEndDateValue,
      setStStartDateValue,
      setTDEndDateValue,
      setTDStartDateValue,
      setTUEndDateValue,
      setTUStartDateValue,
      valueDateType,
    ]
  );

  const resetClick = () => {
    if (valueDateType === "TDStartDate") {
      resetTDroppingStartDateAtom();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TDEndDate") {
      resetTDroppingEndDateAtom();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUStartDate") {
      resetTUnloadingStartDateAtom();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "TUEndDate") {
      resetTUnloadingEndDateAtom();
      setTimeShow(!timeShow);
    }

    if (valueDateType === "StStartDate") {
      resetSteamerStartDateAtom();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "StEndDate") {
      resetSteamerEndDateAtom();
      setTimeShow(!timeShow);
    }

    if (valueDateType === "PlStartDate") {
      resetPatrolStartDateAtom();
      setTimeShow(!timeShow);
    }
    if (valueDateType === "PlEndDate") {
      resetPatrolEndDateAtom();
      setTimeShow(!timeShow);
    }
  };

  return (
    <>
      {
        <button
          className={timeShow === false ? styles.btn : styles.click}
          disabled={disabled}
          onClick={() => setTimeShow(!timeShow)}
        >
          {valueDateType === "TDStartDate" //운송 적하 시작
            ? `${tDStartDatevalue.year}-${tDStartDatevalue.month}-${tDStartDatevalue.day}`
            : valueDateType === "TDEndDate" //운송 적하 완료
            ? `${tDEndDatevalue.year}-${tDEndDatevalue.month}-${tDEndDatevalue.day}`
            : valueDateType === "TUStartDate" //운송 양하 시작
            ? `${tUStartDatevalue.year}-${tUStartDatevalue.month}-${tUStartDatevalue.day}`
            : valueDateType === "TUEndDate" //운송 양하 완료
            ? `${tUEndDatevalue.year}-${tUEndDatevalue.month}-${tUEndDatevalue.day}`
            : valueDateType === "StStartDate" //기선 시작
            ? `${stStartDatevalue.year}-${stStartDatevalue.month}-${stStartDatevalue.day}`
            : valueDateType === "StEndDate" //기선 완료
            ? `${stEndDatevalue.year}-${stEndDatevalue.month}-${stEndDatevalue.day}`
            : valueDateType === "PlStartDate" //순찰 시작
            ? `${plStartDatevalue.year}-${plStartDatevalue.month}-${plStartDatevalue.day}`
            : valueDateType === "PlEndDate" //순찰 완료
            ? `${plEndDatevalue.year}-${plEndDatevalue.month}-${plEndDatevalue.day}`
            : null}
        </button>
      }

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                value={
                  valueDateType === "TDStartDate"
                    ? tDStartDatevalue
                    : valueDateType === "TDEndDate"
                    ? tDEndDatevalue
                    : valueDateType === "TUStartDate"
                    ? tUStartDatevalue
                    : valueDateType === "TUEndDate"
                    ? tUEndDatevalue
                    : valueDateType === "StStartDate"
                    ? stStartDatevalue
                    : valueDateType === "StEndDate"
                    ? stEndDatevalue
                    : valueDateType === "PlStartDate"
                    ? plStartDatevalue
                    : valueDateType === "PlEndDate"
                    ? plEndDatevalue
                    : null
                }
                onChange={handlePickerChange}
                wheel="normal"
              >
                <Picker.Column name="year">
                  {Array.from({ length: 500 }, (_, i) => `${1923 + i}`).map(
                    (year) => (
                      <Picker.Item key={year} value={year}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {year}
                          </div>
                        )}
                      </Picker.Item>
                    )
                  )}
                </Picker.Column>
                <Picker.Column name="month">
                  {Array.from({ length: 12 }, (_, i) =>
                    String(i + 1).padStart(2, "0")
                  ).map((month) => (
                    <Picker.Item key={month} value={month}>
                      {({ selected }) => (
                        <div
                          className={
                            selected
                              ? "font-semibold text-neutral-900"
                              : "text-neutral-400"
                          }
                        >
                          {month}
                        </div>
                      )}
                    </Picker.Item>
                  ))}
                </Picker.Column>
                {valueDateType === "TDStartDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tDStartDatevalue.year),
                      Number(tDStartDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TDEndDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tDEndDatevalue.year),
                      Number(tDEndDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUStartDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tUStartDatevalue.year),
                      Number(tUStartDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "TUEndDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(tUEndDatevalue.year),
                      Number(tUEndDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "StStartDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stStartDatevalue.year),
                      Number(stStartDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "StEndDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(stEndDatevalue.year),
                      Number(stEndDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "PlStartDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(plStartDatevalue.year),
                      Number(plStartDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : valueDateType === "PlEndDate" ? (
                  <Picker.Column name="day">
                    {getDayArray(
                      Number(plEndDatevalue.year),
                      Number(plEndDatevalue.month)
                    ).map((day) => (
                      <Picker.Item key={day} value={day}>
                        {({ selected }) => (
                          <div
                            className={
                              selected
                                ? "font-semibold text-neutral-900"
                                : "text-neutral-400"
                            }
                          >
                            {day}
                          </div>
                        )}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : null}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DateSelect;
