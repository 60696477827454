import React from "react";
import { GreenColor, YellowColor, whiteColor } from "../assets/colors/color";
import { isSameDay, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

function ListItem({ data, NextPageType }) {
  console.log(data.idInspectionSchedule);
  const navigate = useNavigate();

  const state = {
    idInspectionSchedule: data.idInspectionSchedule,
    inspectionType: data.inspectionType,
    ship: data.ship,
  };

  const navigateToPurchase = () => {
    NextPageType === "ManagerInspectionSchedule"
      ? navigate("/manager-registation-inspection-scheduleFix", {
          state: state,
        })
      : navigate(null);
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        onClick={navigateToPurchase}
        style={
          data.inspectionType === "제1종 중간검사" ||
          data.inspectionType === "정기검사"
            ? {
                display: "flex",
                flexDirection: "column",
                gap: 6,
                width: "100%",
                padding: "15px 20px",
                backgroundColor: `${YellowColor}`,
                borderRadius: "10px",
                color: `${whiteColor}`,
              }
            : data.inspectionType === "제2종 중간검사" ||
              data.inspectionType === "임시검사"
            ? {
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: "100%",
                padding: "15px 20px",
                backgroundColor: `${GreenColor}`,
                borderRadius: "10px",
                color: `${whiteColor}`,
              }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <h1 style={{ fontWeight: 500, fontSize: 17 }}>
            {data.inspectionType}
          </h1>
        </div>
        <p>
          <span>선명 : </span>
          <span>{data.ship}</span>
        </p>
        <p>
          <span>날짜 : </span>
          <span>{data.inspectionDate}</span>
        </p>
        <p>
          <span>특이사항 : </span>
          <span>{data.note}</span>
        </p>
      </div>
    </div>
  );
}

function ListBoxTest({ edata, selectedDate, NextPageType }) {
  const SelectSameDate =
    edata &&
    edata.filter((data) =>
      isSameDay(parseISO(data.inspectionDate), selectedDate)
    );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
        marginBottom: 50,
      }}
    >
      {SelectSameDate && SelectSameDate.length > 0 ? (
        SelectSameDate.map((data) => (
          <ListItem
            data={data}
            key={data.idInspectionSchedule}
            NextPageType={NextPageType}
          />
        ))
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default ListBoxTest;
