import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function ModalSubUser1({ handelSub, setmodal1, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 등록하겠습니까?</h1>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "100px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelSub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSubUser2({ setmodal1 }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 등록을 취소하겠습니까?</h1>
        <h3 style={{ fontSize: 16, marginTop: 20, color: "red" }}>
          작성하셨던 데이터는 사라집니다.
        </h3>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "50px",
          }}
        >
          <Button
            onClick={() => setmodal1(false)}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            닫기
          </Button>
          <Button
            onClick={() => navigate("/user-ship-log")}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub1({ handelSub, setmodal1, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 등록하겠습니까?</h1>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "100px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelSub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub2({ setmodal1 }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 등록을 취소하겠습니까?</h1>
        <h3 style={{ fontSize: 16, marginTop: 20, color: "red" }}>
          작성하셨던 데이터는 사라집니다.
        </h3>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "50px",
          }}
        >
          <Button
            onClick={() => setmodal1(false)}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            닫기
          </Button>
          <Button
            onClick={() => navigate("/manager-ship-log")}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub3({ handelSub, setmodal1, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 수정하겠습니까?</h1>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "100px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelSub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            수정하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub4({ setmodal1, handelDel, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>일지를 삭제하겠습니까?</h1>
        <h3 style={{ fontSize: 16, marginTop: 20, color: "red" }}>
          작성하셨던 일지는 사라집니다.
        </h3>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "50px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelDel}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            삭제하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            닫기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub5({ handelSub, setmodal1, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>검사일정을 등록하겠습니까?</h1>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "100px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelSub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            닫기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub6({ setmodal1 }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>검사일정 등록을 취소하겠습니까?</h1>
        <h3 style={{ fontSize: 16, marginTop: 20, color: "red" }}>
          작성하셨던 데이터는 사라집니다.
        </h3>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "50px",
          }}
        >
          <Button
            onClick={() => navigate("/manager-inspection-schedule")}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록 취소하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            닫기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub7({ handelSub, setmodal1, disable }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>검사일정을 수정하겠습니까?</h1>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "100px",
          }}
        >
          <Button
            disabled={disable}
            onClick={handelSub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            수정하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            닫기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
export function ModalSub8({ setmodal1, delsub }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 56,
        background: "rgba(0, 0, 0, 0.8)",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50px",
          backgroundColor: "#fff",
          maxWidth: 700,
          width: "90%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontSize: 20 }}>검사일정 삭제하겠습니까?</h1>
        <h3 style={{ fontSize: 16, marginTop: 20, color: "red" }}>
          저장된 데이터는 사라집니다.
        </h3>
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            maxWidth: 600,
            width: "80%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
            marginTop: "50px",
          }}
        >
          <Button
            onClick={delsub}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            삭제하기
          </Button>
          <Button
            onClick={() => setmodal1(false)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            닫기
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
