import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import { subColor2 } from "../../../../assets/colors/color";
import ManagerAppBar from "../../common/ManagerAppBar";
import ManagerShipLogPatrolJournalFix from "./ManagerShipLogPatrolJournalFix";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DateTextValidationAtom } from "../../../../recoil/TimeOptionsValidation";
import { format, parseISO } from "date-fns";
import {
  PatrolEndDateAtom,
  PatrolEndTimeAtom,
  PatrolStartDateAtom,
  PatrolStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import { useState } from "react";
import { PSignificantAtom, statusAtom } from "../../../../recoil/OptionsAtom";
import { ModalSub3, ModalSub4 } from "../../../../components/ModalSub";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import axios from "axios";
import { getShipData } from "../../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";
function ManagerShipLogPatrolFix() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyId = shipInfo.idJourney;
  const journeyDetailId = shipInfo.journeyDetailId;

  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const shipApi = useRecoilValue(getShipData(`patrol?journeyId=${journeyId}`));
  console.log(shipApi);

  const [step, setStep] = useRecoilState(statusAtom);
  //특이사항
  const [PSignificant, setPSignificant] = useRecoilState(PSignificantAtom);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  //순찰시작일시
  const [shippingWorkStartDate, setShippingWorkStartDate] =
    useRecoilState(PatrolStartDateAtom);
  const [shippingWorkStartTime, setShippingWorkStartTime] =
    useRecoilState(PatrolStartTimeAtom);
  console.log(shippingWorkStartTime);

  const patrolWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //순찰 완료 일시
  const [shippingWorkDoneDate, setShippingWorkDoneDate] =
    useRecoilState(PatrolEndDateAtom);
  const [shippingWorkDoneTime, setShippingWorkDoneTime] =
    useRecoilState(PatrolEndTimeAtom);

  const patrolWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  const before =
    parseISO(patrolWorkStartAt).getTime() <=
    parseISO(patrolWorkDoneAt).getTime();

  const setDateTextValidationAtom = useSetRecoilState(DateTextValidationAtom);

  useEffect(() => {
    if (shipApi.patrolWorkStartAt !== null) {
      setShippingWorkStartDate({
        year: format(parseISO(shipApi.workStartAt), "yyyy"),
        month: format(parseISO(shipApi.workStartAt), "MM"),
        day: format(parseISO(shipApi.workStartAt), "dd"),
      });
      setShippingWorkStartTime({
        hh: format(parseISO(shipApi.workStartAt), "HH"),
        mm: format(parseISO(shipApi.workStartAt), "mm"),
      });
    }
    if (shipApi.patrolWorkDoneAt !== null) {
      setShippingWorkDoneDate({
        year: format(parseISO(shipApi.workDoneAt), "yyyy"),
        month: format(parseISO(shipApi.workDoneAt), "MM"),
        day: format(parseISO(shipApi.workDoneAt), "dd"),
      });
      setShippingWorkDoneTime({
        hh: format(parseISO(shipApi.workDoneAt), "HH"),
        mm: format(parseISO(shipApi.workDoneAt), "mm"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStep(shipApi.status);
    setPSignificant(shipApi.remarks);

    if (before) {
      setDateTextValidationAtom("");
    } else {
      setDateTextValidationAtom("완료일시가 시작일시보다 빠릅니다.");
    }
  }, [
    before,
    setDateTextValidationAtom,
    setPSignificant,
    setStep,
    shipApi.status,
    shipApi.remarks,
  ]);

  console.log("특이사항", shipApi.remarks);
  const data = {
    journeyId: journeyId,
    status: step,
    jobType: "순찰",
    workStartAt: patrolWorkStartAt,
    workDoneAt: patrolWorkDoneAt,
    remark: PSignificant,
  };

  console.log("순찰 업데이트", data);

  const dataValidation =
    data.journeyId && //선박 아이디
    data.status && //상태
    data.jobType && //작업구분 고정갑
    data.workStartAt && //시작일시
    data.workDoneAt && //완료일시
    before;

  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/patrol/update/admin", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("수정된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  const delsub = () => {
    setDisable(true);
    axios
      .delete(`/api/journey/patrol/delete?detailJourneyId=${journeyDetailId}`)
      .then((response) => {
        console.log(response);
        if (response.data.status === 203) {
          window.location.href = "/manager-ship-log";
        }
        if (response.data.status === 404) {
          alert("새로고침후 다시 시도해 보세요!");
        }
      })
      .catch((res) => {
        alert("새로고침후 다시 시도해 보세요!");
      });
  };

  return (
    <div>
      <div>
        <ManagerAppBar />
        <Container
          maxWidth="sm"
          sx={{
            marginTop: "76px",
            marginBottom: "20px",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroupStep step={step} setStep={setStep} />
          <ShipTitleLog ShipName={shipInfo.shipName} color={`${subColor2}`} />
          <ManagerShipLogPatrolJournalFix shipApi={shipApi} step={step} />
          <ButtonGroup
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: 99,
            }}
          >
            <Button
              onClick={() => {
                setmodal1(!modal1);
              }}
              // disabled={!dataValidation}
              color="secondary"
              sx={{
                padding: "10px 0",
                flex: 1,
                borderRadius: "99px  0 0 99px",
                fontFamily: ["Noto Sans KR", "sans-serif"],
              }}
            >
              수정하기
            </Button>
            <Button
              onClick={() => setmodal2(!modal1)}
              color="red"
              sx={{
                padding: "10px 0",
                flex: 1,
                fontFamily: ["Noto Sans KR", "sans-serif"],
                borderRadius: "0  99px 99px 0",
                fontWeight: 500,
              }}
            >
              삭제하기
            </Button>
          </ButtonGroup>
          {!dataValidation ? (
            <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
          ) : null}
          {modal1 ? (
            <ModalSub3
              handelSub={handelSub}
              setmodal1={setmodal1}
              disable={disable}
            />
          ) : null}
          {modal2 ? (
            <ModalSub4
              setmodal1={setmodal2}
              handelDel={delsub}
              disable={disable}
            />
          ) : null}
        </Container>
      </div>
    </div>
  );
}

export default ManagerShipLogPatrolFix;
