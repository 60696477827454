import React from "react";
import { format, parseISO } from "date-fns";
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek } from "date-fns";
import { isSameMonth, isSameDay, addDays } from "date-fns";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./CDate.module.css";
import {
  mainColor,
  silverColor,
  subColor1,
  subColor2,
  textColor,
  whiteColor,
} from "../assets/colors/color";

const EvnetDot = (data) => {
  console.log(data);
  return (
    <div
      style={
        data.data.shipType === "운송"
          ? {
              margin: 3,
              padding: 3,
              height: 6,
              width: 6,
              background: `${mainColor}`,
              borderRadius: 999,
            }
          : data.data.shipType === "기선STS"
          ? {
              margin: 3,
              padding: 3,
              height: 6,
              width: 6,
              background: `${subColor1}`,
              borderRadius: 999,
            }
          : data.data.shipType === "순찰"
          ? {
              margin: 3,
              padding: 3,
              height: 6,
              width: 6,
              background: `${subColor2}`,
              borderRadius: 999,
            }
          : data.data.shipType === "예인선"
          ? {
              margin: 3,
              padding: 3,
              height: 6,
              width: 6,
              background: `${textColor}`,
              borderRadius: 999,
            }
          : {}
      }
    ></div>
  );
};

const RenderHeader = ({ currentMonth, prevMonth, nextMonth }) => {
  return (
    <div className={styles.DateHeder}>
      <div className={styles.DateHederContainer}>
        <IconButton
          ype="button"
          color="inherit"
          aria-label="prev"
          onClick={prevMonth}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <span
          style={{
            height: 40,
            fontWeight: 700,
            fontSize: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <span>{format(currentMonth, "yyyy")}년</span>
          <span>{format(currentMonth, "MM")}월</span>
        </span>
        <IconButton
          ype="button"
          color="inherit"
          aria-label="next"
          onClick={nextMonth}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

const RenderDays = () => {
  const days = [];
  const date = ["일", "월", "화", "수", "목", "금", "토"];

  for (let i = 0; i < 7; i++) {
    days.push(
      <div className={styles.DateDays} key={i}>
        {date[i]}
      </div>
    );
  }
  return <div className={styles.DateDaysContainer}>{days}</div>;
};

const RenderCells = ({
  edata,
  currentMonth,
  selectedDate,
  onDateClick,
  today,
}) => {
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  console.log(edata);

  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, "d");

      const cloneDay = day.toISOString();

      const some =
        edata &&
        // eslint-disable-next-line no-loop-func
        edata.some((data) => isSameDay(parseISO(data.workStartAt), day));

      days.push(
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            overflowX: "hidden",
            whiteSpace: "nowrap",
          }}
          key={day}
        >
          <div
            className={`${styles.cols} ${
              !isSameMonth(day, monthStart)
                ? `${styles.Disabled}`
                : isSameDay(day, selectedDate)
                ? `${styles.selected}`
                : isSameDay(day, today)
                ? `${styles.today}`
                : `${styles.valid}`
            }`}
            key={day}
            onClick={() => onDateClick(parseISO(cloneDay))}
          >
            <div
              style={
                format(currentMonth, "M") !== format(day, "M")
                  ? {
                      color: `${silverColor}`,
                      textAlign: "center",
                      flex: 1,
                      padding: "10px 0",
                    }
                  : {
                      flex: 1,
                      textAlign: "center",
                      padding: "10px 0",
                    }
              }
            >
              <span
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "14px",
                  height: "14px",
                  borderRadius: 999,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {some && (
                  <span
                    style={{
                      backgroundColor: `${textColor}`,
                      width: 14,
                      height: 14,
                      borderRadius: 999,
                      color: `${whiteColor}`,
                    }}
                  >
                    {
                      edata // eslint-disable-next-line no-loop-func
                        .filter((data) =>
                          isSameDay(parseISO(data.workStartAt), day)
                        ).length
                    }
                  </span>
                )}
              </span>
              {formattedDate}
            </div>
          </div>

          <div
            style={{
              maxWidth: "78px",
              height: "10px",
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
              gridTemplateColumns: "1fr 1fr 1fr",
              minWidth: 0,
              justifyItems: "center",
              margin: "0 5px",
              overflow: "hidden",
              justifItems: "center",
            }}
          >
            {some &&
              edata
                // eslint-disable-next-line no-loop-func
                .filter((data) => isSameDay(parseISO(data.workStartAt), day))
                .map((data) => {
                  return <EvnetDot data={data} key={data.journeyId} />;
                })}
          </div>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className={styles.calendarRow} key={day}>
        {days}
      </div>
    );
    days = [];
  }
  return <div className={styles.CalendarBody}>{rows}</div>;
};

export const CDateM = ({
  edata,
  onDateClick,
  selectedDate,
  prevMonth,
  nextMonth,
  currentMonth,
}) => {
  console.log(edata);

  const today = new Date();

  return (
    <div className={styles.calendar}>
      <RenderHeader
        currentMonth={currentMonth}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
      />
      <RenderDays />
      <RenderCells
        edata={edata}
        currentMonth={currentMonth}
        today={today}
        selectedDate={selectedDate}
        onDateClick={onDateClick}
      />
    </div>
  );
};
