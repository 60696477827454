import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const useGetData = (url, options) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(url, {
            ...options,
          })
          .then((response) => {
            const dataItem = response.data;
            setData(dataItem);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        setError(err);
        alert(err);
      }
    };
    if (isLoading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, error, isLoading };
};

export default useGetData;
