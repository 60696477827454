import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { mainColor, whiteColor } from "../../../../assets/colors/color";
import UserShipLogTransitJournal from "./UserShipLogTransitJournal";
import UserShipLogTransitDropping from "./UserShipLogTransitDropping";
import UserShipLogTransitUnloading from "./UserShipLogTransitUnloading";
import UserShipLogTransitGR from "./UserShipLogTransitGR";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserShipLogTransitTabs({
  shipApi,
  shipApi2,
  shipApi3,
  shippingRateOfH,
  unloadingRateOfH,
}) {
  const tabs = ["일지", "적하", "양하 ", "GAUGE\nREPORT"];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: `${mainColor}` }}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabs.map((tab, idx) => (
            <AntTab key={idx} label={tab} {...a11yProps(0)} />
          ))}
        </AntTabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <UserShipLogTransitJournal shipApi={shipApi} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserShipLogTransitDropping
          shipApi2={shipApi2}
          shippingRateOfH={shippingRateOfH}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UserShipLogTransitUnloading
          shipApi3={shipApi3}
          unloadingRateOfH={unloadingRateOfH}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <UserShipLogTransitGR noteList={shipApi2.noteList} shipApi={shipApi} />
      </CustomTabPanel>
    </Box>
  );
}

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flex: 1,
    padding: "15px 0",
    minWidth: "25%",
    borderRadius: "10px 10px 0 0",
    fontFamily: ["Noto Sans KR", "sans-serif"],
    whiteSpace: "pre-line",
    fontSizs: 20,
    fontSize: 17,
    fontWeight: 500,
    "&:hover": {
      color: `${mainColor}`,
      opacity: 1,
    },
    "&.Mui-selected": {
      backgroundColor: `${mainColor}`,
      color: `${whiteColor}`,
    },
    "&.Mui-focusVisible": {
      backgroundColor: `${mainColor}`,
    },
  })
);

const AntTabs = styled(Tabs)({
  borderBottom: `2px solid ${mainColor}`,
  whiteSpace: "pre-line",
});
