import React from "react";
import { Container } from "@mui/material";
import { Row2 } from "../../../components/Row";
import { mainColor, subColor1 } from "../../../assets/colors/color";
import { ShipTitleNext } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";

import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getShipFlightLogSTS } from "../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function ShipFlightLogSteamshipNext() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(getShipFlightLogSTS(`${journeyDetailId}`));
  console.log(shipApi);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitleNext ShipName={shipApi.ship} color={`${subColor1}`} />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"차항차"} Desc={shipApi.nextVoyageNumber} />
          <Row2 Tilte={"일자"} Desc={shipApi.nextWorkDate} />
          <Row2 Tilte={"용선주"} Desc={shipApi.nextCharterer} />
          <Row2 Tilte={"모선명"} Desc={shipApi.motherShipName} />
          <Row2 Tilte={"자선명"} Desc={shipApi.sonShipName} />
          <Row2 Tilte={"화물명"} Desc={shipApi.nextCargo} />
          <Row2 Tilte={"수량"} Desc={shipApi.nextAmount} />
          <Row2 Tilte={"장소"} Desc={shipApi.nextShippingBerthingPlace} />
          <Row2 Tilte={"시작일시"} Desc={shipApi.nextWorkStartAt} />
          <Row2 Tilte={"종료일시"} Desc={shipApi.nextWorkDoneAt} />
        </div>
      </Container>
    </div>
  );
}

export default ShipFlightLogSteamshipNext;
