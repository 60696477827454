import { atom } from "recoil";
import { v1 } from "uuid";

//운송 적하 수량
export const TmtNumberAtom = atom({
  key: `TmtNumberAtom/${v1()}`,
  default: "",
});

//모선명
export const MotherShipNameAtom = atom({
  key: `TmtNumberAtom/${v1()}`,
  default: "",
});

//자선명
export const SonShipNameAtom = atom({
  key: `TmtNumberAtom/${v1()}`,
  default: "",
});

//적하 안전관리
export const ShippingSafetyAtom = atom({
  key: `ShippingSafetyAtom/${v1()}`,
  default: "",
});
//양하 안전관리
export const UnloadingSafetyAtom = atom({
  key: `UnloadingSafetyAtom/${v1()}`,
  default: "",
});
//기선 안전관리
export const STSSafetyAtom = atom({
  key: `SafetyAtom/${v1()}`,
  default: "",
});

//적하 강취
export const ShippingExtortionAtom = atom({
  key: `ShippingExtortionAtom/${v1()}`,
  default: "",
});
//양하 강취
export const UnloadingExtortionAtom = atom({
  key: `UnloadingExtortionAtom/${v1()}`,
  default: "",
});

//기선 특이사항
export const STSSignificantAtom = atom({
  key: `STSSignificantAtom/${v1()}`,
  default: "",
});
//순찰 특이사항
export const PSignificantAtom = atom({
  key: `PSignificantAtom/${v1()}`,
  default: "",
});

//비고
export const noteTAtom = atom({
  key: `noteTAtom/${v1()}`,
  default: "",
});
//비고
export const noteSAtom = atom({
  key: `noteSAtom/${v1()}`,
  default: "",
});

//용선주

export const ChartererAtom = atom({
  key: `ChartererAtom/${v1()}`,
  default: {
    data: "용선주를 선택해주세요",
  },
});

//화물명
export const CargoAtom = atom({
  key: `CargoAtom/${v1()}`,
  default: {
    data: "화물명을 선택해주세요",
  },
});

//공급자
export const SupplierAtom = atom({
  key: `SupplierAtom/${v1()}`,
  default: {
    data: "공급자를 선택해주세요",
  },
});

//수급자
export const RecipientAtom = atom({
  key: `RecipientAtom/${v1()}`,
  default: {
    data: "수급자를 선택해주세요",
  },
});

//중개사
export const BrokerAtom = atom({
  key: `BrokerAtom/${v1()}`,
  default: {
    data: "중개사를 선택해주세요",
  },
});

//하역사 적하
export const TDLoaderAndUnloaderAtom = atom({
  key: `TDLoaderAndUnloaderAtom/${v1()}`,
  default: {
    data: "하역사를 선택해주세요",
  },
});

//하역사 양하
export const TULoaderAndUnloaderAtom = atom({
  key: `TULoaderAndUnloaderAtom/${v1()}`,
  default: {
    data: "하역사를 선택해주세요",
  },
});

//검정사 적하
export const TDCertifierAtom = atom({
  key: `TDCertifierAtom/${v1()}`,
  default: {
    data: "검정사를 선택해주세요",
  },
});
//검정사 양하
export const TUCertifierAtom = atom({
  key: `TDCertifierAtom/${v1()}`,
  default: {
    data: "검정사를 선택해주세요",
  },
});

//작업장소 운송 적하
export const TDWorkplaceAtom = atom({
  key: `TDWorkplaceAtom/${v1()}`,
  default: {
    data: "접안장소를 선택해주세요",
  },
});
//작업장소 운송 양하
export const TUWorkplaceAtom = atom({
  key: `TUWorkplaceAtom/${v1()}`,
  default: {
    data: "접안장소를 선택해주세요",
  },
});

// 운송 적하 예인선
export const TDTug = atom({
  key: `TDTug/${v1()}`,
  default: {
    data: "예인선을 선택해주세요",
  },
});

//운송 양하 예인선
export const TUTug = atom({
  key: `TUTug/${v1()}`,
  default: {
    data: "예인선을 선택해주세요",
  },
});

export const voyageNumberAtom = atom({
  key: `voyageNumber/${v1()}`,
  default: "등록하기 이후 자동생성",
});
export const statusAtom = atom({
  key: `statusAtom/${v1()}`,
  default: "계획",
});

//선원 list
export const CrewAtom1 = atom({
  key: `CrewAtom1/${v1()}`,
  default: {
    data: "선원을 선택해 주세요",
  },
});
export const CrewAtom2 = atom({
  key: `CrewAtom2/${v1()}`,
  default: {
    data: "선원을 선택해 주세요",
  },
});
export const CrewAtom3 = atom({
  key: `CrewAtom3/${v1()}`,
  default: {
    data: "선원을 선택해 주세요",
  },
});
export const CrewAtom4 = atom({
  key: `CrewAtom4/${v1()}`,
  default: {
    data: "선원을 선택해 주세요",
  },
});

//GAUGE\nREPORT
//PORT
export const PortGauge1Atom = atom({
  key: `PortGauge1/${v1()}`,
  default: "",
});
export const PortGauge2Atom = atom({
  key: `PortGauge2/${v1()}`,
  default: "",
});
export const PortGauge3Atom = atom({
  key: `PortGauge3/${v1()}`,
  default: "",
});
export const PortGauge4Atom = atom({
  key: `PortGauge4/${v1()}`,
  default: "",
});
export const PortGauge5Atom = atom({
  key: `PortGauge5/${v1()}`,
  default: "",
});
export const PortGaugeToTalAtom = atom({
  key: `PortGaugeToTal/${v1()}`,
  default: "",
});
export const PortKL1Atom = atom({
  key: `PortKL1/${v1()}`,
  default: "",
});
export const PortKL2Atom = atom({
  key: `PortKL2/${v1()}`,
  default: "",
});
export const PortKL3Atom = atom({
  key: `PortKL3/${v1()}`,
  default: "",
});
export const PortKL4Atom = atom({
  key: `PortKL4/${v1()}`,
  default: "",
});
export const PortKL5Atom = atom({
  key: `PortKL5/${v1()}`,
  default: "",
});
export const PortKLTotalAtom = atom({
  key: `PortKLTotal/${v1()}`,
  default: "",
});

//STAR BOARD

export const StartBoardGauge1Atom = atom({
  key: `StartBoardGauge1/${v1()}`,
  default: "",
});
export const StartBoardGauge2Atom = atom({
  key: `StartBoardGauge2/${v1()}`,
  default: "",
});
export const StartBoardGauge3Atom = atom({
  key: `StartBoardGauge3/${v1()}`,
  default: "",
});
export const StartBoardGauge4Atom = atom({
  key: `StartBoardGauge4/${v1()}`,
  default: "",
});
export const StartBoardGauge5Atom = atom({
  key: `StartBoardGauge5/${v1()}`,
  default: "",
});
export const StartBoardGaugeToTalAtom = atom({
  key: `StartBoardGaugeToTal/${v1()}`,
  default: "",
});
export const StartBoardKL1Atom = atom({
  key: `StartBoardKL1/${v1()}`,
  default: "",
});
export const StartBoardKL2Atom = atom({
  key: `StartBoardKL2/${v1()}`,
  default: "",
});
export const StartBoardKL3Atom = atom({
  key: `StartBoardKL3/${v1()}`,
  default: "",
});
export const StartBoardKL4Atom = atom({
  key: `StartBoardKL4/${v1()}`,
  default: "",
});
export const StartBoardKL5Atom = atom({
  key: `StartBoardKL5/${v1()}`,
  default: "",
});
export const StartBoardKLTotalAtom = atom({
  key: `StartBoardKLTotal/${v1()}`,
  default: "",
});

//온도

export const TemperatureAtom = atom({
  key: `Temperature/${v1()}`,
  default: "",
});

//비중
export const ImportanceAtom = atom({
  key: `Importance/${v1()}`,
  default: "",
});

//메모
export const MemoAtom = atom({
  key: `Memo/${v1()}`,
  default: "",
});
export const countAtom = atom({
  key: `countAtom/${v1()}`,
  default: 0,
});
export const showAtom = atom({
  key: `showAtom/${v1()}`,
  default: false,
});
export const tokenAtom = atom({
  key: `tokenAtom/${v1()}`,
  default: "",
});
