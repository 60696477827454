import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  CrewAtom1,
  CrewAtom2,
  CrewAtom3,
  CrewAtom4,
} from "../recoil/OptionsAtom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function SelectCrew({ api, disabled, optionTypes }) {
  const [timeShow, setTimeShow] = useState(false);

  const CrewList = {
    data: api && api.map((item) => item.name),
  };

  const CrewList1 = CrewList.data;
  const findDuplicates = (arr) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);
  console.log(CrewList1);
  const duplicates = findDuplicates(CrewList1);
  console.log(duplicates); // ['apple', 'banana']

  const [crew1, setCrew1] = useRecoilState(CrewAtom1);
  const resetCrewAtom1 = useResetRecoilState(CrewAtom1);
  const [crew2, setCrew2] = useRecoilState(CrewAtom2);
  const resetCrewAtom2 = useResetRecoilState(CrewAtom2);
  const [crew3, setCrew3] = useRecoilState(CrewAtom3);
  const resetCrewAtom3 = useResetRecoilState(CrewAtom3);
  const [crew4, setCrew4] = useRecoilState(CrewAtom4);
  const resetCrewAtom4 = useResetRecoilState(CrewAtom4);

  const resetClick = () => {
    if (optionTypes === "crew1") {
      resetCrewAtom1();
      setTimeShow(!timeShow);
    }
    if (optionTypes === "crew2") {
      resetCrewAtom2();
      setTimeShow(!timeShow);
    }
    if (optionTypes === "crew3") {
      resetCrewAtom3();
      setTimeShow(!timeShow);
    }
    if (optionTypes === "crew4") {
      resetCrewAtom4();
      setTimeShow(!timeShow);
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        onClick={() => setTimeShow(!timeShow)}
        style={{
          width: "100%",
          borderRadius: 5,
          cursor: "pointer",
          padding: "16px 8px",
          border: "none",
          background: `${whiteColor}`,
          fontSize: 16,
          fontFamily: ["Noto Sans KR", "sans-serif"],
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {optionTypes === "crew1"
            ? `${crew1.data}`
            : optionTypes === "crew2"
            ? `${crew2.data}`
            : optionTypes === "crew3"
            ? `${crew3.data}`
            : optionTypes === "crew4"
            ? `${crew4.data}`
            : null}
          {timeShow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </span>
      </button>

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                style={{ textAlign: "center" }}
                value={
                  optionTypes === "crew1"
                    ? crew1
                    : optionTypes === "crew2"
                    ? crew2
                    : optionTypes === "crew3"
                    ? crew3
                    : optionTypes === "crew4"
                    ? crew4
                    : null
                }
                onChange={
                  optionTypes === "crew1"
                    ? (e) => setCrew1(e)
                    : optionTypes === "crew2"
                    ? (e) => setCrew2(e)
                    : optionTypes === "crew3"
                    ? (e) => setCrew3(e)
                    : optionTypes === "crew4"
                    ? (e) => setCrew4(e)
                    : null
                }
              >
                {Object.keys(CrewList).map((name) => (
                  <Picker.Column key={name} name={name}>
                    {CrewList[name].map((option, idx) => (
                      <Picker.Item
                        key={idx}
                        value={option}
                        style={{ fontSize: 20 }}
                      >
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ))}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SelectCrew;
