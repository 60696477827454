import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import { createTheme, ThemeProvider } from "@mui/material";
import IdFind from "./pages/IdPwFind/IdFind";
import PwFind from "./pages/IdPwFind/PwFind";

// 사용자화면
import JoinTheMembership from "./pages/JoinTheMembership/JoinTheMembership";
import UserMain from "./pages/user/UserMain/UserMain";
import UserAlarm from "./pages/user/common/UserAlarm";
import UserTapMenu from "./pages/user/common/UserTapMenu";
import UserInspectionSchedule from "./pages/user/UserInspectionSchedule/UserInspectionSchedule";
import UserShipSchedule from "./pages/user/UserShipSchedule/UserShipSchedule";
import UserShipScheduleTransit from "./pages/user/UserShipSchedule/UserShipScheduleTransit";
import UserShipScheduleSteamship from "./pages/user/UserShipSchedule/UserShipScheduleSteamship";
import UserShipSchedulePatrol from "./pages/user/UserShipSchedule/UserShipSchedulePatrol";
import UserShipLog from "./pages/user/UserShipLog/UserShipLog";
import UserShipLogTransit from "./pages/user/UserShipLog/ShipLogTransit/UserShipLogTransit";
import UserShipLogSteamship from "./pages/user/UserShipLog/ShipLogSteamship/UserShipLogSteamship";
import UserShipLogPatrol from "./pages/user/UserShipLog/ShipLogPatrol/UserShipLogPatrol";

//관리자화면
import ManagerAlarm from "./pages/manager/common/ManagerAlarm";
import ManagerTapMenu from "./pages/manager/common/ManagerTapMenu";
import ManagerMain from "./pages/manager/ManagerMain/ManagerMain";
//관리자 작업일정
import ManaserShipSchedule from "./pages/manager/ManagerShipSchedule/ManagerShipSchedule";
import ManagerShipSchedulePatrol from "./pages/manager/ManagerShipSchedule/ManagerShipSchedulePatrol";
import ManagerShipScheduleSteamship from "./pages/manager/ManagerShipSchedule/ManagerShipScheduleSteamship";
import ManagerShipScheduleTransit from "./pages/manager/ManagerShipSchedule/ManagerShipScheduleTransit";

//관리자 작업일지
import ManagerShipLog from "./pages/manager/ManagerShipLog/ManagerShipLog";
//관리자 작업일지 등록
import ManagerShipLogPatrol from "./pages/manager/ManagerShipLog/ShipLogPatrol/ManagerShipLogPatrol";
import ManagerShipLogSteamship from "./pages/manager/ManagerShipLog/ShipLogSteamship/ManagerShipLogSteamship";
import ManagerShipLogTransit from "./pages/manager/ManagerShipLog/ShipLogTransit/ManagerShipLogTransit";
//관리자 작업일지 수정
import ManagerShipLogTransitFix from "./pages/manager/ManagerShipLog/ShipLogTransitFix/ManagerShipLogTransitFix";
import ManagerShipLogSteamshipFix from "./pages/manager/ManagerShipLog/ShipLogSteamshipFix/ManagerShipLogSteamshipFix";
import ManagerShipLogPatrolFix from "./pages/manager/ManagerShipLog/ShipLogPatrolFix/ManagerShipLogPatrolFix";

import ManagerInspectionSchedul from "./pages/manager/ManagerInspectionSchedule/ManagerInspectionSchedule";
import ManagerShipLogRegistration from "./pages/manager/ManagerShipLog/ManagerShipLogRegistration";
import ShipFlightLog from "./pages/manager/ManagerShipFlightLog/ShipFlightLog";
import ShipFlightLogTransit from "./pages/manager/ManagerShipFlightLog/ShipFlightLogTransit";
import ShipFlightLogTransitNext from "./pages/manager/ManagerShipFlightLog/ShipFlightLogTransitNext";
import ShipFlightLogSteamship from "./pages/manager/ManagerShipFlightLog/ShipFlightLogSteamship";
import ShipFlightLogSteamshipNext from "./pages/manager/ManagerShipFlightLog/ShipFlightLogSteamshipNext";
import ShipFlightLogPatrol from "./pages/manager/ManagerShipFlightLog/ShipFlightLogPatrol";
import ShipFlightLogPatrolNext from "./pages/manager/ManagerShipFlightLog/ShipFlightLogPatrolNext";
import ManagerShipOperationPlan from "./pages/manager/ManagerShipOperationPlan/ManagerShipOperationPlan";
import CrewOperationChart from "./pages/manager/CrewOperationChart/CrewOperationChart";
import CrewInformation from "./pages/manager/CrewInformation/CrewInformation";
import CrewInformationDetails from "./pages/manager/CrewInformation/CrewInformationDetails";
import RegistationInspectionSchedule from "./pages/manager/ManagerInspectionSchedule/RegistationInspectionSchedule";
import RegistationInspectionScheduleFix from "./pages/manager/ManagerInspectionSchedule/RegistationInspectionScheduleFix";
import CrewConnectionList12 from "./pages/manager/CrewConnection/CrewConnectionList12";
import CrewConnection12 from "./pages/manager/CrewConnection/CrewConnection12";
import UserShipScheduleTugShip from "./pages/user/UserShipSchedule/UserShipScheduleTugShip";
import ManagerShipScheduleTugShip from "./pages/manager/ManagerShipSchedule/ManagerShipScheduleTugShip";
import ShipFlightLogSteamshipNextX from "./pages/manager/ManagerShipFlightLog/ShipFlightLogSteamshipNextX";
import ShipFlightLogPatrolNextX from "./pages/manager/ManagerShipFlightLog/ShipFlightLogPatrolNextX";
import ShipFlightLogTransitNextX from "./pages/manager/ManagerShipFlightLog/ShipFlightLogTransitNextX";
import ManagerAlarmHD from "./pages/manager/common/ManagerAlarmHD";
import UserAlarmHD from "./pages/user/common/UserAlarmHD";
import ShipFlightLogPatrolNextTug from "./pages/manager/ManagerShipFlightLog/ShipFlightLogPatrolNextTug";
import ManagerAlarmDa from "./pages/manager/common/ManagerAlarmDa";
import Notification from "./components/Notification";
import UserAlarmDa from "./pages/user/common/UserAlarmDa";

// import "./firebase";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Noto Sans KR", "sans-serif"].join("."),
    },
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#3A5F96",
      },
      secondary: {
        main: "#3A5F96",
        contrastText: "#fff",
      },
      info: {
        main: "#001A3E",
      },
      red: {
        main: "#A63E3F",
        contrastText: "#fff",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Notification />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/user-join" element={<JoinTheMembership />} />
        <Route path="/user-idFind" element={<IdFind />} />
        <Route path="/user-PwFind" element={<PwFind />} />

        {/* 유저 */}
        <Route path="/user-main" element={<UserMain />} />
        <Route path="/user-Alarm" element={<UserAlarm />} />
        <Route path="/user-AlarmHD" element={<UserAlarmHD />} />
        <Route path="/user-AlarmDA" element={<UserAlarmDa />} />
        <Route path="/user-Tap" element={<UserTapMenu />} />
        {/* 선박 작업일정 */}
        <Route path="/user-ship-schedule" element={<UserShipSchedule />} />
        <Route
          path="/user-ship-schedule-transit"
          element={<UserShipScheduleTransit />}
        />
        <Route
          path="/user-ship-schedule-steamship"
          element={<UserShipScheduleSteamship />}
        />
        <Route
          path="/user-ship-schedule-patrol"
          element={<UserShipSchedulePatrol />}
        />
        <Route
          path="/user-ship-schedule-Tug"
          element={<UserShipScheduleTugShip />}
        />
        {/* 선박 작업 일지 */}
        <Route path="/user-ship-log" element={<UserShipLog />} />
        <Route path="/user-ship-log-transit" element={<UserShipLogTransit />} />
        <Route
          path="/user-ship-log-steamship"
          element={<UserShipLogSteamship />}
        />
        <Route path="/user-ship-log-patrol" element={<UserShipLogPatrol />} />
        {/* 선박 검사 일정 */}
        <Route
          path="/user-inspection-schedule"
          element={<UserInspectionSchedule />}
        />
        {/* 관리자 */}
        <Route path="/manager-main" element={<ManagerMain />} />
        <Route path="/manager-AlarmHD" element={<ManagerAlarmHD />} />
        <Route path="/manager-Tap" element={<ManagerTapMenu />} />
        <Route path="/manager-Alarm-da" element={<ManagerAlarmDa />} />
        <Route path="/manager-Alarm" element={<ManagerAlarm />} />

        {/* 선박 작업일정 */}
        <Route
          path="/manager-ship-schedule"
          element={<ManaserShipSchedule />}
        />
        <Route
          path="/manager-ship-schedule-transit"
          element={<ManagerShipScheduleTransit />}
        />
        <Route
          path="/manager-ship-schedule-steamship"
          element={<ManagerShipScheduleSteamship />}
        />
        <Route
          path="/manager-ship-schedule-patrol"
          element={<ManagerShipSchedulePatrol />}
        />
        <Route
          path="/manager-ship-schedule-Tug"
          element={<ManagerShipScheduleTugShip />}
        />
        {/* 선박 작업 일지 */}
        <Route path="/manager-ship-log" element={<ManagerShipLog />} />
        <Route
          path="/manager-ship-log-registration"
          element={<ManagerShipLogRegistration />}
        />
        {/* 선박작업일지 새로 등록 */}
        <Route
          path="/manager-ship-log-transit"
          element={<ManagerShipLogTransit />}
        />
        <Route
          path="/manager-ship-log-steamship"
          element={<ManagerShipLogSteamship />}
        />
        <Route
          path="/manager-ship-log-patrol"
          element={<ManagerShipLogPatrol />}
        />
        {/* 선박작업일지 수정 */}
        <Route
          path="/manager-ship-log-transitFix"
          element={<ManagerShipLogTransitFix />}
        />
        <Route
          path="/manager-ship-log-steamshipFix"
          element={<ManagerShipLogSteamshipFix />}
        />
        <Route
          path="/manager-ship-log-patrolFix"
          element={<ManagerShipLogPatrolFix />}
        />
        {/* 선박 검사 일정 */}
        <Route
          path="/manager-inspection-schedule"
          element={<ManagerInspectionSchedul />}
        />
        <Route
          path="/manager-registation-inspection-schedule"
          element={<RegistationInspectionSchedule />}
        />
        <Route
          path="/manager-registation-inspection-scheduleFix"
          element={<RegistationInspectionScheduleFix />}
        />
        {/* 선박 운항일지 */}
        <Route path="/manager-ship-flight-log" element={<ShipFlightLog />} />
        <Route
          path="/manager-ship-flight-log-transit"
          element={<ShipFlightLogTransit />}
        />
        <Route
          path="/manager-ship-flight-log-transitNext"
          element={<ShipFlightLogTransitNext />}
        />
        <Route
          path="/manager-ship-flight-log-transitNextX"
          element={<ShipFlightLogTransitNextX />}
        />
        <Route
          path="/manager-ship-flight-log-steamship"
          element={<ShipFlightLogSteamship />}
        />
        <Route
          path="/manager-ship-flight-log-steamshipNext"
          element={<ShipFlightLogSteamshipNext />}
        />
        <Route
          path="/manager-ship-flight-log-steamshipNextX"
          element={<ShipFlightLogSteamshipNextX />}
        />
        <Route
          path="/manager-ship-flight-log-patrol"
          element={<ShipFlightLogPatrol />}
        />
        <Route
          path="/manager-ship-flight-log-patrolNext"
          element={<ShipFlightLogPatrolNext />}
        />
        <Route
          path="/manager-ship-flight-log-patrolNextTug"
          element={<ShipFlightLogPatrolNextTug />}
        />
        <Route
          path="/manager-ship-flight-log-patrolNextX"
          element={<ShipFlightLogPatrolNextX />}
        />
        {/* 선박 운항계획표 */}
        <Route
          path="/manager-Ship-operation-plan"
          element={<ManagerShipOperationPlan />}
        />
        {/* 선원 관리부 */}
        <Route
          path="/manager-crew-operation-chart"
          element={<CrewOperationChart />}
        />
        {/* 선박별 선원연결 */}
        <Route path="/manager-crew-connection" element={<CrewConnection12 />} />
        <Route
          path="/manager-crew-connectionList"
          element={<CrewConnectionList12 />}
        />
        {/* 선원정보*/}
        <Route path="/manager-crew-information" element={<CrewInformation />} />

        <Route
          path="/manager-crew-information-details"
          element={<CrewInformationDetails />}
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
