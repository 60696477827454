import React from "react";
import { Row2, RowInput, RowSelectTime } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { format, parseISO } from "date-fns";
import {
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
} from "../../../../recoil/OptionsAtom";
import { useRecoilState } from "recoil";

//양하

function UserShipLogTransitUnloading({ shipApi3, unloadingRateOfH }) {
  //안전관리
  const [UnloadingSafety, setUnloadingSafety] =
    useRecoilState(UnloadingSafetyAtom);

  //강취
  const [UnloadingExtortion, setUnloadingExtortion] = useRecoilState(
    UnloadingExtortionAtom
  );

  const status = shipApi3.status;
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2
          Tilte={"시작일시"}
          TitleColor={`${silverColor}`}
          Desc={format(parseISO(shipApi3.workStartAt), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          Tilte={"완료일시"}
          TitleColor={`${silverColor}`}
          Desc={format(parseISO(shipApi3.workDoneAt), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          TitleColor={`${silverColor}`}
          Tilte={"접안장소"}
          Desc={shipApi3.berthingPlace}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"접안시간"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUBerthingTimeDate"}
          valueTimeType1={"TUBerthingTime1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"호스연결"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUHoseConnectionDate"}
          valueTimeType1={"TUHoseConnection1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"작업시작"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUStartWorkDate"}
          valueTimeType1={"TUStartWork1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"작업완료"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUWorkCompleteDate"}
          valueTimeType1={"TUWorkComplete1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"호스분리"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUHoseSeparationDate"}
          valueTimeType1={"TUHoseSeparation1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"이안시간"}
          TitleColor={`${mainColor}`}
          valueDateType={"TUIanTimeDate"}
          valueTimeType1={"TUIanTime1"}
        />
        <Row2
          Tilte={"예인선"}
          Desc={shipApi3.tugShip}
          TitleColor={`${silverColor}`}
        />
        <Row2
          Tilte={"H/RATE"}
          Desc={unloadingRateOfH}
          TitleColor={`${silverColor}`}
        />
        <Row2
          Tilte={"감정사"}
          Desc={shipApi3.certifier}
          TitleColor={`${silverColor}`}
        />
        <Row2
          Tilte={"하역사"}
          Desc={shipApi3.unloader}
          TitleColor={`${silverColor}`}
        />
        <RowInput
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"안전관리"}
          placeholder={"안전관리를 입력해주세요"}
          onchangeText={(e) => setUnloadingSafety(e.target.value)}
          value={UnloadingSafety}
        />
        <RowInput
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"강취"}
          placeholder={"강취를 입력해 주세요"}
          onchangeText={(e) => setUnloadingExtortion(e.target.value)}
          value={UnloadingExtortion}
        />
      </div>
    </div>
  );
}

export default UserShipLogTransitUnloading;
