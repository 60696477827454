import React, { useState, useEffect } from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { Container, Button } from "@mui/material";
import { mainColor } from "../../../assets/colors/color";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getCrewList, getCrewShipList } from "../../../recoil/GetData";
import SelectCrew from "../../../components/SelectCrew";
import {
  CrewAtom1,
  CrewAtom2,
  CrewAtom3,
  CrewAtom4,
} from "../../../recoil/OptionsAtom";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function CrewConnectionList12() {
  const [dataValidation, setDataValidation] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const shipName = urlParams.get("ship");

  const crewList = useRecoilValue(getCrewList);
  console.log(crewList);
  const getShipCrewList = useRecoilValue(getCrewShipList(`${id}`));
  console.log(getShipCrewList);

  const crew1 = useRecoilValue(CrewAtom1).data;
  const setCrew1 = useSetRecoilState(CrewAtom1);
  const crew1ID = crewList
    .filter((item) => (item.name === crew1 ? item.userId : ""))
    .map((item) => item.userId)
    .toString();
  console.log(crew1);
  console.log(crew1ID);

  const crew2 = useRecoilValue(CrewAtom2).data;
  const setCrew2 = useSetRecoilState(CrewAtom2);
  const crew2ID =
    crewList &&
    crewList
      .filter((item) => (item.name === crew2 ? item.userId : ""))
      .map((item) => item.userId)
      .toString();

  const crew3 = useRecoilValue(CrewAtom3).data;
  const setCrew3 = useSetRecoilState(CrewAtom3);
  const crew3ID =
    crewList &&
    crewList
      .filter((item) => (item.name === crew3 ? item.userId : ""))
      .map((item) => item.userId)
      .toString();
  const crew4 = useRecoilValue(CrewAtom4).data;
  const setCrew4 = useSetRecoilState(CrewAtom4);
  const crew4ID =
    crewList &&
    crewList
      .filter((item) => (item.name === crew4 ? item.userId : ""))
      .map((item) => item.userId)
      .toString();

  const data = {
    shipId: id,
    sailorSequenceVoList: [
      { sailorId: crew1ID, sequence: 1 },
      { sailorId: crew2ID, sequence: 2 },
      { sailorId: crew3ID, sequence: 3 },
      { sailorId: crew4ID, sequence: 4 },
    ],
  };

  // sailorSequenceVoList에서 유효한 sailorId만 추출합니다.
  const sailorIdListnNull =
    data && Array.isArray(data.sailorSequenceVoList)
      ? data.sailorSequenceVoList
          .map((item) => item.sailorId) // sailorId 값만 추출합니다.
          .filter(
            (sailorId) =>
              sailorId !== undefined && sailorId !== null && sailorId !== ""
          ) // 유효한 sailorId만 필터링합니다.
      : []; // sailorSequenceVoList가 없거나 배열이 아니면 빈 배열을 사용

  const DelData = {
    userIdList: [sailorIdListnNull],
  };

  console.log(DelData);
  const List = new Set(sailorIdListnNull);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  useEffect(() => {
    if (List.size < sailorIdListnNull.length) {
      console.log("중복있음");
      setDataValidation(true);
    } else {
      console.log("중복없음");
      setDataValidation(false);
    }
    if (getShipCrewList.length === 1) {
      setCrew1({ data: getShipCrewList[0] });
    } else if (getShipCrewList.length === 2) {
      setCrew1({ data: getShipCrewList[0] });
      setCrew2({ data: getShipCrewList[1] });
    } else if (getShipCrewList.length === 3) {
      setCrew1({ data: getShipCrewList[0] });
      setCrew2({ data: getShipCrewList[1] });
      setCrew3({ data: getShipCrewList[2] });
    } else if (getShipCrewList.length === 4) {
      setCrew1({ data: getShipCrewList[0] });
      setCrew2({ data: getShipCrewList[1] });
      setCrew3({ data: getShipCrewList[2] });
      setCrew4({ data: getShipCrewList[3] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Post = (event) => {
    console.log(data);
    event.preventDefault();
    axios
      .post("/api/admin/v2/sailor/link", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          window.location.href = "/manager-crew-connection";
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const Del = () => {
    axios
      .delete("/api/admin/sailor/unlink", {
        data: {
          shipId: id,
          userIdList: sailorIdListnNull,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 203) {
          window.location.href = "/manager-crew-connection";
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
        }}
      >
        <h1 style={{ fontSize: "2rem", padding: "10px" }}>{shipName}</h1>
        <div
          style={{
            borderBottom: `3px solid ${mainColor}`,
            width: "100%",
            textAlign: "center",
            padding: "10px 0",
          }}
        >
          <h2
            style={{
              background: `${mainColor}`,
              padding: "16px 5px",
              color: "#ffffff",
              fontSize: "1.15rem",
              marginBottom: "10px",
            }}
          >
            연결된 선원
          </h2>
          <SelectCrew api={crewList} optionTypes={"crew1"} />
          <SelectCrew api={crewList} optionTypes={"crew2"} />
          <SelectCrew api={crewList} optionTypes={"crew3"} />
          <SelectCrew api={crewList} optionTypes={"crew4"} />
        </div>
        {dataValidation === true ? (
          <p style={{ color: "red" }}>중복된 값이 있습니다</p>
        ) : null}
        <Button
          disabled={dataValidation}
          sx={{ width: "100%", height: 50 }}
          variant="contained"
          color="secondary"
          onClick={Post}
        >
          저장하기
        </Button>
        <Button
          sx={{ width: "100%", height: 50 }}
          variant="contained"
          color="red"
          onClick={Del}
        >
          선원 초기화 하기
        </Button>
      </Container>
    </div>
  );
}

export default CrewConnectionList12;
