import { atom } from "recoil";
import { v1 } from "uuid";

export const DateValidationAtom = atom({
  key: `DateValidationAtom/${v1()}`, //유효성 검사날짜
  default: false,
});
export const DateValidationAtom1 = atom({
  key: `DateValidationAtom1/${v1()}`, //유효성 검사날짜
  default: true,
});
export const DateValidationAtom2 = atom({
  key: `DateValidationAtom2/${v1()}`, //유효성 검사날짜
  default: true,
});
export const DateValidationAtom3 = atom({
  key: `DateValidationAtom3/${v1()}`, //유효성 검사날짜
  default: true,
});
export const DateTextValidationAtom = atom({
  key: `DateTextValidationAtom/${v1()}`, //유효성 검사 글자
  default: "",
});
export const DateTextValidationAtom1 = atom({
  key: `DateTextValidationAtom1/${v1()}`, //유효성 검사 글자
  default: "",
});
export const DateTextValidationAtom2 = atom({
  key: `DateTextValidationAtom2/${v1()}`, //유효성 검사 글자
  default: "",
});
export const DateTextValidationAtom3 = atom({
  key: `DateTextValidationAtom3/${v1()}`, //유효성 검사 글자
  default: "",
});
