import React from "react";
import { Row2, RowInput, RowSelectDateTime } from "../../../../components/Row";
import {
  mainColor,
  silverColor,
  whiteColor,
} from "../../../../assets/colors/color";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import { PSignificantAtom } from "../../../../recoil/OptionsAtom";

function UserShipLogPatrolJournal({ shipApi }) {
  const userJSON = localStorage.getItem("userData");
  const userInfo = JSON.parse(userJSON);
  const status = shipApi.status;

  //특이사항
  const [PSignificant, setPSignificant] = useRecoilState(PSignificantAtom);

  return (
    <>
      <div
        style={{
          width: "100%",

          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <div
          style={{
            backgroundColor: `${mainColor}`,
            width: "100%",
            padding: "15px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <p
            style={{
              color: `${whiteColor}`,
              fontSize: "1rem",
              lineHeight: 1.5,
              fontWeight: 700,
            }}
          >
            일지
          </p>
        </div>
        <Row2
          Tilte={"항차"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.voyageNumber}
        />
        <Row2
          Tilte={"작성자"}
          TitleColor={`${mainColor}`}
          Desc={userInfo?.name}
        />
        <Row2
          Tilte={"작성일자"}
          TitleColor={`${mainColor}`}
          Desc={format(new Date(), "yyyy-MM-dd HH:mm")}
        />
        <Row2 Tilte={"작업구분"} Desc={shipApi.jobType} />
        <RowSelectDateTime
          Tilte={"시작일시"}
          disabled={status === "계획" || status === "완료" ? true : false}
          valueDateType={"PlStartDate"}
          valueTimeType={"PatrolStartTime"}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          disabled={status === "계획" || status === "완료" ? true : false}
          valueDateType={"PlEndDate"}
          valueTimeType={"PatrolEndTime"}
        />
        <Row2 Tilte={"순찰장소"} Desc={shipApi.patrolPlace} />
        <RowInput
          Tilte={"특이사항"}
          disabled={status === "계획" || status === "완료" ? true : false}
          placeholder={"특이사항을 입력해 주세요"}
          onchangeText={(e) => setPSignificant(e.target.value)}
          value={PSignificant}
        />
      </div>
    </>
  );
}

export default UserShipLogPatrolJournal;
