import React from "react";
import {
  RowInput,
  RowSelectDateTime,
  RowSelectListM,
  RowSelectTime,
} from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { noteSAtom, statusAtom } from "../../../../recoil/OptionsAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { OptionGetSelectorFamilly } from "../../../../recoil/GetData";
import { NoteText, NoteTextList } from "../../../../components/Note";
//선박 일지 등록 기선 내용 고침
function ManagerShipLogSteamshipDetailFix({ shipApi2 }) {
  console.log("123123123", shipApi2);
  const noteList = shipApi2.noteList;

  const noteList2 = [...noteList];
  const noteList3 =
    noteList2 &&
    noteList2.sort(
      (a, b) =>
        new Date(`${a.writtenAt.substr(0, 10)}T${a.writtenAt.substr(11)}`) -
        new Date(`${b.writtenAt.substr(0, 10)}T${b.writtenAt.substr(11)}`)
    );
  const [note, setNote] = useRecoilState(noteSAtom);
  const status = useRecoilValue(statusAtom);
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <RowSelectDateTime
          Tilte={"시작일시"}
          valueDateType={"StStartDate"}
          valueTimeType={"SteamerStartTime"}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          valueDateType={"StEndDate"}
          valueTimeType={"SteamerEndTime"}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"접안장소"}
          optionTypes={"TDworkplace"}
          api={workplaceApi}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"접안시간"}
          valueDateType={"STSBerthingDate"}
          valueTimeType1={"STSBerthingTime1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"오일펜스\n설치"}
          valueDateType={"STSOilInstallationDate"}
          valueTimeType1={"STSOilInstallation1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"오일펜스\n회수"}
          valueDateType={"STSRecoveryDate"}
          valueTimeType1={"STSRecoveryTime1"}
          disabled={"disabled"}
        />

        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"이안시간"}
          valueDateType={"STSIanDate"}
          valueTimeType1={"STSIanTime1"}
          disabled={"disabled"}
        />
        <RowInput
          TitleColor={`${silverColor}`}
          Tilte={"안전관리"}
          placeholder={shipApi2.safety ? shipApi2.safety : "-"}
          disabled={true}
        />
        <RowInput
          TitleColor={`${silverColor}`}
          Tilte={"특이사항"}
          placeholder={shipApi2.remark ? shipApi2.remark : "-"}
          disabled={true}
        />
      </div>
      {noteList3.length > 0 ? (
        <NoteTextList
          noteList={noteList3}
          note={note}
          onChange={(e) => setNote(e.target.value)}
          disabled={status === "완료" ? true : false}
        />
      ) : (
        <NoteText
          note={note}
          onChange={(e) => setNote(e.target.value)}
          disabled={status === "완료" ? true : false}
        />
      )}
    </div>
  );
}

export default ManagerShipLogSteamshipDetailFix;
