import React from "react";
import {
  Row2,
  RowInput,
  RowInputMT,
  RowSelectListM,
} from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  MotherShipNameAtom,
  SonShipNameAtom,
  TmtNumberAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import { OptionGetSelectorFamilly } from "../../../../recoil/GetData";

function ManagerShipLogSteamshipJournalFix({ shipApi }) {
  const status = useRecoilValue(statusAtom);
  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer")); //용선주
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo")); // 화물명
  const [TmtNumber, setTmtNumber] = useRecoilState(TmtNumberAtom);
  const [MotherShipName, setMotherShipName] =
    useRecoilState(MotherShipNameAtom);
  const [SonShipName, setSonShipName] = useRecoilState(SonShipNameAtom);
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2 Tilte={"항차"} Desc={shipApi.voyageNumber} />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성자"} Desc="-" />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성일자"} Desc="-" />
        <Row2 Tilte={"작업구분"} Desc={"기선STS"} />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"용선주"}
          optionTypes={"charterer"}
          api={chartererAPI}
          disabled={status === "완료" ? true : false}
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"자선명"}
          placeholder={"자선명을 입력해 주세요"}
          onchangeText={(e) => setSonShipName(e.target.value)}
          value={SonShipName}
          disabled={status === "완료" ? true : false}
        />
        <RowInput
          TitleColor={`${mainColor}}`}
          Tilte={"모선명"}
          placeholder={"모선명을 입력해 주세요"}
          onchangeText={(e) => setMotherShipName(e.target.value)}
          value={MotherShipName}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"화물명"}
          optionTypes={"cargo"}
          api={cargoAPI}
          disabled={status === "완료" ? true : false}
        />

        <RowInputMT
          Tilte={"수량"}
          placeholder={"수량을 입력해주세요"}
          onchangeText={(e) => setTmtNumber(e.target.value)}
          value={TmtNumber}
          disabled={status === "완료" ? true : false}
        />
      </div>
    </div>
  );
}

export default ManagerShipLogSteamshipJournalFix;
