import React from "react";
import JoinAppBar from "../../components/JoinAppbar";
import { Button, Container } from "@mui/material";
import { mainColor, whiteColor } from "../../assets/colors/color";
import { Link } from "react-router-dom";
import InputLable from "../../components/InputLable";
import PopupModal from "../../components/PopUpModal";
import axios from "axios";

function IdFind() {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [userID, setUserID] = React.useState("");
  const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setPhone("");
    window.location.href = "/";
  };

  const handleFindId = () => {
    // console.log("find ID: ", name, phone);

    axios
      .get(`/api/auth/find-id?name=${name}&phoneNumber=${phone}`) // Pass idParam as params
      .then((response) => {
        const resultData = response.data;
        console.log("response: ", resultData.data);
        if (resultData.data != null) {
          setUserID(response.data.data);
          setOpen(true);
        } else {
          // setOpen(false);
          alert("데이터 결과 없음");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const onChangeName = (e) => {
    const currentName = e.target.value;
    setName(currentName);
  };

  const onChangePhone = (e) => {
    const currentPhone = e.target.value;
    setPhone(currentPhone);
  };

  return (
    <div>
      <JoinAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: 20 }}>아이디/비밀번호 찾기</h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: 20,
            borderBottom: `2px solid ${mainColor}`,
          }}
        >
          <Link
            to={"/user-idFind"}
            style={{
              textDecoration: "none",
              flex: 1,
              padding: "15px 0",
              backgroundColor: `${mainColor}`,
              borderRadius: "10px 10px 0 0",
              color: `${whiteColor}`,
              fontWeight: 500,
            }}
          >
            <span>아이디찾기</span>
          </Link>
          <Link
            to={"/user-PwFind"}
            style={{
              textDecoration: "none",
              flex: 1,
              padding: "15px 0",
              borderRadius: "10px 10px 0 0",
              color: `${mainColor}`,
              fontWeight: 700,
            }}
          >
            <span>비밀번호 찾기</span>
          </Link>
        </div>
        <InputLable
          Name={"user-name"}
          Label={"이름 "}
          Placeholder={"이름을 입력하세요"}
          Star={true}
          value={name}
          handleInputChange={onChangeName}
        />
        <InputLable
          Name={"user-phone"}
          Label={"전화번호 "}
          Placeholder={"전화번호를 입력하세요"}
          Star={true}
          value={phone}
          handleInputChange={onChangePhone}
        />

        <Button
          sx={{ width: "100%", marginTop: 2, borderRadius: 999, height: 50 }}
          variant="contained"
          color="secondary"
          onClick={handleFindId}
        >
          아이디 찾기
        </Button>
        <div>
          {/* <Button onClick={handleOpen}>Open Modal</Button> */}
          <PopupModal open={open} handleClose={handleClose}>
            <h2>귀하의 사용자 ID는 다음과 같습니다:</h2>
            <h1
              style={{
                textAlign: "center",
                fontSize: "2rem",
                lineHeight: "50px",
              }}
            >
              "{userID}"
            </h1>
            <Button
              to={"/"}
              sx={{
                width: "100%",
                marginTop: 2,
                borderRadius: 999,
                height: 50,
              }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              로그인
            </Button>
          </PopupModal>
        </div>
      </Container>
    </div>
  );
}

export default IdFind;
