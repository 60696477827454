export const mainColor = "#3A5F96";
export const subColor1 = "#A63E3F";
export const subColor2 = "#217346 ";
export const textColor = "#333";
export const whiteColor = "#fff";
export const silverColor = "#8B8B8B";
export const disabledColor = "#cdcdcd";
export const YellowColor = "#DBC500";
export const GreenColor = "#4C9900";

export const CalenderRedDot = "#4C9900";
export const CalendarBlueDot = "#000000";
// export const GreenColor = "#4C9900";
