/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitle } from "../../../../components/ShipTitle";
import UserAppBar from "../../common/UserAppBar";
import { subColor2 } from "../../../../assets/colors/color";
import UserShipLogPatrolJournal from "./UserShipLogPatrolJournal";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { PSignificantAtom } from "../../../../recoil/OptionsAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getShipData } from "../../../../recoil/GetData";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";
import {
  PatrolEndDateAtom,
  PatrolEndTimeAtom,
  PatrolStartDateAtom,
  PatrolStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import {
  DateTextValidationAtom,
  DateValidationAtom,
} from "../../../../recoil/TimeOptionsValidation";
import { useState } from "react";
import { ModalSubUser1, ModalSubUser2 } from "../../../../components/ModalSub";
import { loginAuthenticationUser } from "../../../../hooks/loginAuthentication";

function UserShipLogPatrol() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyId = shipInfo.idJourney;
  const shipName = shipInfo.shipName;

  const shipApi = useRecoilValue(getShipData(`patrol?journeyId=${journeyId}`));
  console.log(shipApi);

  //순찰-시작일
  const [plStartDatevalue, setPlStartDateValue] =
    useRecoilState(PatrolStartDateAtom);
  //순찰 시작일시 시간
  const [patrolStartTime, setPatrolStartTime] =
    useRecoilState(PatrolStartTimeAtom);

  const patrolWorkStartAt = `${plStartDatevalue.year}-${plStartDatevalue.month}-${plStartDatevalue.day}T${patrolStartTime.hh}:${patrolStartTime.mm}:00`;

  //순찰- 완료일
  const [plEndDatevalue, setPlEndDateValue] = useRecoilState(PatrolEndDateAtom);

  //순찰 완료일시 시간
  const [patrolEndTime, setPatrolEndTime] = useRecoilState(PatrolEndTimeAtom);

  const patrolWorkDoneAt = `${plEndDatevalue.year}-${plEndDatevalue.month}-${plEndDatevalue.day}T${patrolEndTime.hh}:${patrolEndTime.mm}:00`;

  const status = shipApi.status;
  const [PSignificant, setPSignificant] = useRecoilState(PSignificantAtom);
  const data = {
    journeyId: journeyId,
    jobType: "순찰",
    workStartAt: patrolWorkStartAt,
    workDoneAt: patrolWorkDoneAt,
    remark: PSignificant,
  };
  console.log(data);

  const before =
    parseISO(patrolWorkStartAt).getTime() <
    parseISO(patrolWorkDoneAt).getTime();

  const setDateValidationAtom = useSetRecoilState(DateValidationAtom);
  const setDateTextValidationAtom = useSetRecoilState(DateTextValidationAtom);

  useEffect(() => {
    if (shipApi.remarks !== null) {
      setPSignificant(shipApi.remarks);
    }
    if (shipApi.workStartAt !== null) {
      setPlStartDateValue({
        year: format(parseISO(shipApi.workStartAt), "yyyy"),
        month: format(parseISO(shipApi.workStartAt), "MM"),
        day: format(parseISO(shipApi.workStartAt), "dd"),
      });
      setPatrolStartTime({
        hh: format(parseISO(shipApi.workStartAt), "HH"),
        mm: format(parseISO(shipApi.workStartAt), "mm"),
      });
    }
    if (shipApi.workDoneAt !== null) {
      setPlEndDateValue({
        year: format(parseISO(shipApi.workDoneAt), "yyyy"),
        month: format(parseISO(shipApi.workDoneAt), "MM"),
        day: format(parseISO(shipApi.workDoneAt), "dd"),
      });
      setPatrolEndTime({
        hh: format(parseISO(shipApi.workDoneAt), "HH"),
        mm: format(parseISO(shipApi.workDoneAt), "mm"),
      });
      if (before) {
        setDateValidationAtom(true);
        setDateTextValidationAtom("");
      } else {
        setDateValidationAtom(false);
        setDateTextValidationAtom("날짜와 시간을 잘확인해 주세요");
      }
    }
  }, []);
  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/patrol/update/user", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/user-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch(() => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div>
      <div>
        <UserAppBar />
        <Container
          maxWidth="sm"
          sx={{
            marginTop: "76px",
            marginBottom: "20px",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ShipTitle
            Step={status}
            ShipName={shipName}
            Date={format(parseISO(shipApi.workStartAt), "yyyy / MM / dd")}
            color={`${subColor2}`}
          />
          <p>
            <span style={{ color: "red" }}>"확정"</span>단계에서만 작성이
            가능합니다.
          </p>
          <UserShipLogPatrolJournal shipApi={shipApi} />
          <ButtonGroup
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: 99,
            }}
          >
            <Button
              disabled={status === "계획" || status === "완료" ? true : false}
              onClick={() => {
                setmodal1(!modal1);
              }}
              color="secondary"
              sx={{
                padding: "10px 0",
                flex: 1,
                borderRadius: "99px  0 0 99px",
                fontFamily: ["Noto Sans KR", "sans-serif"],
              }}
            >
              등록하기
            </Button>
            <Button
              onClick={() => setmodal2(!modal1)}
              color="red"
              sx={{
                padding: "10px 0",
                flex: 1,
                fontFamily: ["Noto Sans KR", "sans-serif"],
                borderRadius: "0  99px 99px 0",
                fontWeight: 500,
              }}
            >
              취소하기
            </Button>
          </ButtonGroup>
          {modal1 ? (
            <ModalSubUser1
              handelSub={handelSub}
              setmodal1={setmodal1}
              disable={disable}
            />
          ) : null}
          {modal2 ? <ModalSubUser2 setmodal1={setmodal2} /> : null}
        </Container>
      </div>
    </div>
  );
}

export default UserShipLogPatrol;
