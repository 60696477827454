import React from "react";
import { Row3, RowInput, RowInput2 } from "../../../../components/Row";
import { mainColor, whiteColor } from "../../../../assets/colors/color";
import { useRecoilState } from "recoil";
import {
  ImportanceAtom,
  MemoAtom,
  PortGauge1Atom,
  PortGauge2Atom,
  PortGauge3Atom,
  PortGauge4Atom,
  PortGauge5Atom,
  PortGaugeToTalAtom,
  PortKL1Atom,
  PortKL2Atom,
  PortKL3Atom,
  PortKL4Atom,
  PortKL5Atom,
  PortKLTotalAtom,
  StartBoardGauge1Atom,
  StartBoardGauge2Atom,
  StartBoardGauge3Atom,
  StartBoardGauge4Atom,
  StartBoardGauge5Atom,
  StartBoardGaugeToTalAtom,
  StartBoardKL1Atom,
  StartBoardKL2Atom,
  StartBoardKL3Atom,
  StartBoardKL4Atom,
  StartBoardKL5Atom,
  StartBoardKLTotalAtom,
  TemperatureAtom,
  noteTAtom,
} from "../../../../recoil/OptionsAtom";
import { NoteText, NoteTextList } from "../../../../components/Note";

function UserShipLogTransitGR({ noteList, shipApi }) {
  const status = shipApi.status;
  const noteList2 = [...noteList];
  const noteList3 =
    noteList2 &&
    noteList2.sort(
      (a, b) =>
        new Date(`${a.writtenAt.substr(0, 10)}T${a.writtenAt.substr(11)}`) -
        new Date(`${b.writtenAt.substr(0, 10)}T${b.writtenAt.substr(11)}`)
    );
  console.log(shipApi);

  const [portGauge1, setPortGauge1] = useRecoilState(PortGauge1Atom);
  const [portGauge2, setPortGauge2] = useRecoilState(PortGauge2Atom);
  const [portGauge3, setPortGauge3] = useRecoilState(PortGauge3Atom);
  const [portGauge4, setPortGauge4] = useRecoilState(PortGauge4Atom);
  const [portGauge5, setPortGauge5] = useRecoilState(PortGauge5Atom);
  const [portGaugeToTal, setPortGaugeToTal] =
    useRecoilState(PortGaugeToTalAtom);
  const [portKL1, setPortKL1] = useRecoilState(PortKL1Atom);
  const [portKL2, setPortKL2] = useRecoilState(PortKL2Atom);
  const [portKL3, setPortKL3] = useRecoilState(PortKL3Atom);
  const [portKL4, setPortKL4] = useRecoilState(PortKL4Atom);
  const [portKL5, setPortKL5] = useRecoilState(PortKL5Atom);
  const [portKLTotal, setPortKLTotal] = useRecoilState(PortKLTotalAtom);

  const [startBoardGauge1, setStartBoardGauge1] =
    useRecoilState(StartBoardGauge1Atom);
  const [startBoardGauge2, setStartBoardGauge2] =
    useRecoilState(StartBoardGauge2Atom);
  const [startBoardGauge3, setStartBoardGauge3] =
    useRecoilState(StartBoardGauge3Atom);
  const [startBoardGauge4, setStartBoardGauge4] =
    useRecoilState(StartBoardGauge4Atom);
  const [startBoardGauge5, setStartBoardGauge5] =
    useRecoilState(StartBoardGauge5Atom);
  const [startBoardGaugeToTal, setStartBoardGaugeToTal] = useRecoilState(
    StartBoardGaugeToTalAtom
  );

  const [startBoardKL1, setStartBoardKL1] = useRecoilState(StartBoardKL1Atom);
  const [startBoardKL2, setStartBoardKL2] = useRecoilState(StartBoardKL2Atom);
  const [startBoardKL3, setStartBoardKL3] = useRecoilState(StartBoardKL3Atom);
  const [startBoardKL4, setStartBoardKL4] = useRecoilState(StartBoardKL4Atom);
  const [startBoardKL5, setStartBoardKL5] = useRecoilState(StartBoardKL5Atom);
  const [startBoardKLTotal, setStartBoardKLTotal] = useRecoilState(
    StartBoardKLTotalAtom
  );

  //온도

  const [temperature, setTemperature] = useRecoilState(TemperatureAtom);
  //비중
  const [importance, setImportance] = useRecoilState(ImportanceAtom);
  //메모
  const [memo, setMemo] = useRecoilState(MemoAtom);

  //비고
  const [note, setNote] = useRecoilState(noteTAtom);

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: 20,
            padding: "20px 0",
            fontWeight: 700,
          }}
        >
          PORT
        </div>
        <Row3
          Tilte={"TANK NO"}
          Desc1={"GAUGE"}
          Desc2={"KL"}
          bgcolor={`${mainColor}`}
          Desxcolor={`${whiteColor}`}
          TitleColor={`${whiteColor}`}
        />
        <RowInput2
          Tilte={"1"}
          value1={portGauge1}
          onchangeText1={(e) => setPortGauge1(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKL1}
          onchangeText2={(e) => setPortKL1(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"2"}
          value1={portGauge2}
          onchangeText1={(e) => setPortGauge2(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKL2}
          onchangeText2={(e) => setPortKL2(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"3"}
          value1={portGauge3}
          onchangeText1={(e) => setPortGauge3(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKL3}
          onchangeText2={(e) => setPortKL3(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"4"}
          value1={portGauge4}
          onchangeText1={(e) => setPortGauge4(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKL4}
          onchangeText2={(e) => setPortKL4(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"5"}
          value1={portGauge5}
          onchangeText1={(e) => setPortGauge5(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKL5}
          onchangeText2={(e) => setPortKL5(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"TOTAL"}
          value1={portGaugeToTal}
          onchangeText1={(e) => setPortGaugeToTal(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={portKLTotal}
          onchangeText2={(e) => setPortKLTotal(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />

        <div
          style={{
            fontSize: 20,
            padding: "20px 0",
            fontWeight: 700,
          }}
        >
          STAR BOARD
        </div>
        <Row3
          Tilte={"TANK NO"}
          Desc1={"GAUGE"}
          Desc2={"KL"}
          bgcolor={`${mainColor}`}
          Desxcolor={`${whiteColor}`}
          TitleColor={`${whiteColor}`}
        />
        <RowInput2
          Tilte={"1"}
          value1={startBoardGauge1}
          onchangeText1={(e) => setStartBoardGauge1(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKL1}
          onchangeText2={(e) => setStartBoardKL1(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"2"}
          value1={startBoardGauge2}
          onchangeText1={(e) => setStartBoardGauge2(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKL2}
          onchangeText2={(e) => setStartBoardKL2(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"3"}
          value1={startBoardGauge3}
          onchangeText1={(e) => setStartBoardGauge3(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKL3}
          onchangeText2={(e) => setStartBoardKL3(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"4"}
          value1={startBoardGauge4}
          onchangeText1={(e) => setStartBoardGauge4(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKL4}
          onchangeText2={(e) => setStartBoardKL4(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"5"}
          value1={startBoardGauge5}
          onchangeText1={(e) => setStartBoardGauge5(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKL5}
          onchangeText2={(e) => setStartBoardKL5(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput2
          Tilte={"TOTAL"}
          value1={startBoardGaugeToTal}
          onchangeText1={(e) => setStartBoardGaugeToTal(e.target.value)}
          placeholder1={"입력해주세요"}
          value2={startBoardKLTotal}
          onchangeText2={(e) => setStartBoardKLTotal(e.target.value)}
          placeholder2={"입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
          borderTop: `3px solid ${mainColor}`,
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <RowInput
          Tilte={"온도"}
          value={temperature}
          onchangeText={(e) => setTemperature(e.target.value)}
          placeholder={"온도를 입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput
          Tilte={"비중"}
          value={importance}
          onchangeText={(e) => setImportance(e.target.value)}
          placeholder={"비중를 입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
        <RowInput
          Tilte={"메모"}
          value={memo}
          onchangeText={(e) => setMemo(e.target.value)}
          placeholder={"메모를 입력해주세요"}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
      </div>
      {noteList3.length > 0 ? (
        <NoteTextList
          disabled={status === "계획" || status === "완료" ? true : false}
          noteList={noteList3}
          note={note}
          onChange={(e) => setNote(e.target.value)}
        />
      ) : (
        <NoteText
          note={note}
          onChange={(e) => setNote(e.target.value)}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
      )}
    </div>
  );
}

export default UserShipLogTransitGR;
