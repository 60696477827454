//선박작업일정
import React, { useEffect, useState } from "react";
import UserAppBar from "../common/UserAppBar";
import { Container } from "@mui/material";
import { CDate } from "../../../components/CDate";
import { addMonths, format, subMonths } from "date-fns";
import { mainColor } from "../../../assets/colors/color";
import { useRecoilValue } from "recoil";
import { getScheduleShipList } from "../../../recoil/GetData";
import ListBoxSchedule from "../../../components/ListBoxSchedule";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";
function UserShipSchedule() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const selectDay = format(selectedDate, "yyyy-M-dd");
  const year = format(currentMonth, "yyyy");
  const month = format(currentMonth, "MM");

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const shipScheduleData = useRecoilValue(
    getScheduleShipList(`${year}-${month}-23`)
  );
  console.log("shipScheduleData", shipScheduleData);

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          marginBottom: "70px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "20px" }}>선박 작업 일정</h1>

        <CDate
          edata={shipScheduleData && shipScheduleData}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          currentMonth={currentMonth}
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: `${mainColor}`,
            padding: "8px 0",
          }}
        >
          선택된 날짜 : {selectDay}{" "}
        </p>
        <ListBoxSchedule
          edata={shipScheduleData && shipScheduleData}
          selectedDate={selectedDate}
          NextPageType={"UserShipSchedule"}
        />
      </Container>
    </div>
  );
}

export default UserShipSchedule;
