import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { noticeAllList } from "../../../recoil/GetData";
import { format, parseISO } from "date-fns";
import ManagerAppBar from "./ManagerAppBar";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ManagerAlarmList({ data }) {
  const navigate = useNavigate();

  const navigateToPurchase = () => {
    navigate("/manager-Alarm-da", {
      state: { idNotification: data.idNotification },
    });
  };

  return (
    <div
      onClick={navigateToPurchase}
      style={
        data.status === "UNCONFIRMED"
          ? {
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: 16,
              borderBottom: "1px solid #cdcdcd",
            }
          : {
              backgroundColor: "#cdcdcd",
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: 16,
              borderBottom: "1px solid #cdcdcd",
            }
      }
    >
      <p style={{ fontSize: 13 }}>
        {format(parseISO(data.createdAt), "yyyy-MM-dd HH:mm")}
      </p>
      <p style={{ fontSize: 17, fontWeight: 700 }}>{data.title}</p>
    </div>
  );
}

function ManagerAlarm() {
  const navigate = useNavigate();
  const noticeLsit = useRecoilValue(noticeAllList);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, [navigate]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "#fff",
      }}
    >
      <ManagerAppBar />
      <div style={{ marginTop: 60 }}>
        {noticeLsit &&
          noticeLsit.map((data) => (
            <ManagerAlarmList key={data.idNotification} data={data} />
          ))}
      </div>
    </div>
  );
}

export default ManagerAlarm;
