import React from "react";
import styles from "./Row.module.css";
import TimeSelectM from "./TimeSelectM";
import DateSelect from "./DateSelect";
import SelectM from "./SelectM";
import TimeSelectDTSE from "./TimeSelectDTSE";
import DateSelectDetail from "./DateSelectDetail";

export function Row2({ Tilte, Desc, bgcolor, Desxcolor, TitleColor }) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <div className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </div>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <span className={styles.Desc} style={{ color: `${Desxcolor}` }}>
        {Desc}
      </span>
    </div>
  );
}
export function Row3({ Tilte, Desc1, Desc2, bgcolor, Desxcolor, TitleColor }) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <div className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </div>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>

      <span className={styles.Desc1} style={{ color: `${Desxcolor}` }}>
        {Desc1}
      </span>
      <span
        className={styles.Line2}
        style={{ backgroundColor: `${Desxcolor}` }}
      />
      <span className={styles.Desc2} style={{ color: `${Desxcolor}` }}>
        {Desc2}
      </span>
    </div>
  );
}
export function RowInput2({
  value1,
  value2,
  Tilte,
  bgcolor,
  placeholder1,
  placeholder2,
  Desxcolor,
  TitleColor,
  onchangeText1,
  onchangeText2,
  disabled,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <input
        onChange={onchangeText1}
        value={value1}
        type="text"
        className={styles.DescInput2}
        placeholder={placeholder1}
        style={{
          color: `${Desxcolor}`,
          border: "none",
          outline: "none",
          textAlign: "center",
          fontSize: 17,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          appearance: "none",
        }}
        disabled={disabled}
      />
      <span
        className={styles.Line2}
        style={{ backgroundColor: `${Desxcolor}` }}
      />
      <input
        onChange={onchangeText2}
        value={value2}
        type="text"
        className={styles.DescInput2}
        placeholder={placeholder2}
        style={{
          color: `${Desxcolor}`,
          border: "none",
          outline: "none",
          textAlign: "center",
          fontSize: 17,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          appearance: "none",
        }}
        disabled={disabled}
      />
    </div>
  );
}
export function RowInput({
  value,
  Tilte,
  bgcolor,
  placeholder,
  Desxcolor,
  TitleColor,
  onchangeText,
  disabled,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <input
        onChange={onchangeText}
        value={value}
        type="text"
        className={styles.DescInput}
        placeholder={placeholder}
        style={{
          color: `${Desxcolor}`,
          border: "none",
          outline: "none",
          textAlign: "center",
          fontSize: 17,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          appearance: "none",
        }}
        disabled={disabled}
      />
    </div>
  );
}
export function RowInputMT({
  value,
  Tilte,
  bgcolor,
  placeholder,
  Desxcolor,
  TitleColor,
  onchangeText,
  disabled,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <input
        onChange={onchangeText}
        value={value}
        type="number"
        className={styles.DescInput}
        placeholder={placeholder}
        style={{
          color: `${Desxcolor}`,
          border: "none",
          outline: "none",
          textAlign: "right",
          fontSize: 17,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          appearance: "none",
        }}
        disabled={disabled}
      />
      <span
        style={{
          padding: "0 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        (MT)
      </span>
    </div>
  );
}
export function RowSelectTime({
  valueDateType,
  Tilte,
  bgcolor,
  Desxcolor,
  TitleColor,
  valueTimeType1,
  disabled,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <div className={styles.select1}>
        <span className={styles.select} style={{ color: `${Desxcolor}` }}>
          <DateSelectDetail valueDateType={valueDateType} disabled={disabled} />{" "}
          :
          <TimeSelectDTSE valueTimeType={valueTimeType1} disabled={disabled} />
        </span>
      </div>
    </div>
  );
}

export function RowSelectDateTime({
  valueTimeType,
  valueDateType,
  Tilte,
  bgcolor,
  Desxcolor,
  TitleColor,
  disabled,
  text,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <div className={styles.select1}>
        <span className={styles.select} style={{ color: `${Desxcolor}` }}>
          <DateSelect valueDateType={valueDateType} disabled={disabled} /> :
          <TimeSelectM valueTimeType={valueTimeType} disabled={disabled} />
        </span>
        <div style={{ color: "red", fontSize: 15 }}> {text}</div>
      </div>
    </div>
  );
}
export function RowSelectListM({
  setData,
  data,
  api,
  Tilte,
  bgcolor,
  Desxcolor,
  TitleColor,
  disabled,
  optionTypes,
}) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <span style={{ color: `${Desxcolor}`, width: "70%" }}>
        <SelectM
          optionTypes={optionTypes}
          api={api}
          data={data}
          setDate={setData}
          disabled={disabled}
        />
      </span>
    </div>
  );
}
export function RowSelectListM2({
  Tilte,
  bgcolor,
  Desxcolor,
  TitleColor,
  disabled,
  optionTypes,
}) {
  return (
    <div
      className={styles.RowContainer}
      aria-disabled={disabled}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Title} style={{ color: `${TitleColor}` }}>
        {Tilte}
      </span>
      <span className={styles.LineContainer}>
        <span
          className={styles.Line1}
          style={{ backgroundColor: `${Desxcolor}` }}
        />
      </span>
      <span style={{ color: `${Desxcolor}`, width: "70%" }}>
        <SelectM optionTypes={optionTypes} />
      </span>
    </div>
  );
}
export function Rowworker1({ Desc4, bgcolor, color }) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Desc4} style={{ color: `${color}` }}>
        {Desc4}
      </span>
    </div>
  );
}
export function Rowworker2({ Desc1, Desc2, bgcolor, color }) {
  return (
    <div
      className={styles.RowContainer}
      style={{ backgroundColor: `${bgcolor}` }}
    >
      <span className={styles.Desc3} style={{ color: `${color}` }}>
        {Desc1}
      </span>
      <span className={styles.Line3} style={{ backgroundColor: `${color}` }} />
      <span className={styles.Desc3} style={{ color: `${color}` }}>
        {Desc2}
      </span>
    </div>
  );
}
