// 선박별 선원연결
import { Button, Container } from "@mui/material";
import React from "react";
import { mainColor } from "../../../assets/colors/color";
import ManagerAppBar from "../common/ManagerAppBar";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { getAllShipList } from "../../../recoil/GetData";
import {
  CrewAtom1,
  CrewAtom2,
  CrewAtom3,
  CrewAtom4,
} from "../../../recoil/OptionsAtom";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function CrewConnection12() {
  const navigate = useNavigate();
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const resetCrew1 = useResetRecoilState(CrewAtom1);

  const resetCrew2 = useResetRecoilState(CrewAtom2);

  const resetCrew3 = useResetRecoilState(CrewAtom3);

  const resetCrew4 = useResetRecoilState(CrewAtom4);

  const shipAllList = useRecoilValue(getAllShipList);
  console.log(shipAllList);

  const handleDetailPost = (item) => {
    console.log("item: ", item);
    navigate(
      `/manager-crew-connectionList?id=${item.shipId}&ship=${item.ship}`
    );
    resetCrew1();
    resetCrew2();
    resetCrew3();
    resetCrew4();
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {shipAllList &&
          shipAllList.map((data) => (
            <Button
              key={data.shipId}
              sx={{
                fontWeight: 700,
                fontFamily: ["Noto Sans KR", "sans-serif"],
                maxWidth: "1200px",
                width: "100%",
                borderRadius: 999,
                border: `1px solid ${mainColor}`,
              }}
              variant="contained"
              color="primary"
              onClick={() => handleDetailPost(data)}
            >
              {data.ship}
            </Button>
          ))}
      </Container>
    </div>
  );
}

export default CrewConnection12;
