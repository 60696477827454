import React from "react";
import { Button, Container } from "@mui/material";
import { Row2 } from "../../../components/Row";
import { mainColor, subColor1 } from "../../../assets/colors/color";
import { ShipTitle } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { getScheduleShip, getShipFlightLogSTS } from "../../../recoil/GetData";
import { format, parseISO } from "date-fns";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function ShipFlightLogSteamship() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const navigate = useNavigate();

  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(
    getScheduleShip(`sts?journeyDetailId=${journeyDetailId}`)
  );
  const shipApiNext = useRecoilValue(getShipFlightLogSTS(`${journeyDetailId}`));

  console.log(journeyDetailId);
  console.log(shipApi);
  console.log(shipApiNext);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const state = {
    idJourney: shipInfo.idJourney,
    journeyDetailId: shipInfo.journeyDetailId,
  };
  const navigateToPurchase = () => {
    if (shipApiNext === null) {
      navigate("/manager-ship-flight-log-steamshipNextX", {
        state: state,
      });
    } else {
      navigate("/manager-ship-flight-log-steamshipNext", {
        state: state,
      });
    }
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle ShipName={shipApi.ship} color={`${subColor1}`} />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.voyageNumber} />
          <Row2 Tilte={"용선주"} Desc={shipApi.charterer} />
          <Row2 Tilte={"모선명"} Desc={shipApi.motherShip} />
          <Row2 Tilte={"자선명"} Desc={shipApi.sonShip} />
          <Row2 Tilte={"화물명"} Desc={shipApi.cargo} />
          <Row2 Tilte={"수량"} Desc={shipApi.amount} />
          <Row2 Tilte={"장소"} Desc={shipApi.berthingPlace} />
          <Row2
            Tilte={"시작일시"}
            Desc={format(parseISO(shipApi.workStartAt), "yyyy-MM-dd HH:mm")}
          ></Row2>
          <Row2
            Tilte={"종료일시"}
            Desc={format(parseISO(shipApi.workDoneAt), "yyyy-MM-dd HH:mm")}
          />
        </div>
        <Button
          onClick={navigateToPurchase}
          sx={{
            fontFamily: ["Noto Sans KR", "sans-serif"],
            fontSize: "17px",
            fontWeight: "500",
            maxWidth: "1200px",
            width: "100%",
            bottom: 0,
            borderRadius: 0,
            height: 50,
            left: "50%",
            transform: "translateX( -50% )",
            position: "fixed",
            borderTop: "2px solid #fff",
          }}
          variant="contained"
          color="secondary"
        >
          차항정보
        </Button>
      </Container>
    </div>
  );
}

export default ShipFlightLogSteamship;
