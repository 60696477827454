import React from "react";
import { mainColor, textColor, whiteColor } from "../assets/colors/color";
import { format, parseISO } from "date-fns";
export function NoteText({ note, onChange, disabled }) {
  return (
    <div
      style={{
        borderRadius: "10px 10px 0 0",
        marginTop: "20px",
        background: `${mainColor}`,
        width: "100%",
        textAlign: "center",
        color: `${whiteColor}`,
        borderBottom: `2px solid ${mainColor}`,
      }}
    >
      <p
        style={{
          borderRadius: 10,
          background: `${mainColor}`,
          width: "100%",
          padding: "15px 5px",
          textAlign: "center",
          color: `${whiteColor}`,
        }}
      >
        비고
      </p>
      <input
        disabled={disabled}
        value={note}
        onChange={onChange}
        type="text"
        style={{
          textAlign: "center",
          width: "96%",
          padding: "15px 5px",
          border: "none",
          fontSize: 15,
        }}
        placeholder="비고를 입력해주세요"
      />
    </div>
  );
}
export function NoteTextList({ noteList, note, onChange, disabled }) {
  return (
    <div
      style={{
        borderRadius: "10px 10px 0 0",
        marginTop: "20px",
        background: `${mainColor}`,
        width: "100%",
        textAlign: "center",
        color: `${whiteColor}`,
        borderBottom: `2px solid ${mainColor}`,
      }}
    >
      <p
        style={{
          borderRadius: 10,
          background: `${mainColor}`,
          width: "100%",
          padding: "15px 5px",
          textAlign: "center",
          color: `${whiteColor}`,
        }}
      >
        비고
      </p>
      <div style={{ border: `2px solid ${mainColor}` }}>
        {noteList.map((item) => (
          <div
            key={item.writtenAt}
            style={{
              textAlign: "end",
              padding: 15,
              color: `${mainColor}`,
              backgroundColor: `${whiteColor}`,
            }}
          >
            <p style={{ fontSize: 13 }}>
              {item.writer} / {item.writtenAt}
            </p>
            <p style={{ color: `${textColor}`, marginTop: 3 }}>{item.note}</p>
          </div>
        ))}
      </div>
      <input
        disabled={disabled}
        value={note}
        onChange={onChange}
        type="text"
        style={{
          textAlign: "center",
          width: "96%",
          padding: "15px 5px",
          border: "none",
          fontSize: 15,
        }}
        placeholder="비고를 입력해주세요"
      />
    </div>
  );
}
export function NoteTextListText({ noteList }) {
  return (
    <div
      style={{
        borderRadius: "10px 10px 0 0",
        marginTop: "20px",
        background: `${mainColor}`,
        width: "100%",
        textAlign: "center",
        color: `${whiteColor}`,
        borderBottom: `2px solid ${mainColor}`,
      }}
    >
      <p
        style={{
          borderRadius: 10,
          background: `${mainColor}`,
          width: "100%",
          padding: "15px 5px",
          textAlign: "center",
          color: `${whiteColor}`,
        }}
      >
        비고
      </p>
      <div style={{ border: `2px solid ${mainColor}` }}>
        {noteList.map((item) => (
          <div
            key={item.writtenAt}
            style={{
              textAlign: "end",
              padding: 15,
              color: `${mainColor}`,
              backgroundColor: `${whiteColor}`,
            }}
          >
            <p style={{ fontSize: 13 }}>
              {item.writer} /{" "}
              {format(parseISO(item.writtenAt), "yyyy-MM-dd HH:mm")}
            </p>
            <p style={{ color: `${textColor}`, marginTop: 3 }}>{item.note}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
