import React from "react";
import { format, isSameDay, parseISO } from "date-fns";
import {
  disabledColor,
  subColor1,
  whiteColor,
} from "../../../assets/colors/color";

function ListItem({ data }) {
  console.log(data);
  const startDate = parseISO(data.workStartAt);

  const time = format(startDate, "HH:mm");

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          fontWeight: 700,
          paddingTop: 10,
          paddingRight: 10,
          borderRight: `2px solid ${disabledColor}`,
        }}
      >
        {time}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
          width: "100%",
          padding: "15px 20px",
          backgroundColor: `${subColor1}`,
          marginLeft: "10px",
          borderRadius: "10px",
          color: `${whiteColor}`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: 500, fontSize: 17 }}>{data.ship}</h1>
          <span
            style={{
              borderRadius: 999,
              border: `2px solid`,
              padding: 5,
              fontWeight: 700,
              fontSize: 15,
            }}
          >
            {data.status}
          </span>
        </div>
        <p style={{ marginTop: 6 }}>
          <span>운항회차 : </span>
          <span>{data.flightCount}</span>
        </p>
        <p>
          <span>용선주 : </span>
          <span>{data.charterer}</span>
        </p>
        <p>
          <span>화물명 : </span>
          <span>{data.cargo}</span>
        </p>
        <p>
          <span>수량 : </span>
          <span>{data.amount}</span>
        </p>
        <p>
          <span>수량누계 : </span>
          <span>{data.totalAmount}</span>
        </p>
      </div>
    </div>
  );
}

function TabListBoxS({ edata, selectedDate }) {
  const SelectSameDate =
    edata &&
    edata.filter((data) => isSameDay(parseISO(data.workStartAt), selectedDate));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      {SelectSameDate && SelectSameDate.length > 0 ? (
        SelectSameDate.map((data) => (
          <ListItem data={data} key={data.journeyId} />
        ))
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          기선 STS 일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default TabListBoxS;
