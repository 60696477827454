/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import styles from "./Login.module.css";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { noticesubList } from "../../hooks/noticesubList";
import { onButtonClick } from "../../hooks/onButtonClick";
import getCookie from "../../cookie/getCookie";
import setCookie from "../../cookie/setCookie";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Login() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const tokenJSON = sessionStorage.getItem("firebaseToken");
  const firebaseToken = JSON.parse(tokenJSON);

  const token = useCallback(async () => {
    if (!firebaseToken) return; // firebaseToken이 없으면 함수 실행 중지
    try {
      const response = await axios.patch(
        "/api/auth/fcm/token",
        { fcmToken: firebaseToken },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:15007",
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error("FCM Token update error:", error);
    }
  }, [firebaseToken]);

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "/api/auth/login",
        { uid: userId, password: userPassword },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:15007",
          },
        }
      );

      if (response.data.status === 200) {
        const { data } = response.data;
        if (data && data.approvalStatus === false) {
          alert("로그인 권한이 없습니다. 관리자에게 문의해주세요!");
        } else if (data && data.approvalStatus === true) {
          handleSuccessfulLogin(data);
        } else {
          alert(
            `귀하의 계정 ID 또는 비밀번호가 일치하지 않습니다. 다시 시도해 주세요.1 아이디=${userId} 비밀번호=${userPassword} 오류=${response.data.status}  => ${response.data.resultMsg}`
          );
        }
      } else {
        console.log(response.data.status);
        console.log(response.data.resultMsg);
        alert(
          `귀하의 계정 ID 또는 비밀번호가 일치하지 않습니다. 다시 시도해 주세요.2 아이디=${userId} 비밀번호=${userPassword} 오류=${response.data.status}  => ${response.data.resultMsg}`
        );
      }
    } catch (error) {
      console.error("Login error:", error);
      sendErrorData(error);
      alert(
        `로그인 중 오류가 발생했습니다. 관리자에게 문의해주세요. 아이디=${userId} 비밀번호=${userPassword} 오류: ${error}`
      );
    }
  };

  const handleSuccessfulLogin = (userData) => {
    const { sessionId, role } = userData;
    noticesubList();
    onButtonClick(sessionId);
    if (checkbox) {
      token();
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("role", JSON.stringify(role));
      localStorage.setItem("JSESSIONID", JSON.stringify(sessionId));
    } else {
      token();
      localStorage.setItem("userData", JSON.stringify(userData));
      sessionStorage.setItem("role", JSON.stringify(role));
    }
    navigate(role === "ADMIN" ? "/manager-main" : "/user-main");
  };

  const sendErrorData = async (error) => {
    const errorData = {
      data: error.config.data,
      accessControlAllowOrigin:
        error.response.headers["access-control-allow-credentials"],
      contentType: error.response.headers.contentType, // 이 부분이 수정되었습니다.
      method: error.config.method, // 수정: 정확한 속성 접근
      timeout: error.config.timeout, // 수정: 정확한 속성 접근
      url: error.config.url, // 수정: 정확한 속성 접근
      message: error.message,
    };
    console.log(errorData);
    try {
      const response = await axios.post("/api/error_log/add", errorData, {
        withCredentials: true,
      });
      console.log(response.data); // 성공적으로 응답을 받았을 때의 데이터를 로그에 출력
    } catch (error) {
      console.error(error); // 오류가 발생했을 때의 오류를 로그에 출력
    }
  };

  useEffect(() => {
    // 쿠키에서 JSESSIONID 값을 가져옵니다.
    let jsessionId = getCookie("JSESSIONID");
    // 로컬 스토리지에서 JSESSIONID 값을 가져옵니다.
    const jsessionIdLocal = localStorage.getItem("JSESSIONID");

    // 로컬 스토리지와 세션 스토리지에서 역할 정보를 가져옵니다.
    const localStorageRoleRaw = localStorage.getItem("role");
    const sessionStorageRoleRaw = sessionStorage.getItem("role");

    // 역할 정보가 `null`인 경우를 대비하여 빈 문자열로 초기화합니다.
    const localStorageRole = localStorageRoleRaw
      ? localStorageRoleRaw.replace(/"/g, "")
      : "";
    const sessionStorageRole = sessionStorageRoleRaw
      ? sessionStorageRoleRaw.replace(/"/g, "")
      : "";

    // 로컬 스토리지에 JSESSIONID 값이 있고, 쿠키에는 없는 경우 쿠키에 값을 설정합니다.
    if (!jsessionId && jsessionIdLocal) {
      setCookie("JSESSIONID", jsessionIdLocal, 365);
      jsessionId = jsessionIdLocal; // 새로운 jsessionId 값을 반영합니다.
    }

    // 역할 정보와 JSESSIONID 값을 기반으로 적절한 페이지로 리다이렉션합니다.
    if (localStorageRole && jsessionId) {
      navigate(
        localStorageRole === "ADMIN"
          ? "/manager-main"
          : localStorageRole === "USER"
          ? "/user-main"
          : "/"
      );
    } else if (sessionStorageRole && jsessionId) {
      navigate(
        sessionStorageRole === "ADMIN"
          ? "/manager-main"
          : sessionStorageRole === "USER"
          ? "/user-main"
          : "/"
      );
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <img src={logo} alt="logo" />
      <header className={styles.appHeader}>
        <h1 style={{ fontSize: "30px" }}>로그인</h1>
        <form className={styles.loginContainer}>
          <TextField
            type="text"
            id="filled-basic"
            label="아이디"
            variant="filled"
            value={userId}
            sx={{ width: " 100%", marginBottom: "10px" }}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
          <TextField
            type="password"
            id="filled-basic"
            label="비밀번호"
            variant="filled"
            sx={{ width: " 100%", marginBottom: "10px" }}
            value={userPassword}
            onChange={(e) => {
              setUserPassword(e.target.value);
            }}
          />

          <div className={styles.loginMore}>
            <span className={styles.autoLogin}>
              자동로그인{" "}
              <Checkbox
                color="secondary"
                {...label}
                onClick={() => setCheckbox(!checkbox)}
              />
            </span>
            <span style={{ display: "flex", gap: "5px" }}>
              <Link
                to={"/user-join"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span>회원가입</span>
              </Link>
              <span> | </span>
              <Link
                to={"/user-idFind"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span>아이디/비밀번호 찾기</span>
              </Link>
            </span>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className={styles.loginButton}
            onClick={handleSubmitLogin}
          >
            로그인
          </Button>
        </form>
      </header>
    </Container>
  );
}

export default Login;
