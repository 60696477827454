import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import ManagerShipLogSteamshipTabs from "./ManagerShipLogSteamshipTabs";
import { subColor1 } from "../../../../assets/colors/color";
import ManagerAppBar from "../../common/ManagerAppBar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CargoAtom,
  ChartererAtom,
  MotherShipNameAtom,
  SonShipNameAtom,
  TDWorkplaceAtom,
  TmtNumberAtom,
  noteSAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import {
  SteamerEndDateAtom,
  SteamerEndTimeAtom,
  SteamerStartDateAtom,
  SteamerStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import { useState } from "react";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import { parseISO } from "date-fns";
import { DateTextValidationAtom } from "../../../../recoil/TimeOptionsValidation";
import { useEffect } from "react";
import { ModalSub1, ModalSub2 } from "../../../../components/ModalSub";
import {
  OptionGetSelectorFamilly,
  getAllShipList,
  getAllShipType,
} from "../../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";

//작업일지 기선
function ManagerShipLogSteamship() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const [step, setStep] = useRecoilState(statusAtom);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const location = useLocation();
  const shipInfo = { ...location.state };

  // 선박  typeId

  const shipTypeApi = useRecoilValue(getAllShipType);
  const shipType = shipInfo.shipType;

  const shipTypeId =
    shipTypeApi &&
    shipTypeApi
      .filter((item) => (item.type === shipType ? item.shipTypeId : ""))
      .map((item) => item.shipTypeId)
      .toString();

  // 선박  id

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipName = shipInfo.shipName;

  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipName ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //용선주id값 받아오기
  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer"));
  const charterer = useRecoilValue(ChartererAtom).data;
  const chartererId =
    chartererAPI &&
    chartererAPI
      .filter((item) => (item.charterer === charterer ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //화물명id값 받아오기
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo"));
  const cargo = useRecoilValue(CargoAtom).data;
  const cargoId =
    cargoAPI &&
    cargoAPI
      .filter((item) => (item.cargo === cargo ? item.id : ""))
      .map((item) => item.id)
      .toString();

  // 수량 입력값 받아오기
  const amount = useRecoilValue(TmtNumberAtom);

  //모선명
  const motherShipName = useRecoilValue(MotherShipNameAtom);
  //자선명
  const sonShipName = useRecoilValue(SonShipNameAtom);

  //기선시작일시
  const shippingWorkStartDate = useRecoilValue(SteamerStartDateAtom);
  const shippingWorkStartTime = useRecoilValue(SteamerStartTimeAtom);
  console.log(shippingWorkStartTime);

  const shippingWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //기선 완료 일시
  const shippingWorkDoneDate = useRecoilValue(SteamerEndDateAtom);
  const shippingWorkDoneTime = useRecoilValue(SteamerEndTimeAtom);

  const shippingWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  //작업장소 id값받아오기
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const workplaceTD = useRecoilValue(TDWorkplaceAtom).data.split(" / ")[0];

  const WorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //비고값 받아오기
  const note = useRecoilValue(noteSAtom);

  const before =
    parseISO(shippingWorkStartAt).getTime() <=
    parseISO(shippingWorkDoneAt).getTime();
  const setDateTextValidationAtom = useSetRecoilState(DateTextValidationAtom);

  const data = {
    shipId: shipId,
    shipTypeId: shipTypeId,
    status: step,
    jobType: "기선STS",
    chartererId: chartererId, //용선주
    cargoId: cargoId,
    motherShipName: motherShipName, //모선명
    sonShipName: sonShipName, //자선명
    amount: amount,
    shippingWorkStartAt: shippingWorkStartAt,
    shippingWorkDoneAt: shippingWorkDoneAt,
    berthingPlaceId: WorkplaceAtomId,
    note: note,
  };
  console.log(data);

  useEffect(() => {
    if (before) {
      setDateTextValidationAtom("");
    } else {
      setDateTextValidationAtom("완료일시가 시작일시보다 빠릅니다.");
    }
  }, [before, setDateTextValidationAtom]);

  const dataValidation =
    data.shipId && //선박 아이디
    data.shipTypeId && //선박 타입 아이디
    data.status && //상태
    data.jobType && //작업구분 고정갑
    data.chartererId && //용선주
    data.cargoId && //화물명
    data.motherShipName && //모선명
    data.sonShipName && //자선명
    data.amount && //수량
    data.shippingWorkStartAt && //시작일시
    data.shippingWorkDoneAt &&
    before && //완료일시
    data.berthingPlaceId;

  const [disable, setDisable] = React.useState(false);
  const handelSub = async (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .post("/api/admin/journey/sts/add", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonGroupStep step={step} setStep={setStep} />
        <ShipTitleLog ShipName={shipInfo.shipName} color={`${subColor1}`} />
        <ManagerShipLogSteamshipTabs />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            onClick={() => {
              setmodal1(!modal1);
            }}
            disabled={!dataValidation}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            등록하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            취소하기
          </Button>
        </ButtonGroup>
        {!dataValidation ? (
          <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
        ) : null}
        {modal1 ? (
          <ModalSub1
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? <ModalSub2 setmodal1={setmodal2} /> : null}
      </Container>
    </div>
  );
}

export default ManagerShipLogSteamship;
