import React from "react";
import { Row2, RowInput, RowSelectDateTime } from "../../../../components/Row";
import {
  mainColor,
  silverColor,
  whiteColor,
} from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import { PSignificantAtom, statusAtom } from "../../../../recoil/OptionsAtom";

//선박일지 순찰 내용
function ManagerShipLogPatrolJournalFix({ shipApi }) {
  const status = useRecoilValue(statusAtom);
  //특이사항
  const [PSignificant, setPSignificant] = useRecoilState(PSignificantAtom);
  return (
    <>
      <div
        style={{
          width: "100%",

          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <div
          style={{
            backgroundColor: `${mainColor}`,
            width: "100%",
            padding: "15px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <p
            style={{
              color: `${whiteColor}`,
              fontSize: "1rem",
              lineHeight: 1.5,
              fontWeight: 700,
            }}
          >
            일지
          </p>
        </div>
        <Row2 Tilte={"항차"} Desc={"등록하기 이후 자동생성"} />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성자"} Desc="-" />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성일자"} Desc="-" />
        <Row2 Tilte={"작업구분"} Desc={shipApi.jobType} />
        <RowSelectDateTime
          Tilte={"시작일시"}
          disabled={status === "완료" ? true : false}
          valueDateType={"PlStartDate"}
          valueTimeType={"PatrolStartTime"}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          disabled={status === "완료" ? true : false}
          valueDateType={"PlEndDate"}
          valueTimeType={"PatrolEndTime"}
        />

        <Row2 Tilte={"순찰장소"} Desc={shipApi.patrolPlace} />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"특이사항"}
          placeholder={
            shipApi.remarks ? shipApi.remarks : "안전관리를 입력해 주세요."
          }
          disabled={
            shipApi.status === "완료" || status === "완료" ? true : false
          }
          onchangeText={(e) => setPSignificant(e.target.value)}
          value={PSignificant}
        />
      </div>
    </>
  );
}

export default ManagerShipLogPatrolJournalFix;
