import axios from "axios";
import { selector, selectorFamily } from "recoil";
import { v1 } from "uuid";

//선박 검사일정
export const getInspectionList = selectorFamily({
  key: `getInspectionList/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/schedule/inspection/${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getInspectionList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//선박 검사 list
export const getInspectionListOption = selector({
  key: `getInspectionListOption/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(
        `/api/schedule/admin/inspection/type/list`
      );
      return data.data;
    } catch (error) {
      console.error("Error in getInspectionListOption:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
// 선박전체 list
export const getAllShipList = selector({
  key: `getAllShipList/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(`/api/admin/ship/all`);
      return data.data;
    } catch (error) {
      console.error("Error in getAllShipList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//선박타입
export const getAllShipType = selector({
  key: `getAllShipType/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(`/api/admin/ship/type`);
      return data.data;
    } catch (error) {
      console.error("Error in getAllShipType:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//순찰 작업장소
export const getPatrolPlaceList = selector({
  key: `getPatrolPlaceList/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(`/api/journey/option/patrolPlace/list`);
      return data.data;
    } catch (error) {
      console.error("Error in getPatrolPlaceList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//검사일정디테일
export const getRegistationInspectionSchedule = selectorFamily({
  key: `getRegistationInspectionSchedule/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(
        `/api/schedule/admin/inspection/detail?inspectionScheduleId=${API}`
      );
      return data.data;
    } catch (error) {
      console.error("Error in getRegistationInspectionSchedule:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//선원관리부
export const getCrewOperationChart = selectorFamily({
  key: `getCrewOperationChart/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(
        `/api/admin/schedule/sailor?date=${API}`
      );
      return data.data;
    } catch (error) {
      console.error("Error in getCrewOperationChart:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//선원 List
export const getCrewList = selector({
  key: `getCrewList/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(`/api/auth/admin/user/list`);
      data.data.unshift({
        userId: null,
        uid: null,
        name: "선원 선택안함",
      });
      console.log(data.data);
      return data.data;
    } catch (error) {
      console.error("Error in getCrewList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//옵션 List
export const OptionGetSelectorFamilly = selectorFamily({
  key: `OptionGetSelectorFamilly/${v1()}`,
  get: (option) => async () => {
    try {
      const { data } = await axios.get(`api/journey/option/${option}/list`);
      return data.data;
    } catch (error) {
      console.error("Error in OptionGetSelectorFamilly:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//선박 디테일 데이터
export const getShipData = selectorFamily({
  key: `getShipData/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/journey/${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getShipData:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//선박 월별 데이터
export const getShipDataAdmin = selectorFamily({
  key: `getShipDataAdmin/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/admin/journey/?localDate=${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getShipDataAdmin:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//월운항계획표 선박데이터
export const getManagerShipOperationPlan = selectorFamily({
  key: `getManagerShipOperationPlan/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/plan/monthly/${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getManagerShipOperationPlan:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//예인선 list
export const getTugLsit = selector({
  key: `getTugLsit/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get("/api/admin/ship/tuglist");
      return data.data;
    } catch (error) {
      console.error("Error in getTugLsit:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//월운항계획표 선박데이터
export const getCrewShipList = selectorFamily({
  key: `getCrewShipList/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(
        `/api/admin/sailor/specific/ship/list?shipId=${API}`
      );
      return data.data;
    } catch (error) {
      console.error("Error in getCrewShipList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//운할일정 선박데이터 달마다
export const getScheduleShipList = selectorFamily({
  key: `getScheduleShipList/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/schedule/list?localDate=${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getScheduleShipList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//운할일정 선박데이터 선박마다
export const getScheduleShip = selectorFamily({
  key: `getScheduleShip/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/schedule/${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getScheduleShip:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//운항일지 차항정보
export const getShipFlightLog = selectorFamily({
  key: `getShipFlightLog/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(`/api/schedule/nextVoyage?${API}`);
      return data.data;
    } catch (error) {
      console.error("Error in getShipFlightLog:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//기선 차항정보
export const getShipFlightLogSTS = selectorFamily({
  key: `getShipFlightLogSTS/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(
        `/api/schedule/nextVoyage/sts?journeyDetailId=${API}`
      );
      return data.data;
    } catch (error) {
      console.error("Error in getShipFlightLogSTS:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//알람
export const noticeAllList = selector({
  key: `noticeAllList/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get("/api/firebase/admin/notice/list");
      return data.data;
    } catch (error) {
      console.error("Error in noticeAllList:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//알람
export const countNum = selector({
  key: `countNum/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get("/api/firebase/admin/notice/list");
      return data.data.filter((element) => "UNCONFIRMED" === element.status)
        .length;
    } catch (error) {
      console.error("Error fetching notification count:", error);
      // 오류가 발생했을 때 기본값으로 0을 반환
      return 0;
    }
  },
});

//알람디테일
export const noticeDa = selectorFamily({
  key: `noticeDa/${v1()}`,
  get: (API) => async () => {
    try {
      const { data } = await axios.get(
        `/api/firebase/admin/notice/detail?notificationId=${API}`
      );
      return data.data;
    } catch (error) {
      console.error("Error in noticeDa:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//알람디테일구독
export const noticesub = selector({
  key: `noticesub/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(
        "/api/firebase/admin/topic/subscribe/status"
      );
      return data.data;
    } catch (error) {
      console.error("Error in noticesub:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});

//선원정보
export const crewInfoList1 = selector({
  key: `crewInfoList1/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get("/api/auth/admin/user/list");
      return data.data;
    } catch (error) {
      console.error("Error in crewInfoList1:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
//선원정보
export const subscribe = selector({
  key: `subscribe/${v1()}`,
  get: async () => {
    try {
      const { data } = await axios.get(
        "/api/firebase/admin/topic/subscribe/status"
      );
      return data.data;
    } catch (error) {
      console.error("Error in subscribe:", error);
      return []; // 오류 발생 시 빈 배열 반환
    }
  },
});
