import React from "react";
import { Container } from "@mui/material";
import { Row2 } from "../../../components/Row";
import { mainColor, subColor2 } from "../../../assets/colors/color";
import { ShipTitleNext } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getShipFlightLog } from "../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ShipFlightLogPatrolNext() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);
  const shipApi = useRecoilValue(
    getShipFlightLog(
      `shipId=${shipInfo.shipId}&jobType=${shipInfo.jobType}&shippingWorkStartAt=${shipInfo.shippingWorkStartAt}`
    )
  );
  console.log(shipApi);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitleNext ShipName={shipInfo.ship} color={`${subColor2}`} />
        <div
          style={{
            width: "100%",
            borderTop: `3px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"차항차"} Desc={shipApi.nextVoyageNumber} />
          <Row2 Tilte={"일자"} Desc={shipApi.nextWorkDate} />
          <Row2 Tilte={"장소"} Desc={shipApi.patrolPlace} />
          <Row2 Tilte={"시작일시"} Desc={shipApi.nextWorkStartAt} />
          <Row2 Tilte={"종료일시"} Desc={shipApi.nextWorkDoneAt} />
        </div>
      </Container>
    </div>
  );
}

export default ShipFlightLogPatrolNext;
