import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import { subColor1 } from "../../../../assets/colors/color";
import ManagerAppBar from "../../common/ManagerAppBar";
import ManagerShipLogSteamshipTabsFix from "./ManagerShipLogSteamshipTabsFix";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DateTextValidationAtom } from "../../../../recoil/TimeOptionsValidation";
import { format, parseISO } from "date-fns";
import {
  CargoAtom,
  ChartererAtom,
  MotherShipNameAtom,
  SonShipNameAtom,
  TDWorkplaceAtom,
  TmtNumberAtom,
  noteSAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import {
  SteamerEndDateAtom,
  SteamerEndTimeAtom,
  SteamerStartDateAtom,
  SteamerStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import {
  OptionGetSelectorFamilly,
  getAllShipList,
  getAllShipType,
  getShipData,
} from "../../../../recoil/GetData";
import { useState } from "react";
import {
  STSBerthingDateAtom1,
  STSBerthingTimeAtom1,
  STSIanDateAtom1,
  STSIanTimeAtom1,
  STSOilInstallationAtom1,
  STSOilInstallationDateAtom1,
  STSRecoveryDateAtom1,
  STSRecoveryTimeAtom1,
} from "../../../../recoil/TimeAtom";
import { ModalSub3, ModalSub4 } from "../../../../components/ModalSub";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";
//작업일지 기선
function ManagerShipLogSteamshipFix() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const location = useLocation();
  const shipInfo = { ...location.state };
  console.log(shipInfo);

  const journeyId = shipInfo.idJourney;
  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(getShipData(`sts?journeyId=${journeyId}`));
  console.log(shipApi);

  const shipApi2 = useRecoilValue(
    getShipData(`sts/detail?journeyId=${journeyId}`)
  );
  console.log(shipApi2);

  const [step, setStep] = useRecoilState(statusAtom);
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const shipTypeApi = useRecoilValue(getAllShipType);
  const shipType = shipInfo.shipType;

  const shipTypeId =
    shipTypeApi &&
    shipTypeApi
      .filter((item) => (item.type === shipType ? item.shipTypeId : ""))
      .map((item) => item.shipTypeId)
      .toString();

  // 선박  id

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipName = shipInfo.shipName;

  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipName ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //용선주id값 받아오기
  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer"));
  const charterer = useRecoilValue(ChartererAtom).data;
  const setCharterer = useSetRecoilState(ChartererAtom);
  const chartererId =
    chartererAPI &&
    chartererAPI
      .filter((item) => (item.charterer === charterer ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //화물명id값 받아오기
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo"));
  const cargo = useRecoilValue(CargoAtom).data;
  const setCargo = useSetRecoilState(CargoAtom);
  const cargoId =
    cargoAPI &&
    cargoAPI
      .filter((item) => (item.cargo === cargo ? item.id : ""))
      .map((item) => item.id)
      .toString();

  // 수량 입력값 받아오기
  const [amount, setAmount] = useRecoilState(TmtNumberAtom);

  //모선명
  const [motherShipName, setMotherShipName] =
    useRecoilState(MotherShipNameAtom);
  //자선명
  const [sonShipName, setSonShipName] = useRecoilState(SonShipNameAtom);

  //기선시작일시
  const [shippingWorkStartDate, setShippingWorkStartDate] =
    useRecoilState(SteamerStartDateAtom);
  const [shippingWorkStartTime, setShippingWorkStartTime] =
    useRecoilState(SteamerStartTimeAtom);
  console.log(shippingWorkStartTime);

  const shippingWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //기선 완료 일시
  const [shippingWorkDoneDate, setShippingWorkDoneDate] =
    useRecoilState(SteamerEndDateAtom);
  const [shippingWorkDoneTime, setShippingWorkDoneTime] =
    useRecoilState(SteamerEndTimeAtom);

  const shippingWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  //작업장소 id값받아오기
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const workplaceTD = useRecoilValue(TDWorkplaceAtom).data.split(" / ")[0];
  const setWorkplaceTD = useSetRecoilState(TDWorkplaceAtom);

  const WorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //기선 접안시간 날짜
  const setSTSBerthingDate = useSetRecoilState(STSBerthingDateAtom1);
  const setSTSBerthingTime1 = useSetRecoilState(STSBerthingTimeAtom1);
  const setSTSBerthingTime2 = useRecoilValue(STSBerthingTimeAtom1);
  console.log("1이거 확인해봐23123", setSTSBerthingTime2);
  console.log("1이거 확인해봐23123", shipApi2.dockingAt);

  //기선 오일 설치 날짜
  const setSTSOilInstallationDate = useSetRecoilState(
    STSOilInstallationDateAtom1
  );
  const setSTSOilInstallation1 = useSetRecoilState(STSOilInstallationAtom1);

  //기선  기선 오일회수 날짜
  const setSTSRecoveryDate = useSetRecoilState(STSRecoveryDateAtom1);
  const setSTSRecoveryTime1 = useSetRecoilState(STSRecoveryTimeAtom1);

  //기선  기선 이안시간 날짜
  const setSTSIanDate = useSetRecoilState(STSIanDateAtom1);
  const setSTSIanTime1 = useSetRecoilState(STSIanTimeAtom1);
  const berfore =
    parseISO(shippingWorkStartAt).getTime() <=
    parseISO(shippingWorkDoneAt).getTime();

  const setDateTextValidationAtom = useSetRecoilState(DateTextValidationAtom);
  const note = useRecoilValue(noteSAtom);

  const data = {
    journeyId: journeyId,
    shipId: shipId,
    shipTypeId: shipTypeId,
    status: step,
    jobType: "기선STS",
    chartererId: chartererId, //용선주
    cargoId: cargoId,
    motherShipName: motherShipName, //모선명
    sonShipName: sonShipName, //자선명
    amount: amount,
    shippingWorkStartAt: shippingWorkStartAt,
    shippingWorkDoneAt: shippingWorkDoneAt,
    berthingPlaceId: WorkplaceAtomId,
    note: note,
  };

  useEffect(() => {
    if (shipApi2.shippingWorkStartAt !== null) {
      setShippingWorkStartDate({
        year: format(parseISO(shipApi2.shippingWorkStartAt), "yyyy"),
        month: format(parseISO(shipApi2.shippingWorkStartAt), "MM"),
        day: format(parseISO(shipApi2.shippingWorkStartAt), "dd"),
      });
      setShippingWorkStartTime({
        hh: format(parseISO(shipApi2.shippingWorkStartAt), "HH"),
        mm: format(parseISO(shipApi2.shippingWorkStartAt), "mm"),
      });
    }
    //기선 완료일시
    if (shipApi2.shippingWorkDoneAt !== null) {
      console.log(parseISO(shipApi2.shippingWorkDoneAt));
      setShippingWorkDoneDate({
        year: format(parseISO(shipApi2.shippingWorkDoneAt), "yyyy"),
        month: format(parseISO(shipApi2.shippingWorkDoneAt), "MM"),
        day: format(parseISO(shipApi2.shippingWorkDoneAt), "dd"),
      });
      setShippingWorkDoneTime({
        hh: format(parseISO(shipApi2.shippingWorkDoneAt), "HH"),
        mm: format(parseISO(shipApi2.shippingWorkDoneAt), "mm"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWorkplaceTD({ data: shipApi2.berthingAt });
    setStep(shipApi.status);
    setCargo({ data: shipApi.cargo });
    setCharterer({ data: shipApi.charterer });
    setStep(shipApi.status);

    if (berfore) {
      setDateTextValidationAtom("");
    } else {
      setDateTextValidationAtom("완료일시가 시작일시보다 빠릅니다.");
    }

    if (shipApi2.dockingAt !== null) {
      //기선 접안시간
      console.log("1231231321321", parseISO(shipApi2.dockingAt));
      setSTSBerthingDate({
        year: format(parseISO(shipApi2.dockingAt), "yyyy"),
        month: format(parseISO(shipApi2.dockingAt), "MM"),
        day: format(parseISO(shipApi2.dockingAt), "dd"),
      });
      setSTSBerthingTime1({
        hh: format(parseISO(shipApi2.dockingAt), "HH"),
        mm: format(parseISO(shipApi2.dockingAt), "mm"),
      });
    }
    if (shipApi2.setOilFenceAt !== null) {
      //오일설치
      setSTSOilInstallationDate({
        year: format(parseISO(shipApi2.setOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.setOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.setOilFenceAt), "dd"),
      });
      setSTSOilInstallation1({
        hh: format(parseISO(shipApi2.setOilFenceAt), "HH"),
        mm: format(parseISO(shipApi2.setOilFenceAt), "mm"),
      });
    }
    if (shipApi2.collectOilFenceAt !== null) {
      //오일회수
      setSTSRecoveryDate({
        year: format(parseISO(shipApi2.collectOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.collectOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.collectOilFenceAt), "dd"),
      });
      setSTSRecoveryTime1({
        hh: format(parseISO(shipApi2.collectOilFenceAt), "HH"),
        mm: format(parseISO(shipApi2.collectOilFenceAt), "mm"),
      });
    }
    if (shipApi2.departureAt !== null) {
      //이안시간
      console.log(shipApi2.departureAt);
      setSTSIanDate({
        year: format(parseISO(shipApi2.collectOilFenceAt), "yyyy"),
        month: format(parseISO(shipApi2.collectOilFenceAt), "MM"),
        day: format(parseISO(shipApi2.collectOilFenceAt), "dd"),
      });
      setSTSIanTime1({
        hh: format(parseISO(shipApi2.departureAt), "HH"),
        mm: format(parseISO(shipApi2.departureAt), "mm"),
      });
    }

    setAmount(shipApi.amount);

    setMotherShipName(shipApi.motherShip);

    setSonShipName(shipApi.sonShip);
  }, [
    berfore,
    setAmount,
    setCargo,
    setCharterer,
    setDateTextValidationAtom,
    setMotherShipName,
    setSTSBerthingDate,
    setSTSBerthingTime1,
    setSTSIanDate,
    setSTSIanTime1,
    setSTSOilInstallation1,
    setSTSOilInstallationDate,
    setSTSRecoveryDate,
    setSTSRecoveryTime1,
    setSonShipName,
    setStep,
    setWorkplaceTD,
    shipApi.amount,
    shipApi.cargo,
    shipApi.cargoName,
    shipApi.charterer,
    shipApi.chartererName,
    shipApi.motherShip,
    shipApi.motherShipName,
    shipApi.sonShip,
    shipApi.sonShipName,
    shipApi.status,
    shipApi2.berthingAt,
    shipApi2.collectOilFenceAt,
    shipApi2.departureAt,
    shipApi2.dockingAt,
    shipApi2.setOilFenceAt,
    shipApi2.shippingWorkDoneAt,
    shipApi2.shippingWorkStartAt,
  ]);

  const dataValidation =
    data.journeyId &&
    data.shipId && //선박 아이디
    data.shipTypeId && //선박 타입 아이디
    data.status && //상태
    data.jobType && //작업구분 고정갑
    data.chartererId && //용선주
    data.cargoId && //화물명
    data.motherShipName && //모선명
    data.sonShipName && //자선명
    data.amount && //수량
    data.shippingWorkStartAt && //시작일시
    data.shippingWorkDoneAt &&
    berfore && //완료일시
    data.berthingPlaceId; //작업장소

  const [disable, setDisable] = React.useState(false);
  const handelSub = async (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/sts/update/admin", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("수정된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  const delsub = () => {
    setDisable(true);
    axios
      .delete(`/api/journey/sts/delete?detailJourneyId=${journeyDetailId}`)
      .then((response) => {
        console.log(response);
        if (response.data.status === 203) {
          window.location.href = "/manager-ship-log";
        }
        if (response.data.status === 404) {
          alert("새로고침후 다시 시도해 보세요!");
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };
  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonGroupStep step={step} setStep={setStep} />
        <ShipTitleLog ShipName={shipInfo.shipName} color={`${subColor1}`} />
        <ManagerShipLogSteamshipTabsFix shipApi={shipApi} shipApi2={shipApi2} />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            onClick={() => {
              setmodal1(!modal1);
            }}
            disabled={!dataValidation}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            수정하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            // onClick={delsub}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            삭제하기
          </Button>
        </ButtonGroup>
        {!dataValidation ? (
          <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
        ) : null}
        {modal1 ? (
          <ModalSub3
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? (
          <ModalSub4
            handelDel={delsub}
            setmodal1={setmodal2}
            disable={disable}
          />
        ) : null}
      </Container>
    </div>
  );
}

export default ManagerShipLogSteamshipFix;
