import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  PatrolEndTimeAtom,
  PatrolStartTimeAtom,
  SteamerEndTimeAtom,
  SteamerStartTimeAtom,
  TDroppingEndTimeAtom,
  TDroppingStartTimeAtom,
  TUnloadingEndTimeAtom,
  TUnloadingStartTimeAtom,
} from "../recoil/DateAndTimeAtom";
import styles from "./TimeSelectM.module.css";

const TimeSelections = {
  hh: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  mm: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
};

function TimeSelectM({ valueTimeType, disabled }) {
  const [timeShow, setTimeShow] = useState(false);

  //운송 적하 시작일시 시간
  const [tDroppingStartTime, setTDroppingStartTime] = useRecoilState(
    TDroppingStartTimeAtom
  );
  const resettDroppingStartTime = useResetRecoilState(TDroppingStartTimeAtom);

  //운송 적하 완료일시 시간
  const [tDroppingEndTime, setTDroppingEndTime] =
    useRecoilState(TDroppingEndTimeAtom);
  const resettDroppingEndTime = useResetRecoilState(TDroppingEndTimeAtom);

  //운송 양하 시작일시 시간
  const [tUnloadingStartTime, setTUnloadingStartTime] = useRecoilState(
    TUnloadingStartTimeAtom
  );
  const resettUnloadingStartTime = useResetRecoilState(TUnloadingStartTimeAtom);

  //운송 양하 완료일시 시간
  const [tUnloadingEndTime, setTUnloadingEndTime] = useRecoilState(
    TUnloadingEndTimeAtom
  );
  const resettUnloadingEndTime = useResetRecoilState(TUnloadingEndTimeAtom);

  //기선 시작일시 시간
  const [steamerStartTime, setSteamerStartTime] =
    useRecoilState(SteamerStartTimeAtom);
  const resetsteamerStartTime = useResetRecoilState(SteamerStartTimeAtom);

  //기선 완료일시 시간
  const [steamerEndTime, setSteamerEndTime] =
    useRecoilState(SteamerEndTimeAtom);
  const resetsteamerEndTime = useResetRecoilState(SteamerEndTimeAtom);
  //순찰 시작일시 시간
  const [patrolStartTime, setPatrolStartTime] =
    useRecoilState(PatrolStartTimeAtom);
  const resetpatrolStartTime = useResetRecoilState(PatrolStartTimeAtom);
  //순찰 완료일시 시간
  const [patrolEndTime, setPatrolEndTime] = useRecoilState(PatrolEndTimeAtom);
  const resetpatrolEndTime = useResetRecoilState(PatrolEndTimeAtom);

  const resetClick = () => {
    if (valueTimeType === "TDroppingStartTime") {
      setTimeShow(!timeShow);
      resettDroppingStartTime();
    }
    if (valueTimeType === "TDroppingEndTime") {
      resettDroppingEndTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUnloadingStartTime") {
      resettUnloadingStartTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUnloadingEndTime") {
      resettUnloadingEndTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "SteamerStartTime") {
      resetsteamerStartTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "SteamerEndTime") {
      resetsteamerEndTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "PatrolStartTime") {
      resetpatrolStartTime();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "PatrolEndTime") {
      resetpatrolEndTime();
      setTimeShow(!timeShow);
    }
  };
  return (
    <>
      <button
        className={timeShow === false ? styles.btn : styles.click}
        disabled={disabled}
        onClick={() => setTimeShow(!timeShow)}
      >
        {valueTimeType === "TDroppingStartTime" //운송 적하 시작일시 시간
          ? `${tDroppingStartTime.hh}:${tDroppingStartTime.mm}`
          : valueTimeType === "TDroppingEndTime" //운송 적하 완료일시 시간
          ? `${tDroppingEndTime.hh}:${tDroppingEndTime.mm}`
          : valueTimeType === "TUnloadingStartTime" //운송 양하 시작일시 시간
          ? `${tUnloadingStartTime.hh}:${tUnloadingStartTime.mm}`
          : valueTimeType === "TUnloadingEndTime" //운송 양하 완료일시 시간
          ? `${tUnloadingEndTime.hh}:${tUnloadingEndTime.mm}`
          : valueTimeType === "SteamerStartTime" //기선 시작일시 시간
          ? `${steamerStartTime.hh}:${steamerStartTime.mm}`
          : valueTimeType === "SteamerEndTime" //기선 완료 일시 시간
          ? `${steamerEndTime.hh}:${steamerEndTime.mm}`
          : valueTimeType === "PatrolStartTime" //순찰 시작일시 시간
          ? `${patrolStartTime.hh}:${patrolStartTime.mm}`
          : valueTimeType === "PatrolEndTime" //순찰 완료 일시 시간
          ? `${patrolEndTime.hh}:${patrolEndTime.mm}`
          : null}
      </button>

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                value={
                  valueTimeType === "TDroppingStartTime" //운송 적하 시작일시 시간
                    ? tDroppingStartTime
                    : valueTimeType === "TDroppingEndTime" //운송 적하 완료일시 시간
                    ? tDroppingEndTime
                    : valueTimeType === "TUnloadingStartTime" //운송 양하 시작일시 시간
                    ? tUnloadingStartTime
                    : valueTimeType === "TUnloadingEndTime" //운송 양하 완료일시 시간
                    ? tUnloadingEndTime
                    : valueTimeType === "SteamerStartTime" //기선 시작일시 시간
                    ? steamerStartTime
                    : valueTimeType === "SteamerEndTime" //기선 완료 일시 시간
                    ? steamerEndTime
                    : valueTimeType === "PatrolStartTime" //순찰 시작일시 시간
                    ? patrolStartTime
                    : valueTimeType === "PatrolEndTime" //순찰 완료 일시 시간
                    ? patrolEndTime
                    : null
                }
                onChange={
                  valueTimeType === "TDroppingStartTime" //운송 적하 시작일시 시간
                    ? setTDroppingStartTime
                    : valueTimeType === "TDroppingEndTime" //운송 적하 완료일시 시간
                    ? setTDroppingEndTime
                    : valueTimeType === "TUnloadingStartTime" //운송 양하 시작일시 시간
                    ? setTUnloadingStartTime
                    : valueTimeType === "TUnloadingEndTime" //운송 양하 완료일시 시간
                    ? setTUnloadingEndTime
                    : valueTimeType === "SteamerStartTime" //기선 시작일시 시간
                    ? setSteamerStartTime
                    : valueTimeType === "SteamerEndTime" //기선 완료 일시 시간
                    ? setSteamerEndTime
                    : valueTimeType === "PatrolStartTime" //순찰 시작일시 시간
                    ? setPatrolStartTime
                    : valueTimeType === "PatrolEndTime" //순찰 완료 일시 시간
                    ? setPatrolEndTime
                    : null
                }
                wheel="normal"
              >
                {Object.keys(TimeSelections).map((name) => (
                  <Picker.Column key={name} name={name}>
                    {TimeSelections[name].map((option) => (
                      <Picker.Item
                        key={option}
                        value={option}
                        style={{ fontSize: 20 }}
                      >
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ))}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TimeSelectM;
