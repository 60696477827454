import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  BrokerAtom,
  CargoAtom,
  ChartererAtom,
  RecipientAtom,
  SupplierAtom,
  TDCertifierAtom,
  TDLoaderAndUnloaderAtom,
  TDTug,
  TDWorkplaceAtom,
  TUCertifierAtom,
  TULoaderAndUnloaderAtom,
  TUTug,
  TUWorkplaceAtom,
} from "../recoil/OptionsAtom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function SelectM({ optionTypes, api, disabled }) {
  const [timeShow, setTimeShow] = useState(false);

  // 용선주
  const chartererList = {
    data: api && api.map((item) => item.charterer),
  };
  const [charterer, setCharterer] = useRecoilState(ChartererAtom);
  const resetcharterer = useResetRecoilState(ChartererAtom);

  //화물명
  const cargoList = { data: api && api.map((item) => item.cargo) };
  const [cargo, setCargo] = useRecoilState(CargoAtom);
  const resetcargo = useResetRecoilState(CargoAtom);
  //공급자
  const supplierList = {
    data: api && api.map((item) => item.supplier),
  };
  const [supplier, setSupplier] = useRecoilState(SupplierAtom);
  const resetsupplier = useResetRecoilState(SupplierAtom);

  //수급자

  const recipientList = {
    data: api && api.map((item) => item.recipient),
  };
  const [recipient, setRecipient] = useRecoilState(RecipientAtom);
  const resetrecipient = useResetRecoilState(RecipientAtom);

  //중개사

  const brokerList = {
    data: api && api.map((item) => item.broker),
  };
  const [broker, setBroker] = useRecoilState(BrokerAtom);
  const resetbroker = useResetRecoilState(BrokerAtom);

  //작업장소운송 적하

  const workplaceList = {
    data:
      api &&
      api.map((item) => `${item.berthingPlaceName} / (${item.berthingCode})`),
  };
  const [tdWorkplace, setTDWorkplace] = useRecoilState(TDWorkplaceAtom);
  const resettdWorkplace = useResetRecoilState(TDWorkplaceAtom);
  //작업장소운송 양하
  const workplaceListTU = {
    data:
      api &&
      api.map((item) => `${item.berthingPlaceName} / (${item.berthingCode})`),
  };
  const [tuWorkplace, setTUWorkplace] = useRecoilState(TUWorkplaceAtom);
  const resettuWorkplace = useResetRecoilState(TUWorkplaceAtom);

  // 적하 예인선

  const tdTugList = {
    data: api && api.map((item) => item.shipName),
  };
  const [tdTug, setTDTug] = useRecoilState(TDTug);
  const resettdTug = useResetRecoilState(TDTug);

  const tuTugList = {
    data: api && api.map((item) => item.shipName),
  };
  const [tuTug, setTUTug] = useRecoilState(TUTug);
  const resettuTug = useResetRecoilState(TUTug);
  //검정사 적하

  const TDcertifierList = {
    data: api && api.map((item) => item.certifier),
  };
  const [tdCertifier, setTDCertifier] = useRecoilState(TDCertifierAtom);
  const resettdCertifier = useResetRecoilState(TDCertifierAtom);
  //검정사양하
  const TUcertifierList = {
    data: api && api.map((item) => item.certifier),
  };

  const [tuCertifier, setTUCertifier] = useRecoilState(TUCertifierAtom);
  const resettuCertifier = useResetRecoilState(TUCertifierAtom);

  //하역사 적하

  const TDloaderAndUnloaderList = {
    data: api && api.map((item) => item.unLoader),
  };
  const [tdLoaderAndUnloader, setTdLoaderAndUnloader] = useRecoilState(
    TDLoaderAndUnloaderAtom
  );
  const resettdLoaderAndUnloader = useResetRecoilState(TDLoaderAndUnloaderAtom);

  //하역사 양하
  const TUloaderAndUnloaderList = {
    data: api && api.map((item) => item.unLoader),
  };
  const [tuLoaderAndUnloader, setTuLoaderAndUnloader] = useRecoilState(
    TULoaderAndUnloaderAtom
  );
  const resettuLoaderAndUnloader = useResetRecoilState(TULoaderAndUnloaderAtom);

  const [f, setf] = useState(0);
  const [f1, setf1] = useState(0);
  const [f2, setf2] = useState(0);
  const [f3, setf3] = useState(0);
  const [f4, setf4] = useState(0);
  const [f5, setf5] = useState(0);
  const [f6, setf6] = useState(0);
  const [f7, setf7] = useState(0);
  const [f8, setf8] = useState(0);
  const [f9, setf9] = useState(0);
  const [f10, setf10] = useState(0);
  const [f11, setf11] = useState(0);
  const [f12, setf12] = useState(0);

  const Click = () => {
    if (optionTypes === "charterer" && f === 0) {
      setTimeShow(!timeShow);
      setCharterer({ data: "기타" });
      setf(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "cargo" && f1 === 0) {
      setTimeShow(!timeShow);
      setCargo({ data: "기타" });
      setf1(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "supplier" && f2 === 0) {
      setTimeShow(!timeShow);
      setSupplier({ data: "기타" });
      setf2(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "recipient" && f3 === 0) {
      setTimeShow(!timeShow);
      setRecipient({ data: "기타" });
      setf3(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "broker" && f4 === 0) {
      setTimeShow(!timeShow);
      setBroker({ data: "기타" });
      setf4(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "TDworkplace" && f5 === 0) {
      setTimeShow(!timeShow);
      setTDWorkplace({ data: "광양 OCI 부두 / (MFK01)" });
      setf5(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "TUworkplace" && f6 === 0) {
      setTimeShow(!timeShow);
      setTUWorkplace({ data: "광양 OCI 부두 / (MFK01)" });
      setf6(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "TDTug" && f7 === 0) {
      setTimeShow(!timeShow);
      setTDTug({ data: "1효동호" });
      setf7(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "TUTug" && f8 === 0) {
      setTimeShow(!timeShow);
      setTUTug({ data: "1효동호" });
      setf8(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "tdCertifier" && f9 === 0) {
      setTimeShow(!timeShow);
      setTDCertifier({ data: "동아검정" });
      setf9(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "tuCertifier" && f10 === 0) {
      setTimeShow(!timeShow);
      setTUCertifier({ data: "동아검정" });
      setf10(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "tdLoaderAndUnloader" && f11 === 0) {
      setTimeShow(!timeShow);
      setTdLoaderAndUnloader({ data: "효동엠에스티에스" });
      setf11(1);
    } else {
      setTimeShow(!timeShow);
    }
    if (optionTypes === "tuLoaderAndUnloader" && f12 === 0) {
      setTimeShow(!timeShow);
      setTuLoaderAndUnloader({ data: "효동엠에스티에스" });
      setf12(1);
    } else {
      setTimeShow(!timeShow);
    }
  };

  const resetClick = () => {
    if (optionTypes === "charterer") {
      resetcharterer();
      setTimeShow(!timeShow);
      setf(0);
    }
    if (optionTypes === "cargo") {
      resetcargo();
      setTimeShow(!timeShow);
      setf1(0);
    }
    if (optionTypes === "supplier") {
      resetsupplier();
      setTimeShow(!timeShow);
      setf2(0);
    }
    if (optionTypes === "recipient") {
      resetrecipient();
      setTimeShow(!timeShow);
      setf3(0);
    }
    if (optionTypes === "broker") {
      resetbroker();
      setTimeShow(!timeShow);
      setf4(0);
    }
    if (optionTypes === "TDworkplace") {
      resettdWorkplace();
      setTimeShow(!timeShow);
      setf5(0);
    }
    if (optionTypes === "TUworkplace") {
      resettuWorkplace();
      setTimeShow(!timeShow);
      setf6(0);
    }
    if (optionTypes === "TDTug") {
      resettdTug();
      setTimeShow(!timeShow);
      setf7(0);
    }
    if (optionTypes === "TUTug") {
      resettuTug();
      setTimeShow(!timeShow);
      setf8(0);
    }
    if (optionTypes === "tdCertifier") {
      resettdCertifier();
      setTimeShow(!timeShow);
      setf9(0);
    }
    if (optionTypes === "tuCertifier") {
      resettuCertifier();
      setTimeShow(!timeShow);
      setf10(0);
    }
    if (optionTypes === "tdLoaderAndUnloader") {
      resettdLoaderAndUnloader();
      setTimeShow(!timeShow);
      setf11(0);
    }
    if (optionTypes === "tuLoaderAndUnloader") {
      resettuLoaderAndUnloader();
      setTimeShow(!timeShow);
      setf12(0);
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        onClick={Click}
        style={{
          width: "100%",
          borderRadius: 5,
          cursor: "pointer",
          padding: "16px 8px",
          border: "none",
          background: `${whiteColor}`,
          fontSize: 16,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {optionTypes === "charterer" // 용선주
          ? `${charterer.data}`
          : optionTypes === "cargo" //화물명
          ? `${cargo.data}`
          : optionTypes === "supplier" //공급자
          ? `${supplier.data}`
          : optionTypes === "recipient" //수급자
          ? `${recipient.data}`
          : optionTypes === "broker" //중개사
          ? `${broker.data}`
          : optionTypes === "TDworkplace" //접안장소 적하
          ? `${tdWorkplace.data}`
          : optionTypes === "TUworkplace" // 접안장소 양하
          ? `${tuWorkplace.data}`
          : optionTypes === "TDTug" //예인선 적하
          ? `${tdTug.data}`
          : optionTypes === "TUTug" //예인선 양하
          ? `${tuTug.data}`
          : optionTypes === "tdCertifier" //감정사 적하
          ? `${tdCertifier.data}`
          : optionTypes === "tuCertifier" //감정사 양하
          ? `${tuCertifier.data}`
          : optionTypes === "tdLoaderAndUnloader" //하역사 적하
          ? `${tdLoaderAndUnloader.data}`
          : optionTypes === "tuLoaderAndUnloader" //하역사 양하
          ? `${tuLoaderAndUnloader.data}`
          : null}
        {timeShow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </button>

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                style={{ textAlign: "center" }}
                value={
                  optionTypes === "charterer" //용선주
                    ? charterer
                    : optionTypes === "cargo" //화물명
                    ? cargo
                    : optionTypes === "supplier" //공급자
                    ? supplier
                    : optionTypes === "recipient" //수급자
                    ? recipient
                    : optionTypes === "broker" //중개자
                    ? broker
                    : optionTypes === "TDworkplace" //운송 적하 접안장소
                    ? tdWorkplace
                    : optionTypes === "TUworkplace" //운송 양하 접안장소
                    ? tuWorkplace
                    : optionTypes === "TDTug" //예인선 적하
                    ? tdTug
                    : optionTypes === "TUTug" //예인선 양하
                    ? tuTug
                    : optionTypes === "tdCertifier" //감정사 적하
                    ? tdCertifier
                    : optionTypes === "tuCertifier" //감정사 양하
                    ? tuCertifier
                    : optionTypes === "tdLoaderAndUnloader" //하역사 적하
                    ? tdLoaderAndUnloader
                    : optionTypes === "tuLoaderAndUnloader" //하역사 양하
                    ? tuLoaderAndUnloader
                    : null
                }
                onChange={
                  optionTypes === "charterer" //용선주
                    ? (e) => setCharterer(e)
                    : optionTypes === "cargo" //화물명
                    ? (e) => setCargo(e)
                    : optionTypes === "supplier" //공급자
                    ? (e) => setSupplier(e)
                    : optionTypes === "recipient" //수급자
                    ? (e) => setRecipient(e)
                    : optionTypes === "broker" //중개사
                    ? (e) => setBroker(e)
                    : optionTypes === "TDworkplace" //운송 적하 접안장소
                    ? (e) => setTDWorkplace(e)
                    : optionTypes === "TUworkplace" //운송 양하 접안장소
                    ? (e) => setTUWorkplace(e)
                    : optionTypes === "TDTug" //예인선 양하
                    ? (e) => setTDTug(e)
                    : optionTypes === "TUTug" //예인선 적하
                    ? (e) => setTUTug(e)
                    : optionTypes === "tdCertifier" //감정사 적하
                    ? (e) => setTDCertifier(e)
                    : optionTypes === "tuCertifier" //감정사 양하
                    ? (e) => setTUCertifier(e)
                    : optionTypes === "tdLoaderAndUnloader" //하역사 적하
                    ? (e) => setTdLoaderAndUnloader(e)
                    : optionTypes === "tuLoaderAndUnloader" //하역사 적하
                    ? (e) => setTuLoaderAndUnloader(e)
                    : null
                }
              >
                {Object.keys(
                  optionTypes === "charterer" //용선주
                    ? chartererList
                    : optionTypes === "cargo" //화물명
                    ? cargoList
                    : optionTypes === "supplier" //공급자
                    ? supplierList
                    : optionTypes === "recipient" //수급자
                    ? recipientList
                    : optionTypes === "broker" //중개사
                    ? brokerList
                    : optionTypes === "TDworkplace" //운송 적하 접안장소
                    ? workplaceList
                    : optionTypes === "TUworkplace" //운송 양하 접안장소
                    ? workplaceListTU
                    : optionTypes === "TDTug" //예인선 적하
                    ? tdTugList
                    : optionTypes === "TUTug" //예인선 양하
                    ? tuTugList
                    : optionTypes === "tdCertifier" //감정사 적하
                    ? TDcertifierList
                    : optionTypes === "tuCertifier" //감정사 양하
                    ? TUcertifierList
                    : optionTypes === "tdLoaderAndUnloader" //하역사 적하
                    ? TDloaderAndUnloaderList
                    : optionTypes === "tuLoaderAndUnloader" //하역사 양하
                    ? TUloaderAndUnloaderList
                    : null
                ).map((name) => (
                  <Picker.Column key={name} name={name}>
                    {(optionTypes === "charterer" //용선주
                      ? chartererList[name]
                      : optionTypes === "cargo" //화물명
                      ? cargoList[name]
                      : optionTypes === "supplier" //공급자
                      ? supplierList[name]
                      : optionTypes === "recipient" //수급자
                      ? recipientList[name]
                      : optionTypes === "broker" //중개사
                      ? brokerList[name]
                      : optionTypes === "TDworkplace" //운송 적하 접안장소
                      ? workplaceList[name]
                      : optionTypes === "TUworkplace" //운송 양하 접안장소
                      ? workplaceListTU[name]
                      : optionTypes === "TDTug" //예인선 적하
                      ? tdTugList[name]
                      : optionTypes === "TUTug" //예인선 양하
                      ? tuTugList[name]
                      : optionTypes === "tdCertifier" //감정사 적하
                      ? TDcertifierList[name]
                      : optionTypes === "tuCertifier" //감정사 양하
                      ? TUcertifierList[name]
                      : optionTypes === "tdLoaderAndUnloader" //하역사 적하
                      ? TDloaderAndUnloaderList[name]
                      : optionTypes === "tuLoaderAndUnloader" //하역사 양하
                      ? TUloaderAndUnloaderList[name]
                      : null
                    ).map((option, idx) => (
                      <Picker.Item
                        key={idx}
                        value={option}
                        style={{ fontSize: 20 }}
                      >
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ))}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SelectM;
