import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { mainColor, whiteColor } from "../../../assets/colors/color";
import axios from "axios";
import { noticesub } from "../../../recoil/GetData";
import { useRecoilValue } from "recoil";
import ManagerAppBar from "./ManagerAppBar";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ManagerAlarmHD() {
  const subs = useRecoilValue(noticesub);
  const [checked, setChecked] = useState([
    subs.scheduleTopic,
    subs.inspectionsTopic,
  ]);

  // API 호출을 관리하는 함수
  const manageSubscription = async (topic, isSubscribing) => {
    const action = isSubscribing ? "subscribe" : "unsubscribe";
    try {
      const response = await axios.post(
        `/api/firebase/${action}?topicName=hyodong-${topic}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:15007",
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Subscription error:", error);
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  // API 호출을 관리하는 함수 All
  const manageSubscriptionAll = async (isSubscribing) => {
    const action = isSubscribing ? "subscribe" : "unsubscribe";
    try {
      const response = await axios.post(`/api/firebase/${action}/all`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      });
      console.log(response);
    } catch (error) {
      console.error("Subscription error:", error);
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  // 체크박스 변경 처리 함수
  const handleChange = (index, event) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);

    const topic = index === 0 ? "schedule" : "inspection";
    manageSubscription(topic, event.target.checked);
  };

  // "모든 알림" 스위치 처리 함수
  const handleAllChange = (event) => {
    const newCheckedValue = event.target.checked;
    setChecked([newCheckedValue, newCheckedValue]);

    manageSubscriptionAll(newCheckedValue);
  };

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  // 구독 상태에 따른 처리
  useEffect(() => {
    setChecked([subs.scheduleTopic, subs.inspectionsTopic]);
  }, [subs.scheduleTopic, subs.inspectionsTopic]);

  return (
    <div
      style={{
        position: "fixed",
        marginTop: 56,
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: "#fff",
      }}
    >
      <ManagerAppBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: `${mainColor}`,
          padding: 10,
          color: `${whiteColor}`,
        }}
      >
        <Typography sx={{ marginLeft: "15px", fontSize: "18px" }}>
          모든 알림
        </Typography>
        <FormControlLabel
          control={
            <IOSSwitch11
              sx={{ m: 1 }}
              checked={checked[0] && checked[1]}
              onChange={handleAllChange}
            />
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Typography sx={{ marginLeft: "15px", fontSize: "17px" }}>
          선박 작업 일정이 새로 생성되었을 때
        </Typography>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={checked[0]}
              onChange={(e) => handleChange(0, e)}
            />
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Typography sx={{ marginLeft: "15px", fontSize: "17px" }}>
          선박 검사 일정이 새로 생성되었을 때
        </Typography>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={checked[1]}
              onChange={(e) => handleChange(1, e)}
            />
          }
        />
      </div>
    </div>
  );
}

const IOSSwitch11 = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    color: "#3A5F96",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        border: "2px solid #fff",
        backgroundColor: theme.palette.mode === "dark" ? "#3A5F96" : "#3A5F96",
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#3A5F96",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    marginTop: 1,
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#3A5F96" : "#3A5F96",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default ManagerAlarmHD;
