import dayjs from "dayjs";
import { atom } from "recoil";
import { v1 } from "uuid";

const now = dayjs(new Date());

// 운송 일지 적하 접안날짜시간

export const TDBerthingDateAtom1 = atom({
  key: `TDBerthingDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});

export const TDBerthingTimeAtom1 = atom({
  key: `TDBerthingTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송 일지 적하 호스연결
export const TDHoseConnectionDateAtom1 = atom({
  key: `TDHoseConnectionDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TDHoseConnectionAtom1 = atom({
  key: `TDHoseConnectionAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송 일지 적하 작업시작
export const TDStartWorkDateAtom1 = atom({
  key: `TDStartWorkDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TDStartWorkAtom1 = atom({
  key: `TDStartWorkAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송일지 적하 작업완료
export const TDWorkCompleteDateAtom1 = atom({
  key: `TDWorkCompleteDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TDWorkCompleteAtom1 = atom({
  key: `TDWorkCompleteAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//운송일지 적하 호스분리
export const TDHoseSeparationDateAtom1 = atom({
  key: `TDHoseSeparationDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TDHoseSeparationAtom1 = atom({
  key: `TDHoseSeparationAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//운송일지 적하 이안시간
export const TDIanTimeDateAtom1 = atom({
  key: `TDIanTimeDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TDIanTimeAtom1 = atom({
  key: `TDIanTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송 일지 양하 접안시간
export const TUBerthingTimeDateAtom1 = atom({
  key: `TUBerthingTimeDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUBerthingTimeAtom1 = atom({
  key: `TUBerthingTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송 일지 양하 호스연결
export const TUHoseConnectionDateAtom1 = atom({
  key: `TUHoseConnectionDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUHoseConnectionAtom1 = atom({
  key: `TUHoseConnectionAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송 일지 양하 작업시작
export const TUStartWorkDateAtom1 = atom({
  key: `TUStartWorkDateAtom11/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUStartWorkAtom1 = atom({
  key: `TUStartWorkAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 운송일지 양하 작업완료
export const TUWorkCompleteDateAtom1 = atom({
  key: `TUWorkCompleteDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUWorkCompleteAtom1 = atom({
  key: `TUWorkCompleteAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//운송일지 양하 호스분리
export const TUHoseSeparationDateAtom1 = atom({
  key: `TUHoseSeparationDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUHoseSeparationAtom1 = atom({
  key: `TUHoseSeparationAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//운송일지 양하 이안시간
export const TUIanTimeDateAtom1 = atom({
  key: `TUIanTimeDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const TUIanTimeAtom1 = atom({
  key: `TUIanTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 기선 접안시간
export const STSBerthingDateAtom1 = atom({
  key: `STSBerthingDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const STSBerthingTimeAtom1 = atom({
  key: `STSBerthingTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//기선 오일 설치
export const STSOilInstallationDateAtom1 = atom({
  key: `STSOilInstallationDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const STSOilInstallationAtom1 = atom({
  key: `STSOilInstallationAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

//기선 오일회수
export const STSRecoveryDateAtom1 = atom({
  key: `STSRecoveryDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const STSRecoveryTimeAtom1 = atom({
  key: `STSRecoveryTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});

// 기선 이안시간
export const STSIanDateAtom1 = atom({
  key: ` STSIanDateAtom1/${v1()}`,
  default: {
    year: `${now.get("year")}`,
    month:
      now.get("month") + 1 < 10
        ? `0${now.get("month") + 1}`
        : `${now.get("month") + 1}`,
    day:
      `${now.get("date")}` < 10 ? `0${now.get("date")}` : `${now.get("date")}`,
  },
});
export const STSIanTimeAtom1 = atom({
  key: `STSIanTimeAtom1/${v1()}`,
  default: {
    hh: "--",
    mm: "--",
  },
});
