import React from "react";
import { Row2, RowInputMT } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import { TmtNumberAtom } from "../../../../recoil/OptionsAtom";

//일지
function UserShipLogTransitJournal({ shipApi }) {
  const status = shipApi.status;
  const [TmtNumber, setTmtNumber] = useRecoilState(TmtNumberAtom);
  const userJSON = localStorage.getItem("userData");

  const userInfo = JSON.parse(userJSON);

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2
          Tilte={"항차"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.voyage}
        />
        <Row2
          Tilte={"작성자"}
          TitleColor={`${silverColor}`}
          Desc={userInfo?.name}
        />
        <Row2
          Tilte={"작성일자"}
          TitleColor={`${silverColor}`}
          Desc={format(new Date(), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          Tilte={"용선주"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.charterer}
        />
        <Row2
          Tilte={"중개사"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.broker}
        />
        <Row2
          Tilte={"화물명"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.cargo}
        />
        <Row2
          Tilte={"공급자"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.supplier}
        />
        <Row2
          Tilte={"수급자"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.recipient}
        />
        <Row2
          Tilte={"수량"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.amount}
        />
        <RowInputMT
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"B/L"}
          placeholder={"수량을 입력해주세요"}
          onchangeText={(e) => setTmtNumber(e.target.value)}
          value={TmtNumber}
        />
      </div>
    </div>
  );
}

export default UserShipLogTransitJournal;
