import React, { useEffect } from "react";
import UserAppBar from "../common/UserAppBar";
import { Container } from "@mui/material";
import { Row2, Rowworker1 } from "../../../components/Row";
import { mainColor, subColor2, whiteColor } from "../../../assets/colors/color";
import { ShipTitle } from "../../../components/ShipTitle";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { format, parseISO } from "date-fns";
import { getScheduleShip } from "../../../recoil/GetData";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";

//선박 작업 일정 순찰
function UserShipSchedulePatrol() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const journeyDetailId = shipInfo.journeyDetailId;
  console.log(journeyDetailId);

  const shipApi = useRecoilValue(
    getScheduleShip(`patrol?journeyDetailId=${journeyDetailId}`)
  );
  console.log(shipApi);

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          Step={shipApi.status}
          ShipName={shipApi.ship}
          Date={format(parseISO(shipInfo.shippingWorkStartAt), "yyyy / M / dd")}
          color={`${subColor2}`}
        />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.voyageNumber} />
          <Row2
            Tilte={"구분"}
            Desc="순찰"
            bgcolor={`${mainColor}`}
            TitleColor={`${whiteColor}`}
            Desxcolor={`${whiteColor}`}
          />
          <Row2
            bgcolor={`${whiteColor}`}
            Tilte={"작업지"}
            Desc={shipApi.patrolPlaceList}
          />
          <Row2
            Tilte={"시작일시"}
            Desc={format(parseISO(shipApi.workStartAt), "yyyy-MM-dd HH:mm")}
          />
          <Row2
            Tilte={"완료일시"}
            Desc={format(parseISO(shipApi.workDoneAt), "yyyy-MM-dd HH:mm")}
          />
          <Row2 Tilte={"특이사항"} Desc={shipApi.remark} />
        </div>
        <div style={{ width: "100%", marginTop: 10 }}>
          <h1 style={{ fontSize: 22, textAlign: "center" }}>근무자 정보</h1>
          <div
            style={{
              borderBottom: `2px solid ${mainColor}`,
              marginTop: 10,
            }}
          >
            <Rowworker1
              Desc4={"순찰"}
              bgcolor={`${mainColor}`}
              color={`${whiteColor}`}
            />
            {shipApi.sailorList.length > 0 ? (
              shipApi.sailorList.map((data) => (
                <Rowworker1 Desc4={data.name} key={data.name} />
              ))
            ) : (
              <p>선원을 추가하세요</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserShipSchedulePatrol;
