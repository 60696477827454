import React from "react";
import {
  RowInput,
  RowSelectDateTime,
  RowSelectListM,
  RowSelectTime,
} from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import { OptionGetSelectorFamilly } from "../../../../recoil/GetData";
import { noteSAtom, statusAtom } from "../../../../recoil/OptionsAtom";
import { NoteText } from "../../../../components/Note";
import { DateTextValidationAtom } from "../../../../recoil/TimeOptionsValidation";

//선박 일지 등록 기선 내용
function ManagerShipLogSteamshipDetail() {
  const status = useRecoilValue(statusAtom);
  const [note, setNote] = useRecoilState(noteSAtom);
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const DateTextValidation = useRecoilValue(DateTextValidationAtom);
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <RowSelectDateTime
          Tilte={"시작일시"}
          valueDateType={"StStartDate"}
          valueTimeType={"SteamerStartTime"}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          valueDateType={"StEndDate"}
          valueTimeType={"SteamerEndTime"}
          text={DateTextValidation}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"접안장소"}
          optionTypes={"TDworkplace"}
          api={workplaceApi}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"접안시간"}
          valueDateType={"STSBerthingDate"}
          valueTimeType1={"STSBerthingTime1"}
          disabled={true}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"오일펜스\n설치"}
          valueDateType={"STSOilInstallationDate"}
          valueTimeType1={"STSOilInstallation1"}
          disabled={true}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"오일펜스\n회수"}
          valueDateType={"STSRecoveryDate"}
          valueTimeType1={"STSRecoveryTime1"}
          disabled={true}
        />
        <RowSelectTime
          TitleColor={`${silverColor}`}
          Tilte={"이안시간"}
          valueDateType={"STSIanDate"}
          valueTimeType1={"STSIanTime1"}
          disabled={true}
        />
        <RowInput
          TitleColor={`${silverColor}`}
          Tilte={"안전관리"}
          placeholder={"-"}
          disabled={true}
        />
        <RowInput
          TitleColor={`${silverColor}`}
          Tilte={"특이사항"}
          placeholder={"-"}
          disabled={true}
        />
      </div>
      <NoteText note={note} onChange={(e) => setNote(e.target.value)} />
    </div>
  );
}

export default ManagerShipLogSteamshipDetail;
