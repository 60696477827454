import React from "react";
import { Row2, RowInput, RowSelectDateTime } from "../../../../components/Row";
import {
  mainColor,
  silverColor,
  whiteColor,
} from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import { getPatrolPlaceList } from "../../../../recoil/GetData";
import { DateTextValidationAtom } from "../../../../recoil/TimeOptionsValidation";
import { PSignificantAtom, statusAtom } from "../../../../recoil/OptionsAtom";

//선박일지 순찰 내용
function ManagerShipLogPatrolJournal() {
  const workplaceApi = useRecoilValue(getPatrolPlaceList);
  //특이사항
  const [PSignificant, setPSignificant] = useRecoilState(PSignificantAtom);

  const status = useRecoilValue(statusAtom);

  const firstPatrolPlaceName =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 1 ? item.patrolPlace : ""))
      .map((item) => item.patrolPlace)
      .toString();

  const secondPatrolPlaceName =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 2 ? item.patrolPlace : ""))
      .map((item) => item.patrolPlace)
      .toString();

  const thirdPatrolPlaceName =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 3 ? item.patrolPlace : ""))
      .map((item) => item.patrolPlace)
      .toString();
  const DateTextValidation = useRecoilValue(DateTextValidationAtom);
  return (
    <>
      <div
        style={{
          width: "100%",

          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <div
          style={{
            backgroundColor: `${mainColor}`,
            width: "100%",
            padding: "15px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <p
            style={{
              color: `${whiteColor}`,
              fontSize: "1rem",
              lineHeight: 1.5,
              fontWeight: 700,
            }}
          >
            일지
          </p>
        </div>
        <Row2 Tilte={"항차"} Desc={"등록하기 이후 자동생성"} />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성자"} Desc="-" />
        <Row2 TitleColor={`${silverColor}`} Tilte={"작성일자"} Desc="-" />
        <Row2 Tilte={"작업구분"} Desc="순찰" />
        <RowSelectDateTime
          Tilte={"시작일시"}
          valueDateType={"PlStartDate"}
          valueTimeType={"PatrolStartTime"}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          valueDateType={"PlEndDate"}
          valueTimeType={"PatrolEndTime"}
          text={DateTextValidation}
          disabled={status === "완료" ? true : false}
        />
        <Row2
          Tilte={"순찰장소"}
          Desc={`${firstPatrolPlaceName}-${secondPatrolPlaceName}-${thirdPatrolPlaceName}`}
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"특이사항"}
          onchangeText={(e) => setPSignificant(e.target.value)}
          value={PSignificant}
          placeholder={"특이사항을 입력해 주세요"}
          disabled={status === "완료" ? true : false}
        />
      </div>
    </>
  );
}

export default ManagerShipLogPatrolJournal;
