import React from "react";
import styles from "./ShipInspection.module.css";
import {
  GreenColor,
  YellowColor,
  silverColor,
  textColor,
} from "../assets/colors/color";

function ShipInspection() {
  return (
    <div className={styles.ShipInspection}>
      <h1 className={styles.Header}>검사 종류및 주기</h1>
      <div className={styles.HeaderContainer}>
        <p style={{ flex: 0.6 }}>검사종류</p>
        <p>검사명</p>
        <p>Period</p>
        <p style={{ flex: 0.6 }}>표기색상</p>
      </div>
      <div className={styles.row1}>
        <span style={{ flex: 0.6 }}>
          <p>상가검사</p>
        </span>
        <span className={styles.clum2}>
          <p>정기검사</p>
          <p>제1종 중간검사</p>
        </span>
        <span className={styles.clum2}>
          <p>5년</p>
          <p>2년 또는 3년</p>
        </span>
        <span
          style={{
            flex: 0.6,
            backgroundColor: `${YellowColor}`,
            height: "51px",
          }}
        ></span>
      </div>
      <div
        className={styles.row1}
        style={{
          borderTop: `1px solid  ${silverColor}`,
          borderBottom: `2px solid  ${textColor}`,
        }}
      >
        <span style={{ flex: 0.6 }}>
          <p>비상가검사</p>
        </span>
        <span className={styles.clum2}>
          <p>제2종 중간검사</p>
          <p>임시검사</p>
        </span>
        <span className={styles.clum2}>
          <p>1년</p>
          <p>필요시 신청</p>
        </span>
        <span
          style={{
            flex: 0.6,
            backgroundColor: `${GreenColor}`,
            height: "51px",
          }}
        ></span>
      </div>
    </div>
  );
}

export default ShipInspection;
