//선박운항계획표
import { Button, Container } from "@mui/material";
import React from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { useState } from "react";
import TabList from "./TabLsit";
import { addMonths, format, subMonths } from "date-fns";
import { mainColor } from "../../../assets/colors/color";
import { useRecoilValue } from "recoil";
import { getManagerShipOperationPlan } from "../../../recoil/GetData";
import axios from "axios";
import { CDateM } from "../../../components/CDateM";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ManagerShipOperationPlan() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const selectDay = format(selectedDate, "yyyy-M-dd");
  const year = format(currentMonth, "yyyy");
  const month = format(currentMonth, "MM");

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);
  const shipScheduleData1 = useRecoilValue(
    getManagerShipOperationPlan(`floating?localDate=${year}-${month}-23`)
  );
  const shipScheduleData2 = useRecoilValue(
    getManagerShipOperationPlan(`sts?localDate=${year}-${month}-23`)
  );

  const shipScheduleData123 = [...shipScheduleData1, ...shipScheduleData2];

  const shipScheduleData =
    shipScheduleData123 &&
    shipScheduleData123.sort(
      (a, b) => new Date(a.workStartAt) - new Date(b.workStartAt)
    );
  console.log(shipScheduleData);

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  //엑셀파일 다운로드
  const handelXlsx = () => {
    axios({
      method: "GET",
      url: `/api/excel/generate/operation/schedule?year=${year}&month=${month}`,
      responseType: "blob",
      // 구글링 해보니 responseType을 꼭 추가하라고 했다.
    }).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${year}년_${month}월_월운항계획표.xls`);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          marginBottom: "10px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {shipScheduleData && shipScheduleData.length > 0 ? (
          <Button
            onClick={handelXlsx}
            variant="contained"
            color="secondary"
            sx={{
              fontSize: 15,
              width: "100%",
              padding: "6px",
              flex: 1,
              borderRadius: 0,
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            선박 운항계획표 엑셀 다운로드
          </Button>
        ) : null}

        <h1 style={{ fontSize: "20px" }}>선박 운항 계획표</h1>

        <CDateM
          edata={shipScheduleData && shipScheduleData}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          currentMonth={currentMonth}
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: `${mainColor}`,
            padding: "8px 0",
          }}
        >
          선택된 날짜 : {selectDay}{" "}
        </p>
        <TabList
          edata={shipScheduleData && shipScheduleData}
          selectedDate={selectedDate}
        />
      </Container>
    </div>
  );
}

export default ManagerShipOperationPlan;
