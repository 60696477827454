import React from "react";
import { textColor, whiteColor } from "../assets/colors/color";
import { isSameDay, parseISO } from "date-fns";

function ListItem({ data }) {
  return (
    <div
      style={{
        border: `2px solid ${textColor}`,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginLeft: "10px",
        borderRadius: "10px 10px 0 0",
        color: `${textColor}`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "5px 5px 0 0",
          textAlign: "center",
          width: "100%",
          backgroundColor: `${textColor}`,
          color: `${whiteColor}`,
          fontSize: 20,
          fontWeight: 700,
          padding: "10px",
        }}
      >
        {data.shipName}
      </div>
      <div>
        {data.sailorName
          .reduce((unique, item) => {
            return unique.includes(item) ? unique : [...unique, item];
          }, [])
          .filter((element, i) => element != null)
          .map((data) => (
            <div
              style={{ fontWeight: 500, fontSize: 18, padding: 16 }}
              key={data}
            >
              {data}
            </div>
          ))}
      </div>
    </div>
  );
}

function ListBoxCrew({ edata, selectedDate }) {
  console.log(edata);
  const SelectSameDate =
    edata &&
    edata.filter((data) =>
      isSameDay(parseISO(data.shippingWorkStartAt), selectedDate)
    );
  console.log(SelectSameDate);

  const groupValues = SelectSameDate.reduce((acc, current) => {
    acc[current.shipName] = acc[current.shipName] || [];
    acc[current.shipName].push(current.sailorName);
    return acc;
  }, {});

  const groups = Object.keys(groupValues).map((key) => {
    return { shipName: key, sailorName: groupValues[key] };
  });
  console.log(groups);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      {groups && groups.length > 0 ? (
        groups.map((data, idx) =>
          data.shipName === "101효동케미" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "1효동케미" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "3효동케미" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "15효동케미" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "1효동호" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "5효동호" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "7효동호" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "99효동호" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "9효동호" ? (
            <ListItem key={data.shipName} data={data} />
          ) : data.shipName === "효동방제1" ? (
            <ListItem key={data.shipName} data={data} />
          ) : null
        )
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default ListBoxCrew;
