// 선원정보

import React from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { Container } from "@mui/material";
import Person from "../../../assets/images/PersonIcon.svg";
import useGetData from "../../../hooks/useGetDate";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function CrewInformation() {
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  console.log("id:", id);

  const crewDetails = useGetData(
    `/api/auth/admin/specific/user-data?userId=${id}`
  ).data.data;
  console.log("crewInformation", crewDetails);

  const formattedPhoneNumber = crewDetails?.phoneNumber.replace(
    /(\d{3})(\d{4})(\d{4})/,
    "$1-$2-$3"
  );

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "80px",
          marginBottom: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          {crewDetails ? (
            <>
              <div style={{ textAlign: "center", padding: 15 }}>
                <img
                  src={Person}
                  alt="사람 이미지 아이콘"
                  width={250}
                  style={{
                    display: "block",
                    margin: "0 auto",
                    marginBottom: "25px",
                  }}
                />
                <h1 style={{ fontSize: "2rem" }}>{crewDetails?.name || "-"}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "16x 16px",
                  gap: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 25,
                    width: "100%",
                    padding: "5px",
                    marginTop: "25px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>아이디 : </label>
                    <span>{crewDetails?.uid || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>소속 : </label>
                    <span>{crewDetails?.affiliation || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>직책 : </label>
                    <span>{crewDetails?.position || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>나이 : </label>
                    <span>{crewDetails?.age || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>입사일자 : </label>
                    <span>{crewDetails?.dateOfEntry || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>연락처 : </label>
                    <span>{formattedPhoneNumber || "-"}</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ width: "25%" }}>주소 : </label>
                    <span>{crewDetails?.address || "-"}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p style={{ textAlign: "center", padding: "16px" }}>
              승무원 정보 로드 중...
            </p>
          )}
        </div>
      </Container>
    </div>
  );
}

export default CrewInformation;
