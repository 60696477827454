import * as React from "react";
import { useRecoilValue } from "recoil";
import { noticeDa } from "../../../recoil/GetData";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom/dist";
import ManagerAppBar from "./ManagerAppBar";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ManagerAlarmDa() {
  const navigate = useNavigate();
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, [navigate]);
  const location = useLocation();

  const notice = { ...location.state };

  const idNotificationD = useRecoilValue(noticeDa(notice.idNotification));
  console.log(idNotificationD);

  // console.log(noticeLsit);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "#fff",
      }}
    >
      <ManagerAppBar />
      <div style={{ marginTop: 70 }}>
        <div
          style={{
            padding: 16,
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 30,
            justifyContent: "center",
          }}
        >
          <p style={{ fontSize: 15 }}>
            {format(parseISO(idNotificationD.createdAt), "yyyy-MM-dd HH:mm")}
          </p>
          <p style={{ fontSize: 15 }}>{idNotificationD.title}</p>
          <p style={{ fontSize: 17, fontWeight: 700 }}>
            {idNotificationD.body}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ManagerAlarmDa;
