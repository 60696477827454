import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { useRecoilValue } from "recoil";
import { countNum } from "../../../recoil/GetData";
import ManagerTapMenu from "./ManagerTapMenu";

export default function UserAppBar() {
  const [show, setShow] = React.useState(false);
  const count = useRecoilValue(countNum);

  // console.log(count);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => setShow(!show)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>

              <Link
                to="/manager-main"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  src={logo}
                  alt="logo"
                  component="div"
                  style={{ height: "40px", width: "164px" }}
                />
              </Link>

              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => (window.location.href = "/manager-Alarm")}
              >
                <Badge badgeContent={count ? count : 0} color="error">
                  <NotificationsIcon color="inherit" />
                </Badge>
              </IconButton>
            </Container>
          </Toolbar>
        </AppBar>
        {show === true ? <ManagerTapMenu /> : null}
      </Box>
    </>
  );
}
