import React from "react";
import { Modal } from "@mui/material";
// import { silverColor } from "../assets/colors/color";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "4px",
  minWidth: "85%",
};

function PopupModal({ open, children }) {
  return (
    <Modal open={open}>
      <div style={modalStyle}>{children}</div>
    </Modal>
  );
}

export default PopupModal;
