import React from "react";
import { Button, Container } from "@mui/material";
import JoinAppBar from "../../components/JoinAppbar";
import InputLable from "../../components/InputLable";
import InputLableButton from "../../components/InputLableButton";
import { silverColor } from "../../assets/colors/color";
// import Post from "./Post";
import PopupModal from "../../components/PopUpModal";
import DaumPostcode from "react-daum-postcode";
// import { postAsync } from "./../../api/axios";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputLablePw from "../../components/InputLablePw";

function JoinTheMembership() {
  //  초기값 세팅 - 아이디, 닉네임, 비밀번호, 비밀번호확인, 이메일, 전화번호, 생년월일
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  // const [email, setEmail] = React.useState("");
  const [belong, setBelong] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [age, setAge] = React.useState("");
  const [join, setJoin] = React.useState("");
  // const [joinYear, setJoinYear] = React.useState("");
  // const [joinMonth, setJoinMonth] = React.useState("");
  const [phone, setPhone] = React.useState("");
  // const [birth, setBirth] = React.useState("");

  // 오류메세지 상태 저장
  const [idMessage, setIdMessage] = React.useState("");
  const [nameMessage, setNameMessage] = React.useState("");
  const [passwordMessage, setPasswordMessage] = React.useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] =
    React.useState("");
  // const [emailMessage, setEmailMessage] = React.useState("");
  const [phoneMessage, setPhoneMessage] = React.useState("");
  // const [birthMessage, setBirthMessage] = React.useState("");

  // 유효성 검사
  const [isId, setIsId] = React.useState(false);
  const [isIdOk, setIdOk] = React.useState(null);
  const [isname, setIsName] = React.useState(false);
  const [isPassword, setIsPassword] = React.useState(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = React.useState(false);
  // const [isEmail, setIsEmail] = React.useState(false);
  // const [isPhone, setIsPhone] = React.useState(false);
  // const [isBirth, setIsBirth] = React.useState(false);
  // file
  const refFile = React.useRef();
  const [img, setImg] = React.useState("");
  const [filename, setFilename] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const onClickHandler = () => {
    refFile.current.click();
  };

  const onChangeHandler = (e) => {
    const selectedFile = e.target.files;
    console.log(selectedFile);

    if (selectedFile.length > 0) {
      setImg(selectedFile);
      setFilename(selectedFile[0].name);
    }
  };

  const [address, setAddress] = React.useState("");
  const [addressDetail, setAddressDetail] = React.useState("");

  const handleInput = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
    // setPopup(!popup);
  };

  const complete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let queryAddress = data.query;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    console.log("Data: ", data);
    console.log("Data Query: ", queryAddress);
    console.log(fullAddress);
    console.log(data.zonecode);

    setAddress(fullAddress);
    setAddressDetail(queryAddress);
    setOpen(false);
  };

  const handleComplete = () => {
    setOpen(true);
  };

  const onCheckId = () => {
    axios
      .get(`/api/auth/duplicate-check/uid?uid=${id}`) // Pass idParam as params
      .then((response) => {
        setIdOk(response.data.data);
        if (response.status === 200) {
          console.log("response: ", response.data);
          if (response.data.data === true) {
            setIdMessage("이 사용자 이름은 이미 사용 중입니다.");
          } else {
            setIdMessage("사용가능한 아이디 입니다.");
          }
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const onChangeId = (e) => {
    const currentId = e.target.value;
    setId(currentId);
    const idRegExp = /^[a-zA-z0-9]{4,12}$/;

    if (!idRegExp.test(currentId)) {
      setIdMessage("4-12사이 대소문자 또는 숫자만 입력해 주세요!");
      setIsId(false);
    } else {
      setIdMessage("아이디 중복확인을 해주세요");
      setIsId(true);
    }
  };

  const onChangeName = (e) => {
    const currentName = e.target.value;
    setName(currentName);

    if (currentName.length < 2 || currentName.length > 5) {
      setNameMessage("닉네임은 2글자 이상 5글자 이하로 이름을 입력해주세요!");
      setIsName(false);
    } else {
      setNameMessage("사용가능한 이름 입니다.");
      setIsName(true);
    }
  };

  const onChangePassword = (e) => {
    const currentPassword = e.target.value;
    setPassword(currentPassword);
    const passwordRegExp = /^[0-9]{4,10}$/;
    if (!passwordRegExp.test(currentPassword)) {
      setPasswordMessage("숫자조합으로 4자리 이상 10자리 이하로 입력해주세요!");
      setIsPassword(false);
    } else {
      setPasswordMessage("안전한 비밀번호 입니다.");
      setIsPassword(true);
    }
  };
  const onChangePasswordConfirm = (e) => {
    const currentPasswordConfirm = e.target.value;
    setPasswordConfirm(currentPasswordConfirm);
    if (password !== currentPasswordConfirm) {
      setPasswordConfirmMessage("비밀번호가 일치하지않습니다");
      setIsPasswordConfirm(false);
    }
    if (password === currentPasswordConfirm) {
      setPasswordConfirmMessage("비밀번호가 일치합니다");
      setIsPasswordConfirm(true);
    }
  };

  const onChangeBelong = (e) => {
    const currentBelong = e.target.value;
    setBelong(currentBelong);
  };

  const onChangePosition = (e) => {
    const currentPosition = e.target.value;
    setPosition(currentPosition);
  };

  const onChangeAge = (e) => {
    const currentAge = e.target.value;
    setAge(currentAge);
  };

  const onChangeJoin = (e) => {
    const currentJoin = e.target.value;
    setJoin(currentJoin);
  };

  const onChangePhone = (e) => {
    const currentPhone = e.target.value;
    setPhone(currentPhone);
  };

  // const addHyphen = (e) => {
  //   const currentNumber = e.target.value;
  //   setPhone(currentNumber);
  //   if (currentNumber.length === 3 || currentNumber.length === 8) {
  //     setPhone(currentNumber + "-");
  //     onChangePhone(currentNumber + "-");
  //   } else {
  //     onChangePhone(currentNumber);
  //   }
  // };

  // const onChangeBirth = (e) => {
  //   const currentBirth = e.target.value;
  //   setBirth(currentBirth);
  // };
  const dis =
    id &&
    password &&
    name &&
    belong &&
    position &&
    age &&
    join &&
    phone &&
    address &&
    addressDetail;

  const handleJoinMembership = () => {
    console.log("Join Membership Button");
    const data = {
      uid: id,
      password: password,
      name: name,
      affiliation: belong,
      position: position,
      age: age,
      dateOfEntry: join,
      // lengthOfServiceOfMonth: joinMonth,
      phoneNumber: phone,
      address: address,
      addressDetail: addressDetail,
    };

    const formdata = new FormData();
    formdata.append(
      "userRegistrationVo",
      new Blob([JSON.stringify(data)], { type: "application/json" })
    );

    formdata.append("file", img[0]);

    for (const pair of formdata.entries()) {
      console.log(pair[0], pair[1]);
    }
    console.log(formdata);

    axios
      .post(`api/auth/registration`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const resultData = response.data;
        console.log("response: ", resultData);
        if (resultData.resultMsg === "INSERT SUCCESS") {
          setId("");
          setPassword("");
          setPasswordConfirm("");
          setPasswordConfirmMessage("");
          setPasswordMessage("");
          setNameMessage("");
          setIdMessage("");
          setIdOk(null);
          setIsId(false);
          setName("");
          setBelong("");
          setPosition("");
          setAge("");
          setJoin("");
          setPhone("");
          setAddress("");
          setAddressDetail("");
          setImg("");
          setFilename("");
          alert("등록이 성공적으로 완료되었습니다");
          navigate("/");
        }
        if (resultData.resultMsg === "Null Point Exception") {
          alert("입력값이 정확하지 않습니다. 한번 더 확인해 주세요!");
        }
      })
      .catch((error) => {
        alert("입력값이 정확하지 않습니다. 한번 더 확인해 주세요!");
      });
  };

  return (
    <div>
      <JoinAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: 24 }}>회원가입</h1>

        <InputLable
          Name={"user-name"}
          Label={"이름"}
          Placeholder={"이름을 입력하세요"}
          Star={true}
          value={name}
          handleInputChange={onChangeName}
          InputMessage={nameMessage}
        />
        <InputLableButton
          Name={"user-id"}
          Label={"아이디"}
          Placeholder={"아이디를 입력하세요"}
          BtText={"중복확인"}
          Star={true}
          InputType={"text"}
          value={id}
          isIdOk={isIdOk}
          handleCheckID={onCheckId}
          handleInputChange={onChangeId}
          InputMessage={idMessage}
          isDisabled={!isId}
        />
        <InputLablePw
          Name={"user-pw"}
          Label={"비밀번호"}
          Placeholder={"비밀번호를 입력하세요"}
          Star={true}
          value={password}
          handleInputChange={onChangePassword}
          InputMessage={passwordMessage}
        />
        <InputLablePw
          Name={"user-pwCheck"}
          Label={"비밀번호 재입력"}
          Placeholder={"비밀번호를 재입력 하세요"}
          Star={true}
          value={passwordConfirm}
          handleInputChange={onChangePasswordConfirm}
          InputMessage={passwordConfirmMessage}
        />
        <InputLable
          Name={"user-belong"}
          Label={"소속"}
          Placeholder={"소속을 입력하세요"}
          value={belong}
          handleInputChange={onChangeBelong}
        />
        <InputLable
          Name={"user-position"}
          Label={"직책"}
          Placeholder={"직책을 입력하세요."}
          value={position}
          handleInputChange={onChangePosition}
        />
        <InputLable
          Name={"user-age"}
          Label={"나이"}
          Placeholder={"나이를 입력하세요 ex) 65"}
          value={age}
          type={"number"}
          handleInputChange={onChangeAge}
        />
        <InputLable
          Name={"user-enteringDate"}
          Label={"입사일자"}
          Placeholder={"입사일자를 입력하세요 ex)20031212"}
          value={join}
          type={"date"}
          handleInputChange={onChangeJoin}
        />
        <InputLable
          Name={"user-number"}
          Label={"연락처"}
          Placeholder={"연락처를 입력하세요"}
          value={phone}
          handleInputChange={onChangePhone}
          InputMessage={phoneMessage}
        />
        <label
          style={{
            marginLeft: 5,
            textAlign: "left",
            width: "100%",
            fontSize: 12,
          }}
        >
          주소
        </label>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: 20,
          }}
        >
          <input
            style={{ width: "70%" }}
            placeholder="주소"
            type="text"
            required={true}
            name="address"
            onChange={handleInput}
            value={address}
          />
          <Button
            onClick={handleComplete}
            style={{ width: "30%" }}
            variant="contained"
            color="secondary"
          >
            우편번호 찾기
          </Button>
          <PopupModal open={open} handleClose={handleClose}>
            <div
              style={{
                background: "rgba(0,0,0,0.25)",
                zIndex: 100,
                position: "fixed",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <DaumPostcode autoClose onComplete={complete} />
            </div>
          </PopupModal>
          {/* {popup && (
            <Post
              company={enroll_company}
              setcompany={setEnroll_company}
              popup={handleComplete}
            ></Post>
          )} */}
        </div>
        <>
          <label
            style={{
              marginLeft: 5,
              textAlign: "left",
              width: "100%",
              fontSize: 12,
            }}
          >
            해가시면허
          </label>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: 20,
            }}
          >
            <input
              type="text"
              name="filename"
              value={filename}
              placeholder="선택된 파일이없습니다."
              readOnly
              style={{
                width: "70%",
                padding: "14px 10px",
                color: `${silverColor}`,
              }}
            />
            <Button
              onClick={onClickHandler}
              style={{ width: "30%" }}
              variant="contained"
              color="secondary"
            >
              파일첨부
            </Button>
            <input
              type="file"
              ref={refFile}
              onChange={onChangeHandler}
              style={{ display: "none" }}
            />
          </div>
        </>
        <Button
          sx={{ width: "100%", marginTop: 2, borderRadius: 999, height: 50 }}
          variant="contained"
          color="secondary"
          onClick={handleJoinMembership}
          disabled={!dis}
        >
          회원가입
        </Button>
        <div style={{ color: "red" }}>
          {dis ? "" : "모든값이 입력되지 않았습니다."}
        </div>
      </Container>
    </div>
  );
}

export default JoinTheMembership;
