import React from "react";
import {
  disabledColor,
  mainColor,
  subColor1,
  subColor2,
  textColor,
  whiteColor,
} from "../assets/colors/color";
import { format, isSameDay, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

function ListItem({ data, NextPageType }) {
  console.log(data.shippingWorkStartAt);
  console.log(data);

  const startDate = parseISO(data.shippingWorkStartAt);

  const time = format(startDate, "HH:mm");

  const state = {
    idJourney: data.idJourney,
    journeyDetailId: data.journeyDetailId,
    shippingWorkStartAt: data.shippingWorkStartAt,
    shipName: data.ship, //배이름
    shipType: data.shipType, //배 타입
    jobType: data.jobType, //배 타입
    status: data.status, //배 단계
    voyageNumber: data.voyageNumber, //항차
    charterer: data.charterer,
    cargo: data.cargo,
  };
  console.log("List Box Data: ", state);

  const navigate = useNavigate();

  const navigateToPurchase = () => {
    if (NextPageType === "UserShipSchedule") {
      //user-작업 일정
      data.shipType === "운송"
        ? navigate("/user-ship-schedule-transit", {
            state: state,
          })
        : data.shipType === "기선STS"
        ? navigate("/user-ship-schedule-steamship", {
            state: state,
          })
        : data.shipType === "순찰"
        ? navigate("/user-ship-schedule-patrol", {
            state: state,
          })
        : data.shipType === "예인선"
        ? navigate("/user-ship-schedule-Tug", {
            state: state,
          })
        : navigate("/user-ship-schedule");
    } else if (NextPageType === "ManagerShipSchedule") {
      //manager - 작업일정보기
      data.shipType === "운송"
        ? navigate("/manager-ship-schedule-transit", {
            state: state,
          })
        : data.shipType === "기선STS"
        ? navigate("/manager-ship-schedule-steamship", {
            state: state,
          })
        : data.shipType === "순찰"
        ? navigate("/manager-ship-schedule-patrol", {
            state: state,
          })
        : data.shipType === "예인선"
        ? navigate("/manager-ship-schedule-Tug", {
            state: state,
          })
        : navigate("/manager-ship-schedule");
    } else {
      navigate(null);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            paddingTop: 10,
            paddingRight: 10,
            borderRight: `2px solid ${disabledColor}`,
          }}
        >
          {time}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: 10,
          }}
        >
          <div
            onClick={navigateToPurchase}
            style={
              data.shipType === "운송"
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    width: "100%",
                    padding: "15px 20px",
                    backgroundColor: `${mainColor}`,
                    borderRadius: "10px",
                    color: `${whiteColor}`,
                  }
                : data.shipType === "기선STS"
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    width: "100%",
                    padding: "15px 20px",
                    backgroundColor: `${subColor1}`,
                    borderRadius: "10px",
                    color: `${whiteColor}`,
                  }
                : data.shipType === "순찰"
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    width: "100%",
                    padding: "15px 20px",
                    backgroundColor: `${subColor2}`,
                    borderRadius: "10px",
                    color: `${whiteColor}`,
                  }
                : data.shipType === "예인선"
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    width: "100%",
                    padding: "15px 20px",
                    backgroundColor: `${textColor}`,
                    borderRadius: "10px",
                    color: `${whiteColor}`,
                  }
                : {}
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontWeight: 500, fontSize: 17 }}>{data.ship}</h1>
              <span
                style={{
                  borderRadius: 999,
                  border: `2px solid`,
                  padding: 5,
                  fontWeight: 700,
                  fontSize: 15,
                }}
              >
                {data.status}
              </span>
            </div>
            <p style={{ marginTop: 6 }}>
              <span>항차 : </span>
              <span>{data.voyageNumber}</span>
            </p>
            <p>
              <span>구분 : </span>
              <span>
                {data.shipType === data.jobType
                  ? data.shipType
                  : `${data.shipType} / ${data.jobType}`}
              </span>
            </p>
            {data.shipType === "운송" ||
            data.shipType === "기선STS" ||
            data.shipType === "예인선" ? (
              <div>
                <p>
                  <span>용선주 : </span>
                  <span>{data.charterer}</span>
                </p>
                <p style={{ marginTop: 6 }}>
                  <span>화물 : </span>
                  <span>{data.cargo}</span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function ListBoxSchedule({ edata, selectedDate, NextPageType }) {
  console.log(edata);
  const SelectSameDate =
    edata &&
    edata.filter((data) =>
      isSameDay(parseISO(data.shippingWorkStartAt), selectedDate)
    );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      {SelectSameDate && SelectSameDate.length > 0 ? (
        SelectSameDate.map((data) => (
          <ListItem
            data={data}
            key={data.idJourney}
            NextPageType={NextPageType}
          />
        ))
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default ListBoxSchedule;
