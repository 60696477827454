import React from "react";
import {
  Row2,
  RowInput,
  RowSelectDateTime,
  RowSelectListM,
  RowSelectTime,
} from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ShippingExtortionAtom,
  ShippingSafetyAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import {
  OptionGetSelectorFamilly,
  getTugLsit,
} from "../../../../recoil/GetData";
import { DateTextValidationAtom1 } from "../../../../recoil/TimeOptionsValidation";
//선박작업 일지- 운송 적하

function ManagerShipLogTransitDroppingFix({ shipApi2 }) {
  console.log(shipApi2);
  const status = useRecoilValue(statusAtom);
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const TugApi = useRecoilValue(getTugLsit); //예인선
  const certifierApi = useRecoilValue(OptionGetSelectorFamilly("certifier"));
  const loaderAndUnloaderApi = useRecoilValue(
    OptionGetSelectorFamilly("unloader")
  );
  const DateTextValidation1 = useRecoilValue(DateTextValidationAtom1);

  //안전관리
  const [ShippingSafety, setShippingSafety] =
    useRecoilState(ShippingSafetyAtom);
  //강취
  const [ShippingExtortion, setShippingExtortion] = useRecoilState(
    ShippingExtortionAtom
  );
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <RowSelectDateTime
          Tilte={"시작일시"}
          valueDateType={"TDStartDate"}
          valueTimeType={"TDroppingStartTime"}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          valueDateType={"TDEndDate"}
          valueTimeType={"TDroppingEndTime"}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
          text={DateTextValidation1}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"접안장소"}
          optionTypes={"TDworkplace"}
          api={workplaceApi}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <RowSelectTime
          Tilte={"접안시간"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDBerthingDate"}
          valueTimeType1={"TDBerthingTime1"}
          disabled={true}
        />
        <RowSelectTime
          Tilte={"호스연결"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDHoseConnectionDate"}
          valueTimeType1={"TDHoseConnection1"}
          disabled={true}
        />
        <RowSelectTime
          Tilte={"작업시작"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDStartWorkDate"}
          valueTimeType1={"TDStartWork1"}
          disabled={true}
        />
        <RowSelectTime
          Tilte={"작업완료"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDWorkCompleteDate"}
          valueTimeType1={"TDWorkComplete1"}
          disabled={true}
        />
        <RowSelectTime
          Tilte={"호스분리"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDHoseSeparationDate"}
          valueTimeType1={"TDHoseSeparation1"}
          disabled={true}
        />
        <RowSelectTime
          Tilte={"이안시간"}
          TitleColor={`${silverColor}`}
          valueDateType={"TDIanTimeDate"}
          valueTimeType1={"TDIanTime1"}
          disabled={true}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"예인선"}
          optionTypes={"TDTug"}
          api={TugApi}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <Row2
          Tilte={"H/RATE"}
          Desc={
            shipApi2.rateOfH ? shipApi2.rateOfH : "화물량/(작업완료 - 작업시작)"
          }
        />

        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"감정사"}
          optionTypes={"tdCertifier"}
          api={certifierApi}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"하역사"}
          optionTypes={"tdLoaderAndUnloader"}
          api={loaderAndUnloaderApi}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"안전관리"}
          placeholder={
            shipApi2.safety ? shipApi2.safety : "안전관리를 입력해 주세요."
          }
          onchangeText={(e) => setShippingSafety(e.target.value)}
          value={ShippingSafety}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"강취"}
          placeholder={
            shipApi2.lineHandling
              ? shipApi2.lineHandling
              : "강취를 입력해 주세요."
          }
          onchangeText={(e) => setShippingExtortion(e.target.value)}
          value={ShippingExtortion}
          disabled={
            shipApi2.status === "완료" || status === "완료" ? true : false
          }
        />
      </div>
    </div>
  );
}

export default ManagerShipLogTransitDroppingFix;
