import getCookie from "../cookie/getCookie";

export const loginAuthenticationAdmin = () => {
  const jsessionId = getCookie("JSESSIONID");
  console.log("SessionCookie: ", jsessionId);
  const localStorageRoleRaw = localStorage.getItem("role");
  const sessionStorageRoleRaw = sessionStorage.getItem("role");

  // `null`을 반환할 경우 빈 문자열로 대체하여 `replace` 메소드 호출 문제 방지
  const localStorageRole = localStorageRoleRaw
    ? localStorageRoleRaw.replace(/"/g, "")
    : "";
  const sessionStorageRole = sessionStorageRoleRaw
    ? sessionStorageRoleRaw.replace(/"/g, "")
    : "";

  console.log(localStorageRole);
  console.log(sessionStorageRole);

  if (localStorageRole === "USER") {
    document.cookie =
      "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    alert("관리자만 접속가능한 페이지입니다. 선원아이디로 접속하길 바랍니다.");
    window.location.href = "/";
  } else if (sessionStorageRole === "USER") {
    document.cookie =
      "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    alert("관리자만 접속가능한 페이지입니다. 선원아이디로 접속하길 바랍니다.");
    window.location.href = "/";
  }

  if (jsessionId === null) {
    localStorage.clear();
    sessionStorage.clear();
    alert("로그인이 만료되었습니다. 로그인을 다시 해주세요.");
    window.location.href = "/";
  }
};

export const loginAuthenticationUser = () => {
  const jsessionId = getCookie("JSESSIONID");
  console.log("SessionCookie: ", jsessionId);
  const localStorageRoleRaw = localStorage.getItem("role");
  const sessionStorageRoleRaw = sessionStorage.getItem("role");

  // `null`을 반환할 경우 빈 문자열로 대체하여 `replace` 메소드 호출 문제 방지
  const localStorageRole = localStorageRoleRaw
    ? localStorageRoleRaw.replace(/"/g, "")
    : "";
  const sessionStorageRole = sessionStorageRoleRaw
    ? sessionStorageRoleRaw.replace(/"/g, "")
    : "";

  console.log(localStorageRole);
  console.log(sessionStorageRole);

  if (localStorageRole === "ADMIN") {
    document.cookie =
      "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    alert("관리자만 접속가능한 페이지입니다. 선원아이디로 접속하길 바랍니다.");
    window.location.href = "/";
  } else if (sessionStorageRole === "ADMIN") {
    document.cookie =
      "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    alert("관리자만 접속가능한 페이지입니다. 선원아이디로 접속하길 바랍니다.");
    window.location.href = "/";
  }

  if (jsessionId === null) {
    localStorage.clear();
    sessionStorage.clear();
    alert("로그인이 만료되었습니다. 로그인을 다시 해주세요.");
    window.location.href = "/";
  }
};
