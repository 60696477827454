/* eslint-disable no-undef */

export function onButtonClick(sessionId) {
  const userAgent = navigator.userAgent.toLowerCase();
  console.log("버튼실행");
  console.log(sessionId);

  if (userAgent.includes("android")) {
    // 안드로이드 기기에서 실행 중인 경우
    try {
      if (typeof Android !== "undefined" && Android) {
        // Android 객체가 정의되어 있고, onButtonClicked 함수가 존재하는 경우
        Android.onButtonClicked(sessionId);
      }
    } catch (e) {
      // 오류 처리
      console.error("Android 함수 호출 중 오류 발생:", e);
    }
  }
}
