import React from "react";
import { Button, Container } from "@mui/material";
import { Row2, Row3 } from "../../../components/Row";
import { mainColor, whiteColor } from "../../../assets/colors/color";
import { ShipTitle } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import {
  getAllShipList,
  getShipData,
  getShipFlightLog,
} from "../../../recoil/GetData";
import { format, parseISO } from "date-fns";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
//선박 운항 일지 운송
function ShipFlightLogTransit() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const journeyDetailId = shipInfo.journeyDetailId;
  const navigate = useNavigate();
  console.log(shipInfo);

  const shipApi = useRecoilValue(
    getShipData(`floating?detailJourneyId=${journeyDetailId}`)
  );

  console.log(journeyDetailId);

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipInfo.ship ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  const shipApiNext = useRecoilValue(
    getShipFlightLog(
      `shipId=${shipId}&jobType=${shipInfo.jobType}&shippingWorkStartAt=${shipInfo.shippingWorkStartAt}`
    )
  );

  console.log(shipApiNext);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const state = {
    shipId: shipId,
    shipType: shipInfo.shipType,
    jobType: shipInfo.jobType,
    shippingWorkStartAt: shipInfo.shippingWorkStartAt,
  };
  const navigateToPurchase = () => {
    if (shipApiNext === null) {
      navigate("/manager-ship-flight-log-transitNextX");
    } else {
      navigate("/manager-ship-flight-log-transitNext", {
        state: state,
      });
    }
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          // Step={shipApi.status}
          ShipName={shipApi.ship}
          color={`${mainColor}`}
        />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.voyage} />
          <Row2 Tilte={"용선주"} Desc={shipApi.charterer} />
          <Row2 Tilte={"공급자"} Desc={shipApi.supplier} />
          <Row2 Tilte={"수급자"} Desc={shipApi.recipient} />
          <Row2 Tilte={"중개사"} Desc={shipApi.broker} />
          <Row2 Tilte={"화물명"} Desc={shipApi.cargo} />
          <Row2 Tilte={"수량"} Desc={shipApi.amount} />
          <Row2 Tilte={"수량누계"} Desc={shipApi.totalAmount} />
          <Row3
            Tilte={"구분"}
            Desc1={"적하"}
            Desc2={"양하"}
            bgcolor={`${mainColor}`}
            TitleColor={`${whiteColor}`}
            Desxcolor={`${whiteColor}`}
          />
          <Row3
            Tilte={"작업장소"}
            Desc1={`광양 OCI부두 \n (MFK01)`}
            Desc2={`석유화학부투#1 \n (MBU01)`}
            bgcolor={`${whiteColor}`}
            TitleColor={`${mainColor}`}
          />

          <Row3
            Tilte={"시작일시"}
            Desc1={format(
              parseISO(shipApi.shipping.workStartAt),
              "yyyy-MM-dd HH:mm"
            )}
            Desc2={format(
              parseISO(shipApi.unloading.workStartAt),
              "yyyy-MM-dd HH:mm"
            )}
          />
          <Row3
            Tilte={"완료일시"}
            Desc1={format(
              parseISO(shipApi.shipping.workDoneAt),
              "yyyy-MM-dd HH:mm"
            )}
            Desc2={format(
              parseISO(shipApi.unloading.workDoneAt),
              "yyyy-MM-dd HH:mm"
            )}
          />
          <Row3
            Tilte={"예인선"}
            Desc1={shipApi.shipping.tugShip}
            Desc2={shipApi.unloading.tugShip}
          />
        </div>

        <Button
          onClick={navigateToPurchase}
          sx={{
            fontFamily: ["Noto Sans KR", "sans-serif"],
            fontSize: "17px",
            fontWeight: "500",
            maxWidth: "1200px",
            width: "100%",
            bottom: 0,
            borderRadius: 0,
            height: 50,
            left: "50%",
            transform: "translateX( -50% )",
            position: "fixed",
            borderTop: "2px solid #fff",
          }}
          variant="contained"
          color="secondary"
        >
          차항정보
        </Button>
      </Container>
    </div>
  );
}

export default ShipFlightLogTransit;
