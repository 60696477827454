import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import { mainColor, whiteColor } from "../../../assets/colors/color";
import axios from "axios";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";

function UserTapMenu() {
  const [flightManagementOpen, setFlightManagementOpen] = React.useState(false);
  const [inspectionManagementOpen, setInspectionManagementOpen] =
    React.useState(false);

  const FlightHandleClick = () => {
    setFlightManagementOpen(!flightManagementOpen);
  };
  const InspectionHandleClick = () => {
    setInspectionManagementOpen(!inspectionManagementOpen);
  };

  React.useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const handleLogoutBtn = () => {
    axios
      .get(`/api/auth/logout`)
      .then((response) => {
        const resultData = response.data;
        console.log("response: ", resultData.data);
        if (resultData.resultMsg === "LOGOUT SUCCESS") {
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          sessionStorage.clear();
          alert("로그아웃 성공");
          window.location.href = "/";
        }
        localStorage.clear();
        sessionStorage.clear();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        localStorage.clear();
        sessionStorage.clear();
      });
  };

  return (
    <>
      <div
        style={{
          // textAlign: "center",
          // maxWidth: 1200,
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 56,
          bottom: 0,
          zIndex: 999,
          backgroundColor: "#fff",
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: `${mainColor}`,
            color: `${whiteColor}`,
            paddingBottom: "2px",
            paddingTop: "1px",
          }}
          component="nav"
        >
          <ListItemButton
            onClick={FlightHandleClick}
            sx={{ bgcolor: `${mainColor}`, color: `${whiteColor}` }}
          >
            <ListItemText primary="운항관리" sx={{ fontWeight: 700 }} />
            {flightManagementOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </ListItemButton>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: `${whiteColor}`, color: `${mainColor}` }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => (window.location.href = "/user-ship-schedule")}
              >
                <ListItemText
                  primary="선박 작업 일정"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => (window.location.href = "/user-ship-log")}
              >
                <ListItemText
                  primary="선박 작업 일지"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={InspectionHandleClick}
            sx={{ bgcolor: `${mainColor}` }}
          >
            <ListItemText primary="검사일정" />
            {inspectionManagementOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </ListItemButton>
          <Collapse
            in={inspectionManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: `${whiteColor}`, color: `${mainColor}` }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/user-inspection-schedule")
                }
              >
                <ListItemText primary="선박 검사 일정" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>

        <ListItemButton
          sx={{
            width: "100%",
            bgcolor: `${whiteColor}`,
            color: `${mainColor}`,
            borderTop: "1px solid",
            position: "absolute",
            bottom: "102px",
          }}
          onClick={() => (window.location.href = "/user-AlarmHD")}
        >
          <NotificationsIcon color="inherit" sx={{ marginRight: "10px" }} />
          <ListItemText primary="푸쉬 알림 설정" />
        </ListItemButton>

        <ListItemButton
          sx={{
            width: "100%",
            bgcolor: `${whiteColor}`,
            color: `${mainColor}`,
            borderTop: "1px solid",
            position: "absolute",
            bottom: "56px",
          }}
        >
          <PersonIcon color="inherit" sx={{ marginRight: "10px" }} />
          <ListItemText primary="로그아웃" onClick={handleLogoutBtn} />
        </ListItemButton>
      </div>
    </>
  );
}

export default UserTapMenu;
