import React from "react";
import { Container } from "@mui/material";
import ManagerAppBar from "../common/ManagerAppBar";

import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getShipFlightLogSTS } from "../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ShipFlightLogSteamshipNextX() {
  const location = useLocation();
  const shipInfo = { ...location.state };

  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(getShipFlightLogSTS(`${journeyDetailId}`));
  console.log(shipApi);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: 100, fontSize: 20, fontWeight: 700 }}>
          등록된 차항정보가 없습니다.
        </div>
      </Container>
    </div>
  );
}

export default ShipFlightLogSteamshipNextX;
