import React from "react";
import JoinAppBar from "../../components/JoinAppbar";
import { Button, Container } from "@mui/material";
import { mainColor, whiteColor } from "../../assets/colors/color";
import { Link } from "react-router-dom";
import InputLable from "../../components/InputLable";
import axios from "axios";
import PopupModal from "../../components/PopUpModal";

function PwFind() {
  const [name, setName] = React.useState("");
  const [id, setID] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  const [passwordMessage, setPasswordMessage] = React.useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] =
    React.useState("");

  const [open, setOpen] = React.useState(false);

  const onChangeName = (e) => {
    const currentName = e.target.value;
    setName(currentName);
  };

  const onChangeID = (e) => {
    const currentID = e.target.value;
    setID(currentID);
  };

  const onChangePhone = (e) => {
    const currentPhone = e.target.value;
    setPhone(currentPhone);
  };

  const handleFindPassword = () => {
    console.log("find Password: ", name, id, phone);

    axios
      .get(
        `/api/auth/find-password?name=${name}&uid=${id}&phoneNumber=${phone}`
      ) // Pass idParam as params
      .then((response) => {
        if (response.data.data === true) {
          console.log("response: ", response.data);
          setOpen(true);
        } else {
          // setOpen(false);
          alert("데이터 결과 없음");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setPassword("");
    setPasswordConfirm("");
    setPasswordMessage("");
    setPasswordConfirmMessage("");
  };

  const handleChangePassword = () => {
    // setOpen(false);
    const data = {
      uid: id,
      password: password,
    };

    console.log("Data: ", data);
    axios
      .patch(`/api/auth/change-password`, data)
      .then((response) => {
        if (response.data.resultMsg === "UPDATE SUCCESS") {
          console.log("response: ", response.data);
          setPassword("");
          setPasswordConfirm("");
          setName("");
          setID("");
          setPhone("");
          alert("비밀번호가 성공적으로 변경되었습니다");
          setOpen(false);
        } else {
          // setOpen(false);
          alert("데이터 결과 없음");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const onChangePassword = (e) => {
    const currentPassword = e.target.value;
    setPassword(currentPassword);
    const passwordRegExp =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!passwordRegExp.test(currentPassword)) {
      setPasswordMessage(
        "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
      );
      // setIsPassword(false);
    } else {
      setPasswordMessage("안전한 비밀번호 입니다.");
      // setIsPassword(true);
    }
  };
  const onChangePasswordConfirm = (e) => {
    const currentPasswordConfirm = e.target.value;
    setPasswordConfirm(currentPasswordConfirm);
    // console.log(password, passwordConfirm);
    if (password !== currentPasswordConfirm) {
      setPasswordConfirmMessage("비밀번호가 일치하지않습니다");
    } else {
      setPasswordConfirmMessage("비밀번호가 일치합니다.");
    }
  };

  return (
    <div>
      <JoinAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: 20 }}>아이디/비밀번호 찾기</h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: 20,
            borderBottom: `2px solid ${mainColor}`,
          }}
        >
          <Link
            to={"/user-idFind"}
            style={{
              textDecoration: "none",
              flex: 1,
              padding: "15px 0",
              borderRadius: "10px 10px 0 0",
              color: `${mainColor}`,
              fontWeight: 700,
            }}
          >
            <span>아이디찾기</span>
          </Link>
          <Link
            to={"/user-PwFind"}
            style={{
              textDecoration: "none",
              flex: 1,
              padding: "15px 0",
              backgroundColor: `${mainColor}`,
              borderRadius: "10px 10px 0 0",
              color: `${whiteColor}`,
              fontWeight: 500,
            }}
          >
            <span>비밀번호 찾기</span>
          </Link>
        </div>
        <InputLable
          Name={"user-name"}
          Label={"이름 "}
          Placeholder={"이름을 입력하세요"}
          Star={true}
          value={name}
          handleInputChange={onChangeName}
        />
        <InputLable
          Name={"user-id"}
          Label={"아이디 "}
          Placeholder={"아이디를 입력하세요"}
          Star={true}
          value={id}
          handleInputChange={onChangeID}
        />
        <InputLable
          Name={"user-phone"}
          Label={"전화번호 "}
          Placeholder={"전화번호를 입력하세요"}
          Star={true}
          value={phone}
          handleInputChange={onChangePhone}
        />

        <Button
          sx={{
            width: "100%",
            marginTop: 2,
            borderRadius: 999,
            height: 50,
            fontWeight: 700,
          }}
          variant="contained"
          color="secondary"
          onClick={handleFindPassword}
        >
          비밀번호 찾기
        </Button>
        <div>
          {/* <Button onClick={handleOpen}>Open Modal</Button> */}
          <PopupModal
            open={open}
            // handleClose={handleClose}
            style={{ width: "80%" }}
          >
            <InputLable
              Name={"user-pw"}
              Label={"비밀번호"}
              Placeholder={"비밀번호를 입력하세요"}
              Star={true}
              value={password}
              type={"password"}
              handleInputChange={onChangePassword}
              InputMessage={passwordMessage}
            />
            <InputLable
              Name={"user-pwCheck"}
              Label={"비밀번호 재입력"}
              Placeholder={"비밀번호를 재입력 하세요"}
              Star={true}
              value={passwordConfirm}
              type={"password"}
              handleInputChange={onChangePasswordConfirm}
              InputMessage={passwordConfirmMessage}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                to={"/"}
                sx={{
                  width: "48%",
                  marginTop: 2,
                  borderRadius: 999,
                  height: 50,
                }}
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                닫다
              </Button>
              <Button
                to={"/"}
                sx={{
                  width: "48%",
                  marginTop: 2,
                  borderRadius: 999,
                  height: 50,
                }}
                variant="contained"
                color="secondary"
                onClick={handleChangePassword}
              >
                비밀번호 변경
              </Button>
            </div>
          </PopupModal>
        </div>
      </Container>
    </div>
  );
}

export default PwFind;
