import React from "react";
import { silverColor } from "../assets/colors/color";

export function ShipTitle({ Step, ShipName, Date, color }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      <p style={{ fontWeight: 700, color: `${silverColor}` }}>{Step}</p>
      <h1 style={{ fontSize: 28, color: `${color}` }}>{ShipName}</h1>
      <p style={{ fontWeight: 700, color: `${silverColor}` }}>{Date}</p>
    </div>
  );
}
export function ShipTitleNext({ ShipName, color }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      <h1 style={{ fontSize: 28 }}>차항정보</h1>
      <p style={{ fontWeight: 700, color: `${color}` }}>{ShipName}</p>
    </div>
  );
}

export function ShipTitleLog({ ShipName, Date, color }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      <h1 style={{ fontSize: 28, color: `${color}` }}>{ShipName}</h1>
    </div>
  );
}
