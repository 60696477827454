//선박작업일지
import { addMonths, format, subMonths } from "date-fns";
import { useState } from "react";
import UserAppBar from "../common/UserAppBar";
import { Container } from "@mui/material";
import { CDate } from "../../../components/CDate";
import { mainColor } from "../../../assets/colors/color";
import ListBox from "../../../components/ListBox";
import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { getShipDataAdmin } from "../../../recoil/GetData";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";
import {
  ImportanceAtom,
  MemoAtom,
  PSignificantAtom,
  PortGauge1Atom,
  PortGauge2Atom,
  PortGauge3Atom,
  PortGauge4Atom,
  PortGauge5Atom,
  PortGaugeToTalAtom,
  PortKL1Atom,
  PortKL2Atom,
  PortKL3Atom,
  PortKL4Atom,
  PortKL5Atom,
  PortKLTotalAtom,
  STSSafetyAtom,
  STSSignificantAtom,
  ShippingExtortionAtom,
  ShippingSafetyAtom,
  StartBoardGauge1Atom,
  StartBoardGauge2Atom,
  StartBoardGauge3Atom,
  StartBoardGauge4Atom,
  StartBoardGauge5Atom,
  StartBoardGaugeToTalAtom,
  StartBoardKL1Atom,
  StartBoardKL2Atom,
  StartBoardKL3Atom,
  StartBoardKL4Atom,
  StartBoardKL5Atom,
  StartBoardKLTotalAtom,
  TemperatureAtom,
  TmtNumberAtom,
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
  noteTAtom,
} from "../../../recoil/OptionsAtom";
import {
  STSBerthingDateAtom1,
  STSBerthingTimeAtom1,
  STSIanDateAtom1,
  STSIanTimeAtom1,
  STSOilInstallationAtom1,
  STSOilInstallationDateAtom1,
  STSRecoveryDateAtom1,
  STSRecoveryTimeAtom1,
  TDBerthingDateAtom1,
  TDBerthingTimeAtom1,
  TDHoseConnectionAtom1,
  TDHoseConnectionDateAtom1,
  TDHoseSeparationAtom1,
  TDHoseSeparationDateAtom1,
  TDIanTimeAtom1,
  TDIanTimeDateAtom1,
  TDStartWorkAtom1,
  TDStartWorkDateAtom1,
  TDWorkCompleteAtom1,
  TDWorkCompleteDateAtom1,
  TUBerthingTimeAtom1,
  TUBerthingTimeDateAtom1,
  TUHoseConnectionAtom1,
  TUHoseConnectionDateAtom1,
  TUHoseSeparationAtom1,
  TUHoseSeparationDateAtom1,
  TUIanTimeAtom1,
  TUIanTimeDateAtom1,
  TUStartWorkAtom1,
  TUStartWorkDateAtom1,
  TUWorkCompleteAtom1,
  TUWorkCompleteDateAtom1,
} from "../../../recoil/TimeAtom";

function UserShipLog() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const selectDay = format(selectedDate, "yyyy-M-dd");
  const year = format(currentMonth, "yyyy");
  const month = format(currentMonth, "MM");
  const TmtNumber = useResetRecoilState(TmtNumberAtom);
  //적하
  const TDBerthingDate = useResetRecoilState(TDBerthingDateAtom1);
  const TDBerthingTime = useResetRecoilState(TDBerthingTimeAtom1);

  const TDHoseConnectionDate = useResetRecoilState(TDHoseConnectionDateAtom1);
  const TDHoseConnection = useResetRecoilState(TDHoseConnectionAtom1);

  const TDStartWorkDate = useResetRecoilState(TDStartWorkDateAtom1);
  const TDStartWork = useResetRecoilState(TDStartWorkAtom1);

  const TDWorkCompleteDate = useResetRecoilState(TDWorkCompleteDateAtom1);
  const TDWorkComplete = useResetRecoilState(TDWorkCompleteAtom1);

  const TDHoseSeparationDate = useResetRecoilState(TDHoseSeparationDateAtom1);
  const TDHoseSeparation = useResetRecoilState(TDHoseSeparationAtom1);

  const TDIanTimeDate = useResetRecoilState(TDIanTimeDateAtom1);
  const TDIanTime = useResetRecoilState(TDIanTimeAtom1);

  //안전관리
  const ShippingSafety = useResetRecoilState(ShippingSafetyAtom);
  //강취
  const ShippingExtortion = useResetRecoilState(ShippingExtortionAtom);

  //양하

  const TUBerthingTimeDate = useResetRecoilState(TUBerthingTimeDateAtom1);
  const TUBerthingTime = useResetRecoilState(TUBerthingTimeAtom1);

  const TUHoseConnectionDate = useResetRecoilState(TUHoseConnectionDateAtom1);
  const TUHoseConnection = useResetRecoilState(TUHoseConnectionAtom1);

  const TUStartWorkDate = useResetRecoilState(TUStartWorkDateAtom1);
  const TUStartWork = useResetRecoilState(TUStartWorkAtom1);

  const TUWorkCompleteDate = useResetRecoilState(TUWorkCompleteDateAtom1);
  const TUWorkComplete = useResetRecoilState(TUWorkCompleteAtom1);

  const TUHoseSheparationDate = useResetRecoilState(TUHoseSeparationDateAtom1);
  const TUHoseSeparation = useResetRecoilState(TUHoseSeparationAtom1);

  const TUIanTimeDate = useResetRecoilState(TUIanTimeDateAtom1);
  const TUIanTime = useResetRecoilState(TUIanTimeAtom1);

  //안전관리
  const UnloadingSafety = useResetRecoilState(UnloadingSafetyAtom);

  //강취
  const UnloadingExtortion = useResetRecoilState(UnloadingExtortionAtom);

  const portGauge1 = useResetRecoilState(PortGauge1Atom);
  const portGauge2 = useResetRecoilState(PortGauge2Atom);
  const portGauge3 = useResetRecoilState(PortGauge3Atom);
  const portGauge4 = useResetRecoilState(PortGauge4Atom);
  const portGauge5 = useResetRecoilState(PortGauge5Atom);
  const portGaugeToTal = useResetRecoilState(PortGaugeToTalAtom);
  const portKL1 = useResetRecoilState(PortKL1Atom);
  const portKL2 = useResetRecoilState(PortKL2Atom);
  const portKL3 = useResetRecoilState(PortKL3Atom);
  const portKL4 = useResetRecoilState(PortKL4Atom);
  const portKL5 = useResetRecoilState(PortKL5Atom);
  const portKLTotal = useResetRecoilState(PortKLTotalAtom);

  const startBoardGauge1 = useResetRecoilState(StartBoardGauge1Atom);
  const startBoardGauge2 = useResetRecoilState(StartBoardGauge2Atom);
  const startBoardGauge3 = useResetRecoilState(StartBoardGauge3Atom);
  const startBoardGauge4 = useResetRecoilState(StartBoardGauge4Atom);
  const startBoardGauge5 = useResetRecoilState(StartBoardGauge5Atom);
  const startBoardGaugeToTal = useResetRecoilState(StartBoardGaugeToTalAtom);

  const startBoardKL1 = useResetRecoilState(StartBoardKL1Atom);
  const startBoardKL2 = useResetRecoilState(StartBoardKL2Atom);
  const startBoardKL3 = useResetRecoilState(StartBoardKL3Atom);
  const startBoardKL4 = useResetRecoilState(StartBoardKL4Atom);
  const startBoardKL5 = useResetRecoilState(StartBoardKL5Atom);
  const startBoardKLTotal = useResetRecoilState(StartBoardKLTotalAtom);

  //온도

  const temperature = useResetRecoilState(TemperatureAtom);
  //비중
  const importance = useResetRecoilState(ImportanceAtom);
  //메모
  const memo = useResetRecoilState(MemoAtom);
  //비고
  const note = useResetRecoilState(noteTAtom);

  //기선

  //기선 접안시간 날짜
  const stsBerthingDate = useResetRecoilState(STSBerthingDateAtom1);
  const stsBerthingTime1 = useResetRecoilState(STSBerthingTimeAtom1);

  //기선 오일 설치 날짜
  const stsOilInstallationDate = useResetRecoilState(
    STSOilInstallationDateAtom1
  );
  const stsOilInstallation1 = useResetRecoilState(STSOilInstallationAtom1);

  //기선  기선 오일회수 날짜
  const stsRecoveryDate = useResetRecoilState(STSRecoveryDateAtom1);
  const stsRecoveryTime1 = useResetRecoilState(STSRecoveryTimeAtom1);

  //기선  기선 이안시간 날짜
  const stsIanDate = useResetRecoilState(STSIanDateAtom1);
  const stsIanTime1 = useResetRecoilState(STSIanTimeAtom1);

  //안전관리
  const safety = useResetRecoilState(STSSafetyAtom);

  //강취
  const remark = useResetRecoilState(STSSignificantAtom);

  //안전관리
  const Safety = useResetRecoilState(STSSafetyAtom);

  //특이사항
  const STSSignificant = useResetRecoilState(STSSignificantAtom);

  //순찰
  const PSignificant = useResetRecoilState(PSignificantAtom);

  useEffect(() => {
    loginAuthenticationUser();
    //운송
    TmtNumber();
    TDBerthingDate();
    TDBerthingTime();
    TDHoseConnectionDate();
    TDHoseConnection();
    TDStartWorkDate();
    TDStartWork();
    TDWorkCompleteDate();
    TDWorkComplete();
    TDHoseSeparationDate();
    TDHoseSeparation();
    TDIanTimeDate();
    TDIanTime();
    ShippingSafety();
    ShippingExtortion();
    TUBerthingTimeDate();
    TUBerthingTime();
    TUHoseConnectionDate();
    TUHoseConnection();
    TUStartWorkDate();
    TUStartWork();
    TUWorkCompleteDate();
    TUWorkComplete();
    TUHoseSheparationDate();
    TUHoseSeparation();
    TUIanTimeDate();
    TUIanTime();
    UnloadingSafety();
    UnloadingExtortion();
    portGauge1();
    portGauge2();
    portGauge3();
    portGauge4();
    portGauge5();
    portGaugeToTal();
    portKL1();
    portKL2();
    portKL3();
    portKL4();
    portKL5();
    portKLTotal();
    startBoardGauge1();
    startBoardGauge2();
    startBoardGauge3();
    startBoardGauge4();
    startBoardGauge5();
    startBoardGaugeToTal();
    startBoardKL1();
    startBoardKL2();
    startBoardKL3();
    startBoardKL4();
    startBoardKL5();
    startBoardKLTotal();
    note();
    memo();
    importance();
    temperature();

    //기선
    stsBerthingDate();
    stsBerthingTime1();
    stsOilInstallationDate();
    stsOilInstallation1();
    stsRecoveryDate();
    stsRecoveryTime1();
    stsIanDate();
    stsIanTime1();
    safety();
    remark();
    Safety();
    STSSignificant();

    //순찰
    PSignificant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shipScheduleData = useRecoilValue(
    getShipDataAdmin(`${year}-${month}-23`)
  );

  console.log(shipScheduleData);

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          marginBottom: "70px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "20px" }}>선박 작업 일지</h1>

        <CDate
          edata={shipScheduleData && shipScheduleData}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          currentMonth={currentMonth}
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: `${mainColor}`,
            padding: "8px 0",
          }}
        >
          선택된 날짜 : {selectDay}{" "}
        </p>
        <ListBox
          edata={shipScheduleData && shipScheduleData}
          selectedDate={selectedDate}
          NextPageType={"UserShipLog"}
        />
      </Container>
    </div>
  );
}

export default UserShipLog;
