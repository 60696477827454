import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener, requestPermission } from "../firebase";
import firebase from "firebase/app";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      console.log("알림 표시: ", notification);
      notify();
    }
  }, [notification]);

  if (firebase.messaging.isSupported()) {
    requestPermission();
    onMessageListener()
      .then((payload) => {
        console.log("알림 수신: ", payload);
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
      })
      .catch((err) => console.log("failed: ", err));

    console.log("적용됨");
  } else {
    console.log("적용안됨");
    console.log("FCM이 이 브라우저에서 지원되지 않습니다.");
  }

  return <Toaster />;
};

export default Notification;
