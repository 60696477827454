import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../hooks/useGetDate";
import { useState } from "react";
import { silverColor } from "../../../assets/colors/color";

import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function ManagerShipLogRegistration() {
  const navigate = useNavigate();

  //비고값

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  // 선박 운항기능
  const shipTypeList = useGetData("/api/admin/ship/type").data.data;
  const [shipType, setShipType] = useState("");

  //운송선박 종류

  const transitList = useGetData("/api/admin/ship/list?shipType=운송").data
    .data;
  const [transitShip, setTransitShip] = useState("");

  // console.log(transitList);
  //기선 선박종류

  const steamerList = useGetData("/api/admin/ship/list?shipType=기선STS").data
    .data;
  const [steamerShip, setSteamerShip] = useState("");
  //순찰 선박종류

  const patrolList = useGetData("/api/admin/ship/list?shipType=순찰").data.data;
  const [patrolShip, setPatrolShip] = useState("");

  const handleChange = (e, type) => {
    const value = e.target.value;
    type === "shipType"
      ? setShipType(value)
      : type === "TransitShip"
      ? setTransitShip(value)
      : type === "SteamerShip"
      ? setSteamerShip(value)
      : setPatrolShip(value);
  };

  const navigateToPurchase = () => {
    shipType === "운송"
      ? navigate("/manager-ship-log-transit", {
          state: { shipName: transitShip, shipType: shipType },
        })
      : shipType === "기선STS"
      ? navigate("/manager-ship-log-steamship", {
          state: { shipName: steamerShip, shipType: shipType },
        })
      : shipType === "순찰"
      ? navigate("/manager-ship-log-patrol", {
          state: { shipName: patrolShip, shipType: shipType },
        })
      : navigate("/manager-ship-log-registration");
  };
  const data = {
    shipType: shipType,
    transitShip: transitShip,
    steamerShip: steamerShip,
    patrolShip: patrolShip,
  };

  const dataValidation =
    (data.shipType === "순찰" && data.patrolShip) ||
    (data.shipType === "기선STS" && data.steamerShip) ||
    (data.shipType === "운송" && data.transitShip);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "70px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <h1 style={{ fontSize: "20px" }}>선박 작업 일정</h1>
          <Box sx={{ minWidth: 120, width: "100%" }}>
            <FormControl color="secondary" fullWidth>
              <p
                style={{
                  marginLeft: 5,
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                선박운항 기능을 선택해 주세요
              </p>
              <Select
                defaultValue={shipType}
                value={shipType}
                onChange={(e) => handleChange(e, `shipType`)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled sx={{ color: `${silverColor}` }} value="">
                  선박운항 기능을 선택해 주세요
                </MenuItem>
                {shipTypeList &&
                  shipTypeList.map((item) => {
                    return (
                      <MenuItem key={item.shipTypeId} value={item.type}>
                        {item.type}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          {shipType === "운송" ? (
            <Box sx={{ minWidth: 120, width: "100%" }}>
              <FormControl color="secondary" fullWidth>
                <p
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  선박 운항선 이름 - 운송
                </p>
                <Select
                  defaultValue={transitShip}
                  value={transitShip}
                  onChange={(e) => handleChange(e, `TransitShip`)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled sx={{ color: `${silverColor}` }} value="">
                    운항선 이름을 선택해주세요.
                  </MenuItem>
                  {transitList &&
                    transitList.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          ) : shipType === "기선STS" ? (
            <Box sx={{ minWidth: 120, width: "100%" }}>
              <FormControl color="secondary" fullWidth>
                <p
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  선박 운항선 이름 - 기선STS
                </p>
                <Select
                  defaultValue={steamerShip}
                  value={steamerShip}
                  onChange={(e) => handleChange(e, `SteamerShip`)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled sx={{ color: `${silverColor}` }} value="">
                    운항선 이름을 선택해주세요.
                  </MenuItem>
                  {steamerList &&
                    steamerList.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          ) : shipType === "순찰" ? (
            <Box sx={{ minWidth: 120, width: "100%" }}>
              <FormControl color="secondary" fullWidth>
                <p
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  선박 운항선 이름 - 순찰
                </p>
                <Select
                  defaultValue={patrolShip}
                  value={patrolShip}
                  onChange={(e) => handleChange(e, `PatrolShip`)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled sx={{ color: `${silverColor}` }} value="">
                    운항선 이름을 선택해주세요.
                  </MenuItem>
                  {patrolList &&
                    patrolList.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <p style={{ color: "red" }}>선박 운항 기능을 먼저 선택해 주세요</p>
          )}

          <Button
            sx={{
              maxWidth: "1200px",
              width: "100%",
              borderRadius: 999,
            }}
            variant="contained"
            color="secondary"
            onClick={(e) => {
              navigateToPurchase(e);
            }}
            disabled={!dataValidation}
          >
            등록하기
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default ManagerShipLogRegistration;
