import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import { mainColor } from "../../../../assets/colors/color";
import ManagerAppBar from "../../common/ManagerAppBar";
import { useLocation } from "react-router-dom";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  BrokerAtom,
  CargoAtom,
  ChartererAtom,
  RecipientAtom,
  ShippingExtortionAtom,
  ShippingSafetyAtom,
  SupplierAtom,
  TDCertifierAtom,
  TDLoaderAndUnloaderAtom,
  TDTug,
  TDWorkplaceAtom,
  TUCertifierAtom,
  TULoaderAndUnloaderAtom,
  TUTug,
  TUWorkplaceAtom,
  TmtNumberAtom,
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
  noteTAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import ManagerShipLogTransitTabsFix from "./ManagerShipLogTransitTabsFix";

//작업일지 운송 수정
import {
  DateTextValidationAtom1,
  DateTextValidationAtom2,
  DateTextValidationAtom3,
} from "../../../../recoil/TimeOptionsValidation";
import { format, parseISO } from "date-fns";
import {
  TDroppingEndDateAtom,
  TDroppingEndTimeAtom,
  TDroppingStartDateAtom,
  TDroppingStartTimeAtom,
  TUnloadingEndDateAtom,
  TUnloadingEndTimeAtom,
  TUnloadingStartDateAtom,
  TUnloadingStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import { ModalSub3, ModalSub4 } from "../../../../components/ModalSub";
import {
  TDBerthingDateAtom1,
  TDBerthingTimeAtom1,
  TDHoseConnectionAtom1,
  TDHoseConnectionDateAtom1,
  TDHoseSeparationAtom1,
  TDHoseSeparationDateAtom1,
  TDIanTimeAtom1,
  TDIanTimeDateAtom1,
  TDStartWorkAtom1,
  TDStartWorkDateAtom1,
  TDWorkCompleteAtom1,
  TDWorkCompleteDateAtom1,
  TUBerthingTimeAtom1,
  TUBerthingTimeDateAtom1,
  TUHoseConnectionAtom1,
  TUHoseConnectionDateAtom1,
  TUHoseSeparationAtom1,
  TUHoseSeparationDateAtom1,
  TUIanTimeAtom1,
  TUIanTimeDateAtom1,
  TUStartWorkAtom1,
  TUStartWorkDateAtom1,
  TUWorkCompleteAtom1,
  TUWorkCompleteDateAtom1,
} from "../../../../recoil/TimeAtom";
import {
  OptionGetSelectorFamilly,
  getAllShipList,
  getAllShipType,
  getShipData,
  getTugLsit,
} from "../../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";

function ManagerShipLogTransitFix() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(
    getShipData(`floating?detailJourneyId=${journeyDetailId}`)
  );
  console.log(shipApi);
  const shipApi2 = useRecoilValue(
    getShipData(`floating/shipping?journeyDetailId=${journeyDetailId}`)
  );
  console.log(shipApi2);
  const shipApi3 = useRecoilValue(
    getShipData(`floating/unloading?journeyDetailId=${journeyDetailId}`)
  );
  console.log(shipApi3);
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const [step, setStep] = useRecoilState(statusAtom);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  // 선박  typeId

  const shipTypeApi = useRecoilValue(getAllShipType);
  const shipType = shipInfo && shipInfo.shipType;

  const shipTypeId =
    shipTypeApi &&
    shipTypeApi
      .filter((item) => (item.type === shipType ? item.shipTypeId : ""))
      .map((item) => item.shipTypeId)
      .toString();

  // 선박  id

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipName = shipInfo && shipInfo.shipName;

  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipName ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //용선주id값 받아오기
  const chartererAPI = useRecoilValue(OptionGetSelectorFamilly("charterer"));
  const charterer = useRecoilValue(ChartererAtom).data;
  const setCharterer = useSetRecoilState(ChartererAtom);

  const chartererId =
    chartererAPI &&
    chartererAPI
      .filter((item) => (item.charterer === charterer ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //화물명id값 받아오기
  const cargoAPI = useRecoilValue(OptionGetSelectorFamilly("cargo"));
  const cargo = useRecoilValue(CargoAtom).data;
  const setCargo = useSetRecoilState(CargoAtom);

  const cargoId =
    cargoAPI &&
    cargoAPI
      .filter((item) => (item.cargo === cargo ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //공급자id값 받아오기
  const supplierAPI = useRecoilValue(OptionGetSelectorFamilly("supplier")); // 공급자
  const supplier = useRecoilValue(SupplierAtom).data;
  const setSupplier = useSetRecoilState(SupplierAtom);

  const supplierId =
    supplierAPI &&
    supplierAPI
      .filter((item) => (item.supplier === supplier ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //수급자 id값받아오기
  const recipientAPI = useRecoilValue(OptionGetSelectorFamilly("recipient")); //수급자
  const recipient = useRecoilValue(RecipientAtom).data;
  const setRecipient = useSetRecoilState(RecipientAtom);

  const recipientId =
    recipientAPI &&
    recipientAPI
      .filter((item) => (item.recipient === recipient ? item.id : ""))
      .map((item) => item.id)
      .toString();

  console.log(recipientId);

  //중개사 id값받아오기
  const brokerApi = useRecoilValue(OptionGetSelectorFamilly("broker"));
  const broker = useRecoilValue(BrokerAtom).data;
  const setBroker = useSetRecoilState(BrokerAtom);

  const brokerId =
    brokerApi &&
    brokerApi
      .filter((item) => (item.broker === broker ? item.id : ""))
      .map((item) => item.id)
      .toString();

  // 수량 입력값 받아오기
  const [amount, setAmount] = useRecoilState(TmtNumberAtom);
  //적하 안전관리
  const [ShippingSafety, setShippingSafety] =
    useRecoilState(ShippingSafetyAtom);
  //적하 강취
  const [ShippingExtortion, setShippingExtortion] = useRecoilState(
    ShippingExtortionAtom
  );

  //양하안전관리
  const [UnloadingSafety, setUnloadingSafety] =
    useRecoilState(UnloadingSafetyAtom);

  //양하강취
  const [UnloadingExtortion, setUnloadingExtortion] = useRecoilState(
    UnloadingExtortionAtom
  );

  //적하시작일시
  const [shippingWorkStartDate, setShippingWorkStartDate] = useRecoilState(
    TDroppingStartDateAtom
  );
  const [shippingWorkStartTime, setShippingWorkStartTime] = useRecoilState(
    TDroppingStartTimeAtom
  );

  const shippingWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //적하 완료 일시
  const [shippingWorkDoneDate, setShippingWorkDoneDate] =
    useRecoilState(TDroppingEndDateAtom);
  const [shippingWorkDoneTime, setShippingWorkDoneTime] =
    useRecoilState(TDroppingEndTimeAtom);

  const shippingWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  //양하시작일시
  const [unloadingWorkStartDate, setUnloadingWorkStartDate] = useRecoilState(
    TUnloadingStartDateAtom
  );
  const [unloadingWorkStartTime, setUnloadingWorkStartTime] = useRecoilState(
    TUnloadingStartTimeAtom
  );

  const unloadingWorkStartAt = `${unloadingWorkStartDate.year}-${unloadingWorkStartDate.month}-${unloadingWorkStartDate.day}T${unloadingWorkStartTime.hh}:${unloadingWorkStartTime.mm}:00`;
  //양하 완료 일시
  const [unloadingWorkDoneDate, setUnloadingWorkDoneDate] = useRecoilState(
    TUnloadingEndDateAtom
  );
  const [unloadingWorkDoneTime, setUunloadingWorkDoneTime] = useRecoilState(
    TUnloadingEndTimeAtom
  );

  const unloadingWorkDoneAt = `${unloadingWorkDoneDate.year}-${unloadingWorkDoneDate.month}-${unloadingWorkDoneDate.day}T${unloadingWorkDoneTime.hh}:${unloadingWorkDoneTime.mm}:00`;

  //적하 작업장소 id값받아오기
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing"));
  const setSuppworkplaceTD = useSetRecoilState(TDWorkplaceAtom);
  const workplaceTD = useRecoilValue(TDWorkplaceAtom).data.split(" / ")[0];

  const TDWorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 작업장소 id값

  const workplaceTU = useRecoilValue(TUWorkplaceAtom).data.split(" / ")[0];
  const setSuppworkplaceTU = useSetRecoilState(TUWorkplaceAtom);

  const TUWorkplaceAtomId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.berthingPlaceName === workplaceTU ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //적하 검정사
  const certifierApi = useRecoilValue(OptionGetSelectorFamilly("certifier"));
  const certifierTD = useRecoilValue(TDCertifierAtom).data;
  const setCertifierTD = useSetRecoilState(TDCertifierAtom);

  const certifierTDId =
    certifierApi &&
    certifierApi
      .filter((item) => (item.certifier === certifierTD ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 검정사

  const certifierTU = useRecoilValue(TUCertifierAtom).data;
  const setCertifierTU = useSetRecoilState(TUCertifierAtom);

  const certifierTUId =
    certifierApi &&
    certifierApi
      .filter((item) => (item.certifier === certifierTU ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //적하 하역사

  const loaderAndUnloaderApi = useRecoilValue(
    OptionGetSelectorFamilly("unloader")
  );
  const TDUnloader = useRecoilValue(TDLoaderAndUnloaderAtom).data;
  const setTDUnloader = useSetRecoilState(TDLoaderAndUnloaderAtom);

  const TDshippingUnloaderId =
    loaderAndUnloaderApi &&
    loaderAndUnloaderApi
      .filter((item) => (item.unLoader === TDUnloader ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //양하 하역사
  const TUUnloader = useRecoilValue(TULoaderAndUnloaderAtom).data;
  const setTUUnloader = useSetRecoilState(TULoaderAndUnloaderAtom);

  const TUshippingUnloaderId =
    loaderAndUnloaderApi &&
    loaderAndUnloaderApi
      .filter((item) => (item.unLoader === TUUnloader ? item.id : ""))
      .map((item) => item.id)
      .toString();

  //적하 예인선
  const TugApi = useRecoilValue(getTugLsit); //예인선
  console.log(TugApi);

  const tdTug = useRecoilValue(TDTug).data;
  const setTDTug = useSetRecoilState(TDTug);

  const tdTugId =
    TugApi &&
    TugApi.filter((item) => (item.shipName === tdTug ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();
  // 양하 예인선
  const tuTug = useRecoilValue(TUTug).data;
  const setTUTug = useSetRecoilState(TUTug);
  const tuTugId =
    TugApi &&
    TugApi.filter((item) => (item.shipName === tuTug ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //접안시간 적하
  const setTDBerthingDate = useSetRecoilState(TDBerthingDateAtom1);
  const setTDBerthingTime = useSetRecoilState(TDBerthingTimeAtom1);

  //호스연결 적하
  const setTDHoseConnectionDate = useSetRecoilState(TDHoseConnectionDateAtom1);
  const setTDHoseConnection = useSetRecoilState(TDHoseConnectionAtom1);

  //작업시작 적하
  const setTDStartWorkDate = useSetRecoilState(TDStartWorkDateAtom1);
  const setTDStartWork = useSetRecoilState(TDStartWorkAtom1);

  //작업완료 적하
  const setTDWorkCompleteDate = useSetRecoilState(TDWorkCompleteDateAtom1);
  const setTDWorkComplete = useSetRecoilState(TDWorkCompleteAtom1);

  //호스분리 적하
  const setTDHoseSeparationDate = useSetRecoilState(TDHoseSeparationDateAtom1);
  const setTDHoseSeparation = useSetRecoilState(TDHoseSeparationAtom1);

  //이안시간 적하
  const setTDIanTimeDate = useSetRecoilState(TDIanTimeDateAtom1);
  const setTDIanTime = useSetRecoilState(TDIanTimeAtom1);

  //접안시간 양하
  const setTUBerthingTimeDate = useSetRecoilState(TUBerthingTimeDateAtom1);
  const setTUBerthingTime = useSetRecoilState(TUBerthingTimeAtom1);

  //호스연결 양하
  const setTUHoseConnectionDate = useSetRecoilState(TUHoseConnectionDateAtom1);
  const setTUHoseConnection = useSetRecoilState(TUHoseConnectionAtom1);

  //작업시작 양하
  const setTUStartWorkDate = useSetRecoilState(TUStartWorkDateAtom1);
  const setTUStartWork = useSetRecoilState(TUStartWorkAtom1);

  //작업완료 양하
  const setTUWorkCompleteDate = useSetRecoilState(TUWorkCompleteDateAtom1);
  const setTUWorkComplete = useSetRecoilState(TUWorkCompleteAtom1);

  //호스분리 양하
  const setTUHoseSeparationDate = useSetRecoilState(TUHoseSeparationDateAtom1);
  const setTUHoseSeparation = useSetRecoilState(TUHoseSeparationAtom1);

  //이안시간 양하
  const setTUIanTimeDate = useSetRecoilState(TUIanTimeDateAtom1);
  const setTUIanTime = useSetRecoilState(TUIanTimeAtom1);

  const note = useRecoilValue(noteTAtom);

  const data = {
    detailJourneyId: journeyDetailId,
    shipId: shipId,
    shipTypeId: shipTypeId,
    status: step,
    chartererId: chartererId, //용선주
    brokerId: brokerId, //중개사
    cargoId: cargoId, //화물명
    supplierId: supplierId, //공급자
    receipientId: recipientId, //수급자
    amount: amount, //수량
    note: note,
    //적하
    shippingWorkStartAt: shippingWorkStartAt, //적하 시작일시
    shippingWorkDoneAt: shippingWorkDoneAt, //적하 완료일시
    shippingBerthingPlaceId: TDWorkplaceAtomId, //적하 접안장소
    shippingCertifierId: certifierTDId, //적하 검정사
    shippingUnloaderId: TDshippingUnloaderId, //적하 하역사
    shippingTugShipId: tdTugId, //적하예인선

    shippingSafety: ShippingSafety, //적하 안전관리
    shippingLineHandling: ShippingExtortion, //적하 강취

    //양하
    unloadingWorkStartAt: unloadingWorkStartAt, //양하 시작일시
    unloadingWorkDoneAt: unloadingWorkDoneAt, //양하 완료일시
    unloadingBerthingPlaceId: TUWorkplaceAtomId, //양하 접안장소
    unloadingCertifierId: certifierTUId, //양하 검정사
    unloadingUnloaderId: TUshippingUnloaderId, //양하 하역사
    unloadingTugShipId: tuTugId, //양하예인선

    unloadingSafety: UnloadingSafety, //양하 안전관리
    unloadingLineHandling: UnloadingExtortion, //양하 강취
  };

  console.log("운송 데이터 업데이트", data);

  //적하시작완료
  const before1 =
    parseISO(shippingWorkStartAt).getTime() <=
    parseISO(shippingWorkDoneAt).getTime();

  console.log(before1);

  //적하 완료 양하 시작

  const before2 =
    parseISO(shippingWorkDoneAt).getTime() <
    parseISO(unloadingWorkStartAt).getTime();

  const before22 =
    parseISO(shippingWorkDoneAt).getTime() ===
    parseISO(unloadingWorkStartAt).getTime();

  //양하 시작 적하완료

  const before3 =
    parseISO(unloadingWorkStartAt).getTime() <=
    parseISO(unloadingWorkDoneAt).getTime();

  const setDateTextValidation1 = useSetRecoilState(DateTextValidationAtom1);
  const setDateTextValidation2 = useSetRecoilState(DateTextValidationAtom2);
  const setDateTextValidation3 = useSetRecoilState(DateTextValidationAtom3);

  useEffect(() => {
    setShippingWorkStartDate({
      year: format(parseISO(shipApi2.workStartAt), "yyyy"),
      month: format(parseISO(shipApi2.workStartAt), "MM"),
      day: format(parseISO(shipApi2.workStartAt), "dd"),
    });
    setShippingWorkStartTime({
      hh: format(parseISO(shipApi2.workStartAt), "HH"),
      mm: format(parseISO(shipApi2.workStartAt), "mm"),
    });
    setShippingWorkDoneDate({
      year: format(parseISO(shipApi2.workDoneAt), "yyyy"),
      month: format(parseISO(shipApi2.workDoneAt), "MM"),
      day: format(parseISO(shipApi2.workDoneAt), "dd"),
    });
    setShippingWorkDoneTime({
      hh: format(parseISO(shipApi2.workDoneAt), "HH"),
      mm: format(parseISO(shipApi2.workDoneAt), "mm"),
    });
    // 양하
    setUnloadingWorkStartDate({
      year: format(parseISO(shipApi3.workStartAt), "yyyy"),
      month: format(parseISO(shipApi3.workStartAt), "MM"),
      day: format(parseISO(shipApi3.workStartAt), "dd"),
    });
    setUnloadingWorkStartTime({
      hh: format(parseISO(shipApi3.workStartAt), "HH"),
      mm: format(parseISO(shipApi3.workStartAt), "mm"),
    });
    setUnloadingWorkDoneDate({
      year: format(parseISO(shipApi3.workDoneAt), "yyyy"),
      month: format(parseISO(shipApi3.workDoneAt), "MM"),
      day: format(parseISO(shipApi3.workDoneAt), "dd"),
    });
    setUunloadingWorkDoneTime({
      hh: format(parseISO(shipApi3.workDoneAt), "HH"),
      mm: format(parseISO(shipApi3.workDoneAt), "mm"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStep(shipApi2.status);
    setCharterer({ data: shipApi.charterer });
    setCargo({ data: shipApi.cargo });
    setSupplier({ data: shipApi.supplier });
    setRecipient({ data: shipApi.recipient });
    setBroker({ data: shipApi.broker });
    setAmount(shipApi.amount);
    //적하 안전관리
    setShippingSafety(shipApi2.safety);
    //적하 강취
    setShippingExtortion(shipApi2.lineHandling);
    //양하 안전관리
    setUnloadingSafety(shipApi3.safety);
    //양하 강취
    setUnloadingExtortion(shipApi3.lineHandling);

    if (before1) {
      //적하 완료 시작
      setDateTextValidation1("");
    } else {
      setDateTextValidation1("적하 완료일시가 적하 시작일시보다 빠릅니다");
    }

    if (before2) {
      //적하 완료 시작
      setDateTextValidation2("");
    } else {
      setDateTextValidation2("양하 시작일시가 적하 완료일시보다 빠릅니다.");
    }
    if (before22) {
      //적하 완료 시작
      setDateTextValidation2("양하 시작일시가 적하 완료일시와 같습니다.");
    }
    if (before3) {
      //적하 완료 시작
      setDateTextValidation3("");
    } else {
      setDateTextValidation3("양하 완료일시가 양하 시작일시보다 빠릅니다.");
    }

    setTDUnloader({ data: shipApi2.unloader });
    setTUUnloader({ data: shipApi3.unloader });
    setCertifierTD({ data: shipApi2.certifier });
    setCertifierTU({ data: shipApi3.certifier });
    setSuppworkplaceTD({ data: shipApi2.berthingPlace });
    setSuppworkplaceTU({ data: shipApi3.berthingPlace });
    setTDTug({ data: shipApi2.tugShip });
    setTUTug({ data: shipApi3.tugShip });

    if (shipApi2.dockingAt !== null) {
      setTDBerthingDate({
        year: format(parseISO(shipApi2.dockingAt), "yyyy"),
        month: format(parseISO(shipApi2.dockingAt), "M"),
        day: format(parseISO(shipApi2.dockingAt), "dd"),
      });
      setTDBerthingTime({
        hh: format(parseISO(shipApi2.dockingAt), "HH"),
        mm: format(parseISO(shipApi2.dockingAt), "mm"),
      });
    }
    if (shipApi2.hoseConnectionAt !== null) {
      //적하 호스연결
      setTDHoseConnectionDate({
        year: format(parseISO(shipApi2.hoseConnectionAt), "yyyy"),
        month: format(parseISO(shipApi2.hoseConnectionAt), "M"),
        day: format(parseISO(shipApi2.hoseConnectionAt), "dd"),
      });
      setTDHoseConnection({
        hh: format(parseISO(shipApi2.hoseConnectionAt), "HH"),
        mm: format(parseISO(shipApi2.hoseConnectionAt), "mm"),
      });
    }
    if (shipApi2.jobStartAt !== null) {
      //적하 작업시작
      setTDStartWorkDate({
        year: format(parseISO(shipApi2.jobStartAt), "yyyy"),
        month: format(parseISO(shipApi2.jobStartAt), "M"),
        day: format(parseISO(shipApi2.jobStartAt), "dd"),
      });
      setTDStartWork({
        hh: format(parseISO(shipApi2.jobStartAt), "HH"),
        mm: format(parseISO(shipApi2.jobStartAt), "mm"),
      });
    }
    if (shipApi2.jobDoneAt !== null) {
      //적하 작업완료
      setTDWorkCompleteDate({
        year: format(parseISO(shipApi2.jobDoneAt), "yyyy"),
        month: format(parseISO(shipApi2.jobDoneAt), "M"),
        day: format(parseISO(shipApi2.jobDoneAt), "dd"),
      });
      setTDWorkComplete({
        hh: format(parseISO(shipApi2.jobDoneAt), "HH"),
        mm: format(parseISO(shipApi2.jobDoneAt), "mm"),
      });
    }
    if (shipApi2.hoseDisconnectionAt !== null) {
      //적하 호스분리
      setTDHoseSeparationDate({
        year: format(parseISO(shipApi2.hoseDisconnectionAt), "yyyy"),
        month: format(parseISO(shipApi2.hoseDisconnectionAt), "M"),
        day: format(parseISO(shipApi2.hoseDisconnectionAt), "dd"),
      });
      setTDHoseSeparation({
        hh: format(parseISO(shipApi2.hoseDisconnectionAt), "HH"),
        mm: format(parseISO(shipApi2.hoseDisconnectionAt), "mm"),
      });
    }
    if (shipApi2.departureAt !== null) {
      //적하 이안시간
      setTDIanTimeDate({
        year: format(parseISO(shipApi2.departureAt), "yyyy"),
        month: format(parseISO(shipApi2.departureAt), "M"),
        day: format(parseISO(shipApi2.departureAt), "dd"),
      });
      setTDIanTime({
        hh: format(parseISO(shipApi2.departureAt), "HH"),
        mm: format(parseISO(shipApi2.departureAt), "mm"),
      });
    }
    if (shipApi3.dockingAt !== null) {
      //양하 접안시간
      setTUBerthingTimeDate({
        year: format(parseISO(shipApi3.dockingAt), "yyyy"),
        month: format(parseISO(shipApi3.dockingAt), "M"),
        day: format(parseISO(shipApi3.dockingAt), "dd"),
      });
      setTUBerthingTime({
        hh: format(parseISO(shipApi3.dockingAt), "HH"),
        mm: format(parseISO(shipApi3.dockingAt), "mm"),
      });
    }
    if (shipApi3.hoseConnectionAt !== null) {
      //양하 호스연결
      setTUHoseConnectionDate({
        year: format(parseISO(shipApi3.hoseConnectionAt), "yyyy"),
        month: format(parseISO(shipApi3.hoseConnectionAt), "M"),
        day: format(parseISO(shipApi3.hoseConnectionAt), "dd"),
      });
      setTUHoseConnection({
        hh: format(parseISO(shipApi3.hoseConnectionAt), "HH"),
        mm: format(parseISO(shipApi3.hoseConnectionAt), "mm"),
      });
    }
    if (shipApi3.jobStartAt !== null) {
      //양하 작업시작
      setTUStartWorkDate({
        year: format(parseISO(shipApi3.jobStartAt), "yyyy"),
        month: format(parseISO(shipApi3.jobStartAt), "M"),
        day: format(parseISO(shipApi3.jobStartAt), "dd"),
      });
      setTUStartWork({
        hh: format(parseISO(shipApi3.jobStartAt), "HH"),
        mm: format(parseISO(shipApi3.jobStartAt), "mm"),
      });
    }
    if (shipApi3.jobDoneAt !== null) {
      //양하 작업완료
      setTUWorkCompleteDate({
        year: format(parseISO(shipApi3.jobDoneAt), "yyyy"),
        month: format(parseISO(shipApi3.jobDoneAt), "M"),
        day: format(parseISO(shipApi3.jobDoneAt), "dd"),
      });
      setTUWorkComplete({
        hh: format(parseISO(shipApi3.jobDoneAt), "HH"),
        mm: format(parseISO(shipApi3.jobDoneAt), "mm"),
      });
    }
    if (shipApi3.hoseDisconnectionAt !== null) {
      //양하 호스분리
      setTUHoseSeparationDate({
        year: format(parseISO(shipApi3.hoseDisconnectionAt), "yyyy"),
        month: format(parseISO(shipApi3.hoseDisconnectionAt), "M"),
        day: format(parseISO(shipApi3.hoseDisconnectionAt), "dd"),
      });
      setTUHoseSeparation({
        hh: format(parseISO(shipApi3.hoseDisconnectionAt), "HH"),
        mm: format(parseISO(shipApi3.hoseDisconnectionAt), "mm"),
      });
    }
    if (shipApi3.departureAt !== null) {
      //양하 이안시간
      setTUIanTimeDate({
        year: format(parseISO(shipApi3.departureAt), "yyyy"),
        month: format(parseISO(shipApi3.departureAt), "M"),
        day: format(parseISO(shipApi3.departureAt), "dd"),
      });
      setTUIanTime({
        hh: format(parseISO(shipApi3.departureAt), "HH"),
        mm: format(parseISO(shipApi3.departureAt), "mm"),
      });
    }
  }, [
    before1,
    before2,
    before22,
    before3,
    setAmount,
    setShippingExtortion,
    setShippingSafety,
    setUnloadingExtortion,
    setUnloadingSafety,
    setBroker,
    setCargo,
    setCertifierTD,
    setCertifierTU,
    setCharterer,
    setDateTextValidation1,
    setDateTextValidation2,
    setDateTextValidation3,
    setRecipient,
    setStep,
    setSupplier,
    setSuppworkplaceTD,
    setSuppworkplaceTU,
    setTDBerthingDate,
    setTDBerthingTime,
    setTDHoseConnection,
    setTDHoseConnectionDate,
    setTDHoseSeparation,
    setTDHoseSeparationDate,
    setTDIanTime,
    setTDIanTimeDate,
    setTDStartWork,
    setTDStartWorkDate,
    setTDTug,
    setTDUnloader,
    setTDWorkComplete,
    setTDWorkCompleteDate,
    setTUBerthingTime,
    setTUBerthingTimeDate,
    setTUHoseConnection,
    setTUHoseConnectionDate,
    setTUHoseSeparation,
    setTUHoseSeparationDate,
    setTUIanTime,
    setTUIanTimeDate,
    setTUStartWork,
    setTUStartWorkDate,
    setTUTug,
    setTUUnloader,
    setTUWorkComplete,
    setTUWorkCompleteDate,
    shipApi.amount,
    shipApi.broker,
    shipApi.cargo,
    shipApi.charterer,
    shipApi.recipient,
    shipApi.supplier,
    shipApi2.lineHandling,
    shipApi2.safety,
    shipApi2.berthingPlace,
    shipApi2.certifier,
    shipApi2.departureAt,
    shipApi2.dockingAt,
    shipApi2.hoseConnectionAt,
    shipApi2.hoseDisconnectionAt,
    shipApi2.jobDoneAt,
    shipApi2.jobStartAt,
    shipApi2.status,
    shipApi2.tugShip,
    shipApi2.unloader,
    shipApi3.lineHandling,
    shipApi3.safety,
    shipApi3.berthingPlace,
    shipApi3.certifier,
    shipApi3.departureAt,
    shipApi3.dockingAt,
    shipApi3.hoseConnectionAt,
    shipApi3.hoseDisconnectionAt,
    shipApi3.jobDoneAt,
    shipApi3.jobStartAt,
    shipApi3.tugShip,
    shipApi3.unloader,
    shipApi3.workStartAt,
  ]);

  const dataValidation =
    data.shipId &&
    data.shipTypeId &&
    data.status &&
    data.chartererId && //용선주
    data.brokerId && //중개사
    data.cargoId && //화물명
    data.supplierId && //공급자
    data.receipientId && //수급자
    data.amount && //수량
    //적하
    data.shippingWorkStartAt && //적하 시작일시
    data.shippingWorkDoneAt && //적하 완료일시
    data.shippingBerthingPlaceId && //적하 접안장소
    data.shippingCertifierId &&
    data.shippingUnloaderId &&
    data.shippingTugShipId &&
    //양하
    data.unloadingWorkStartAt &&
    data.unloadingWorkDoneAt &&
    data.unloadingBerthingPlaceId &&
    data.unloadingCertifierId &&
    data.unloadingTugShipId &&
    data.unloadingUnloaderId &&
    before1 &&
    before2 &&
    before3;

  console.log(data);
  const [disable, setDisable] = React.useState(false);
  const handelSub = async (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .put("/api/journey/floating/update/admin", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 202) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("수정된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
          // window.location.href = "/manager-ship-log";
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
        // window.location.href = "/manager-ship-log";
      });
  };
  const delsub = () => {
    setDisable(true);
    axios
      .delete(`/api/journey/floating/delete?detailJourneyId=${journeyDetailId}`)
      .then((response) => {
        console.log(response);
        if (response.data.status === 203) {
          window.location.href = "/manager-ship-log";
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };
  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonGroupStep step={step} setStep={setStep} />
        <ShipTitleLog ShipName={shipInfo.shipName} color={`${mainColor}`} />
        <ManagerShipLogTransitTabsFix
          shipApi={shipApi}
          shipApi2={shipApi2}
          shipApi3={shipApi3}
        />
        <ButtonGroup
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            fontFamily: ["Noto Sans KR", "sans-serif"],
            borderRadius: 99,
          }}
        >
          <Button
            disabled={!dataValidation}
            // onClick={handelSub}
            onClick={() => {
              setmodal1(!modal1);
            }}
            color="secondary"
            sx={{
              padding: "10px 0",
              flex: 1,
              borderRadius: "99px  0 0 99px",
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            수정하기
          </Button>
          <Button
            onClick={() => setmodal2(!modal1)}
            color="red"
            sx={{
              padding: "10px 0",
              flex: 1,
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: "0  99px 99px 0",
              fontWeight: 500,
            }}
          >
            삭제하기
          </Button>
        </ButtonGroup>
        {!dataValidation ? (
          <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
        ) : null}
        {modal1 ? (
          <ModalSub3
            handelSub={handelSub}
            setmodal1={setmodal1}
            disable={disable}
          />
        ) : null}
        {modal2 ? (
          <ModalSub4
            setmodal1={setmodal2}
            handelDel={delsub}
            disable={disable}
          />
        ) : null}
      </Container>
    </div>
  );
}

export default ManagerShipLogTransitFix;
