import React, { useState } from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { mainColor, silverColor } from "../../../assets/colors/color";
import { useLocation, useNavigate } from "react-router-dom";
import DateSelectDetail from "../../../components/DateSelectDetail";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { InspectionScheduleAtom } from "../../../recoil/DateAndTimeAtom";
import {
  getAllShipList,
  getInspectionListOption,
  getRegistationInspectionSchedule,
} from "../../../recoil/GetData";
import { format, parseISO } from "date-fns";
import { ModalSub7, ModalSub8 } from "../../../components/ModalSub";
import ShipInspection from "../../../components/ShipInspection";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function RegistationInspectionScheduleFix() {
  const location = useLocation();
  const InspectionSchedule = { ...location.state };
  const navigate = useNavigate();

  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  const getRegistationInspection = useRecoilValue(
    getRegistationInspectionSchedule(
      `${InspectionSchedule.idInspectionSchedule}`
    )
  );
  console.log(getRegistationInspection);

  //선박 전체
  const [shipAll, setshipAll] = useState(`${getRegistationInspection.ship}`);
  const shipAllList = useRecoilValue(getAllShipList);
  console.log(shipAllList);

  const shipID =
    shipAllList &&
    shipAllList
      .filter((item) => (item.ship === shipAll ? item.ship : ""))
      .map((item) => item.shipId)
      .toString();

  console.log(shipID);

  //검사명 List
  const inspectionScheduleList = useRecoilValue(getInspectionListOption);
  console.log(inspectionScheduleList);

  const [inspectionSchedule, setInspectionSchedule] = useState(
    `${getRegistationInspection.inspectionType}`
  );

  const inspectionTypeId =
    inspectionScheduleList &&
    inspectionScheduleList
      .filter((item) =>
        item.inspectionType === inspectionSchedule ? item.idInspectionType : ""
      )
      .map((item) => item.idInspectionType)
      .toString();

  console.log(inspectionTypeId);

  //특이사항
  const [text, setText] = useState(`${getRegistationInspection.note}`);

  const [inspectionDate, setInspectionDate] = useRecoilState(
    InspectionScheduleAtom
  );

  console.log(inspectionDate);
  const inspectionDate2 = `${inspectionDate.YYYY}-${inspectionDate.MM}-${inspectionDate.DD}`;

  const handleChange = (e, type) => {
    const value = e.target.value;
    type === "shipAll" ? setshipAll(value) : setInspectionSchedule(value);
  };

  const onChange = (e) => {
    setText(e.target.value);
  };

  const data = {
    inspectionScheduleId: getRegistationInspection.inspectionScheduleId,
    inspectionDate: inspectionDate2,
    inspectionTypeId: inspectionTypeId,
    shipId: shipID,
    note: text,
  };
  console.log(data);
  const dataValidation =
    data.inspectionDate && data.inspectionTypeId && data.shipId && data.note;
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);
  React.useEffect(() => {
    setText(getRegistationInspection.note);
    setInspectionDate({
      year: format(parseISO(getRegistationInspection.inspectionDate), "yyyy"),
      month: format(parseISO(getRegistationInspection.inspectionDate), "MM"),
      day: format(parseISO(getRegistationInspection.inspectionDate), "dd"),
    });
  }, [
    getRegistationInspection.inspectionDate,
    getRegistationInspection.note,
    navigate,
    setInspectionDate,
  ]);
  const [disable, setDisable] = React.useState(false);
  const handelSub = (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .patch("/api/schedule/admin/inspection/modify", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          window.location.href = "/manager-inspection-schedule";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  const delsub = () => {
    axios
      .delete(
        `api/schedule/admin/inspection/delete?inspectionScheduleId=${getRegistationInspection.inspectionScheduleId}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === 203) {
          window.location.href = "/manager-inspection-schedule";
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "70px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <h1 style={{ fontSize: "20px" }}>선박 작업 일정</h1>
          <Box sx={{ minWidth: 120, width: "100%" }}>
            <FormControl color="secondary" fullWidth>
              <p
                style={{
                  marginLeft: 5,
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                검사일정 날짜
              </p>
              <DateSelectDetail valueDateType={"InspectionSchedule"} />
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120, width: "100%" }}>
            <FormControl color="secondary" fullWidth>
              <p
                style={{
                  marginLeft: 5,
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                선박 운항선명
              </p>
              <Select
                value={shipAll}
                onChange={(e) => handleChange(e, `shipAll`)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled sx={{ color: `${silverColor}` }} value={""}>
                  선박운항선명을 선택해 주세요.
                </MenuItem>
                {shipAllList &&
                  shipAllList.map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item.ship}>
                        {item.ship}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: "100%" }}>
            <FormControl color="secondary" fullWidth>
              <p
                style={{
                  marginLeft: 5,
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                검사명
              </p>
              <Select
                defaultValue={inspectionSchedule}
                value={inspectionSchedule}
                onChange={(e) => handleChange(e, "inspectionSchedule")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled sx={{ color: `${silverColor}` }} value="">
                  검사명을 선택해 주세요.
                </MenuItem>
                {inspectionScheduleList.map((item) => {
                  return (
                    <MenuItem
                      key={item.idInspectionType}
                      value={item.inspectionType}
                    >
                      {item.inspectionType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: "100%" }}>
            <FormControl color="secondary" fullWidth>
              <p
                style={{
                  marginLeft: 5,
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                특이사항
              </p>

              <textarea
                defaultValue={getRegistationInspection.note}
                onChange={onChange}
                placeholder="내용을 입력해 주세요."
                style={{
                  padding: 10,
                  width: "93.5%",
                  height: "6.5em",
                  borderRadius: 5,
                  resize: "none",
                  fontSize: 17,
                  fontFamily: ["Noto Sans KR", "sans-serif"],
                  outlineColor: `${mainColor}`,
                }}
              />
            </FormControl>
          </Box>
          <ButtonGroup
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: 99,
            }}
          >
            <Button
              disabled={!dataValidation}
              // onClick={handelSub}
              onClick={() => {
                setmodal1(!modal1);
              }}
              color="secondary"
              sx={{
                padding: "10px 0",
                flex: 1,
                borderRadius: "99px  0 0 99px",
                fontFamily: ["Noto Sans KR", "sans-serif"],
              }}
            >
              수정하기
            </Button>
            <Button
              onClick={() => setmodal2(!modal1)}
              color="red"
              sx={{
                padding: "10px 0",
                flex: 1,
                fontFamily: ["Noto Sans KR", "sans-serif"],
                borderRadius: "0  99px 99px 0",
                fontWeight: 500,
              }}
            >
              삭제하기
            </Button>
          </ButtonGroup>
          {!dataValidation ? (
            <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다</p>
          ) : null}
          {modal1 ? (
            <ModalSub7
              handelSub={handelSub}
              setmodal1={setmodal1}
              disable={disable}
            />
          ) : null}
          {modal2 ? <ModalSub8 delsub={delsub} setmodal1={setmodal2} /> : null}
        </div>
        <ShipInspection />
      </Container>
    </div>
  );
}

export default RegistationInspectionScheduleFix;
