import React from "react";
import { Container } from "@mui/material";
import { Row2, Row3, Rowworker2 } from "../../../components/Row";
import { mainColor, whiteColor } from "../../../assets/colors/color";
import { ShipTitle } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";

import { useLocation } from "react-router-dom";
import { getScheduleShip } from "../../../recoil/GetData";
import { useRecoilValue } from "recoil";
import { format, parseISO } from "date-fns";
import { NoteTextListText } from "../../../components/Note";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
//선반 작업일정 운송
function ManagerShipScheduleTransit() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const journeyDetailId = shipInfo.journeyDetailId;

  const shipApi = useRecoilValue(
    getScheduleShip(`floating?journeyDetailId=${journeyDetailId}`)
  );

  const noteList = shipApi.noteList;
  const noteList2 = [...noteList];
  const noteList3 =
    noteList2 &&
    noteList2.sort(
      (a, b) =>
        new Date(`${a.writtenAt.substr(0, 10)}T${a.writtenAt.substr(11)}`) -
        new Date(`${b.writtenAt.substr(0, 10)}T${b.writtenAt.substr(11)}`)
    );
  console.log(shipApi);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          Step={shipApi.status}
          ShipName={shipApi.ship}
          Date={format(
            parseISO(shipApi.shipping.workStartAt),
            "yyyy / MM / dd"
          )}
          color={`${mainColor}`}
        />
        <div
          style={{
            width: "100%",
            borderTop: `2px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.voyage} />
          <Row2 Tilte={"용선주"} Desc={shipApi.charterer} />
          <Row2 Tilte={"화물"} Desc={shipApi.cargo} />
          <Row2 Tilte={"수량"} Desc={shipApi.amount} />
          <Row3
            Tilte={"구분"}
            Desc1={"적하"}
            Desc2={"양하"}
            bgcolor={`${mainColor}`}
            TitleColor={`${whiteColor}`}
            Desxcolor={`${whiteColor}`}
          />
          <Row3
            Tilte={"작업장소"}
            Desc1={shipApi.shipping.berthingPlace}
            Desc2={shipApi.unloading.berthingPlace}
          />
          <Row3
            Tilte={"예인선"}
            Desc1={shipApi.shipping.tugShip}
            Desc2={shipApi.unloading.tugShip}
          />
          <Row3
            Tilte={"시작일시"}
            Desc1={format(
              parseISO(shipApi.shipping.workStartAt),
              "yyyy-MM-dd HH:mm"
            )}
            Desc2={format(
              parseISO(shipApi.unloading.workStartAt),
              "yyyy-MM-dd HH:mm"
            )}
          />
          <Row3
            Tilte={"완료일시"}
            Desc1={format(
              parseISO(shipApi.shipping.workDoneAt),
              "yyyy-MM-dd HH:mm"
            )}
            Desc2={format(
              parseISO(shipApi.unloading.workDoneAt),
              "yyyy-MM-dd HH:mm"
            )}
          />
          <Row3
            Tilte={"안전관리"}
            Desc1={shipApi.shipping.safety}
            Desc2={shipApi.unloading.safety}
          />
          <Row3
            Tilte={"강취"}
            Desc1={shipApi.shipping.lineHandling}
            Desc2={shipApi.unloading.lineHandling}
          />
          <NoteTextListText noteList={noteList3} />
        </div>
        <div style={{ width: "100%", marginTop: 10 }}>
          <h1 style={{ fontSize: 22, textAlign: "center" }}>근무자 정보</h1>
          <div
            style={{
              borderBottom: `2px solid ${mainColor}`,
              marginTop: 10,
            }}
          >
            <Rowworker2
              Desc1="적하"
              Desc2="양하"
              bgcolor={`${mainColor}`}
              color={`${whiteColor}`}
            />
            {shipApi.shippingSailorList.length > 0 ? (
              shipApi.shippingSailorList.map((data) => (
                <Rowworker2
                  Desc1={data.name}
                  Desc2={data.name}
                  key={data.name}
                />
              ))
            ) : (
              <p>선원을 추가하세요</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ManagerShipScheduleTransit;
