import React from "react";
import { Container } from "@mui/material";
import ShipImg1 from "../../../assets/images/ship1.jpg";
import ShipImg2 from "../../../assets/images/ship2.jpg";
import ShipImg3 from "../../../assets/images/ship3.jpg";
import ImageCard from "../../../components/ImageCard";
import ManagerAppBar from "../common/ManagerAppBar";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ManagerMain() {
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageCard
          ImageUrl={ShipImg1}
          Title={"선박 작업 일정"}
          LinkUrl={"/manager-ship-schedule"}
        />
        <ImageCard
          ImageUrl={ShipImg3}
          Title={"선박 작업 일지"}
          LinkUrl={"/manager-ship-log"}
        />

        <ImageCard
          ImageUrl={ShipImg2}
          Title={"선박 운항 일지"}
          LinkUrl={"/manager-ship-flight-log"}
        />
        <ImageCard
          ImageUrl={ShipImg1}
          Title={"선박 운항 계획표"}
          LinkUrl={"/manager-Ship-operation-plan"}
        />
        <ImageCard
          ImageUrl={ShipImg3}
          Title={"선원 관리부"}
          LinkUrl={"/manager-crew-operation-chart"}
        />
        <ImageCard
          ImageUrl={ShipImg2}
          Title={"선박 검사 일정"}
          LinkUrl={"/manager-inspection-schedule"}
        />
      </Container>
    </div>
  );
}

export default ManagerMain;
