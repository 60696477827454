import React from "react";
import { silverColor } from "../assets/colors/color";

function InputLablePw({
  Label,
  Name,
  Placeholder,
  Star,
  value,
  handleInputChange,
  InputMessage,
  type,
}) {
  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <label
        style={{
          marginLeft: 5,
          textAlign: "left",
          width: "100%",
          fontSize: 12,
        }}
      >
        {Label}
        {Star ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <input
          size={10}
          style={{
            width: "100%",
            padding: "15px 5px",
            border: `1px solid ${silverColor}`,
            borderRadius: 3,
            WebkitTextSecurity: "disc",
          }}
          type="number"
          placeholder={Placeholder}
          name={Name}
          value={value}
          onChange={handleInputChange}
        />
      </div>
      <p style={{ fontSize: 12 }}>{InputMessage}</p>
    </div>
  );
}

export default InputLablePw;
