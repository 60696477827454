import React, { useEffect } from "react";
import { Container } from "@mui/material";
import ShipImg1 from "../../../assets/images/ship1.jpg";
import ShipImg2 from "../../../assets/images/ship2.jpg";
import ShipImg3 from "../../../assets/images/ship3.jpg";
import ImageCard from "../../../components/ImageCard";
import UserAppBar from "../common/UserAppBar";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";

function UserMain() {
  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageCard
          ImageUrl={ShipImg1}
          Title={"선박 작업 일정"}
          LinkUrl={"/user-ship-schedule"}
        />
        <ImageCard
          ImageUrl={ShipImg2}
          Title={"선박 작업 일지"}
          LinkUrl={"/user-ship-log"}
        />

        <ImageCard
          ImageUrl={ShipImg3}
          Title={"선박 검사 일정"}
          LinkUrl={"/user-inspection-schedule"}
        />
      </Container>
    </div>
  );
}

export default UserMain;
