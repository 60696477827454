import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { mainColor, whiteColor } from "../../../assets/colors/color";
import axios from "axios";

import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function ManagerTapMenu() {
  const [flightManagementOpen, setFlightManagementOpen] = React.useState(false);
  const [inspectionManagementOpen, setInspectionManagementOpen] =
    React.useState(false);
  const [inspectionManagementOpenLast, setInspectionManagementOpenLast] =
    React.useState(false);
  const navigate = useNavigate();

  //JSESSION ID AUTHENTICATION
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, [navigate]);

  const FlightHandleClick = () => {
    setFlightManagementOpen(!flightManagementOpen);
  };
  const InspectionHandleClick = () => {
    setInspectionManagementOpen(!inspectionManagementOpen);
  };
  const InspectionHandleClickLast = () => {
    setInspectionManagementOpenLast(!inspectionManagementOpenLast);
  };

  const handleLogoutAction = () => {
    console.log("Logout Button");
    axios
      .get(`/api/auth/logout`)
      .then((response) => {
        const resultData = response.data;
        console.log("response: ", resultData.data);
        if (resultData.resultMsg === "LOGOUT SUCCESS") {
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("로그아웃 성공");
          window.location.href = "/";
        }
        localStorage.clear();
        sessionStorage.clear();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 56,
          bottom: 0,
          zIndex: 999,
          backgroundColor: "#fff",
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: `${mainColor}`,
            color: `${whiteColor}`,
            paddingBottom: "2px",
            paddingTop: "1px",
          }}
          component="nav"
        >
          <ListItemButton
            onClick={FlightHandleClick}
            sx={{ bgcolor: `${mainColor}`, color: `${whiteColor}` }}
          >
            <ListItemText primary="운항관리" sx={{ fontWeight: 700 }} />
            {flightManagementOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </ListItemButton>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: `${whiteColor}`, color: `${mainColor}` }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-ship-schedule")
                }
              >
                <ListItemText
                  primary="선박 작업 일정"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => (window.location.href = "/manager-ship-log")}
              >
                <ListItemText
                  primary="선박 작업 일지"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-ship-flight-log")
                }
              >
                <ListItemText
                  primary="선박 운항 일지"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-Ship-operation-plan")
                }
              >
                <ListItemText
                  primary="선박 운항 계획표"
                  sx={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Collapse
            in={flightManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-crew-operation-chart")
                }
              >
                <ListItemText primary="선원 관리부" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={InspectionHandleClick}
            sx={{ bgcolor: `${mainColor}` }}
          >
            <ListItemText primary="검사일정" />
            {inspectionManagementOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </ListItemButton>
          <Collapse
            in={inspectionManagementOpen}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: `${whiteColor}`, color: `${mainColor}` }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-inspection-schedule")
                }
              >
                <ListItemText primary="선박 검사 일정" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={InspectionHandleClickLast}
            sx={{ bgcolor: `${mainColor}` }}
          >
            <ListItemText primary="관리자 권한" />
            {inspectionManagementOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </ListItemButton>

          <Collapse
            in={inspectionManagementOpenLast}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: `${whiteColor}`, color: `${mainColor}` }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-crew-connection")
                }
              >
                <ListItemText primary="선박별 선원연결" />
              </ListItemButton>
            </List>
          </Collapse>

          <Collapse
            in={inspectionManagementOpenLast}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: `${whiteColor}`,
              color: `${mainColor}`,
              borderTop: "1px solid",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() =>
                  (window.location.href = "/manager-crew-information")
                }
              >
                <ListItemText primary="선원정보" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>

        <ListItemButton
          sx={{
            width: "100%",
            bgcolor: `${whiteColor}`,
            color: `${mainColor}`,
            borderTop: "1px solid",
            position: "absolute",
            bottom: "90px",
            padding: "4px",
          }}
          onClick={() => (window.location.href = "/manager-AlarmHD")}
        >
          <NotificationsIcon color="inherit" sx={{ marginRight: "10px" }} />
          <ListItemText primary="푸쉬 알림 설정" />
        </ListItemButton>

        <ListItemButton
          sx={{
            width: "100%",
            bgcolor: `${whiteColor}`,
            color: `${mainColor}`,
            borderTop: "1px solid",
            position: "absolute",
            bottom: "52px",
            padding: "4px",
          }}
          onClick={handleLogoutAction}
        >
          <PersonIcon color="inherit" sx={{ marginRight: "10px" }} />
          <ListItemText primary="로그아웃" />
        </ListItemButton>
      </div>
    </>
  );
}

export default ManagerTapMenu;
