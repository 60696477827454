import React from "react";
import { Button, Container } from "@mui/material";
import { Row2 } from "../../../components/Row";
import { mainColor, subColor2 } from "../../../assets/colors/color";
import { ShipTitle } from "../../../components/ShipTitle";
import ManagerAppBar from "../common/ManagerAppBar";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import {
  getAllShipList,
  getShipData,
  getShipFlightLog,
} from "../../../recoil/GetData";
import { format, parseISO } from "date-fns";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";
function ShipFlightLogPatrol() {
  const location = useLocation();
  const shipInfo = { ...location.state };
  const journeyId = shipInfo.idJourney;
  const navigate = useNavigate();

  const shipApi = useRecoilValue(getShipData(`patrol?journeyId=${journeyId}`));
  console.log(shipApi);

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipInfo.ship ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  console.log(shipId);

  const shipApiNext = useRecoilValue(
    getShipFlightLog(
      `shipId=${shipId}&jobType=${shipInfo.jobType}&shippingWorkStartAt=${shipInfo.shippingWorkStartAt}`
    )
  );

  console.log(shipApiNext);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const state = {
    ship: shipInfo.ship,
    shipId: shipId,
    shipType: shipInfo.shipType,
    jobType: shipInfo.jobType,
    shippingWorkStartAt: shipInfo.shippingWorkStartAt,
  };
  const navigateToPurchase = () => {
    if (shipApiNext === null) {
      navigate("/manager-ship-flight-log-patrolNextX");
    } else if (shipApiNext.shipType === "운송") {
      navigate("/manager-ship-flight-log-patrolNextTug", {
        state: state,
      });
    } else {
      navigate("/manager-ship-flight-log-patrolNext", {
        state: state,
      });
    }
  };

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShipTitle
          ShipName={shipApi.ship}
          Date={format(parseISO(shipApi.workStartAt), "yyyy / M / dd")}
          color={`${subColor2}`}
        />
        <div
          style={{
            width: "100%",
            borderTop: `3px solid ${mainColor}`,
            borderBottom: `2px solid ${mainColor}`,
            marginTop: 10,
          }}
        >
          <Row2 Tilte={"항차"} Desc={shipApi.voyageNumber} />
          <Row2 Tilte={"장소"} Desc={shipApi.patrolPlace} />
          <Row2
            Tilte={"시작일시"}
            Desc={format(parseISO(shipApi.workStartAt), "yyyy - M - dd hh:mm")}
          />
          <Row2
            Tilte={"종료일시"}
            Desc={format(parseISO(shipApi.workDoneAt), "yyyy - M - dd hh:mm")}
          />
        </div>

        <Button
          onClick={navigateToPurchase}
          sx={{
            fontFamily: ["Noto Sans KR", "sans-serif"],
            fontSize: "17px",
            fontWeight: "500",
            maxWidth: "1200px",
            width: "100%",
            bottom: 0,
            borderRadius: 0,
            height: 50,
            left: "50%",
            transform: "translateX( -50% )",
            position: "fixed",
            borderTop: "2px solid #fff",
          }}
          variant="contained"
          color="secondary"
        >
          차항정보
        </Button>
      </Container>
    </div>
  );
}

export default ShipFlightLogPatrol;
