import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

export default function JoinAppBar() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="logo"
                component="div"
                style={{ height: "40px", width: "164px", textAlign: "center" }}
              />
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: 700,
                }}
              >
                로그인
              </Link>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
