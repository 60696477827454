import React from "react";
import { Container } from "@mui/material";
import ManagerAppBar from "../common/ManagerAppBar";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function ShipFlightLogPatrolNextX() {
  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "76px",
          marginBottom: "56px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: 100, fontSize: 20, fontWeight: 700 }}>
          등록된 차항정보가 없습니다.
        </div>
      </Container>
    </div>
  );
}

export default ShipFlightLogPatrolNextX;
