import axios from "axios";

export const manageSubscriptionAll = async () => {
  try {
    const response = await axios.post(`/api/firebase/subscribe/all`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:15007",
      },
    });
    console.log(response);
  } catch (error) {
    console.error("Subscription error:", error);
    alert("오류가 발생했습니다. 다시 시도해 주세요.");
  }
};
