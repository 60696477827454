//선원 관리부
import React from "react";
import { useState } from "react";
import ManagerAppBar from "../common/ManagerAppBar";
import { Button, Container } from "@mui/material";
import { addMonths, format, subMonths } from "date-fns";
import { mainColor } from "../../../assets/colors/color";
import { useRecoilValue } from "recoil";
import { getCrewOperationChart } from "../../../recoil/GetData";
import ListBoxCrew from "../../../components/ListBoxCrew";
import { CDateCrew } from "../../../components/CDateCrew";
import axios from "axios";
import { loginAuthenticationAdmin } from "../../../hooks/loginAuthentication";

function CrewOperationChart() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const selectDay = format(selectedDate, "yyyy-M-dd");
  const year = format(currentMonth, "yyyy");
  const month = format(currentMonth, "MM");

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);
  const shipScheduleData = useRecoilValue(
    getCrewOperationChart(`${year}-${month}-26`)
  );

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  //엑셀파일 다운로드
  const handelXlsx = () => {
    axios({
      method: "GET",
      url: `/api/excel/generate/sailor/management?year=${year}&month=${month}`,
      responseType: "blob",
      // 구글링 해보니 responseType을 꼭 추가하라고 했다.
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${year}년_${month}월_선원관리부.xls`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert("선원이 들어있지 않습니다!");
      });
  };
  return (
    <div>
      <ManagerAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          marginBottom: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {shipScheduleData && shipScheduleData.length > 0 ? (
          <Button
            onClick={handelXlsx}
            variant="contained"
            color="secondary"
            sx={{
              fontSize: 15,
              width: "100%",
              padding: "6px",
              flex: 1,
              borderRadius: 0,
              fontFamily: ["Noto Sans KR", "sans-serif"],
            }}
          >
            선원 관리부 엑셀 다운로드
          </Button>
        ) : null}

        <h1 style={{ fontSize: "25px" }}>선원 관리부</h1>

        <CDateCrew
          edata={shipScheduleData && shipScheduleData}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          currentMonth={currentMonth}
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: `${mainColor}`,
            padding: "8px 0",
          }}
        >
          선택된 날짜 : {selectDay}{" "}
        </p>
        <ListBoxCrew
          edata={shipScheduleData && shipScheduleData}
          selectedDate={selectedDate}
        />
      </Container>
    </div>
  );
}

export default CrewOperationChart;
