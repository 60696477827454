import React from "react";
import { Button, ButtonGroup, Container } from "@mui/material";
import { ShipTitleLog } from "../../../../components/ShipTitle";
import { subColor2 } from "../../../../assets/colors/color";
import ManagerShipLogPatrolJournal from "./ManagerShipLogPatrolJournal";
import ManagerAppBar from "../../common/ManagerAppBar";

import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import ButtonGroupStep from "../../../../components/ButtonGroupStep";
import {
  PatrolEndDateAtom,
  PatrolEndTimeAtom,
  PatrolStartDateAtom,
  PatrolStartTimeAtom,
} from "../../../../recoil/DateAndTimeAtom";
import axios from "axios";
import { parseISO } from "date-fns";
import { useEffect } from "react";
import {
  DateTextValidationAtom,
  DateValidationAtom,
} from "../../../../recoil/TimeOptionsValidation";
import { ModalSub1, ModalSub2 } from "../../../../components/ModalSub";
import {
  getAllShipList,
  getAllShipType,
  getPatrolPlaceList,
} from "../../../../recoil/GetData";
import { loginAuthenticationAdmin } from "../../../../hooks/loginAuthentication";
import { PSignificantAtom, statusAtom } from "../../../../recoil/OptionsAtom";
function ManagerShipLogPatrol() {
  const [step, setStep] = useRecoilState(statusAtom);

  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);

  React.useEffect(() => {
    loginAuthenticationAdmin();
  }, []);

  const location = useLocation();

  const shipNameType = { ...location.state };

  const shipTypeApi = useRecoilValue(getAllShipType);
  const shipType = shipNameType.shipType;

  const shipTypeId =
    shipTypeApi &&
    shipTypeApi
      .filter((item) => (item.type === shipType ? item.shipTypeId : ""))
      .map((item) => item.shipTypeId)
      .toString();

  // 선박  id

  const shipAllApi = useRecoilValue(getAllShipList);
  const shipName = shipNameType.shipName;

  //특이사항
  const PSignificant = useRecoilValue(PSignificantAtom);

  const shipId =
    shipAllApi &&
    shipAllApi
      .filter((item) => (item.ship === shipName ? item.shipId : ""))
      .map((item) => item.shipId)
      .toString();

  //순찰시작일시
  const shippingWorkStartDate = useRecoilValue(PatrolStartDateAtom);
  const shippingWorkStartTime = useRecoilValue(PatrolStartTimeAtom);
  console.log(shippingWorkStartTime);

  const patrolWorkStartAt = `${shippingWorkStartDate.year}-${shippingWorkStartDate.month}-${shippingWorkStartDate.day}T${shippingWorkStartTime.hh}:${shippingWorkStartTime.mm}:00`;
  //순찰 완료 일시
  const shippingWorkDoneDate = useRecoilValue(PatrolEndDateAtom);
  const shippingWorkDoneTime = useRecoilValue(PatrolEndTimeAtom);

  const patrolWorkDoneAt = `${shippingWorkDoneDate.year}-${shippingWorkDoneDate.month}-${shippingWorkDoneDate.day}T${shippingWorkDoneTime.hh}:${shippingWorkDoneTime.mm}:00`;

  //작업장소 id값받아오기
  const workplaceApi = useRecoilValue(getPatrolPlaceList);

  const firstPatrolPlaceId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 1 ? item.idPatrolPlace : ""))
      .map((item) => item.idPatrolPlace)
      .toString();

  const secondPatrolPlaceId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 2 ? item.idPatrolPlace : ""))
      .map((item) => item.idPatrolPlace)
      .toString();

  const thirdPatrolPlaceId =
    workplaceApi &&
    workplaceApi
      .filter((item) => (item.sequence === 3 ? item.idPatrolPlace : ""))
      .map((item) => item.idPatrolPlace)
      .toString();

  const before =
    parseISO(patrolWorkStartAt).getTime() <=
    parseISO(patrolWorkDoneAt).getTime();

  console.log(before);

  const setDateValidationAtom = useSetRecoilState(DateValidationAtom);
  const setDateTextValidationAtom = useSetRecoilState(DateTextValidationAtom);
  useEffect(() => {
    if (before) {
      setDateTextValidationAtom("");
    } else {
      setDateTextValidationAtom("완료일시가 시작일시보다 빠릅니다.");
    }
  }, [before, setDateTextValidationAtom, setDateValidationAtom]);

  const data = {
    shipId: shipId,
    shipTypeId: shipTypeId,
    status: step,
    jobType: "순찰",
    patrolWorkStartAt: patrolWorkStartAt,
    patrolWorkDoneAt: patrolWorkDoneAt,
    firstPatrolPlaceId: firstPatrolPlaceId,
    secondPatrolPlaceId: secondPatrolPlaceId,
    thirdPatrolPlaceId: thirdPatrolPlaceId,
    remark: PSignificant,
  };

  console.log("순찰 등록", data);

  const dataValidation =
    data.shipId && //선박 아이디
    data.shipTypeId && //선박 타입 아이디
    data.status && //상태
    data.jobType && //작업구분 고정갑
    data.patrolWorkStartAt && //시작일시
    data.patrolWorkDoneAt && //완료일시
    before &&
    data.firstPatrolPlaceId && //작업장소
    data.secondPatrolPlaceId && //작업장소
    data.thirdPatrolPlaceId;

  console.log(data);

  const [disable, setDisable] = React.useState(false);
  const handelSub = async (event) => {
    event.preventDefault();
    setDisable(true);
    axios
      .post("/api/admin/journey/patrol/add", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:15007",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          window.location.href = "/manager-ship-log";
        } else if (response.data.status === 404) {
          setDisable(false);
          alert("입력된 정보가 유효하지 않습니다. 한번 다시 입력해 주세요.");
        } else if (response.data.status === 401) {
          setDisable(false);
          localStorage.clear();
          sessionStorage.clear();
          document.cookie =
            "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          alert("인증되지 않은 사용자입니다. 다시 로그인해 주세요.");
          window.location.href = "/";
          console.log(response);
        }
      })
      .catch((res) => {
        setDisable(false);
        alert("서버와 통신하는 도중 문제가 발생했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div>
      <div>
        <ManagerAppBar />
        <Container
          maxWidth="sm"
          sx={{
            marginTop: "76px",
            marginBottom: "20px",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroupStep step={step} setStep={setStep} />
          <ShipTitleLog
            ShipName={shipNameType.shipName}
            color={`${subColor2}`}
          />
          <ManagerShipLogPatrolJournal />

          <ButtonGroup
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              fontFamily: ["Noto Sans KR", "sans-serif"],
              borderRadius: 99,
            }}
          >
            <Button
              onClick={() => {
                setmodal1(!modal1);
              }}
              // onClick={handelSub}
              disabled={!dataValidation}
              color="secondary"
              sx={{
                padding: "10px 0",
                flex: 1,
                borderRadius: "99px  0 0 99px",
                fontFamily: ["Noto Sans KR", "sans-serif"],
              }}
            >
              등록하기
            </Button>
            <Button
              onClick={() => setmodal2(!modal1)}
              color="red"
              sx={{
                padding: "10px 0",
                flex: 1,
                fontFamily: ["Noto Sans KR", "sans-serif"],
                borderRadius: "0  99px 99px 0",
                fontWeight: 500,
              }}
            >
              취소하기
            </Button>
          </ButtonGroup>
          {!dataValidation ? (
            <p style={{ color: "red" }}>작성되지 않은 값들이 있습니다.</p>
          ) : null}
          {modal1 ? (
            <ModalSub1
              handelSub={handelSub}
              setmodal1={setmodal1}
              disable={disable}
            />
          ) : null}
          {modal2 ? <ModalSub2 setmodal1={setmodal2} /> : null}
        </Container>
      </div>
    </div>
  );
}

export default ManagerShipLogPatrol;
