import React from "react";

import { format, isSameDay, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  disabledColor,
  subColor2,
  whiteColor,
} from "../../../assets/colors/color";

function ListItem({ data }) {
  console.log(data.shippingWorkStartAt);

  const startDate = parseISO(data.shippingWorkStartAt);

  const time = format(startDate, "HH:mm");

  const state = {
    ship: data.ship, //배이름
    shipType: data.shipType, //배 타입
    jobType: data.jobType,
    status: data.status, //배 단계
    voyageNumber: data.voyageNumber, //항차
    idJourney: data.idJourney,
    journeyDetailId: data.journeyDetailId,
    shippingWorkStartAt: data.shippingWorkStartAt,
  };

  const navigate = useNavigate();

  const navigateToPurchase = () => {
    data.shipType === "순찰"
      ? navigate("/manager-ship-flight-log-patrol", {
          state: state,
        })
      : navigate(null);
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          fontWeight: 700,
          paddingTop: 10,
          paddingRight: 10,
          borderRight: `2px solid ${disabledColor}`,
        }}
      >
        {time}
      </div>
      <div
        onClick={navigateToPurchase}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
          width: "100%",
          padding: "15px 20px",
          backgroundColor: `${subColor2}`,
          marginLeft: "10px",
          borderRadius: "10px",
          color: `${whiteColor}`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: 500, fontSize: 17 }}>{data.ship}</h1>
          <span
            style={{
              borderRadius: 999,
              border: `2px solid`,
              padding: 5,
              fontWeight: 700,
              fontSize: 15,
            }}
          >
            {data.status}
          </span>
        </div>
        <p style={{ marginTop: 6 }}>
          <span>항차 : </span>
          <span>{data.voyageNumber}</span>
        </p>
        <p>
          <span>구분 : </span>
          <span>{data.shipType}</span>
        </p>
      </div>
    </div>
  );
}

function TabListBoxP({ edata, selectedDate }) {
  console.log(edata);
  const SelectSameDate =
    edata &&
    edata.filter((data) =>
      isSameDay(parseISO(data.shippingWorkStartAt), selectedDate)
    );

  console.log(selectedDate);
  console.log(SelectSameDate);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        fontSize: "15px",
        fontWeight: 400,
      }}
    >
      {SelectSameDate && SelectSameDate.length > 0 ? (
        SelectSameDate.map((data) =>
          data.shipType === "순찰" ? (
            <ListItem data={data} key={data.idJourney} />
          ) : null
        )
      ) : (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 24,
          }}
        >
          순찰 일정이 없습니다.
        </h1>
      )}
    </div>
  );
}

export default TabListBoxP;
