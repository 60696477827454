import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import { mainColor } from "../assets/colors/color";

function ButtonGroupStep({ step, setStep }) {
  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      color="primary"
      sx={{
        width: "100%",
        border: `1px solid ${mainColor}`,
        fontFamily: ["Noto Sans KR", "sans-serif"],
        borderRadius: 99,
      }}
    >
      <Button
        color={step === "계획" ? "secondary" : "primary"}
        onClick={() => setStep("계획")}
        sx={{
          flex: 1,
          borderRadius: "99px  0 0 99px",
          fontFamily: ["Noto Sans KR", "sans-serif"],
        }}
      >
        계획
      </Button>
      <Button
        color={step === "확정" ? "secondary" : "primary"}
        onClick={() => setStep("확정")}
        sx={{ flex: 1, fontFamily: ["Noto Sans KR", "sans-serif"] }}
      >
        확정
      </Button>
      <Button
        color={step === "완료" ? "secondary" : "primary"}
        onClick={() => setStep("완료")}
        sx={{
          flex: 1,
          borderRadius: "0 99px 99px 0",
          fontFamily: ["Noto Sans KR", "sans-serif"],
        }}
      >
        완료
      </Button>
    </ButtonGroup>
  );
}

export default ButtonGroupStep;
