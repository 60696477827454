import React from "react";
import { Row2, RowInput, RowSelectTime } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import {
  STSSafetyAtom,
  STSSignificantAtom,
  noteTAtom,
} from "../../../../recoil/OptionsAtom";
import { useRecoilState } from "recoil";
import { NoteText, NoteTextList } from "../../../../components/Note";
import { format, parseISO } from "date-fns";

function UserShipLogSteamshipDetail({ shipApi2 }) {
  const status = shipApi2.status;
  const noteList2 = [...shipApi2.noteList];
  const noteList3 =
    noteList2 &&
    noteList2.sort(
      (a, b) =>
        new Date(`${a.writtenAt.substr(0, 10)}T${a.writtenAt.substr(11)}`) -
        new Date(`${b.writtenAt.substr(0, 10)}T${b.writtenAt.substr(11)}`)
    );
  console.log(noteList3);

  //비고
  const [note, setNote] = useRecoilState(noteTAtom);

  //안전관리
  const [Safety, setSafety] = useRecoilState(STSSafetyAtom);

  //특이사항
  const [STSSignificant, setSTSSignificant] =
    useRecoilState(STSSignificantAtom);

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2
          Tilte={"시작일시"}
          TitleColor={`${silverColor}`}
          Desc={format(
            parseISO(shipApi2.shippingWorkStartAt),
            "yyyy-MM-dd HH:mm"
          )}
        />
        <Row2
          Tilte={"완료일시"}
          TitleColor={`${silverColor}`}
          Desc={format(
            parseISO(shipApi2.shippingWorkDoneAt),
            "yyyy-MM-dd HH:mm"
          )}
        />
        <Row2
          Tilte={"접안장소"}
          TitleColor={`${silverColor}`}
          Desc={shipApi2.berthingAt}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          TitleColor={`${mainColor}`}
          Tilte={"접안시간"}
          valueDateType={"STSBerthingDate"}
          valueTimeType1={"STSBerthingTime1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          TitleColor={`${mainColor}`}
          Tilte={"오일펜스\n설치"}
          valueDateType={"STSOilInstallationDate"}
          valueTimeType1={"STSOilInstallation1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          TitleColor={`${mainColor}`}
          Tilte={"오일펜스\n회수"}
          valueDateType={"STSRecoveryDate"}
          valueTimeType1={"STSRecoveryTime1"}
        />
        <RowSelectTime
          disabled={status === "계획" || status === "완료" ? true : false}
          TitleColor={`${mainColor}`}
          Tilte={"이안시간"}
          valueDateType={"STSIanDate"}
          valueTimeType1={"STSIanTime1"}
        />
        <RowInput
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"안전관리"}
          placeholder={"안전관리를 입력해 주세요"}
          onchangeText={(e) => setSafety(e.target.value)}
          value={Safety}
        />
        <RowInput
          disabled={status === "계획" || status === "완료" ? true : false}
          Tilte={"특이사항"}
          placeholder={"특이사항을 입력해 주세요"}
          onchangeText={(e) => setSTSSignificant(e.target.value)}
          value={STSSignificant}
        />
      </div>
      {noteList3.length > 0 ? (
        <NoteTextList
          disabled={status === "계획" || status === "완료" ? true : false}
          noteList={noteList3}
          note={note}
          onChange={(e) => setNote(e.target.value)}
        />
      ) : (
        <NoteText
          note={note}
          onChange={(e) => setNote(e.target.value)}
          disabled={status === "계획" || status === "완료" ? true : false}
        />
      )}
    </div>
  );
}

export default UserShipLogSteamshipDetail;
