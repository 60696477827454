import React, { useState } from "react";
import Picker from "react-mobile-picker";
import { mainColor, subColor1, whiteColor } from "../assets/colors/color";
import { useRecoilState, useResetRecoilState } from "recoil";

import styles from "./TimeSelectDTSE.module.css";
import {
  STSBerthingTimeAtom1,
  STSIanTimeAtom1,
  STSOilInstallationAtom1,
  STSRecoveryTimeAtom1,
  TDBerthingTimeAtom1,
  TDHoseConnectionAtom1,
  TDHoseSeparationAtom1,
  TDIanTimeAtom1,
  TDStartWorkAtom1,
  TDWorkCompleteAtom1,
  TUBerthingTimeAtom1,
  TUHoseConnectionAtom1,
  TUHoseSeparationAtom1,
  TUIanTimeAtom1,
  TUStartWorkAtom1,
  TUWorkCompleteAtom1,
} from "../recoil/TimeAtom";

const TimeSelections = {
  hh: [
    "--",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  mm: [
    "--",
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ],
};

function TimeSelectDTSE({ valueTimeType, disabled }) {
  const [timeShow, setTimeShow] = useState(false);

  //운송 일지 적하 접안시간
  const [tdBerthingTime1, setTDBerthingTime1] =
    useRecoilState(TDBerthingTimeAtom1);
  const resettdBerthingTime1 = useResetRecoilState(TDBerthingTimeAtom1);

  //운송 일지 적하 호스연결
  const [tdHoseConnection1, setTDHoseConnection1] = useRecoilState(
    TDHoseConnectionAtom1
  );
  const resettdHoseConnection1 = useResetRecoilState(TDHoseConnectionAtom1);

  //운송 일지 적하 작업시작
  const [tdStartWork1, setTDStartWork1] = useRecoilState(TDStartWorkAtom1);
  const resettdStartWork1 = useResetRecoilState(TDStartWorkAtom1);

  //운송 일지 적하 작업완료
  const [tdWorkComplete1, setTDWorkComplete1] =
    useRecoilState(TDWorkCompleteAtom1);
  const resettdWorkComplete1 = useResetRecoilState(TDWorkCompleteAtom1);
  //운송 일지 적하 호스분리
  const [tdHoseSeparation1, setTDHoseSeparation1] = useRecoilState(
    TDHoseSeparationAtom1
  );
  const resettdHoseSeparation1 = useResetRecoilState(TDHoseSeparationAtom1);
  //운송 일지 적하 이안시간
  const [tdIanTime1, setTDIanTime1] = useRecoilState(TDIanTimeAtom1);
  const resettdIanTime1 = useResetRecoilState(TDIanTimeAtom1);
  //운송 일지 양하 접안시간
  const [tuBerthingTime1, setTUBerthingTime1] =
    useRecoilState(TUBerthingTimeAtom1);
  const resettuBerthingTime1 = useResetRecoilState(TUBerthingTimeAtom1);
  //운송 일지 양하 호스연결
  const [tuHoseConnection1, setTUHoseConnection1] = useRecoilState(
    TUHoseConnectionAtom1
  );
  const resettuHoseConnection1 = useResetRecoilState(TUHoseConnectionAtom1);
  //운송 일지 양하 작업시작
  const [tuStartWork1, setTUStartWork1] = useRecoilState(TUStartWorkAtom1);
  const resettuStartWork1 = useResetRecoilState(TUStartWorkAtom1);
  //운송 일지 양하 작업완료
  const [tuWorkComplete1, setTUWorkComplete1] =
    useRecoilState(TUWorkCompleteAtom1);
  const resettuWorkComplete1 = useResetRecoilState(TUWorkCompleteAtom1);
  //운송 일지 양하 호스분리
  const [tuHoseSeparation1, setTUHoseSeparation1] = useRecoilState(
    TUHoseSeparationAtom1
  );
  const resettuHoseSeparation1 = useResetRecoilState(TUHoseSeparationAtom1);
  //운송 일지 양하 이안시간
  const [tuIanTime1, setTUIanTime1] = useRecoilState(TUIanTimeAtom1);
  const resettuIanTime1 = useResetRecoilState(TUIanTimeAtom1);
  //기선 접안시간
  const [stsBerthingTime1, setSTSBerthingTime1] =
    useRecoilState(STSBerthingTimeAtom1);
  const resetstsBerthingTime1 = useResetRecoilState(STSBerthingTimeAtom1);
  //기선 오일 설치
  const [stsOilInstallation1, setSTSOilInstallation1] = useRecoilState(
    STSOilInstallationAtom1
  );
  const resetstsOilInstallation1 = useResetRecoilState(STSOilInstallationAtom1);
  //기선 오일 회수
  const [stsRecoveryTime1, setSTSRecoveryTime1] =
    useRecoilState(STSRecoveryTimeAtom1);
  const resetstsRecoveryTime1 = useResetRecoilState(STSRecoveryTimeAtom1);
  //기선 이안시간
  const [stsIanTime1, setSTSIanTime1] = useRecoilState(STSIanTimeAtom1);
  const resetstsIanTime1 = useResetRecoilState(STSIanTimeAtom1);

  const resetClick = () => {
    if (valueTimeType === "TDBerthingTime1") {
      setTimeShow(!timeShow);
      resettdBerthingTime1();
    }
    if (valueTimeType === "TDHoseConnection1") {
      resettdHoseConnection1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TDWorkComplete1") {
      resettdStartWork1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TDHoseSeparation1") {
      resettdWorkComplete1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TDIanTime1") {
      resettdHoseSeparation1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUBerthingTime1") {
      resettdIanTime1();
      setTimeShow(!timeShow);
    }

    if (valueTimeType === "TUBerthingTime1") {
      resettuBerthingTime1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUHoseConnection1") {
      resettuHoseConnection1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUStartWork1") {
      resettuStartWork1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUWorkComplete1") {
      resettuWorkComplete1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUHoseSeparation1") {
      resettuHoseSeparation1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "TUIanTime1") {
      resettuIanTime1();
      setTimeShow(!timeShow);
    }

    if (valueTimeType === "STSBerthingTime1") {
      resetstsBerthingTime1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "STSOilInstallation1") {
      resetstsOilInstallation1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "STSRecoveryTime1") {
      resetstsRecoveryTime1();
      setTimeShow(!timeShow);
    }
    if (valueTimeType === "STSIanTime1") {
      resetstsIanTime1();
      setTimeShow(!timeShow);
    }
  };
  return (
    <>
      <button
        className={timeShow === false ? styles.btn : styles.click}
        disabled={disabled}
        onClick={() => setTimeShow(!timeShow)}
        style={{
          borderRadius: 5,
          cursor: "pointer",
          padding: "16px 8px",
          border: `1px solid ${mainColor} `,
          background: `${whiteColor}`,
          fontSize: 16,
          fontFamily: ["Noto Sans KR", "sans-serif"],
          lineHeight: "16px",
        }}
      >
        {valueTimeType === "TDBerthingTime1" //운송 일지 적하 접안시간1
          ? `${tdBerthingTime1.hh}:${tdBerthingTime1.mm}`
          : valueTimeType === "TDHoseConnection1" //운송 일지 적하 호스연결1
          ? `${tdHoseConnection1.hh}:${tdHoseConnection1.mm}`
          : valueTimeType === "TDStartWork1" //운송 일지 적하 작업시작 1
          ? `${tdStartWork1.hh}:${tdStartWork1.mm}`
          : valueTimeType === "TDWorkComplete1" //운송 일지 적하 작업완료1
          ? `${tdWorkComplete1.hh}:${tdWorkComplete1.mm}`
          : valueTimeType === "TDHoseSeparation1" //운송 일지 적하 호스분리1
          ? `${tdHoseSeparation1.hh}:${tdHoseSeparation1.mm}`
          : valueTimeType === "TDIanTime1" //운송 일지 적하 이안시간1
          ? `${tdIanTime1.hh}:${tdIanTime1.mm}`
          : valueTimeType === "TUBerthingTime1" //운송 일지 양하 접안시간1
          ? `${tuBerthingTime1.hh}:${tuBerthingTime1.mm}`
          : valueTimeType === "TUHoseConnection1" //운송 일지 양하 호스연결1
          ? `${tuHoseConnection1.hh}:${tuHoseConnection1.mm}`
          : valueTimeType === "TUStartWork1" //운송 일지 양하 작업시작 1
          ? `${tuStartWork1.hh}:${tuStartWork1.mm} `
          : valueTimeType === "TUWorkComplete1" //운송 일지 양하 작업완료1
          ? `${tuWorkComplete1.hh}:${tuWorkComplete1.mm}`
          : valueTimeType === "TUHoseSeparation1" //운송 일지 양하 호스분리1
          ? `${tuHoseSeparation1.hh}:${tuHoseSeparation1.mm} `
          : valueTimeType === "TUIanTime1" //운송 일지 양하 이안시간1
          ? `${tuIanTime1.hh}:${tuIanTime1.mm} `
          : valueTimeType === "STSBerthingTime1" // STS접안시간 1
          ? `${stsBerthingTime1.hh}:${stsBerthingTime1.mm}`
          : valueTimeType === "STSOilInstallation1" // STS오일설치1
          ? `${stsOilInstallation1.hh}:${stsOilInstallation1.mm}`
          : valueTimeType === "STSRecoveryTime1" // STS오일회수1
          ? `${stsRecoveryTime1.hh}:${stsRecoveryTime1.mm}`
          : valueTimeType === "STSIanTime1" // STS이안시간 1
          ? `${stsIanTime1.hh}:${stsIanTime1.mm}`
          : null}
      </button>

      {timeShow ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            bottom: 0,
            position: "fixed",
            right: 0,
            zIndex: 10,
            background: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bottom: 0,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setTimeShow(!timeShow)}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${mainColor}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                확인
              </div>
              <div
                onClick={resetClick}
                style={{
                  width: "100%",
                  zIndex: 20,
                  backgroundColor: `${subColor1}`,
                  padding: 16,
                  textAlign: "center",
                  fontWeight: 500,
                  color: `${whiteColor}`,
                }}
              >
                닫기
              </div>
            </div>
            <div
              style={{
                width: "100%",
                padding: 16,
                zIndex: 20,
                backgroundColor: `${whiteColor}`,
              }}
            >
              <Picker
                wheelMode="normal"
                value={
                  valueTimeType === "TDBerthingTime1" //운송 일지 적하 접안시간1
                    ? tdBerthingTime1
                    : valueTimeType === "TDHoseConnection1" //운송 일지 적하 호스연결1
                    ? tdHoseConnection1
                    : valueTimeType === "TDStartWork1" //운송 일지 적하 작업시작 1
                    ? tdStartWork1
                    : valueTimeType === "TDWorkComplete1" //운송 일지 적하 작업완료1
                    ? tdWorkComplete1
                    : valueTimeType === "TDHoseSeparation1" //운송 일지 적하 호스분리1
                    ? tdHoseSeparation1
                    : valueTimeType === "TDIanTime1" //운송 일지 적하 이안시간1
                    ? tdIanTime1
                    : valueTimeType === "TUBerthingTime1" //운송 일지 양하 접안시간1
                    ? tuBerthingTime1
                    : valueTimeType === "TUHoseConnection1" //운송 일지 양하 호스연결1
                    ? tuHoseConnection1
                    : valueTimeType === "TUStartWork1" //운송 일지 양하 작업시작 1
                    ? tuStartWork1
                    : valueTimeType === "TUWorkComplete1" //운송 일지 양하 작업완료1
                    ? tuWorkComplete1
                    : valueTimeType === "TUHoseSeparation1" //운송 일지 양하 호스분리1
                    ? tuHoseSeparation1
                    : valueTimeType === "TUIanTime1" //운송 일지 양하 이안시간1
                    ? tuIanTime1
                    : valueTimeType === "STSBerthingTime1" // STS접안시간 1
                    ? stsBerthingTime1
                    : valueTimeType === "STSOilInstallation1" // STS오일설치1
                    ? stsOilInstallation1
                    : valueTimeType === "STSRecoveryTime1" // STS오일회수1
                    ? stsRecoveryTime1
                    : valueTimeType === "STSIanTime1" // STS이안시간 1
                    ? stsIanTime1
                    : null
                }
                onChange={
                  valueTimeType === "TDBerthingTime1" //운송 일지 적하 접안시간1
                    ? setTDBerthingTime1
                    : valueTimeType === "TDHoseConnection1" //운송 일지 적하 호스연결1
                    ? setTDHoseConnection1
                    : valueTimeType === "TDStartWork1" //운송 일지 적하 작업시작 1
                    ? setTDStartWork1
                    : valueTimeType === "TDWorkComplete1" //운송 일지 적하 작업완료1
                    ? setTDWorkComplete1
                    : valueTimeType === "TDHoseSeparation1" //운송 일지 적하 호스분리1
                    ? setTDHoseSeparation1
                    : valueTimeType === "TDIanTime1" //운송 일지 적하 이안시간1
                    ? setTDIanTime1
                    : valueTimeType === "TUBerthingTime1" //운송 일지 양하 접안시간1
                    ? setTUBerthingTime1
                    : valueTimeType === "TUHoseConnection1" //운송 일지 양하 호스연결1
                    ? setTUHoseConnection1
                    : valueTimeType === "TUStartWork1" //운송 일지 양하 작업시작 1
                    ? setTUStartWork1
                    : valueTimeType === "TUWorkComplete1" //운송 일지 양하 작업완료1
                    ? setTUWorkComplete1
                    : valueTimeType === "TUHoseSeparation1" //운송 일지 양하 호스분리1
                    ? setTUHoseSeparation1
                    : valueTimeType === "TUIanTime1" //운송 일지 양하 이안시간1
                    ? setTUIanTime1
                    : valueTimeType === "STSBerthingTime1" // STS접안시간 1
                    ? setSTSBerthingTime1
                    : valueTimeType === "STSOilInstallation1" // STS오일설치1
                    ? setSTSOilInstallation1
                    : valueTimeType === "STSRecoveryTime1" // STS오일회수1
                    ? setSTSRecoveryTime1
                    : valueTimeType === "STSIanTime1" // STS이안시간 1
                    ? setSTSIanTime1
                    : null
                }
                wheel="normal"
              >
                {Object.keys(TimeSelections).map((name) => (
                  <Picker.Column key={name} name={name}>
                    {TimeSelections[name].map((option) => (
                      <Picker.Item
                        key={option}
                        value={option}
                        style={{ fontSize: 20 }}
                      >
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ))}
              </Picker>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TimeSelectDTSE;
