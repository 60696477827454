import React from "react";
import { Row2 } from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { format } from "date-fns";

function UserShipLogSteamshipJournal({ shipApi }) {
  const userJSON = localStorage.getItem("userData");

  const userInfo = JSON.parse(userJSON);
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <Row2
          Tilte={"항차"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.voyageNumber}
        />
        <Row2
          Tilte={"작성자"}
          TitleColor={`${mainColor}`}
          Desc={userInfo?.name}
        />
        <Row2
          Tilte={"작성일자"}
          TitleColor={`${mainColor}`}
          Desc={format(new Date(), "yyyy-MM-dd HH:mm")}
        />
        <Row2
          Tilte={"작업구분"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.jobType}
        />
        <Row2
          Tilte={"용선주"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.charterer}
        />
        <Row2
          Tilte={"모선명"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.sonShip}
        />
        <Row2
          Tilte={"자선명"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.motherShip}
        />
        <Row2
          Tilte={"화물명"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.cargo}
        />
        <Row2
          Tilte={"수량"}
          TitleColor={`${silverColor}`}
          Desc={shipApi.amount}
        />
      </div>
    </div>
  );
}

export default UserShipLogSteamshipJournal;
