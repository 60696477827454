//선박 검사 일정

import { addMonths, format, subMonths } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { getInspectionList } from "../../../recoil/GetData";
import UserAppBar from "../common/UserAppBar";
import { Container } from "@mui/material";
import { CDateTest } from "../../../components/CDateTest";
import { mainColor } from "../../../assets/colors/color";
import ListBoxTest from "../../../components/ListBoxTest";
import ShipInspection from "../../../components/ShipInspection";
import { loginAuthenticationUser } from "../../../hooks/loginAuthentication";

function UserInspectionSchedule() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const selectDay = format(selectedDate, "yyyy-MM-dd");
  const year = format(currentMonth, "yyyy");
  const month = format(currentMonth, "MM");

  useEffect(() => {
    loginAuthenticationUser();
  }, []);

  const shipScheduleData = useRecoilValue(
    getInspectionList(`list?year=${year}&month=${month}`)
  );

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };
  return (
    <div>
      <UserAppBar />
      <Container
        maxWidth="sm"
        sx={{
          position: "relative",
          marginTop: "76px",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "20px" }}>선박 검사 일정</h1>
        <CDateTest
          edata={shipScheduleData && shipScheduleData}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          currentMonth={currentMonth}
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: `${mainColor}`,
            padding: "8px 0",
          }}
        >
          선택된 날짜 : {selectDay}{" "}
        </p>
        <ListBoxTest
          edata={shipScheduleData && shipScheduleData}
          selectedDate={selectedDate}
        />
        <ShipInspection />
      </Container>
    </div>
  );
}

export default UserInspectionSchedule;
