import React from "react";
import {
  Row2,
  RowInput,
  RowSelectDateTime,
  RowSelectListM,
  RowSelectTime,
} from "../../../../components/Row";
import { mainColor, silverColor } from "../../../../assets/colors/color";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  UnloadingExtortionAtom,
  UnloadingSafetyAtom,
  statusAtom,
} from "../../../../recoil/OptionsAtom";
import {
  OptionGetSelectorFamilly,
  getTugLsit,
} from "../../../../recoil/GetData";
import {
  DateTextValidationAtom2,
  DateTextValidationAtom3,
} from "../../../../recoil/TimeOptionsValidation";

//선박 작업일지 - 운송 양하

function ManagerShipLogTransitUnloading() {
  const status = useRecoilValue(statusAtom);
  const workplaceApi = useRecoilValue(OptionGetSelectorFamilly("berthing")); //작업장소
  const TugApi = useRecoilValue(getTugLsit); //예인선
  const certifierApi = useRecoilValue(OptionGetSelectorFamilly("certifier")); //감정사
  const loaderAndUnloaderApi = useRecoilValue(
    OptionGetSelectorFamilly("unloader") //하역사
  );

  //안전관리
  const [UnloadingSafety, setUnloadingSafety] =
    useRecoilState(UnloadingSafetyAtom);

  //강취
  const [UnloadingExtortion, setUnloadingExtortion] = useRecoilState(
    UnloadingExtortionAtom
  );

  const DateTextValidation2 = useRecoilValue(DateTextValidationAtom2);
  const DateTextValidation3 = useRecoilValue(DateTextValidationAtom3);

  return (
    <div>
      <div
        style={{
          width: "100%",
          borderBottom: `2px solid ${mainColor}`,
        }}
      >
        <RowSelectDateTime
          Tilte={"시작일시"}
          valueDateType={"TUStartDate"}
          valueTimeType={"TUnloadingStartTime"}
          disabled={status === "완료" ? true : false}
          text={DateTextValidation2}
        />
        <RowSelectDateTime
          Tilte={"완료일시"}
          valueDateType={"TUEndDate"}
          valueTimeType={"TUnloadingEndTime"}
          disabled={status === "완료" ? true : false}
          text={DateTextValidation3}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"접안장소"}
          optionTypes={"TUworkplace"}
          api={workplaceApi}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectTime
          Tilte={"접안시간"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUBerthingTimeDate"}
          valueTimeType1={"TUBerthingTime1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          Tilte={"호스연결"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUHoseConnectionDate"}
          valueTimeType1={"TUHoseConnection1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          Tilte={"작업시작"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUStartWorkDate"}
          valueTimeType1={"TUStartWork1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          Tilte={"작업완료"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUWorkCompleteDate"}
          valueTimeType1={"TUWorkComplete1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          Tilte={"호스분리"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUHoseSeparationDate"}
          valueTimeType1={"TUHoseSeparation1"}
          disabled={"disabled"}
        />
        <RowSelectTime
          Tilte={"이안시간"}
          TitleColor={`${silverColor}`}
          valueDateType={"TUIanTimeDate"}
          valueTimeType1={"TUIanTime1"}
          disabled={"disabled"}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"예인선"}
          optionTypes={"TUTug"}
          api={TugApi}
          disabled={status === "완료" ? true : false}
        />
        <Row2 Tilte={"H/RATE"} Desc="화물량/(작업완료 - 작업시작)" />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"감정사"}
          optionTypes={"tuCertifier"}
          api={certifierApi}
          disabled={status === "완료" ? true : false}
        />
        <RowSelectListM
          TitleColor={`${mainColor}}`}
          Tilte={"하역사"}
          optionTypes={"tuLoaderAndUnloader"}
          api={loaderAndUnloaderApi}
          disabled={status === "완료" ? true : false}
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"안전관리"}
          placeholder={"안전관리를 입력해 주세요."}
          disabled={status === "완료" ? true : false}
          onchangeText={(e) => setUnloadingSafety(e.target.value)}
          value={UnloadingSafety}
        />
        <RowInput
          TitleColor={`${mainColor}`}
          Tilte={"강취"}
          placeholder={"강취를 입력해 주세요."}
          disabled={status === "완료" ? true : false}
          onchangeText={(e) => setUnloadingExtortion(e.target.value)}
          value={UnloadingExtortion}
        />
      </div>
    </div>
  );
}

export default ManagerShipLogTransitUnloading;
