import React from "react";
import { Button } from "@mui/material";
import { silverColor } from "../assets/colors/color";

function InputLableButton({
  Label,
  Name,
  Placeholder,
  BtText,
  Star,
  InputType,
  value,
  handleInputChange,
  InputMessage,
  handleCheckID,
  isIdOk,
  isDisabled,
}) {
  return (
    <div style={{ width: "100%" }}>
      <label
        style={{
          marginLeft: 5,
          textAlign: "left",
          width: "100%",
          fontSize: 12,
        }}
      >
        {Label}
        {Star ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 10,
          marginTop: 10,
        }}
      >
        <input
          style={{
            width: "70%",
            padding: "15px 5px",
            border: `1px solid ${
              isIdOk === null ? silverColor : isIdOk ? "#f44336" : "#43a047"
            }`,
            borderRadius: 3,
          }}
          type={InputType}
          placeholder={Placeholder}
          name={Name}
          value={value}
          onChange={handleInputChange}
        />
        {/* Check ID Availability */}
        <Button
          sx={{ width: "30%", padding: "6px 0" }}
          variant="contained"
          color="secondary"
          onClick={handleCheckID}
          disabled={isDisabled}
        >
          {BtText}
        </Button>
      </div>
      <p style={{ fontSize: 12 }}>{InputMessage}</p>
    </div>
  );
}

export default InputLableButton;
